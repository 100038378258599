import "./time-off-pane.styl"
import template from "./time-off-pane.pug"
import { DateUtils } from "@/lib/utils/date"
import { ValidationUtils } from "@/lib/utils/validation"
import { Format as FormatUtils } from "@/lib/utils/format"
import { observable, observableArray, computed, pureComputed } from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { defaultStore } from "@/stores/default-store"
import { TimeOffStore } from "@/stores/time-off-store.core";
import { groupStore } from "@/stores/group-store"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

### Models ###
import { TimeOff } from "@/models/time-off"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

export class TimeOffPaneViewModel extends ModalPane
   constructor: (personId, editingTimeOff) ->
      assertArgs(arguments, optional(String), optional(TimeOff))
      super("Create Time Off", "Save", template())
      @personId = if personId? then personId else null
      @editingTimeOff = editingTimeOff or null
      @loadResourceOptions() if !@personId? and !@editingTimeOff?
      if @editingTimeOff?
         @setTitle("Edit Time Off")
         @setActionText("Update")
      @isEditingBatch = @editingTimeOff?.instances().length > 1
      @isActionEnabled(false)
      @nextBtnText("Occurrences")

      @resourceOptions = observableArray()
      @selectedResource = observable(null)

      @startDate = observable()
      @endDate = observable()
      @startDate.subscribe (newVal) =>
         return if @endDate()?
         @endDate(newVal)
      @timeOptions = observable(defaultStore.getTimeOptions(5))
      @batchStartTime = observable()
      @batchEndTime = observable()
      # Loop timeOptions for defaults.
      for option in @timeOptions()
         if option.value() == 7
            @batchStartTime(option)
         else if option.value() == 15.5
            @batchEndTime(option)
            break

      @defaultRepeatOption = new SegmentedControllerItem("Never", TimeOff.Repeat.NEVER)
      @repeatOptions = observableArray([
         @defaultRepeatOption,
         new SegmentedControllerItem("Weekly", TimeOff.Repeat.WEEKLY)
         new SegmentedControllerItem("Monthly", TimeOff.Repeat.MONTHLY)
         new SegmentedControllerItem("Yearly", TimeOff.Repeat.YEARLY)
         ])
      @selectedRepeatOption = observable(@defaultRepeatOption)
      @reasonOptions = observableArray(defaultStore.getTimeOffReasons())
      @showOtherReasonInput = observable(false)
      @otherInputReason = observable()
      @selectedReason = observable()
      @selectedReason.subscribe (newVal) =>
         return @showOtherReasonInput(false) unless newVal?
         if newVal.value() == "other"
            @showOtherReasonInput(true)
         else
            @showOtherReasonInput(false)
      @defaultTypeOption = new SegmentedControllerItem("Paid", "paid")
      @typeOptions = observableArray([
         @defaultTypeOption
         new SegmentedControllerItem("Unpaid", "unpaid")
         ])
      @selectedType = observable(@defaultTypeOption)
      @cadence = observable(1)
      @cadenceClassifier = pureComputed =>
         return switch @selectedRepeatOption().value()
            when "weekly"
               return "Weeks" if @cadence() > 1
               return "Week"
            when "monthly"
               return "Months" if @cadence() > 1
               return "Month"
            when "yearly"
               return "Years" if @cadence() > 1
               return "Year"
      @endRepeatingDate = observable()
      @includeSaturday = observable(false)
      @includeSunday = observable(false)

      # TODO: This is gross. Clean it up.
      computed =>
         if @editingTimeOff?
            if (
               ((@editingTimeOff.instances().length == 1) and
               (@startDate()? and @editingTimeOff.instances()[0].startDay() != DateUtils.getDetachedDay(@startDate()))) or
               ((@editingTimeOff.instances().length == 1) and
               (@endDate()? and @editingTimeOff.instances()[0].endDay() != DateUtils.getDetachedDay(@endDate()))) or
               (@editingTimeOff.batchStartTime() != @batchStartTime()?.value()) or
               (@editingTimeOff.batchEndTime() != @batchEndTime()?.value()) or
               (@editingTimeOff.isPaid() != (@selectedType()?.value() == "paid")) or
               (@editingTimeOff.applyToSaturday() != @includeSaturday()) or
               (@editingTimeOff.applyToSunday() != @includeSunday())
               )
               @isActionEnabled(true)
            else if @selectedReason()?
               if @selectedReason().value() == "other"
                  @isActionEnabled(@editingTimeOff.reason() != @otherInputReason())
               else if @editingTimeOff.reason() != @selectedReason().value()
                  @isActionEnabled(true)
               else
                  @isActionEnabled(false)
            else
               @isActionEnabled(false)
         else
            if @personId?
               @isActionEnabled(@startDate()? and @endDate()? and @selectedReason()?)
            else
               @isActionEnabled(@startDate()? and @endDate()? and @selectedReason()? and @selectedResource()?)

      @loadEditingValues() if @editingTimeOff?

   selectEndAfterOccurence: =>
      @endOnDate(false)

   selectEndOnDate: =>
      @endAfterOccurences(false)

   getRepeatEndDate: =>
      return "" unless @endRepeatingDate()?
      weekDay = DateUtils.getWeekDayAbbreviation(@endRepeatingDate())
      month = DateUtils.getMonth(@endRepeatingDate())
      date = @endRepeatingDate().getDate()
      year = @endRepeatingDate().getFullYear()
      return "#{weekDay}. #{month} #{date}, #{year}"

   loadResourceOptions: ->
      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities authManager.selectedGroupId(), ['people'], (err, data) =>
         return console.log "error: ", err if err
         @resourceOptions(FormatUtils.keyableSort(data.peopleOptions, 'name'))

   loadEditingValues: ->
      if @editingTimeOff.instances().length == 1
         @startDate(DateUtils.getAttachedDate(@editingTimeOff.instances()[0].startDay()))
         @endDate(DateUtils.getAttachedDate(@editingTimeOff.instances()[0].endDay()))

      unless @editingTimeOff.batchStartTime() == 7
         for option in @timeOptions()
            if option.value() == @editingTimeOff.batchStartTime()
               @batchStartTime(option)
               break
      unless @editingTimeOff.batchEndTime() == 15.5
         for option in @timeOptions()
            if option.value() == @editingTimeOff.batchEndTime()
               @batchEndTime(option)
               break

      for option in @repeatOptions()
         if option.value() == @editingTimeOff.repeat()
            @selectedRepeatOption(option)
            break

      foundReason = false
      for option in @reasonOptions()
         if option.value() == @editingTimeOff.reason()
            foundReason = true
            @selectedReason(option)
            break
      unless foundReason
         @otherInputReason(@editingTimeOff.reason())
         for option in @reasonOptions()
            if option.value() == "other"
               @selectedReason(option)
               break

      for option in @typeOptions()
         editingType = if @editingTimeOff.isPaid() then "paid" else "unpaid"
         if option.value() == editingType
            @selectedType(option)
            break

      unless @editingTimeOff.repeat() == TimeOff.Repeat.NEVER
         @cadence(@editingTimeOff.cadence())
         @endRepeatingDate(DateUtils.getAttachedDate(@editingTimeOff.repeatEndDay()))
         for option in @repeatOptions()
            if option.value() == @editingTimeOff.repeat()
               @selectedRepeatOption(option)
               break

   actionBtnIntercept: (next) =>
      assertArgs(arguments, Function)
      if @batchEndTime().value() <= @batchStartTime().value()
         return @displayingNotice(TimeOffPaneViewModel.Notice.INVALID_END_TIME)
      return @saveUpdate(next) if @editingTimeOff?

      # Save new specific checks.
      if @selectedRepeatOption().value() != TimeOff.Repeat.NEVER
         if @endRepeatingDate()?
            if @endRepeatingDate().getTime() < @endDate().getTime()
               return @displayingNotice(TimeOffPaneViewModel.Notice.INVALID_REPEAT_END_DATE)
            else if @endRepeatingDate().getFullYear() - @startDate().getFullYear() > 5
               return @displayingNotice(TimeOffPaneViewModel.Notice.FIVE_YEAR_LIMIT)
            else if @endRepeatingDate().getFullYear() - @startDate().getFullYear() == 5
               if @endRepeatingDate().getMonth() > @startDate().getMonth()
                  return @displayingNotice(TimeOffPaneViewModel.Notice.FIVE_YEAR_LIMIT)
         else
            return @displayingNotice(TimeOffPaneViewModel.Notice.NO_REPEAT_END)

      switch @selectedRepeatOption().value()
         when "weekly"
            if DateUtils.daysBetweenMs(@startDate().getTime(), @endDate().getTime()) > 6
               return @displayingNotice(TimeOffPaneViewModel.Notice.INVALID_WEEK_RANGE)
         when "monthly"
            if DateUtils.daysBetweenMs(@startDate().getTime(), @endDate().getTime()) > 27
               return @displayingNotice(TimeOffPaneViewModel.Notice.EXCESSIVE_MONTH_RANGE)
            if @startDate().getMonth() != @endDate().getMonth()
               return @displayingNotice(TimeOffPaneViewModel.Notice.INVALID_MONTH_RANGE)
         when "yearly"
            if DateUtils.daysBetweenMs(@startDate().getTime(), @endDate().getTime()) > 364
               return @displayingNotice(TimeOffPaneViewModel.Notice.EXCESSIVE_RANGE)
            if @startDate().getFullYear() != @endDate().getFullYear()
               return @displayingNotice(TimeOffPaneViewModel.Notice.INVALID_YEAR_RANGE)
      @saveNew(next)

   saveNew: (next) =>
      assertArgs(arguments, Function)
      @displayingNotice(TimeOffPaneViewModel.Notice.SAVING)
      timeOffData = {
         person_id: if @personId? then @personId else @selectedResource().value(),
         batch_start_time: @batchStartTime().value(),
         batch_end_time: @batchEndTime().value(),
         is_paid: @selectedType().value() == "paid",
         apply_to_saturday: @includeSaturday(),
         apply_to_sunday: @includeSunday(),
         repeat: @selectedRepeatOption().value(), # weekly, monthly, yearly, never
         # Start and end day should match only the first instance of the time off, not the min/max of every instance.
         # This is because of the way that start/end day is used in tandem with the "repeat", "cadence", and "repeat_end_day" fields.
         #
         # When we have a repeating time off, the first instance of that time-off is always the start & end date that was
         # provided in the modal, and then "repeat" and "cadence" are used on the back end to increment both the start and end dates to generate
         # each instance until you reach the "repeat_end_day".
         start_day: DateUtils.getDetachedDay(@startDate()),
         end_day: DateUtils.getDetachedDay(@endDate()),
      };

      if @selectedRepeatOption().value() != 'never'
         timeOffData['cadence'] = Number(@cadence()) # if repeat is "weekly", then cadence is how many weeks before repeating. eg. "repeat cadence of every 2 weeks"
         timeOffData['repeat_end_day'] = DateUtils.getDetachedDay(@endRepeatingDate())

      if @selectedReason().value() == "other"
         if ValidationUtils.validateInput(@otherInputReason())
            timeOffData['reason'] = @otherInputReason()
         else
            timeOffData['reason'] = "other"
      else
         timeOffData['reason'] = @selectedReason().value()

      timeOff = await TimeOffStore.createTimeOff(timeOffData).payload
      @displayingNotice(null)
      @data.set("timeOff", new TimeOff(timeOff.data))
      next() 

   saveUpdate: (next) =>
      assertArgs(arguments, Function)
      timeOffData = {}
      singleInstance = {}
      if @startDate()? and (@editingTimeOff.instances().length == 1) and
      (@editingTimeOff.instances()[0].startDay() != DateUtils.getDetachedDay(@startDate()))
         singleInstance['id'] = @editingTimeOff.instances()[0].id
         singleInstance['start_day'] = DateUtils.getDetachedDay(@startDate())
      if @endDate()? and (@editingTimeOff.instances().length == 1) and
      (@editingTimeOff.instances()[0].endDay() != DateUtils.getDetachedDay(@endDate()))
         singleInstance['id'] = @editingTimeOff.instances()[0].id unless singleInstance.id?
         singleInstance['end_day'] = DateUtils.getDetachedDay(@endDate())
      if Object.keys(singleInstance).length != 0
         timeOffData['instances'] = [singleInstance]
      if @batchStartTime().value() != @editingTimeOff.batchStartTime()
         timeOffData['batch_start_time'] = @batchStartTime().value()
      if @batchEndTime().value() != @editingTimeOff.batchEndTime()
         timeOffData['batch_end_time'] = @batchEndTime().value()

      editingType = if @editingTimeOff.isPaid() then "paid" else "unpaid"
      if @selectedType().value() != editingType
         timeOffData['is_paid'] = @selectedType().value() == "paid"
      if @editingTimeOff.applyToSaturday() != @includeSaturday()
         timeOffData['apply_to_saturday'] = @includeSaturday()
      if @editingTimeOff.applyToSunday() != @includeSunday()
         timeOffData['apply_to_sunday'] = @includeSunday()

      if @selectedReason()?.value() == "other"
         if @editingTimeOff.reason() != @otherInputReason()
            if ValidationUtils.validateInput(@otherInputReason())
               timeOffData['reason'] = @otherInputReason()
            else
                  timeOffData['reason'] = "other"

      else if @editingTimeOff.reason() != @selectedReason().value()
         timeOffData['reason'] = @selectedReason().value()

      unless Object.keys(timeOffData).length == 0
         if (timeOffData.instances)
            timeOffData['start_day'] = DateUtils.getDetachedDay(@startDate());
            timeOffData['end_day'] = DateUtils.getDetachedDay(@endDate());

         payload = await TimeOffStore.updateTimeOff(@editingTimeOff.id, timeOffData).payload
         @displayingNotice(null)
         @data.set("timeOff", new TimeOff(payload.data))
         next() 

   deleteTimeOff: ->
      return unless @editingTimeOff?
      @data.set("timeOffRemoveId", @editingTimeOff.id)
      modalManager.modalFinished()

TimeOffPaneViewModel.Notice = {
   INVALID_CADENCE: {
      text: 'The "Every" value needs to be a whole number larger than 1'
      info: null
      color: 'red'
      dissmissable: true
   }
   INVALID_END_DATE: {
      text: 'Your end date must be after your start date'
      info: null
      color: 'red'
      dissmissable: true
   }
   INVALID_END_TIME: {
      text: 'Your daily end time must be after your daily start time'
      info: null
      color: 'red'
      dissmissable: true
   }
   INVALID_WEEK_RANGE: {
      text: 'Your selected date range needs to be 7 days or less when repeating weekly'
      info: null
      color: 'red'
      dissmissable: true
   }
   EXCESSIVE_MONTH_RANGE: {
      text: 'Your selected date range needs to be 28 days or less when repeating monthly'
      info: null
      color: 'red'
      dissmissable: true
   }
   INVALID_MONTH_RANGE: {
      text: 'When repeating monthly, your start and end date need to be in the same month.'
      info: null
      color: 'red'
      dissmissable: true
   }
   INVALID_YEAR_RANGE: {
      text: 'When repeating yearly, your start and end date need to be in the same year.'
      info: null
      color: 'red'
      dissmissable: true
   }
   EXCESSIVE_RANGE: {
      text: 'You can not make time off for longer than a year'
      info: null
      color: 'red'
      dissmissable: true
   }
   FIVE_YEAR_LIMIT: {
      text: 'Your repeating end date can not be longer than 5 years from the start date.'
      info: null
      color: 'red'
      dissmissable: true
   }
   NO_REPEAT_END: {
      text: 'You must specify an end date for repeating time off.'
      info: null
      color: 'red'
      dissmissable: true
   }
   INVALID_REPEAT_END_DATE: {
      text: 'You repeating end date must be after you time off start/end dates.'
      info: null
      color: 'red'
      dissmissable: true
   }
   SAVING: {
      text: 'Saving...'
      info: null
      color: 'green'
      dissmissable: false
   }
}
