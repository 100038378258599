import "./person-detail.styl";
import template from "./person-detail.pug";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { FanoutManager, fanoutManager } from "@/lib/managers/fanout-manager";
import type { Observable, ObservableArray, PureComputed } from "knockout";
import { unwrap } from "knockout";
import { observable, observableArray, pureComputed } from "knockout";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import { authManager } from "@/lib/managers/auth-manager";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import { DropDownItem } from "@/lib/components/drop-downs/drop-down";
import type {
   AuthType,
   IdListRecordUpdate,
   UpdatePersonPayload,
} from "@laborchart-modules/lc-core-api";
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller";
import type {
   ActivityCategory,
   SupportedLanguage,
} from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down";
import { ValidationUtils } from "@/lib/utils/validation";
import { Url as UrlUtils } from "@/lib/utils/url";
import type { UpdateCustomFieldInstancePayload } from "@laborchart-modules/common/dist/api/custom-field-instances/update-custom-field-instances";
import { DateUtils } from "@/lib/utils/date";
import { EditNotePaneViewModel } from "@/lib/components/modals/edit-note-pane";
import { Modal } from "@/lib/components/modals/modal";
import { modalManager } from "@/lib/managers/modal-manager";
import type { UpdateNotePayload } from "@laborchart-modules/lc-core-api/dist/api/notes/update-note";
import { ConfirmActionPaneViewModel } from "@/lib/components/modals/confirm-action-pane";
import type { UpdateTagInstancePayload } from "@laborchart-modules/common/dist/api/tag-instances/update-tag-instances";
import { TimeOffPaneOccurrencesViewModel } from "./modals/time-off-occurrences-pane";
import { ProgressNotification } from "@/notifications/progress-notification";
import { notificationManagerInstance } from "@/lib/managers/notification-manager";
import type { IntegratedField } from "@laborchart-modules/common/dist/rethink/schemas/companies";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { CustomFieldType } from "@laborchart-modules/common/dist/rethink/schemas/common/custom-field-type";
import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import {
   FindTimeOffFilter,
   FindTimeOffSortBy,
} from "@laborchart-modules/common/dist/reql-builder/procedures/enums/find-time-off";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { TimeOffPaneViewModel } from "./modals/time-off-pane";
import { ConfirmDeletePersonPaneViewModel } from "@/lib/components/modals/confirm-delete-person-pane";
import type { ObservableData } from "@/lib/utils/observable-data";
import type { FindActivityPaginatedQueryParams } from "@laborchart-modules/lc-core-api/dist/api/activity/find-activity";
import type { CustomFieldInstance } from "@laborchart-modules/common/dist/postgres/schemas/common/custom-json";
import { Popup } from "@/lib/components/popup/popup";
import { AddAttachmentPane } from "@/views/person-detail/popup/add-attachments-pane";
import { Format } from "@/lib/utils/format";
import { router } from "@/lib/router";
import renderReactComponent from "@/react/render-react-component";

// Models
import { Person } from "@/models/person";
import type { Note } from "@/models/note";
import { PermissionLevel } from "@/models/permission-level";
import { TimeOff } from "@/models/time-off";
import type { LegacyCustomFieldInstance } from "@/models/custom-field-instance";
import { CustomField } from "@/models/custom-field";
import { Activity } from "@/models/activity";
import type { Attachment } from "@/models/attachment";
import { Tag } from "@/models/tag";
import { ValueSet } from "@/models/value-set";

// Stores
import { ActivityStore } from "@/stores/activity-store.core";
import { CompanyStore } from "@/stores/company-store.core";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import { DefaultStoreCore } from "@/stores/default-store.core";
import { GroupStore } from "@/stores/group-store.core";
import { NoteStore } from "@/stores/note-store.core";
import { NotificationProfileStore } from "@/stores/notification-profile-store.core";
import { PermissionStore } from "@/stores/permission-store.core";
import { PersonStore } from "@/stores/person-store.core";
import { PositionStore } from "@/stores/position-store.core";
import { TagStore } from "@/stores/tag-store.core";
import { TimeOffStore } from "@/stores/time-off-store.core";
// Legacy Stores
import { defaultStore } from "@/stores/default-store";
import type { FilteredTimeoff } from "@/stores/time-off-store";
import {
   peopleStore as legacyPersonStore,
   PeopleStore as LegacyPersonStore,
} from "@/stores/people-store";
import { InviteStore } from "@/stores/invite-store.core";

import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser";
import { requestContext } from "@/stores/common/request-context";
import { mutateForTimezone } from "@/lib/utils/date-2";

export type EditingPerson = {
   readonly id: string;
   readonly accessGroupIds: ObservableArray<string>;
   readonly address1: Observable<string | null>;
   readonly address2: Observable<string | null>;
   readonly assignableGroupIds: ObservableArray<string>;
   readonly canRecieveEmail: Observable<boolean>;
   readonly canRecieveMobile: Observable<boolean>;
   readonly canRecieveSms: Observable<boolean>;
   readonly cityTown: Observable<string | null>;
   readonly companyId: Observable<string>;
   readonly country: Observable<string | null>;
   readonly customFields: ObservableArray<LegacyCustomFieldInstance> | null;
   readonly dob: Observable<number | null>;
   readonly email: Observable<string | null>;
   readonly emergencyContactEmail: Observable<string | null>;
   readonly emergencyContactName: Observable<string | null>;
   readonly emergencyContactNumber: Observable<string | null>;
   readonly emergencyContactRelation: Observable<string | null>;
   readonly employeeNumber: Observable<string | null>;
   readonly failedLoginCount: Observable<number>;
   readonly firstName: Observable<string>;
   readonly groupIds: ObservableArray<string>;
   readonly hiredDate: Observable<number | null>;
   readonly hourlyWage: Observable<number | null>;
   readonly invitePending: Observable<boolean>;
   readonly isAssignable: Observable<boolean>;
   readonly isMale: Observable<boolean | null>;
   readonly isUser: Observable<boolean>;
   readonly language: Observable<string>;
   readonly lastName: Observable<string>;
   readonly notes: ObservableArray<Note>;
   readonly notificationProfileId: Observable<string | null>;
   readonly permissionLevel: Observable<PermissionLevel | null>;
   readonly permissionLevelId: Observable<string | null>;
   readonly phone: Observable<string | null>;
   readonly positionId: Observable<string | null>;
   readonly profilePicUrl: Observable<string | null>;
   readonly signature: Observable<string | null>;
   readonly stateProvince: Observable<string | null>;
   readonly status: Observable<PersonStatus>;
   readonly zipcode: Observable<string | null>;
};

export type PersonDetailNotice = {
   text: string;
   color: string;
   info: string | null;
   dissmissable: boolean;
};

export type EditingNoteType = {
   content: Observable<string>;
   isPrivate: Observable<boolean>;
   attachments: ObservableArray<SerializedAttachment>;
};

type SelectedActivityCategory = "all" | ActivityCategory;

class PersonDetailTimeoffState {
   readonly person: Observable<Person | null>;
   readonly userPermissions: Record<string, boolean>;
   readonly timeOff: ObservableArray<TimeOff> = observableArray();

   constructor(person: Observable<Person | null>, userPermissions: Record<string, boolean>) {
      this.person = person;
      this.userPermissions = userPermissions;
   }

   showTimeOffModal = () => {
      if (!this.userPermissions.canViewPeopleTimeoff) return;
      const pane1 = new TimeOffPaneViewModel(this.person()!.id);
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal<{ timeOff: TimeOff }>(
         modal,
         null,
         {},
         (modal, modalStatus, observableData) => {
            if (modalStatus == "cancelled") return;
            this.timeOff.unshift(observableData.data.timeOff);
         },
      );
   };

   editTimeOff = (timeOff: FilteredTimeoff) => {
      if (!this.userPermissions.canEditPeopleTimeoff) return;
      const timeOffPane: Array<TimeOffPaneViewModel | TimeOffPaneOccurrencesViewModel> = [
         new TimeOffPaneViewModel(this.person()!.id, timeOff),
      ];
      if (timeOff.instances().length > 1) {
         timeOffPane.push(new TimeOffPaneOccurrencesViewModel(this.person()!.id, timeOff));
      }
      const editTimeOffModal = new Modal();
      editTimeOffModal.setPanes(timeOffPane);
      modalManager.showModal<{ timeOff?: TimeOff; timeOffRemoveId?: string }>(
         editTimeOffModal,
         null,
         {},
         (editTimeOffModal, modalStatus, observableData) => {
            if (modalStatus == "cancelled") return;
            if (observableData.data.timeOffRemoveId) {
               this.deleteTimeOff(observableData);
            } else {
               // Replace edited time off.
               for (const [index, value] of Object.entries(this.timeOff())) {
                  if (value.id == timeOff.id) {
                     this.timeOff.splice(Number(index), 1, observableData.data.timeOff!);
                     break;
                  }
               }
            }
         },
      );
   };

   deleteTimeOff = (
      observableData: ObservableData<{
         timeOff?: TimeOff | undefined;
         timeOffRemoveId?: string | undefined;
      }>,
   ) => {
      const confirmActionPane = [
         new ConfirmActionPaneViewModel(
            "Delete",
            "Deleting will remove all occurences of this time off from schedules.",
         ),
      ];
      const deleteTimeOffModal = new Modal();
      deleteTimeOffModal.setPanes(confirmActionPane);
      modalManager.showModal(
         deleteTimeOffModal,
         null,
         { class: "confirm-action-modal" },
         async (deleteTimeOffModal, modalStatus) => {
            if (modalStatus == "cancelled") return;
            const timeOffIdToRemove = observableData.data.timeOffRemoveId!;
            try {
               await TimeOffStore.deleteTimeOff(timeOffIdToRemove).payload;
            } catch (err) {
               return console.log("Error: ", err);
            }
            for (const value of this.timeOff()) {
               if (value.id == timeOffIdToRemove) {
                  this.timeOff.remove(value);
                  break;
               }
            }
         },
      );
   };
}

class PersonDetailAttachmentState {
   readonly person: Observable<Person | null>;
   readonly userPermissions: Record<string, boolean>;
   readonly attachments: ObservableArray<Attachment> = observableArray();
   constructor(person: Observable<Person | null>, userPermissions: Record<string, boolean>) {
      this.person = person;
      this.userPermissions = userPermissions;
   }

   loadAttachments = () => {
      this.attachments(this.person()!.attachments());
   };

   saveAttachments = async (err: Error) => {
      if (err) return console.log("Error: ", err);
      if (!this.userPermissions.canEditPeopleAttachments) return;
      try {
         await PersonStore.updatePerson(this.person()!.id, {
            attachment_ids: this.attachments().map((a) => a.id),
         }).payload;
      } catch (e) {
         return console.log("Error: ", e);
      }
   };
}

class PersonDetailTagState {
   readonly person: Observable<Person | null>;
   readonly userPermissions: Record<string, boolean>;

   readonly tagGroupEditing: Observable<boolean> = observable(false);
   readonly isAddingNewTag: Observable<boolean> = observable(false);
   readonly editTagTrayOpen: Observable<boolean> = observable(false);
   readonly editingTag: Observable<Tag | null> = observable(null);
   readonly editingTagRequiresExpr: Observable<boolean> = observable(false);
   readonly editingTagExprDate: Observable<Date | null> = observable(null);
   readonly editingTagsAttachments: ObservableArray<Attachment> = observableArray();
   readonly editingTagChangesToSave: PureComputed<boolean> = pureComputed(() =>
      !this.editingTag()?.requireExprDate() || !this.editingTagExprDate()
         ? false
         : this.editingTag()?.exprDate() != DateUtils.getDetachedDay(this.editingTagExprDate()!),
   );
   readonly allTags: ObservableArray<Tag> = observableArray();
   readonly selectedTagOption: Observable<ValueSet<string> | null> = observable(null);
   readonly appliedTagIds: ObservableArray<string> = observableArray();
   readonly tagOptions: PureComputed<Array<ValueSet<string>>> = pureComputed(() => {
      const tagOptions = [];
      for (const tag of this.allTags()) {
         if (this.appliedTagIds().indexOf(tag.id) == -1) {
            tagOptions.push(new ValueSet({ name: tag.name(), value: tag.id }));
         }
      }
      return tagOptions;
   });
   readonly categorizedTags: Observable<Record<string, Array<ValueSet<string>>> | null> =
      observable(null);
   readonly availableCategorizedTags: PureComputed<Record<string, Array<ValueSet<string>>> | null> =
      pureComputed(() => {
         const data: Record<string, Array<ValueSet<string>>> = {};
         if (this.categorizedTags()) {
            for (const [category, tags] of Object.entries(this.categorizedTags()!)) {
               const availableTags = tags.filter((tag) => {
                  return this.appliedTagIds().indexOf(tag.value()) == -1;
               });
               if (availableTags.length > 0) {
                  data[category] = availableTags;
               }
            }
            return data;
         } else {
            return null;
         }
      });

   constructor(person: Observable<Person | null>, permissions: Record<string, boolean>) {
      this.person = person;
      this.userPermissions = permissions;
   }

   toggleTagGroupEditing = () => {
      if (!this.userPermissions.canEditPeopleTags) return;
      if (this.isAddingNewTag()) {
         this.tagGroupEditing(false);
      } else {
         this.tagGroupEditing(!this.tagGroupEditing());
      }
      this.isAddingNewTag(false);
      this.editTagTrayOpen(false);
      this.editingTag(null);
      this.editingTagRequiresExpr(false);
      this.editingTagExprDate(null);
   };

   newTag = () => {
      if (!this.userPermissions.canEditPeopleTags) return;
      this.editingTag(null);
      this.editingTagsAttachments([]);
      this.isAddingNewTag(true);
      this.editTagTrayOpen(true);
      this.tagGroupEditing(false);
   };

   selectNewTag = () => {
      if (!this.userPermissions.canEditPeopleTags) return;
      const selectedTagOption = this.selectedTagOption();
      for (const tag of this.allTags()) {
         if (selectedTagOption == null) return;
         if (tag.id == selectedTagOption.value()) {
            this.editingTagRequiresExpr(tag.requireExprDate());
            this.editingTag(tag);
            break;
         }
      }
   };

   deleteTag = (tag: Tag) => {
      if (!this.userPermissions.canEditPeopleTags) return;
      const pane1 = new ConfirmActionPaneViewModel("Remove Tag", null, "Confirm Deletion");
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal(
         modal,
         null,
         { class: "confirm-action-modal" },
         async (modal, exitStatus) => {
            if (exitStatus != "finished") return;
            try {
               await PersonStore.updatePerson(this.person()!.id, {
                  tag_instances: { [tag.id]: null },
               }).payload;
               this.editTagTrayOpen(false);
               this.editingTag(null);
            } catch (err) {
               return console.log("Error: ", err);
            }
         },
      );
   };

   saveNewTag = async () => {
      if (!this.userPermissions.canEditPeopleTags) return;
      this.appliedTagIds.push(this.editingTag()!.id);

      const tagInstance: UpdateTagInstancePayload = {
         attachment_ids: this.editingTagsAttachments().map((attachment) => attachment.id),
      };

      if (this.editingTagRequiresExpr()) {
         if (this.editingTagExprDate()) {
            const detachedDay = DateUtils.getDetachedDay(this.editingTagExprDate()!);
            tagInstance["expr_date"] = detachedDay;
            this.editingTag()!.exprDate(detachedDay);
         } else {
            return;
         }
      }
      try {
         await PersonStore.updatePerson(this.person()!.id, {
            tag_instances: {
               [this.editingTag()!.id]: tagInstance,
            },
         }).payload;
         this.isAddingNewTag(false);
         this.editTagTrayOpen(false);
         this.editingTagRequiresExpr(false);
         this.editingTagExprDate(null);
         this.editingTagsAttachments();
         this.selectedTagOption(null);
      } catch (err) {
         return console.log("Error: ", err);
      }
   };

   updateTag = async () => {
      if (!this.userPermissions.canEditPeopleTags) return;
      if (
         this.editingTagExprDate() &&
         this.editingTag()!.exprDate() != DateUtils.getDetachedDay(this.editingTagExprDate()!)
      ) {
         const tagInstanceUpdate = {
            expr_date: DateUtils.getDetachedDay(this.editingTagExprDate()!),
         };
         try {
            await PersonStore.updatePerson(this.person()!.id, {
               tag_instances: { [this.editingTag()!.id]: tagInstanceUpdate },
            }).payload;
            this.editingTag()!.exprDate(DateUtils.getDetachedDay(this.editingTagExprDate()!));
         } catch (err) {
            return console.log("Error: ", err);
         }
      }
      this.closeEditingTag();
   };

   editTag = (tag: Tag) => {
      if (!this.userPermissions.canEditPeopleTags) return;
      this.editingTagsAttachments(tag.attachments());
      if (tag.requireExprDate()) {
         this.editingTagRequiresExpr(true);
         this.editingTagExprDate(
            tag.exprDate() ? DateUtils.getAttachedDate(tag.exprDate()!) : null,
         );
      } else {
         this.editingTagRequiresExpr(false);
         this.editingTagExprDate(null);
      }
      this.editingTag(tag);
      this.editTagTrayOpen(true);
   };

   updateAttachmentTags = async (err: Error) => {
      if (err) return console.log("Error: ", err);
      if (!this.userPermissions.canEditPeopleTags) return;
      const attachmentIds = this.editingTagsAttachments().map((attachment) => attachment.id);
      try {
         await PersonStore.updatePerson(this.person()!.id, {
            tag_instances: { [this.editingTag()!.id]: { attachment_ids: attachmentIds } },
         }).payload;
      } catch (err) {
         return console.log("Error: ", err);
      }
   };

   closeEditingTag = () => {
      this.isAddingNewTag(false);
      this.editTagTrayOpen(false);
      this.editingTag(null);
      this.editingTagRequiresExpr(false);
      this.editingTagExprDate(null);
      this.editingTagsAttachments([]);
   };

   getExprRequiredTagClass = (tag: Tag) => {
      if (!tag.exprDate()) return "";
      const detachedNow = DateUtils.getDetachedDay(new Date());
      if (tag.exprDate()! <= detachedNow) {
         return "icon-warning-triangle";
      } else {
         const daysBetween = DateUtils.getDaysBetweenDetachedDays(detachedNow, tag.exprDate()!);
         return daysBetween <= tag.exprDaysWarning()! ? "icon-caution-triangle" : "";
      }
   };
}

class PersonDetailNoteState {
   readonly person: Observable<Person | null>;
   readonly userPermissions: Record<string, boolean>;
   readonly editingNote: Observable<EditingNoteType> = observable({
      content: observable(""),
      isPrivate: observable(false),
      attachments: observableArray<SerializedAttachment>(),
   });
   readonly updatePerson: () => Promise<void>;
   readonly canSaveNewNote: PureComputed<boolean> = pureComputed(() => {
      return ValidationUtils.validateInput(this.editingNote().content());
   });

   constructor(
      person: Observable<Person | null>,
      permissions: Record<string, boolean>,
      updatePerson: () => Promise<void>,
   ) {
      this.person = person;
      this.userPermissions = permissions;
      this.updatePerson = updatePerson;
   }

   canShowEditButton = (note: Note) => {
      return this.userPermissions.canEditPeopleNotes
         ? true
         : note.authorId() == authManager.authedUser()?.id;
   };

   createNewNote = async () => {
      if (!this.userPermissions.canEditPeopleNotes) return;
      const editingNote = this.editingNote();
      if (editingNote != null && editingNote.content() != "") {
         const noteData = {
            content: editingNote.content(),
            is_private: editingNote.isPrivate(),
            attachment_ids: editingNote.attachments().map((attachment) => attachment.id),
         };
         try {
            await NoteStore.createNote(noteData, { person_id: this.person()!.id }).payload;
         } catch (err) {
            return console.error("PersonDetailNotesState createNewNote - Error: ", err);
         }
         this.editingNote({
            content: observable(""),
            isPrivate: observable(false),
            attachments: observableArray<SerializedAttachment>(),
         });
         this.updatePerson();
      }
   };

   getNoteFileCount = (note: Note) => {
      return note.attachments().length == 1
         ? `${note.attachments().length} Attachment`
         : `${note.attachments().length} Attachments`;
   };

   getNoteDetails = (note: Note) => {
      const lastEdited = new Date(note.lastEdited()!);
      const formattedDate = DateUtils.formatDate(lastEdited, defaultStore.getDateFormat(), {
         weekdayFormat: DateUtils.WeekDayFormat.ABBREV,
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         yearFormat: DateUtils.YearFormat.FULL,
      });
      return `${formattedDate} at ${DateUtils.getTime(lastEdited)}`;
   };

   editNote = async (note: Note) => {
      if (!this.userPermissions.canEditPeopleNotes || !note.lastEdited()) return;
      const pane1 = new EditNotePaneViewModel(this.person()!.id, "person", note);
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal<{
         content: string;
         isPrivate: boolean;
         attachments: Attachment[];
      }>(modal, null, { class: "edit-note-modal" }, async (modal, modalStatus, observableData) => {
         if (modalStatus == "cancelled") return;
         const noteUpdate = observableData.data;
         if (Object.keys(noteUpdate).length > 0) {
            const updateData: UpdateNotePayload = {};
            if (noteUpdate.attachments != note.attachments()) {
               updateData.attachment_ids = noteUpdate.attachments.map((a) => a.id);
            }
            if (noteUpdate.content != note.content()) {
               updateData.content = noteUpdate.content;
            }
            if (noteUpdate.isPrivate != note.isPrivate()) {
               updateData.is_private = noteUpdate.isPrivate;
            }
            if (Object.keys(updateData).length > 0) {
               try {
                  await NoteStore.updateNote(note.id, { person_id: this.person()!.id }, updateData)
                     .payload;
               } catch (err) {
                  return console.error("PersonDetailNotesState editNote - Error: ", err);
               }
            }
         }
         this.updatePerson();
      });
   };
}

class PersonDetailActivityState {
   private isLoadingActivities = observable<boolean>(true);
   readonly selectedActivityCategory = observable<SelectedActivityCategory>("all");
   private readonly disableLoadMoreActivity = observable(false);
   private readonly activity = observableArray<Activity>();
   private readonly activityDepth = observable(0);
   // For Core Activity
   private readonly nextStartingAfter = observable<string | null>(null);

   constructor(private readonly personId: string) {}

   private getPersonActivity = async (
      depth: number,
      category: SelectedActivityCategory,
      loadMore: boolean,
   ): Promise<void> => {
      this.isLoadingActivities(true);
      this.selectedActivityCategory(category);
      if (!loadMore) {
         this.activity([]);
         this.disableLoadMoreActivity(false);
      }

      const query: FindActivityPaginatedQueryParams = {
         entity_id: this.personId,
         entity_type: "people" as FindActivityPaginatedQueryParams["entity_type"],
         limit: 25,
      };
      if (loadMore) {
         query["starting_after"] = this.nextStartingAfter() ?? undefined;
      }
      if (category !== "all") {
         query["included_categories"] = [category];
      }
      try {
         const results = await ActivityStore.findActivityPaginated(query).payload;
         for (const activity of results.data) {
            this.activity.push(new Activity(activity));
         }
         this.nextStartingAfter(results.pagination.next_starting_after);
         if (
            this.nextStartingAfter() == null ||
            results.pagination.total_possible === this.activity().length
         ) {
            this.disableLoadMoreActivity(true);
         }
      } catch (error) {
         return console.log(error);
      }
      this.isLoadingActivities(false);
   };

   private updateActivityCategorySelection(category: SelectedActivityCategory) {
      if (this.selectedActivityCategory() === category) return;
      this.selectedActivityCategory(category);
      this.nextStartingAfter(null);
      return fanoutManager.updateListenerData(
         {
            personId: this.personId,
            category: category,
         },
         "vm.PersonDetailViewModel",
         FanoutManager.Channel.PERSON_ACTIVITY,
         {
            personId: this.personId,
         },
         (err) => {
            if (err) {
               return console.log("error: ", err);
            }
            this.getPersonActivity(this.activityDepth(), this.selectedActivityCategory(), false);
         },
      );
   }

   subscribeToPersonActivity(forceRequest: boolean = false) {
      return legacyPersonStore.subscribeToPersonActivity(
         {
            personId: this.personId,
            category: this.selectedActivityCategory(),
         },
         "vm.PersonDetailViewModel",
         forceRequest,
         (err, activity) => {
            if (err) {
               return console.log("error: ", err);
            }
            this.activity(activity);
            this.nextStartingAfter(activity.at(-1)?.id ?? null);
            this.isLoadingActivities(false);
         },
      );
   }

   private loadMoreActivity = async () => {
      this.isLoadingActivities(true);
      this.activityDepth(this.activityDepth() + 40);
      const personActivity = await this.getPersonActivity(
         this.activityDepth(),
         this.selectedActivityCategory(),
         true,
      );
      this.isLoadingActivities(false);
      return personActivity;
   };

   private getActivityIcon(activity: Activity) {
      return (Activity.CategoryIcon as any)[activity.category()];
   }

   private getNoActivityString() {
      switch (this.selectedActivityCategory()) {
         case "person_assignments":
            return "no activity for assignments";
         case "person_notes":
            return "no activity for notes";
         case "person_attachments":
            return "no activity for attachments";
         case "person_tags":
            return "no activity for tags";
         case "person_info":
            return "no activity for info";
         default:
            return "no activity";
      }
   }
}

class PersonDetailBaseState {
   // Prevents being able to click save while a save is already in progress.
   private isSaving = false;

   readonly hasValidEmail = pureComputed(() =>
      ValidationUtils.validateEmail(this.person()?.email() ?? ""),
   );

   readonly person: Observable<Person | null>;
   readonly userPermissions: Record<string, boolean>;
   readonly photo: Observable<string | null> = observable(null);
   readonly languageOptions: ObservableArray<DropDownItem<string>>;
   readonly selectedLanguage: PureComputed<DropDownItem<string> | null>;
   readonly positionOptions: ObservableArray<DropDownItem<string>>;
   readonly selectedPosition: PureComputed<DropDownItem<string> | null>;
   readonly editingPerson: PureComputed<EditingPerson | null>;
   readonly phoneNumber: PureComputed<string | null>;
   readonly canSavePhone: Observable<boolean> = observable(true);
   readonly notificationProfileOptions: ObservableArray<ValueSet<string>>;
   readonly selectedNotificationProfile: PureComputed<ValueSet<string> | null>;
   readonly permissionOptions: ObservableArray<ValueSet<string, { is_admin: boolean }>>;
   readonly selectedPermission: PureComputed<ValueSet<string, { is_admin: boolean }> | null>;
   readonly selectedPersonType: PureComputed<SegmentedControllerItem<string> | null>;
   readonly phoneNumberIsValid: Observable<boolean> = observable(false);
   readonly dob: PureComputed<Date | null>;
   readonly hiredDate: PureComputed<Date | null>;
   readonly companyQrId: PureComputed<string | null>;
   readonly entityQrId: PureComputed<string | null>;
   readonly entityTitle: PureComputed<string | null>;
   readonly entitySubtitle: PureComputed<string | null>;
   readonly qrUrl: PureComputed<string | null>;

   // Custom Fields
   readonly availableCustomFields: ObservableArray<CustomField> = observableArray();
   customFieldValues: Record<string, Observable | ObservableArray> | null = null;

   // Groups
   readonly groupsNeeded: PureComputed<boolean>;
   readonly accessGroupsNeeded: Observable<boolean> = observable(false);
   readonly assignableGroupsNeeded: Observable<boolean> = observable(false);
   readonly orderSelectedGroupNames: PureComputed<Array<MultiDropDownItem<string>>> = pureComputed(
      () => this.selectedGroupOptions(),
   );
   readonly orderedAccessGroupNames: PureComputed<Array<MultiDropDownItem<string>>> = pureComputed(
      () => this.selectedAccessGroups(),
   );
   readonly orderedAssignableGroupNames: PureComputed<Array<MultiDropDownItem<string>>> =
      pureComputed(() => this.selectedAssignableGroups());
   readonly usingTypedGroups: boolean = authManager.usingTypedGroups();
   readonly accessGroupOptions: ObservableArray<MultiDropDownItem<string>> = observableArray();
   readonly selectedAccessGroups: ObservableArray<MultiDropDownItem<string>> = observableArray();
   readonly assignableGroupOptions: ObservableArray<MultiDropDownItem<string>> = observableArray();
   readonly selectedAssignableGroups: ObservableArray<MultiDropDownItem<string>> =
      observableArray();
   readonly groupOptions: ObservableArray<MultiDropDownItem<string>> = observableArray();
   readonly selectedGroupOptions: ObservableArray<MultiDropDownItem<string>> = observableArray();

   // Segmented Controllers
   readonly personTypeUserOption = new SegmentedControllerItem(
      "User",
      PersonDetailBaseState.PersonType.USER,
   );
   readonly personTypeAssignableOption = new SegmentedControllerItem(
      "Assignable",
      PersonDetailBaseState.PersonType.ASSIGNABLE,
   );
   readonly personTypeBothOption = new SegmentedControllerItem(
      "Both",
      PersonDetailBaseState.PersonType.BOTH,
   );
   readonly personTypeOptions = [
      this.personTypeUserOption,
      this.personTypeAssignableOption,
      this.personTypeBothOption,
   ];
   readonly selectedGender: PureComputed<
      SegmentedControllerItem<"male"> | SegmentedControllerItem<"female"> | null
   >;
   readonly genderOptionMale = new SegmentedControllerItem(
      "Male",
      PersonDetailBaseState.Gender.MALE,
   );
   readonly genderOptionFemale = new SegmentedControllerItem(
      "Female",
      PersonDetailBaseState.Gender.FEMALE,
   );
   readonly genderOptions = [this.genderOptionMale, this.genderOptionFemale];
   readonly selectedStatus: PureComputed<SegmentedControllerItem<PersonStatus>>;
   readonly statusOptionActive = new SegmentedControllerItem("Active", PersonStatus.ACTIVE);
   readonly statusOptionInactive = new SegmentedControllerItem("Inactive", PersonStatus.INACTIVE);
   readonly statusOptions = [this.statusOptionActive, this.statusOptionInactive];
   readonly displayingNotice: Observable<PersonDetailNotice | null> = observable(null);
   readonly detailsGroupExpanded: Observable<boolean> = observable(false);
   readonly integratedFields: ObservableArray<IntegratedField> = observableArray();
   private readonly hasIntegratedFields = pureComputed(() => this.integratedFields().length > 0);
   private readonly lockedIntegratedFieldKeys = pureComputed(() =>
      this.integratedFields()
         .filter((item) => item.locked)
         .map((element) => element.property),
   );

   constructor(person: Observable<Person | null>, userPermissions: Record<string, boolean>) {
      this.person = person;
      const dob = this.person()?.dob();
      if (dob) {
         const dobObj = new Date(dob);
         mutateForTimezone(dobObj);
         this.person()?.dob(dobObj.getTime());
      }
      const hiredDate = this.person()?.hiredDate();
      if (hiredDate) {
         const hiredDateObj = new Date(hiredDate);
         mutateForTimezone(hiredDateObj);
         this.person()?.hiredDate(hiredDateObj.getTime());
      }

      this.userPermissions = userPermissions;

      this.editingPerson = pureComputed(() =>
         this.person()
            ? ({
                 id: this.person()!.id,
                 firstName: observable(this.person()!.firstName()),
                 lastName: observable(this.person()!.lastName()),
                 employeeNumber: observable(this.person()!.employeeNumber()),
                 isUser: observable(this.person()!.isUser()),
                 isAssignable: observable(this.person()!.isAssignable()),
                 address1: observable(this.person()!.address1()),
                 address2: observable(this.person()!.address2()),
                 stateProvince: observable(this.person()!.stateProvince()),
                 cityTown: observable(this.person()!.cityTown()),
                 zipcode: observable(this.person()!.zipcode()),
                 country: observable(this.person()!.country()),
                 phone: observable(this.person()!.phone()),
                 email: observable(this.person()!.email()),
                 canRecieveSms: observable(this.person()!.canRecieveSms()),
                 canRecieveEmail: observable(this.person()!.canRecieveEmail()),
                 canRecieveMobile: observable(this.person()!.canRecieveMobile()),
                 emergencyContactName: observable(this.person()!.emergencyContactName()),
                 emergencyContactNumber: observable(this.person()!.emergencyContactNumber()),
                 emergencyContactEmail: observable(this.person()!.emergencyContactEmail()),
                 emergencyContactRelation: observable(this.person()!.emergencyContactRelation()),
                 hourlyWage: observable(this.person()!.hourlyWage()),
                 dob: observable(this.person()!.dob()),
                 language: observable(this.person()!.language()),
                 isMale: observable(this.person()!.isMale()),
                 hiredDate: observable(this.person()!.hiredDate()),
                 positionId: observable(this.person()!.positionId()),
                 status: observable(this.person()!.status()),
                 permissionLevelId: observable(this.person()!.permissionLevel()?.id ?? null),
                 notificationProfileId: observable(
                    this.person()!.notificationProfile()?.id ?? null,
                 ),
                 customFields: this.person()!.customFields
                    ? observableArray(this.person()!.customFields!())
                    : null,
                 groupIds: observableArray(this.person()!.groupIds()),
                 assignableGroupIds: observableArray(this.person()!.assignableGroupIds()),
                 accessGroupIds: observableArray(this.person()!.accessGroupIds()),
              } as EditingPerson)
            : null,
      );

      this.companyQrId = pureComputed(() =>
         this.person() ? this.person()!.baggage().company_qr_id : null,
      );
      this.entityQrId = pureComputed(() => (this.person() ? this.person()!.baggage().qr_id : null));
      this.entityTitle = pureComputed(() =>
         this.person() ? `${this.person()!.firstName()} ${this.person()!.lastName()}` : null,
      );
      this.entitySubtitle = pureComputed(() =>
         this.person() ? this.person()!.employeeNumber() : null,
      );
      this.qrUrl = pureComputed(() => {
         return this.person()
            ? `${location.origin}/qrc/${this.person()!.baggage().company_qr_id}/pe/${
                 this.person()!.baggage().qr_id
              }`
            : null;
      });

      this.dob = pureComputed({
         read: () => (this.editingPerson()!.dob() ? new Date(this.editingPerson()!.dob()!) : null),
         write: (val: Date | null) => {
            this.editingPerson()!.dob(val ? val.getTime() : null);
         },
      });

      this.hiredDate = pureComputed({
         read: () =>
            this.editingPerson()!.hiredDate() ? new Date(this.editingPerson()!.hiredDate()!) : null,
         write: (val: Date | null) => {
            this.editingPerson()!.hiredDate(val ? val.getTime() : null);
         },
      });

      this.selectedPersonType = pureComputed({
         read: () => {
            if (this.person()) {
               if (this.editingPerson()!.isUser() && this.editingPerson()!.isAssignable()) {
                  return this.personTypeBothOption;
               } else if (this.editingPerson()!.isUser()) {
                  return this.personTypeUserOption;
               } else if (this.editingPerson()!.isAssignable()) {
                  return this.personTypeAssignableOption;
               }
            }
            return null;
         },
         write: (value) => {
            if (value == this.personTypeUserOption) {
               this.editingPerson()!.isUser(true);
               this.editingPerson()!.isAssignable(false);
            } else if (value == this.personTypeAssignableOption) {
               this.editingPerson()!.isUser(false);
               this.editingPerson()!.isAssignable(true);
            } else {
               this.editingPerson()!.isUser(true);
               this.editingPerson()!.isAssignable(true);
            }
         },
      });

      this.selectedGender = pureComputed({
         read: () => {
            if (this.editingPerson()!.isMale() === true) {
               return this.genderOptionMale as SegmentedControllerItem<"male">;
            } else if (this.editingPerson()!.isMale() === false) {
               return this.genderOptionFemale as SegmentedControllerItem<"female">;
            } else {
               return null;
            }
         },
         write: (value) => {
            if (value == this.genderOptionMale) {
               this.editingPerson()!.isMale(true);
            } else if (value == this.genderOptionFemale) {
               this.editingPerson()!.isMale(false);
            } else {
               this.editingPerson()!.isMale(null);
            }
         },
      });

      this.selectedStatus = pureComputed({
         read: () => {
            return this.editingPerson()!.status() == PersonStatus.ACTIVE
               ? this.statusOptionActive
               : this.statusOptionInactive;
         },
         write: (value) => {
            this.editingPerson()!.status(
               value == this.statusOptionActive ? PersonStatus.ACTIVE : PersonStatus.INACTIVE,
            );
         },
      });

      this.permissionOptions = observableArray();
      this.selectedPermission = pureComputed({
         read: () => {
            for (const option of this.permissionOptions()) {
               if (option.value() == this.editingPerson()?.permissionLevelId()) {
                  return option;
               }
            }
            return null;
         },
         write: (permission) => {
            this.editingPerson()?.permissionLevelId(permission ? permission!.value() : null);
         },
      });

      this.notificationProfileOptions = observableArray();
      this.selectedNotificationProfile = pureComputed({
         read: () => {
            for (const option of this.notificationProfileOptions()) {
               if (option.value() == this.editingPerson()?.notificationProfileId()) {
                  return option;
               }
            }
            return null;
         },
         write: (profile) => {
            this.editingPerson()?.notificationProfileId(profile ? profile!.value() : null);
         },
      });

      this.languageOptions = observableArray();
      this.selectedLanguage = pureComputed({
         read: () => {
            for (const option of this.languageOptions()) {
               if (option.value() == this.editingPerson()!.language()) {
                  return option;
               }
            }
            return null;
         },
         write: (language: DropDownItem<string> | null) => {
            this.editingPerson()?.language(language!.value());
         },
      });

      this.positionOptions = observableArray();
      this.selectedPosition = pureComputed({
         read: () => {
            for (const option of this.positionOptions()) {
               if (option.value() == this.editingPerson()!.positionId()) {
                  return option;
               }
            }
            return null;
         },
         write: (position) => {
            this.editingPerson()?.positionId(position ? position.value() : null);
         },
      });
      this.phoneNumber = pureComputed({
         read: () => (this.editingPerson() ? this.editingPerson()!.phone() : null),
         write: (val) => this.editingPerson()!.phone(val),
      });

      this.groupsNeeded = pureComputed(() => {
         if (this.selectedPermission()?.baggage()) {
            return (
               this.selectedPersonType()?.value() == "assignable" ||
               this.selectedPermission()?.baggage().is_admin === false
            );
         }
         return true;
      });
   }

   toggleDetailGroup = () => {
      this.detailsGroupExpanded(true);
   };

   cancelDetailGroupChanges = () => {
      this.detailsGroupExpanded(false);
   };

   loadPhoto = () => {
      if (this.person()!.profilePicUrl()) {
         const urlChunks = this.person()!.profilePicUrl()!.split("upload/");
         const cleanedUrl = `${urlChunks[0]}upload/fl_ignore_aspect_ratio/${urlChunks[1]}`;
         this.photo(cleanedUrl);
      } else {
         this.photo(null);
      }
   };

   handleProfilePic = async (url: string) => {
      if (url != this.person()!.profilePicUrl()) {
         try {
            await PersonStore.updatePerson(this.person()!.id, { profile_pic_url: url }).payload;
         } catch (err) {
            console.log("error: ", err);
         }
      }
   };

   loadSelectedGroups = () => {
      const selectedGroups = this.selectGroupsFromOptions(
         this.person()!.groupIds(),
         this.groupOptions(),
      );
      this.selectedGroupOptions(selectedGroups);
      if (this.usingTypedGroups) {
         const selectedAccessGroups = this.selectGroupsFromOptions(
            this.person()!.accessGroupIds(),
            this.accessGroupOptions(),
         );
         this.selectedAccessGroups(selectedAccessGroups);

         const selectedAssignableGroups = this.selectGroupsFromOptions(
            this.person()!.assignableGroupIds(),
            this.assignableGroupOptions(),
         );
         this.selectedAssignableGroups(selectedAssignableGroups);

         this.accessGroupsNeeded(this.person()!.isUser());
         this.assignableGroupsNeeded(this.person()!.isAssignable());
      }
   };

   selectGroupsFromOptions = (
      personsGroupIds: string[],
      groupOptions: Array<MultiDropDownItem<string>>,
   ) => {
      const selectedGroupOptions = [];
      for (const option of groupOptions) {
         if (personsGroupIds.indexOf(option.value()) !== -1) {
            option.selected(true);
            selectedGroupOptions.push(option);
         } else {
            option.selected(false);
         }
      }
      return selectedGroupOptions;
   };

   private checkFieldEditing(field: any) {
      const fieldUnwrapped = unwrap(field);
      try {
         if (!this.userPermissions.canEditPeopleSensitive) {
            if (authManager.peopleSensitiveFields().indexOf(fieldUnwrapped) !== -1) {
               return false;
            }
         }
         if (this.hasIntegratedFields()) {
            if (this.lockedIntegratedFieldKeys().indexOf(fieldUnwrapped) !== -1) {
               return false;
            }
         }
         return true;
      } catch (err: any) {
         Bugsnag.notify(err, (event) => {
            event["context"] = "person-detail_checkFieldEditing";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            return event.addMetadata("checked field", fieldUnwrapped);
         });
         throw err;
      }
   }

   saveUpdates = async () => {
      if (!this.userPermissions.canEditPeopleDetails || this.isSaving) return;
      this.isSaving = true;

      const continueUpdateExecution = async () => {
         // Validation for update
         if (!ValidationUtils.validateInput(this.editingPerson()!.firstName())) {
            this.isSaving = false;
            this.displayingNotice(PersonDetailBaseState.Notice.FIRST_NAME);
            return;
         } else if (!ValidationUtils.validateInput(this.editingPerson()!.lastName())) {
            this.isSaving = false;
            this.displayingNotice(PersonDetailBaseState.Notice.LAST_NAME);
            return;
         }
         if (this.groupsNeeded()) {
            if (this.usingTypedGroups) {
               if (
                  this.selectedPersonType()!.value() == PersonDetailBaseState.PersonType.ASSIGNABLE
               ) {
                  if (this.selectedAssignableGroups().length == 0) {
                     this.isSaving = false;
                     this.displayingNotice(PersonDetailBaseState.Notice.GROUP);
                     return;
                  }
               } else if (
                  this.selectedPersonType()!.value() == PersonDetailBaseState.PersonType.USER
               ) {
                  if (this.selectedAccessGroups().length == 0) {
                     this.isSaving = false;
                     this.displayingNotice(PersonDetailBaseState.Notice.GROUP);
                     return;
                  }
               } else if (
                  this.selectedPersonType()!.value() == PersonDetailBaseState.PersonType.BOTH
               ) {
                  if (
                     this.selectedAssignableGroups().length == 0 ||
                     this.selectedAccessGroups().length == 0
                  ) {
                     this.isSaving = false;
                     this.displayingNotice(PersonDetailBaseState.Notice.BOTH_GROUP_TYPES);
                     return;
                  }
               }
            } else {
               if (this.selectedGroupOptions().length == 0) {
                  this.isSaving = false;
                  this.displayingNotice(PersonDetailBaseState.Notice.GROUP);
                  return;
               }
            }
         }
         if (
            this.selectedPersonType()?.value() == PersonDetailBaseState.PersonType.USER ||
            this.selectedPersonType()?.value() == PersonDetailBaseState.PersonType.BOTH
         ) {
            if (!ValidationUtils.validateInput(this.editingPerson()?.email())) {
               this.isSaving = false;
               this.displayingNotice(PersonDetailBaseState.Notice.EMAIL);
               return;
            } else if (!ValidationUtils.validateEmail(this.editingPerson()?.email() ?? "")) {
               this.isSaving = false;
               this.displayingNotice(PersonDetailBaseState.Notice.INVALID_EMAIL);
               return;
            } else if (!this.selectedPermission()) {
               this.isSaving = false;
               this.displayingNotice(PersonDetailBaseState.Notice.PERMISSION);
               return;
            }
         }
         if (this.phoneNumber() && !this.phoneNumberIsValid()) {
            this.isSaving = false;
            this.displayingNotice(PersonDetailBaseState.Notice.PHONE_ERROR);
            return;
         }

         // Check and execute updates
         const updateData: UpdatePersonPayload<AuthType.SESSION> = {};
         if (
            this.editingPerson()!.firstName() != this.person()!.firstName() ||
            this.editingPerson()!.lastName() != this.person()!.lastName()
         ) {
            const newName = {
               first: this.editingPerson()!.firstName().trim(),
               last: this.editingPerson()!.lastName().trim(),
            };
            updateData.name = newName;
         }
         if (
            this.selectedPosition() != null &&
            (this.person()!.positionId() == null ||
               this.selectedPosition()?.value() != this.person()?.positionId())
         ) {
            updateData.job_title_id = this.selectedPosition()!.value();
         } else if (this.selectedPosition() === null && this.person()?.positionId() != null) {
            updateData.job_title_id = null;
         }
         if (this.selectedPersonType()?.value() == PersonDetailBaseState.PersonType.USER) {
            if (this.person()?.isUser() == false) {
               updateData.is_user = true;
            }
            if (this.person()?.isAssignable() == true) {
               updateData.is_assignable = false;
            }
            if (this.selectedPermission()?.value() !== this.person()?.permissionLevel()?.id) {
               updateData.permission_level_id = this.selectedPermission()?.value();
            }
            if (
               this.selectedNotificationProfile()?.value !==
               this.person()?.notificationProfile()?.id
            ) {
               updateData.notification_profile_id = this.selectedNotificationProfile()?.value();
            }
         } else if (
            this.selectedPersonType()?.value() == PersonDetailBaseState.PersonType.ASSIGNABLE
         ) {
            if (this.person()?.isUser() == true) {
               updateData.is_user = false;
            }
            if (this.person()?.isAssignable() == false) {
               updateData.is_assignable = true;
            }
            if (this.person()?.permissionLevel()) {
               updateData.permission_level_id = null;
               this.selectedPermission(null);
            }
            if (this.person()?.notificationProfile()) {
               updateData.notification_profile_id = null;
               this.selectedNotificationProfile(null);
            }
         } else if (this.selectedPersonType()?.value() == PersonDetailBaseState.PersonType.BOTH) {
            if (this.person()?.isUser() == false) {
               updateData.is_user = true;
            }
            if (this.person()?.isAssignable() == false) {
               updateData.is_assignable = true;
            }
            if (this.selectedPermission()?.value() !== this.person()?.permissionLevel()?.id) {
               updateData.permission_level_id = this.selectedPermission()?.value();
            }
            if (
               this.selectedNotificationProfile()?.value() !==
               this.person()?.notificationProfile()?.id
            ) {
               updateData.notification_profile_id = this.selectedNotificationProfile()?.value();
            }
         }
         if (this.editingPerson()!.phone() != this.person()!.phone()) {
            updateData.phone = this.editingPerson()!.phone();
         }
         if (this.editingPerson()!.canRecieveSms() != this.person()!.canRecieveSms()) {
            updateData.can_receive_sms = this.editingPerson()!.canRecieveSms();
         }
         if (this.editingPerson()!.canRecieveEmail() != this.person()!.canRecieveEmail()) {
            updateData.can_receive_email = this.editingPerson()!.canRecieveEmail();
         }
         if (this.editingPerson()!.canRecieveMobile() != this.person()!.canRecieveMobile()) {
            updateData.can_receive_mobile = this.editingPerson()!.canRecieveMobile();
         }
         if (this.editingPerson()!.email() != this.person()!.email()) {
            updateData.email = this.editingPerson()!.email();
         }
         if (this.editingPerson()!.employeeNumber() != this.person()!.employeeNumber()) {
            updateData.employee_number = this.editingPerson()!.employeeNumber();
         }
         if (this.editingPerson()!.address1() != this.person()!.address1()) {
            updateData.address_1 = this.editingPerson()!.address1();
         }
         if (this.editingPerson()!.address2() != this.person()!.address2()) {
            updateData.address_2 = this.editingPerson()!.address2();
         }
         if (this.editingPerson()!.cityTown() != this.person()!.cityTown()) {
            updateData.city_town = this.editingPerson()!.cityTown();
         }
         if (this.editingPerson()!.stateProvince() != this.person()!.stateProvince()) {
            updateData.state_province = this.editingPerson()!.stateProvince();
         }
         if (this.editingPerson()!.zipcode() != this.person()!.zipcode()) {
            updateData.zipcode = this.editingPerson()!.zipcode();
         }
         if (this.editingPerson()!.country() != this.person()!.country()) {
            updateData.country = this.editingPerson()!.country();
         }
         if (this.editingPerson()!.hourlyWage() != this.person()!.hourlyWage()) {
            updateData.hourly_wage = Number(this.editingPerson()!.hourlyWage());
         }
         if (this.editingPerson()!.hiredDate()! != this.person()!.hiredDate()) {
            updateData.hired_date = this.editingPerson()!.hiredDate()!;
         }
         if (this.editingPerson()!.dob() != this.person()!.dob()) {
            updateData.dob = this.editingPerson()!.dob();
         }
         if (
            this.selectedLanguage() != null &&
            (this.person()?.language() == null ||
               this.selectedLanguage()?.value() != this.person()?.language())
         ) {
            updateData.language = this.selectedLanguage()!.value() as SupportedLanguage;
         } else if (this.selectedLanguage() === null) {
            updateData.job_title_id = null;
         }
         if (
            this.selectedGender() != null &&
            (this.selectedGender()!.value() == PersonDetailBaseState.Gender.MALE) !=
               this.person()!.isMale()
         ) {
            updateData.gender = this.selectedGender()!.value();
         }
         if (this.selectedStatus()!.value() != this.person()!.status()) {
            updateData.status = this.selectedStatus().value();
         }
         if (
            this.editingPerson()!.emergencyContactName() != this.person()!.emergencyContactName()
         ) {
            updateData.emergency_contact_name = this.editingPerson()!.emergencyContactName();
         }
         if (
            this.editingPerson()!.emergencyContactEmail() != this.person()!.emergencyContactEmail()
         ) {
            updateData.emergency_contact_email = this.editingPerson()!.emergencyContactEmail();
         }
         if (
            this.editingPerson()!.emergencyContactNumber() !=
            this.person()!.emergencyContactNumber()
         ) {
            updateData.emergency_contact_number = this.editingPerson()!.emergencyContactNumber();
         }
         if (
            this.editingPerson()!.emergencyContactRelation() !=
            this.person()!.emergencyContactRelation()
         ) {
            updateData.emergency_contact_relation =
               this.editingPerson()!.emergencyContactRelation();
         }
         if (this.groupsNeeded()) {
            if (this.usingTypedGroups) {
               const updatedAssignableGroupIdSet = this.selectedAssignableGroups().map((g) =>
                  g.value(),
               );
               const originalAssignableGroupIdSet = this.person()!.assignableGroupIds();
               const assignableGroupsToAdd = updatedAssignableGroupIdSet.filter(
                  (id) => !originalAssignableGroupIdSet.includes(id),
               );
               const assignableGroupsToRemove = originalAssignableGroupIdSet.filter(
                  (id) => !updatedAssignableGroupIdSet.includes(id),
               );
               if (
                  updatedAssignableGroupIdSet.length != originalAssignableGroupIdSet.length ||
                  assignableGroupsToAdd.length != 0 ||
                  assignableGroupsToRemove.length != 0
               ) {
                  const groupUpdate: IdListRecordUpdate = {
                     ...assignableGroupsToAdd.reduce(
                        (groups: IdListRecordUpdate, groupId: string) => {
                           return {
                              ...groups,
                              [groupId]: true,
                           };
                        },
                        {},
                     ),
                     ...assignableGroupsToRemove.reduce(
                        (groups: IdListRecordUpdate, groupId: string) => {
                           return {
                              ...groups,
                              [groupId]: false,
                           };
                        },
                        {},
                     ),
                  };

                  updateData.assignable_group_ids = groupUpdate;
               }

               const updatedAccessGroupIdSet = this.selectedAccessGroups().map((g) => g.value());
               const originalAccessGroupIdSet = this.person()!.accessGroupIds();
               const accessGroupsToAdd = updatedAccessGroupIdSet.filter(
                  (id) => !originalAccessGroupIdSet.includes(id),
               );
               const accessGroupsToRemove = originalAccessGroupIdSet.filter(
                  (id) => !updatedAccessGroupIdSet.includes(id),
               );
               if (
                  updatedAccessGroupIdSet.length != originalAccessGroupIdSet.length ||
                  accessGroupsToAdd.length != 0 ||
                  accessGroupsToRemove.length != 0
               ) {
                  const groupUpdate: IdListRecordUpdate = {
                     ...accessGroupsToAdd.reduce((groups: IdListRecordUpdate, groupId: string) => {
                        return {
                           ...groups,
                           [groupId]: true,
                        };
                     }, {}),
                     ...accessGroupsToRemove.reduce(
                        (groups: IdListRecordUpdate, groupId: string) => {
                           return {
                              ...groups,
                              [groupId]: false,
                           };
                        },
                        {},
                     ),
                  };

                  updateData.access_group_ids = groupUpdate;
               }
            } else {
               const updatedGroupIdSet = Array.from(
                  new Set(this.selectedGroupOptions().map((g) => g.value())),
               );
               const originalGroupIdSet = this.person()!.groupIds();
               if (
                  updatedGroupIdSet.length != originalGroupIdSet.length ||
                  this.person()!
                     .groupIds()
                     .some((groupId) => !updatedGroupIdSet.includes(groupId!)) ||
                  updatedGroupIdSet.some((groupId) => originalGroupIdSet.indexOf(groupId) == -1)
               ) {
                  const groupsToAdd = updatedGroupIdSet.filter(
                     (id) => !originalGroupIdSet.includes(id),
                  );
                  const groupsToRemove = originalGroupIdSet.filter(
                     (id) => !updatedGroupIdSet.includes(id),
                  );
                  const groupUpdate: IdListRecordUpdate = {
                     ...groupsToAdd.reduce((groups: IdListRecordUpdate, groupId: string) => {
                        return {
                           ...groups,
                           [groupId]: true,
                        };
                     }, {}),
                     ...groupsToRemove.reduce((groups: IdListRecordUpdate, groupId: string) => {
                        return {
                           ...groups,
                           [groupId]: false,
                        };
                     }, {}),
                  };

                  updateData.group_ids = groupUpdate;
               }
            }
         }
         if (this.userPermissions.customFieldModuleEnabled && this.customFieldValues) {
            const customFieldUpdatePayload: UpdateCustomFieldInstancePayload = {};
            const personCustomFields = this.person()!.customFields
               ? this.person()!.customFields!()
               : [];
            for (const [fieldId, val] of Object.entries(this.customFieldValues)) {
               const customField = this.availableCustomFields().find((cf) => cf.id == fieldId);
               const originalPersonField = personCustomFields.find((cf) => cf.fieldId == fieldId);
               if (
                  (Array.isArray(val()) && (val() as string[]).length) ||
                  (!Array.isArray(val()) &&
                     (ValidationUtils.validateInput(val()) || val() === null))
               ) {
                  let field = null;
                  for (const customField of this.availableCustomFields()) {
                     if (customField.id == fieldId) {
                        field = customField;
                        break;
                     }
                  }
                  if (!field) continue;
                  let formattedValue = null;
                  if (Array.isArray(val())) {
                     formattedValue = (val() as MultiDropDownItem[]).map((i) => i.value());
                  } else if (val() instanceof Date) {
                     formattedValue = DateUtils.getDetachedDay(val());
                  } else if (val() === null) {
                     formattedValue = val();
                  } else if (val().value) {
                     formattedValue = val().value();
                  } else if (customField?.type() == "currency" || customField?.type() == "number") {
                     formattedValue = Number.isNaN(Number(val())) ? 0 : Number(val());
                  } else {
                     formattedValue = val();
                  }
                  if (Array.isArray(formattedValue) && Array.isArray(originalPersonField?.value)) {
                     const updateArray = formattedValue;
                     const originalArray = originalPersonField?.value;
                     if (
                        updateArray.length == originalArray!.length &&
                        originalArray!.every((val) => updateArray.includes(val))
                     ) {
                        continue;
                     }
                  }
                  if (originalPersonField?.value != formattedValue) {
                     customFieldUpdatePayload[fieldId] = formattedValue;
                  }
               }
            }
            if (Object.keys(customFieldUpdatePayload).length > 0) {
               updateData.custom_fields = customFieldUpdatePayload;
            }
         }

         if (Object.keys(updateData).length == 0) {
            this.displayingNotice(null);
            this.detailsGroupExpanded(false);
            this.isSaving = false;
         } else {
            this.displayingNotice(PersonDetailBaseState.Notice.ATTEMPTING_TO_SAVE);
            this.isSaving = false;
            try {
               await PersonStore.updatePerson(this.person()!.id, updateData).payload;
               this.displayingNotice(null);
               this.detailsGroupExpanded(false);
            } catch (err: any) {
               if (err.validation) {
                  const errMessages = err.validation.map((v: Error) => v.message);
                  if (errMessages.includes("Email is already in use.")) {
                     this.displayingNotice(PersonDetailBaseState.Notice.PERSON_EMAIL_EXIST);
                  } else if (errMessages.includes("Phone is already in use.")) {
                     this.displayingNotice(PersonDetailBaseState.Notice.PERSON_PHONE_EXIST);
                  }
               }
               this.displayingNotice(PersonDetailBaseState.Notice.PERSON_GENERIC_ERROR);
            }
         }
      };
      if (this.canSavePhone()) {
         continueUpdateExecution();
      } else {
         const savePhoneBlock = this.canSavePhone.subscribe((val) => {
            if (val) {
               savePhoneBlock.dispose();
               continueUpdateExecution();
            }
         });
      }
   };

   static readonly PersonType = {
      USER: "user",
      ASSIGNABLE: "assignable",
      BOTH: "both",
   };

   static readonly Gender = {
      MALE: "male",
      FEMALE: "female",
      NONE: "none",
   };

   static readonly Notice = {
      FIRST_NAME: {
         text: 'Person must have a "First Name".',
         color: "red",
         info: null,
         dissmissable: true,
      },
      LAST_NAME: {
         text: 'Person must have a "Last Name".',
         color: "red",
         info: null,
         dissmissable: true,
      },
      GROUP: {
         text: "At least one group must be selected.",
         info: null,
         color: "red",
         dissmissable: true,
      },
      BOTH_GROUP_TYPES: {
         text: "At least one group must be selected for each group type.",
         info: null,
         color: "red",
         dissmissable: true,
      },
      PHONE_ERROR: {
         text: "There is an error with the provided phone number. Please check the error for details.",
         info: null,
         color: "red",
         dissmissable: true,
      },
      EMAIL: {
         text: "User must have an email.",
         info: null,
         color: "red",
         dissmissable: true,
      },
      INVALID_EMAIL: {
         text: "Email must be valid for a User.",
         info: null,
         color: "red",
         dissmissable: true,
      },
      PERMISSION: {
         text: "Permission level must be selected for a User.",
         info: null,
         color: "red",
         dissmissable: true,
      },
      ATTEMPTING_TO_SAVE: {
         text: "Saving Person...",
         info: null,
         color: "green",
         dissmissable: false,
      },
      PERSON_EMAIL_EXIST: {
         text: "That email is already in use for an existing person.",
         info: null,
         color: "red",
         dissmissable: true,
      },
      PERSON_PHONE_EXIST: {
         text: "A person's phone must be unique within your company.",
         info: null,
         color: "red",
         dissmissable: true,
      },
      PERSON_GENERIC_ERROR: {
         text: "There was an error creating this person. Try Again.",
         info: null,
         color: "red",
         dissmissable: true,
      },
   };
}

export class PersonDetailViewModel extends PageContentViewModel {
   // This is a total page failure, not just a warning.
   private readonly pageError = observable<{ title: string; message: string } | null>(null);
   private readonly state: PersonDetailBaseState;
   private readonly noteState: PersonDetailNoteState;
   private readonly tagState: PersonDetailTagState;
   private readonly attachmentState: PersonDetailAttachmentState;
   private readonly timeOffState: PersonDetailTimeoffState;
   private readonly activityState: PersonDetailActivityState;
   private supportingDataLoaded = false;
   private readonly ignoreConflictIds: string[];
   private readonly hasPendingInvite = pureComputed(() => {
      const person = this.person();
      return (
         person != null && person.isUser() && person.invitePending() && !this.inviteFlowDeprecated()
      );
   });
   private readonly isUser = pureComputed(() => {
      const selectedPersonTypeValue = this.state.selectedPersonType()?.value();
      return selectedPersonTypeValue === "user" || selectedPersonTypeValue === "both";
   });
   private readonly userLockedOut = pureComputed(() => {
      const person = this.person();
      if (!person?.isUser()) {
         return false;
      }
      return person.failedLoginCount() >= person.baggage().user_lockout_limit;
   });
   private readonly resentInvite = observable(false);
   private readonly person: Observable<Person | null>;
   private readonly inviteFlowDeprecated = pureComputed(() => {
      const flagValue = LaunchDarklyBrowser.getFlagValue("login-deprecation");
      return flagValue || requestContext.usingProcoreHostApp;
   });

   // Popups
   private readonly noteAttachmentPopupBuilder = () => {
      return new Popup(
         "Attachments",
         Popup.FrameType.BELOW,
         Popup.ArrowLocation.TOP_LEFT,
         [new AddAttachmentPane(this.noteState.editingNote().attachments)],
         ["notes-cell__attachments", "icon-attachment", "files-uploader__file-input"],
         ["notes-cell__popup--note-attachment"],
      );
   };
   private readonly noteAttachmentPopupWrapper = {
      popupBuilder: this.noteAttachmentPopupBuilder,
      options: {
         triggerClasses: ["icon-attachment"],
         allowClickDefaultClasses: ["files-uploader__file-input"],
      },
   };

   private readonly PageError = {
      OUTSIDE_USERS_GROUP: {
         title: "Access Blocked",
         message: "You do not have access to the groups this person belongs to.",
      },
      RECORD_ARCHIVED: {
         title: "Record Deleted",
         message:
            "This record has been removed. Contact LaborChart support if you think this was an mistake.",
      },
   } as const;

   // Permissions
   readonly permissions = {
      canEditPeopleDetails: authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_DETAILS),
      canEditPeoplePermissions: authManager.checkAuthAction(
         PermissionLevel.Action.EDIT_PEOPLE_PERMISSIONS,
      ),
      canViewPeopleSensitive: authManager.checkAuthAction(
         PermissionLevel.Action.VIEW_PEOPLE_SENSITIVE,
      ),
      canEditPeopleSensitive: authManager.checkAuthAction(
         PermissionLevel.Action.EDIT_PEOPLE_SENSITIVE,
      ),
      canViewPeopleTags: authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS),
      canEditPeopleTags: authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_TAGS),
      canViewPeopleAttachments: authManager.checkAuthAction(
         PermissionLevel.Action.VIEW_PEOPLE_ATTACHMENTS,
      ),
      canEditPeopleAttachments: authManager.checkAuthAction(
         PermissionLevel.Action.EDIT_PEOPLE_ATTACHMENTS,
      ),
      canViewPeopleTimeoff: authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TIMEOFF),
      canEditPeopleTimeoff: authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_TIMEOFF),
      canViewPeopleNotes: authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_NOTES),
      canEditPeopleNotes: authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_NOTES),
      canViewPeopleActivity: authManager.checkAuthAction(
         PermissionLevel.Action.VIEW_PEOPLE_ACTIVITY,
      ),
      canViewNotifications: authManager.checkAuthAction(
         PermissionLevel.Action.VIEW_NOTIFICATIONS_SETTINGS,
      ),
      canEditNotifications: authManager.checkAuthAction(
         PermissionLevel.Action.MANAGE_NOTIFICATIONS_SETTINGS,
      ),
      canDeletePeople: authManager.checkAuthAction(PermissionLevel.Action.DELETE_PEOPLE),
      canViewPeopleFinancials: authManager.checkAuthAction(
         PermissionLevel.Action.VIEW_PEOPLE_FINANCIALS,
      ),
      canViewAssignments: authManager.checkAuthAction(PermissionLevel.Action.VIEW_ASSIGNMENTS),
      canViewProject: authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT),
      canUnlockUsers: authManager.checkAuthAction(PermissionLevel.Action.CAN_UNLOCK_USERS),
      customFieldModuleEnabled: authManager.companyModules()?.customFields ?? false,
      qrCodesEnabled: authManager.companyModules()?.qrCodes ?? false,
      peopleQrCodes: authManager.companyModules()?.peopleQrCodes ?? false,
      tagCategoriesEnabled: authManager.companyModules()?.tagCategories ?? false,
      usingTypedGroups: authManager.usingTypedGroups(),
      isAdmin: authManager.isAdmin(),
      canRefreshAssociatedProjects: authManager.checkAuthAction(
         PermissionLevel.Action.EDIT_PEOPLE_DETAILS,
      ),
   };

   constructor(private readonly personId: string) {
      super(template(), "");
      this.person = observable(null);
      this.state = new PersonDetailBaseState(this.person, this.permissions);
      this.noteState = new PersonDetailNoteState(
         this.person,
         this.permissions,
         this.loadPerson.bind(this),
      );
      this.tagState = new PersonDetailTagState(this.person, this.permissions);
      this.attachmentState = new PersonDetailAttachmentState(this.person, this.permissions);
      this.timeOffState = new PersonDetailTimeoffState(this.person, this.permissions);
      this.activityState = new PersonDetailActivityState(this.personId);

      this.loadPerson();
      this.activityState.subscribeToPersonActivity(true);
      this.ignoreConflictIds = [personId];

      fanoutManager.getSubscription(
         { personId: this.personId },
         "vm.PersonDetailViewModel",
         {
            path: "/people",
            structure: { inGroup: true },
         },
         null,
         this.loadPerson.bind(this),
      );

      if (this.state.usingTypedGroups) {
         this.state.selectedPersonType.subscribe((newVal) => {
            const person = this.person();
            if (person != null && newVal) {
               if (newVal.value() === PersonDetailBaseState.PersonType.ASSIGNABLE) {
                  this.state.accessGroupsNeeded(false);
                  this.state.assignableGroupsNeeded(true);
                  this.state.selectedAccessGroups([]);

                  for (const option of this.state.accessGroupOptions()) {
                     option.selected(false);
                  }
                  if (this.state.selectedAssignableGroups().length === 0) {
                     // Need to add some as defaults.
                     const workingIds =
                        person.assignableGroupIds().length > 0
                           ? person.assignableGroupIds()
                           : person.groupIds();
                     const selectedAssignableGroups = [];
                     for (const option of this.state.assignableGroupOptions()) {
                        if (workingIds.indexOf(option.value()) !== -1) {
                           option.selected(true);
                           selectedAssignableGroups.push(option);
                        } else {
                           // Catching any that were previously selected.
                           option.selected(false);
                        }
                     }
                     this.state.selectedAssignableGroups(selectedAssignableGroups);
                  }
               } else if (newVal.value() === PersonDetailBaseState.PersonType.USER) {
                  this.state.accessGroupsNeeded(true);
                  this.state.assignableGroupsNeeded(false);
                  this.state.selectedAssignableGroups([]);

                  for (const option of this.state.assignableGroupOptions()) {
                     option.selected(false);
                  }
                  if (this.state.selectedAccessGroups().length === 0) {
                     // Need to add some as defaults.
                     const workingIds =
                        person.accessGroupIds().length > 0
                           ? person.accessGroupIds()
                           : person.groupIds();
                     const selectedAccessGroups = [];
                     for (const option of this.state.accessGroupOptions()) {
                        if (workingIds.indexOf(option.value()) !== -1) {
                           option.selected(true);
                           selectedAccessGroups.push(option);
                        } else {
                           // Catching any that were previously selected.
                           option.selected(false);
                        }
                     }
                     this.state.selectedAccessGroups(selectedAccessGroups);
                  }
               } else if (newVal.value() === PersonDetailBaseState.PersonType.BOTH) {
                  this.state.accessGroupsNeeded(true);
                  this.state.assignableGroupsNeeded(true);
                  if (this.state.selectedAssignableGroups().length === 0) {
                     // Need to add some as defaults.
                     const workingIds =
                        person.assignableGroupIds().length > 0
                           ? person.assignableGroupIds()
                           : person.groupIds();
                     const selectedAssignableGroups = [];
                     for (const option of this.state.assignableGroupOptions()) {
                        if (workingIds.indexOf(option.value()) !== -1) {
                           option.selected(true);
                           selectedAssignableGroups.push(option);
                        } else {
                           // Catching any that were previously selected.
                           option.selected(false);
                        }
                     }
                     this.state.selectedAssignableGroups(selectedAssignableGroups);
                  }
                  if (this.state.selectedAccessGroups().length === 0) {
                     // Need to add some as defaults.
                     const workingIds =
                        person.accessGroupIds().length > 0
                           ? person.accessGroupIds()
                           : person.groupIds();
                     const selectedAccessGroups = [];
                     for (const option of this.state.accessGroupOptions()) {
                        if (workingIds.indexOf(option.value()) !== -1) {
                           option.selected(true);
                           selectedAccessGroups.push(option);
                        } else {
                           // Catching any that were previously selected.
                           option.selected(false);
                        }
                     }
                     this.state.selectedAccessGroups(selectedAccessGroups);
                  }
               }
            }
         });
      }
   }

   private clearObservable(observable: Observable<any>) {
      observable(null);
   }

   private checkFieldVisibility = (field: any) => {
      if (this.permissions.canViewPeopleSensitive) return true;
      return authManager.peopleSensitiveFields().indexOf(unwrap(field)) == -1;
   };

   private customFieldValueIsValid = (data: any): boolean => {
      /* eslint-disable no-prototype-builtins */
      return (
         data &&
         this.state.customFieldValues?.hasOwnProperty(data.id) &&
         data.hasOwnProperty("type") &&
         data.hasOwnProperty("values")
      );
      /* eslint-enable no-prototype-builtins */
   };

   private formatCustomField(field: CustomFieldInstance) {
      if (field.type === "date") {
         return DateUtils.formatDetachedDay(field.value as number, defaultStore.getDateFormat());
      } else if (field.type === "bool") {
         return String(field.value).toUpperCase();
      } else if (field.type === "currency") {
         return Format.formatCurrency(field.value as number);
      } else if (field.type === "multi-select") {
         return (field.value as string[]).join(", ");
      } else if (field.type === "number") {
         return Format.formatNumber(field.value as number);
      } else {
         return field.value;
      }
   }

   private getFormattedDay(day: any) {
      return DateUtils.formatDetachedDay(unwrap(day), defaultStore.getDateFormat());
   }

   private getFormattedDate(date: number) {
      const formatDate = new Date(date);
      return DateUtils.getShortNumericDate(formatDate, defaultStore.getDateFormat(), {
         yearFormat: DateUtils.YearFormat.FULL,
      });
   }

   private unlockUser = () => {
      if (!(this.permissions.isAdmin || this.permissions.canUnlockUsers)) {
         return;
      }
      const confirmMsg =
         "This user was locked out due to too many failed password attempts. There may be someone else trying to access their account. Are you sure you want to unlock them?";
      const panes = [new ConfirmActionPaneViewModel("Unlock", confirmMsg)];
      const modal = new Modal();
      modal.setPanes(panes);
      return modalManager.showModal(
         modal,
         null,
         {
            class: "confirm-action-modal--user-lockout",
         },
         async (modal, modalStatus) => {
            if (modalStatus === "cancelled") {
               return;
            }
            try {
               await PersonStore.unlockUser(this.personId).payload;
               this.person()?.failedLoginCount(0);
            } catch (err) {
               return console.log("Error in unlockUser: ", err);
            }
         },
      );
   };

   private getHourlyWageString() {
      return `${this.person()?.hourlyWage()}/hr`;
   }

   private getPersonTypeText(person: Person) {
      let typeText = "";
      if (person.isUser() && person.isAssignable()) {
         typeText = "Assignable User";
      } else if (person.isUser()) {
         typeText = "User";
      } else if (person.isAssignable()) {
         typeText = "Assignable Resource";
      }
      if (person.isUser() && person.invitePending() && !this.inviteFlowDeprecated()) {
         typeText += " (Invite Pending)";
      }
      return typeText;
   }

   private showDeletePersonModal = () => {
      if (!this.permissions.canDeletePeople || this.person() == null) {
         return;
      }
      const pane1 = new ConfirmDeletePersonPaneViewModel(this.person()!.fullName());
      const modal = new Modal();
      modal.setPanes([pane1]);
      return modalManager.showModal(
         modal,
         null,
         {
            class: "confirm-delete-person-modal",
         },
         async (modal, exitStatus) => {
            if (exitStatus === "finished") {
               try {
                  await PersonStore.deletePerson(this.personId).payload;
                  this.dispose(() => router.back());
               } catch (err) {
                  console.log("error: ", err);
               }
            }
         },
      );
   };

   private resendInvite = async () => {
      if (!this.person()?.invitePending() || this.resentInvite()) {
         return;
      }
      this.resentInvite(true);
      try {
         await InviteStore.resendInvite(this.personId);
      } catch (error) {
         console.log("error", error);
      }
   };

   private refreshAssociatedProjects = async (): Promise<void> => {
      const notification = new ProgressNotification({
         message: "Refreshing associated projects...",
         actions: [],
      });
      notificationManagerInstance.show(notification);
      try {
         await PersonStore.refreshAssociatedProjects(this.personId, { project_ids: [] }).payload;
         notification.success({ message: "Refreshed successfully." });
         const dismissFunc = () => notificationManagerInstance.dismiss(notification);
         setTimeout(dismissFunc, 5000);
      } catch (e) {
         notification.failed({ message: "Failed to refresh." });
      }
   };

   private loadSupportingData = async (): Promise<void> => {
      const categorizedTags: Record<string, Array<ValueSet<string>>> | null = this.permissions
         .tagCategoriesEnabled
         ? { Uncategorized: [] }
         : null;
      const tagOptions: Tag[] = [];
      const groupOptions: Array<MultiDropDownItem<string>> = [];
      const accessGroupOptions: Array<MultiDropDownItem<string>> = [];
      const assignableGroupOptions: Array<MultiDropDownItem<string>> = [];
      const permissionOptions: Array<ValueSet<string, { is_admin: boolean }>> = [];
      const notificationProfileOptions: Array<ValueSet<string>> = [];
      const positionOptions: Array<DropDownItem<string>> = [];

      const loadGroups = async () => {
         const groupStream = await GroupStore.findGroupsStream().stream;
         for await (const group of groupStream) {
            // Multiple group arrays protect against synchronization issues
            groupOptions.push(new MultiDropDownItem(group.name, group.id, false));
            accessGroupOptions.push(new MultiDropDownItem(group.name, group.id, false));
            assignableGroupOptions.push(new MultiDropDownItem(group.name, group.id, false));
         }
         this.state.groupOptions(groupOptions);
         this.state.accessGroupOptions(accessGroupOptions);
         this.state.assignableGroupOptions(assignableGroupOptions);
      };
      const loadPermissions = async () => {
         const permissionStream = await PermissionStore.findPermissionLevelsStream({}).stream;
         for await (const permission of permissionStream) {
            permissionOptions.push(
               new ValueSet({
                  name: permission.name,
                  value: permission.id,
                  baggage: { is_admin: permission.is_admin },
               }),
            );
         }
         this.state.permissionOptions(permissionOptions);
      };
      const loadNotificationProfiles = async () => {
         if (!this.permissions.canEditNotifications) return;

         const stream = await NotificationProfileStore.findNotificationProfilesStream({}).stream;
         for await (const notificationProfile of stream) {
            notificationProfileOptions.push(
               new ValueSet({ name: notificationProfile.name, value: notificationProfile.id }),
            );
         }
         this.state.notificationProfileOptions(notificationProfileOptions);
      };
      const loadPositions = async () => {
         const positionStream = await PositionStore.findPositionsStream({}).stream;
         for await (const position of positionStream) {
            positionOptions.push(new DropDownItem(position.name, position.id));
         }
         this.state.positionOptions(positionOptions);
      };
      const loadTags = async () => {
         const tagStream = await TagStore.findTagsStream({}).stream;
         for await (const tag of tagStream) {
            if (this.permissions.tagCategoriesEnabled) {
               if (tag.categories.length == 0) {
                  categorizedTags!["Uncategorized"].push(
                     new ValueSet({ name: tag.name, value: tag.id, color: tag.color }),
                  );
               } else {
                  for (const category of tag.categories) {
                     if (!categorizedTags![category]) {
                        categorizedTags![category] = [];
                     }
                     categorizedTags![category].push(
                        new ValueSet({ name: tag.name, value: tag.id, color: tag.color }),
                     );
                  }
               }
            }
            tagOptions.push(new Tag(tag));
         }
         this.tagState.allTags(tagOptions);
         this.tagState.categorizedTags(categorizedTags);
      };
      const loadIntegratedFields = async () => {
         const integratedFields = await CompanyStore.getIntegratedFields().payload;
         this.state.integratedFields(integratedFields.data.people_integrated_fields);
      };
      const loadCustomFields = async () => {
         if (!this.permissions.customFieldModuleEnabled) return;
         const fields = [];
         const customFieldStream = await CustomFieldStore.findCustomFieldsStream({
            is_on_entities: [CustomFieldEntity.PERSON],
         }).stream;
         for await (const field of customFieldStream) {
            fields.push(new CustomField(field));
         }
         const values: Record<
            string,
            Observable<string | number | boolean | string[] | Date | undefined>
         > = {};
         for (const field of fields) {
            if (field.type() == "multi-select") {
               values[field.id] = observableArray();
            } else {
               values[field.id] = observable();
            }
         }
         this.state.customFieldValues = values;
         if (this.person()! && this.person()!.customFields) {
            for (const appliedField of this.person()!.customFields!()) {
               if (this.state.customFieldValues[appliedField.fieldId]) {
                  if (appliedField.type == CustomFieldType.SELECT) {
                     for (const availableField of fields) {
                        if (availableField.id == appliedField.fieldId) {
                           for (const option of availableField.options()) {
                              if (
                                 (option as MultiDropDownItem<string>).value() == appliedField.value
                              ) {
                                 this.state.customFieldValues[appliedField.fieldId](option);
                                 break;
                              }
                           }
                           break;
                        }
                     }
                  } else if (appliedField.type == CustomFieldType.MULTI_SELECT) {
                     for (const availableField of fields) {
                        if (availableField.id == appliedField.fieldId) {
                           this.state.customFieldValues[appliedField.fieldId]([]);
                           for (const option of availableField.options()) {
                              if (
                                 (appliedField.value as string[]).indexOf(
                                    (option as MultiDropDownItem<string>).value(),
                                 ) != -1
                              ) {
                                 (option as MultiDropDownItem<string>).selected(true);

                                 const multiSelectFields = this.state.customFieldValues[
                                    appliedField.fieldId
                                 ] as ObservableArray;
                                 multiSelectFields.push(option);
                              }
                           }
                           break;
                        }
                     }
                  } else if (appliedField.type == CustomFieldType.DATE) {
                     this.state.customFieldValues[appliedField.fieldId](
                        DateUtils.getAttachedDate(appliedField.value as number),
                     );
                  } else {
                     this.state.customFieldValues[appliedField.fieldId](appliedField.value);
                  }
               }
            }
         }

         // Remove any integration locked custom fields
         const integrationLockedFieldIds: string[] = [];
         for (const field of this.state.integratedFields()) {
            if (field.locked) {
               integrationLockedFieldIds.push(field.property);
            }
         }
         const filteredAvailableFields = fields.filter((field) => {
            return !integrationLockedFieldIds.includes(field.id);
         });

         this.state.availableCustomFields(filteredAvailableFields);
      };
      const loadTimeOff = async () => {
         if (this.permissions.canViewPeopleTimeoff == false) return [];

         const timeOffStream = await TimeOffStore.findTimeOffStream({
            sort_by: FindTimeOffSortBy.START_DATE,
            sort_direction: Order.DESCENDING,
            filters: [
               {
                  name: FindTimeOffFilter.PERSON_ID,
                  type: FilterFieldType.TEXT,
                  property: FindTimeOffFilter.PERSON_ID,
                  value_sets: [{ value: this.person()!.id }],
               },
            ],
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         }).stream;
         const timeOffOptions: TimeOff[] = [];
         for await (const timeOff of timeOffStream) {
            timeOffOptions.push(new TimeOff(timeOff));
         }
         this.timeOffState.timeOff(timeOffOptions);
      };
      this.state.languageOptions(
         DefaultStoreCore.LanguageOptions.map(
            (language) => new DropDownItem(language.name, language.value),
         ),
      );
      await Promise.all([
         loadIntegratedFields(),
         loadGroups(),
         loadPermissions(),
         loadNotificationProfiles(),
         loadPositions(),
         loadTags(),
         loadTimeOff(),
         loadCustomFields(),
      ]);
   };

   private async loadPerson(): Promise<void> {
      this.state.displayingNotice(null);
      const person = (await PersonStore.getPersonDetails(this.personId).payload).data;

      this.setTitle(person.name.first + " " + person.name.last);
      const personDetail = {
         ...person,
         address_1: person.address_1 ?? null,
         address_2: person.address_2 ?? null,
         state_province: person.state_province ?? null,
         city_town: person.city_town ?? null,
         country: person.country ?? null,
         phone: person.phone ?? null,
         email: person.email ?? null,
         hourly_wage: person.hourly_wage ?? null,
         first_name: person.name.first,
         last_name: person.name.last,
         can_recieve_sms: person.can_receive_sms,
         can_recieve_email: person.can_receive_email,
         can_recieve_mobile: person.can_receive_mobile,
         position_id: person.position?.id,
         is_male:
            person.gender == PersonDetailBaseState.Gender.MALE
               ? true
               : person.gender == PersonDetailBaseState.Gender.FEMALE
               ? false
               : null,
         tag_instances: person.tag_instances.map(({ tag, ...instance }) => ({
            ...instance,
            ...tag,
         })),
         baggage: {
            qr_id: person.qr_id,
            company_qr_id: person.company_qr_id,
            user_lockout_limit: person.user_lockout_limit,
            group_names: authManager.getGroupNames(
               person.group_ids ? new Set(person.group_ids) : undefined,
            ),
         },
      };
      this.person(new Person(personDetail));
      const dob = this.person()?.dob();
      if (dob) {
         const dobObj = new Date(dob);
         mutateForTimezone(dobObj);
         this.person()?.dob(dobObj.getTime());
      }
      const hiredDate = this.person()?.hiredDate();
      if (hiredDate) {
         const hiredDateObj = new Date(hiredDate);
         mutateForTimezone(hiredDateObj);
         this.person()?.hiredDate(hiredDateObj.getTime());
      }
      const procoreLinkFlagValue = LaunchDarklyBrowser.getFlagValue("procore-person-link");
      const procoreCompanyId = UrlUtils.getProcoreCompanyIdFromURL();
      const procorePersonId = this.person()?.procorePersonId();
      if (
         procorePersonId &&
         this.person()?.permissionLevel()?.isAdmin() &&
         procoreCompanyId &&
         procoreLinkFlagValue
      ) {
         renderReactComponent("link-to-procore-entity", "LinkToProcoreEntity", {
            entityType: "person",
            procoreEntityId: procorePersonId,
            procoreCompanyId: procoreCompanyId,
         });
      }
      if (this.supportingDataLoaded == false) {
         try {
            await this.loadSupportingData();
            this.supportingDataLoaded = true;
         } catch (err) {
            console.error("Error loading supporting data: ", err);
         }
      }
      this.state.loadSelectedGroups();
      this.state.loadPhoto();
      this.attachmentState.loadAttachments();
   }

   private dismissNotice = () => {
      this.state.displayingNotice(null);
   };

   private dispose = (next: () => void) => {
      legacyPersonStore.emptyVault(LegacyPersonStore.ValutKey.GET_PERSON);
      legacyPersonStore.emptyVault(LegacyPersonStore.ValutKey.PERSON_ACTIVITY);
      fanoutManager.unsubscribe("vm.PersonDetailViewModel", FanoutManager.Channel.PEOPLE, {
         personId: this.personId,
      });
      fanoutManager.unsubscribe("vm.PersonDetailViewModel", FanoutManager.Channel.PERSON_ACTIVITY, {
         personId: this.personId,
         category: this.activityState.selectedActivityCategory(),
      });
      next();
   };
}
