import "./assignments.styl"
import template from "./assignments.pug"
import { App } from "@/views/app"
import { Guid as GUID } from "@/lib/utils/guid"
import { router } from "@/lib/router"
import { ValidationUtils } from "@/lib/utils/validation"
import { DateUtils } from "@/lib/utils/date"
import ko from "knockout";
import $ from "jquery"

import { FormatMessage } from "@/lib/utils/message-formatter"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import * as BrowserStorageUtils from "@/lib/utils/browser-storage"
import { Format as FormatUtils } from "@/lib/utils/format"
import { PageCursor } from "@/lib/utils/page-cursor"
import { ANY } from "@/lib/components/chip-filter/chip-filter"
import { buildDateFilterInstance, } from '@/lib/utils/chip-filter-helper';

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { FanoutManager } from "@/lib/managers/fanout-manager"
import { fanoutManager } from "@/lib/managers/fanout-manager"
import { ProjectStore } from "@/stores/project-store.core"
import { projectStore as legacyProjectStore} from "@/stores/project-store"
import { Assignment2Store } from "@/stores/assignment-2-store.core"
import { DefaultStore } from "@/stores/default-store"
import { defaultStore } from "@/stores/default-store"
import { dragManager } from "@/lib/managers/drag-manager"
import { alertManager } from "@/lib/managers/alert-manager"
import { TagStore } from "@/stores/tag-store.core"
import { groupStore } from "@/stores/group-store"
import { AlertStore } from "@/stores/alert-store.core"
import { SavedViewStore } from "@/stores/saved-view-store.core";
import {
   Notification,
   notificationManagerInstance,
   Action
} from "@/lib/managers/notification-manager";

### Mediators ###
import { PageableLoadingMediator } from "@/lib/mediators/pageable-loading-mediator"
import { ScrollbarMediator } from "@/lib/mediators/scrollbar-mediator"
import { ChipFilterMediator } from "@/lib/mediators/chip-filter-mediator"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"
import { CreateMessageOrAlertPane } from "@/lib/components/modals/create-message-or-alert-pane"
import { ProcessingNoticePaneViewModel } from "@/lib/components/modals/processing-notice-pane"
import { ConfirmActionPaneViewModel } from "@/lib/components/modals/confirm-action-pane"
import { SingleAssignmentTransferPane } from "@/lib/components/modals/single-assignment-transfer-pane/single-assignment-transfer-pane"
import { GanttPane } from "@/lib/components/modals/gantt-pane/gantt-pane"
import { ConfirmAssignmentEndPane } from "@/lib/components/modals/confirm-assignment-end-pane"
import { SelectPeopleByAssignmentPane } from "@/lib/components/modals/select-people-by-assignments-pane"
import { CreateCannedMessagePane } from "@/lib/components/modals/create-canned-message-pane"
import { RecipientsListPane } from "@/lib/components/modals/recipients-list-pane"
import { InvalidBatchEditDatesPane, InvalidAssignment } from "./modals/invalid-batch-edit-dates-pane"
import { SaveViewPane } from "@/lib/components/modals/save-view-pane/save-view-pane";

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupListPane } from "@/lib/components/popup/popup-list-pane"
import { PopupListItem } from "@/lib/components/popup/popup-list-item"
import { CalendarPane } from "@/lib/components/popup/calendar-pane"
import { AutoMessageConfigPane } from "@/lib/components/popup/auto-message-pane"
import { RapidAssignConfigPane } from "@/lib/components/popup/rapid-assign-config-pane/rapid-assign-config-pane"
import { BatchConfigPane, MessageSendOption } from "@/lib/components/popup/batch-config-pane/batch-config-pane"
import { BenchFilterChipsPane } from "@/lib/components/popup/bench-filter-chips-pane"
import { BoardsConfigPane } from "@/lib/components/popup/boards-config-pane"

### Models ###
import { AssignmentCard } from "@/models/assignment-card"
import { Placeholder } from "@/models/placeholder"
import { ValueSet } from "@/models/value-set"
import { CannedMessage } from "@/models/canned-message"
import { PermissionLevel } from "@/models/permission-level"
import { Project } from "@/models/project"
import { Person } from "@/models/person"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"

### Utils ###
import { computeHourlyRate } from "@/lib/utils/hourly-rate"
import { BoardsPage } from "@/views/assignments/boards-page"
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

### React Renderers ###
import renderShiftProjectModal from "@/react/render-shift-project-modal"

### Bugsnag ###
import Bugsnag from "@bugsnag/js"
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

PROJECTS_PER_REQUEST = 10
DEFAULT_RESOURCE_BENCH_STATE = "available"
BENCH_CARDS_PER_REQUEST = 40
SCROLL_DISTANCE_FROM_CONTAINER_VIEW_PORT = 100;
MOUSE_SCROLL_SPEED = 20;
TOUCH_SCROLL_SPEED = 15;
PROJECT_CONTAINER_CLASS = '.assigments-content'

export class AssignmentsViewModel extends PageContentViewModel
   constructor: (queryParams, templateOverride, title) ->
      super(templateOverride || template(), title || "Boards")

      @BOARDS_VIEW_CONFIG_DEFAULTS = {
         showResourcesNotInGroup: false
         multipleColumnLayout: false
      }
      @boardsViewConfig = ko.observable(@BOARDS_VIEW_CONFIG_DEFAULTS)

      @RESOURCE_GROUP_CHECK_ENABLED = LaunchDarklyBrowser.getFlagValue("gantt-group-filter-by-default")
      @CALL_GET_PROJECT_SINGLE_BOARDS_IN_LC_CORE_API = LaunchDarklyBrowser.getFlagValue("call-get-project-single-boards-in-lc-core-api");
      @showResourcesNotInGroup = ko.computed(() =>
         # We're defaulting to true, because that was the traditional behavior before we added a config option for the user
         return if @RESOURCE_GROUP_CHECK_ENABLED then @boardsViewConfig().showResourcesNotInGroup else true
      )

      @MULTIPLE_COULUMN_LAYOUT_ENABLED = LaunchDarklyBrowser.getFlagValue("boards-multiple-column-layout")
      @multipleColumnLayout = ko.computed(() =>
         # Defaulting to false because that was the traditional behavior of the page before this feature
         return if @MULTIPLE_COULUMN_LAYOUT_ENABLED then @boardsViewConfig().multipleColumnLayout else false
      )

      # Show the 'config' button on the page if at least one option is active.
      @BOARDS_CONFIG_PANE_ENABLED = @RESOURCE_GROUP_CHECK_ENABLED or @MULTIPLE_COULUMN_LAYOUT_ENABLED

      @tagsData = ko.observableArray()

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canViewRequests = authManager.checkAuthAction(PermissionLevel.Action.VIEW_REQUESTS)
      @canManageRequests = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS)
      @canViewRequestsNotes = authManager.checkAuthAction(PermissionLevel.Action.VIEW_REQUESTS_NOTES)
      @canManageAssignments = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ASSIGNMENTS)
      @canViewPeople = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE)
      @canViewProject = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT)
      @canAccessGanttPage = authManager.checkAuthAction(PermissionLevel.Action.ACCESS_GANTT_PAGE)
      @canViewProjectTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_TAGS)
      @canViewProjectFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)
      @canViewProjectSensitive = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_SENSITIVE)
      @canViewPeopleTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS)
      @canViewPeopleFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_FINANCIALS)
      @canViewPeopleSensitive = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_SENSITIVE)
      @canCreateMessages = authManager.checkAuthAction(PermissionLevel.Action.CREATE_MESSAGES)
      @canManageAlerts = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ALERTS)
      @canViewAllStatuses = authManager.checkAuthAction(PermissionLevel.Action.CAN_VIEW_ALL_STATUSES)
      @visibleStatusIds = authManager.authedUser()?.permissionLevel()?.visibleStatusIds()
      @canEditProjectDetails = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_DETAILS)
   
      ###------------------------------------
         Data Properties
      ------------------------------------###
      @actingAsParent = templateOverride?
      @initialized = false
      @projectScrollbarMediator = new ScrollbarMediator()
      @chipFilterMediator = new ChipFilterMediator()
      @projectTopLoadingMediator = new PageableLoadingMediator()
      @projectLoadingMediator = new PageableLoadingMediator()
      @topBenchLoadingMediator = new PageableLoadingMediator()
      @benchLoadingMediator = new PageableLoadingMediator()
      
      @projectPageCursor = @getNewProjectCursor()
      @benchPageCursor = @getNewBenchCursor()
      @hasMoreToLoad = ko.pureComputed => return @projectPageCursor.hasMoreBottomRecords()
      @projectPaginationNextStartingAfter = undefined
      @projectPaginationTotalPossible = undefined

      @tagCategoriesEnabled = authManager.companyModules()?.tagCategories
      @isAdmin = authManager.isAdmin()
      @customFieldModuleEnabled = authManager.companyModules()?.customFields
      # TODO: Update this.
      @showRequestCategorized = true
      @projectsOnlyShow = ko.observable(null)
      @currentLoadedProjectKeys = new Set()

      @currentProjectRequest = ko.observable(null)

      @projects = ko.observableArray()
      # These observables are all used to support the lazy-loading experience
      @projectAssignments = ko.observable({})
      @labeledAssignmentCards = ko.observableArray()
      @projectRequests = ko.observable({})
      @labeledRequestCards = ko.observableArray()
      @appliedOnlyShowFilter = ko.observable(null)
      # No need for the 2 below to be observables since they're not being used in our html/pug template
      @projectCumulativeHours = {}
      @projectTotalBurns = {}
      # 2 dimensional array that we will push paginated sets of project-ids to.
      # When a set of projects has had its assignments/requests loaded, that set of ids will be removed
      @projectsStillLoadingAssignmentsRequests = ko.observableArray()

      @tagExprInfo = ko.observableArray()

      @projectQuery = ko.observable()

      @currentBenchRequest = ko.observable(null)

      # Card Observable Arrays
      @benchResourceCount = ko.observable("...")
      @benchResources = ko.observableArray()

      @isFiltering = ko.pureComputed =>
         return @benchFilterChips().length >= 1

      @unassignedResources = ko.observableArray()
      @assignedResources = ko.observableArray()

      # Time stripped date for pagging.
      @viewingDate = ko.observable(new Date())

      @viewingDay = ko.computed =>
         return DateUtils.getDetachedDay(@viewingDate())
      @viewingWeekDay = ko.pureComputed =>
         return DateUtils.getWeekDayAbbreviation(@viewingDate()).concat(".")
      @dateFormat = defaultStore.getDateFormat()
      @viewingDateDisplayString = ko.computed =>
         switch ko.unwrap(@dateFormat)
            when DefaultStore.DateFormat.MM_DD_YYYY then "#{@viewingDate().getMonth() + 1}/#{@viewingDate().getDate()}/#{@viewingDate().getFullYear()}"
            when DefaultStore.DateFormat.YYYY_MM_DD then "#{@viewingDate().getFullYear()}/#{@viewingDate().getMonth() + 1}/#{@viewingDate().getDate()}"
            when DefaultStore.DateFormat.YYYY_DD_MM then "#{@viewingDate().getFullYear()}/#{@viewingDate().getDate()}/{@viewingDate().getMonth() + 1}"
            when DefaultStore.DateFormat.DD_MM_YYYY then "#{@viewingDate().getDate()}/#{@viewingDate().getMonth() + 1}/#{@viewingDate().getFullYear()}"

      # Resource Bench
      @benchSearchValue = ko.observable()
      
      @resourceStateOptions = [
         new SegmentedControllerItem("Available", "available")
         new SegmentedControllerItem("Assigned", "assigned")
         new SegmentedControllerItem("Off", "off")
      ]
      @selectedResourceStateOption = ko.observable(@resourceStateOptions[0])

      @rapidMessageConfig = ko.observable(null)

      # Project Sort By
      @projectSortByOptions = [
         new DropDownItem("Name", AssignmentsViewModel.ProjectSortBy.NAME)
         new DropDownItem("Project Number", AssignmentsViewModel.ProjectSortBy.PROJECT_NUMBER)
         new DropDownItem("Earliest Start Date", AssignmentsViewModel.ProjectSortBy.EARLIEST_START_DATE)
         new DropDownItem("Latest Start Date", AssignmentsViewModel.ProjectSortBy.LATEST_START_DATE)
         new DropDownItem("Earliest End Date", AssignmentsViewModel.ProjectSortBy.EARLIEST_EST_END_DATE)
         new DropDownItem("Latest End Date", AssignmentsViewModel.ProjectSortBy.LATEST_EST_END_DATE)
         new DropDownItem("Most Complete", AssignmentsViewModel.ProjectSortBy.MOST_COMPLETE)
         new DropDownItem("Least Complete", AssignmentsViewModel.ProjectSortBy.LEAST_COMPLETE)
      ]
      @selectedProjectSortBy = ko.observable(@projectSortByOptions[0])

      # Card Sort By
      @cardSortByOptions = [
         new DropDownItem("Job Title", AssignmentsViewModel.AssignmentCardSortBy.POSITION)
         new DropDownItem("Name", AssignmentsViewModel.AssignmentCardSortBy.NAME)
      ]
      @selectedCardSortBy = ko.observable(@cardSortByOptions[0])

      # Button State
      @isProcessingDelete = ko.observable(false)
      @isProcessingUpdate = ko.observable(false)

      ###------------------------------------
         View Properties
      ------------------------------------###
      @groupIdSubscription = authManager.selectedGroupId.subscribeChange(@handleGroupChange)

      @benchScrollbarMediator = new ScrollbarMediator()

      @assignmentProjectsUpdatesAvailable = ko.observable(false)
      @refreshNotification = ko.observable(null)

      # Setup resource bench subscription
      fanoutManager.getSubscription("vm.AssignmentsViewModel",
         FanoutManager.Channel.PEOPLE_ASSIGNMENTS, () => @reloadBench_(0))

      # New subscription for assignments.
      fanoutManager.getSubscription("vm.AssignmentsViewModel", 
         FanoutManager.Channel.GROUPS_ASSIGNMENTS, @handleAssignmentRealTime)

      # New subscription for assignments.
      if authManager.checkAuthAction(PermissionLevel.Action.VIEW_REQUESTS)
         fanoutManager.getSubscription("vm.AssignmentsViewModel", 
            FanoutManager.Channel.GROUPS_REQUESTS, @handleAssignmentRealTime)

      @benchFiltersCountText = ko.pureComputed =>
         count = @benchFilterChips().length
         return "1 Filter Applied" if count == 1
         return "#{count} Filters Applied"

      # Popups
      @projectCellMorePopupBuilder = (project) =>
         return unless @canManageRequests or @canViewProject
         items = []

         projectHasStarted = new Date().getTime() >= new Date(project.startDate()).getTime()
         canShiftProject = (project.status() == "active" or project.status() == "pending") and
            !projectHasStarted and
            @canEditProjectDetails and
            @canManageRequests and
            @canManageAssignments

         if @canViewProject
            items.push(new PopupListItem({title: "Project Details", icon: "icon-building-sidemenu", callbackData: project, clickCallback: @navigateToProject}))
         if canShiftProject
            items.push(new PopupListItem({title: "Shift Project", icon: "icon-sidemenu-schedule", callbackData: project, clickCallback: @showShiftProjectModal}))
         if @canManageRequests
            items.push(new PopupListItem({title: "Create Request", icon: "icon-position-placeholder", callbackData: project, clickCallback: @showNewPlaceholderModal}))
         if @canManageAlerts
            items.push(new PopupListItem({title: "Send Assignment Alerts", icon: "icon-sidemenu-notifications", callbackData: project, clickCallback: @alertProjectsPeople}))
         if @canCreateMessages
            items.push(new PopupListItem({title: "Message People", icon: "icon-sidemenu-messages", callbackData: project, clickCallback: @messageProjectsPeople}))

         return new Popup("Create New", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new PopupListPane("Create New", items)],
            ['project-cell__detail-btn__more-btn', 'project-cell__detail-btn'], ["project-cell__popup--more"])

      @projectCellMorePopupWrapper = {
         popupBuilder: @projectCellMorePopupBuilder
         options: {triggerClasses: ['project-cell__detail-btn__more-btn']}
      }

      ###------------------------------------
         Bench Controls
      ------------------------------------###
      @showingBenchPreviousState = ko.observable(null)
      @showingBench = ko.observable(true)
      @rapidAssignActive = ko.observable(false)
      @rapidAssignActive.subscribe((newValue) =>
         if newValue == false
            for projectId from @rapidAssignProjectsToUpdate
               @refreshProject(projectId)
               @rapidAssignProjectsToUpdate.delete(projectId)
      )

      @rapidStartDate = ko.observable()
      @rapidEndDate = ko.observable()

      @rapidSelectedStatus = ko.observable(null)
      
      @selectedRapidStartTime = ko.observable()
      @selectedRapidEndTime = ko.observable()
      @rapidPercentAllocated = ko.observable(null)

      @rapidOvertime = ko.observable()
      @rapidStraightHours = ko.observable()
      @rapidOvertimeHours = ko.observable()
      @rapidUnpaidHours = ko.observable()

      @rapidSundayOtRate = ko.observable()
      @rapidMondayOtRate = ko.observable()
      @rapidTuesdayOtRate = ko.observable()
      @rapidWednesdayOtRate = ko.observable()
      @rapidThursdayOtRate = ko.observable()
      @rapidFridayOtRate = ko.observable()
      @rapidSaturdayOtRate = ko.observable()

      @rapidSunday = ko.observable(false)
      @rapidMonday = ko.observable(true)
      @rapidTuesday = ko.observable(true)
      @rapidWednesday = ko.observable(true)
      @rapidThursday = ko.observable(true)
      @rapidFriday = ko.observable(true)
      @rapidSaturday = ko.observable(false)

      @rapidMessageType = ko.observable()
      @rapidMessageScheduleDate = ko.observable()
      @rapidMessageScheduleTime = ko.observable()
      
      @rapidAssignConfig = {
         endDate: @rapidEndDate
         endTime: @selectedRapidEndTime
         messageScheduleDate: @rapidMessageScheduleDate
         messageScheduleTime: @rapidMessageScheduleTime
         messageType: @rapidMessageType
         overtime: @rapidOvertime
         overtimeHours: @rapidOvertimeHours
         percentAllocated: @rapidPercentAllocated
         rapidAssignActive: @rapidAssignActive
         selectedStatus: @rapidSelectedStatus
         startDate: @rapidStartDate
         startTime: @selectedRapidStartTime
         straightHours: @rapidStraightHours
         unpaidHours: @rapidUnpaidHours

         sunday: @rapidSunday
         monday: @rapidMonday
         tuesday: @rapidTuesday
         wednesday: @rapidWednesday
         thursday: @rapidThursday
         friday: @rapidFriday
         saturday: @rapidSaturday

         sundayOtRate: @rapidSundayOtRate
         mondayOtRate: @rapidMondayOtRate
         tuesdayOtRate: @rapidTuesdayOtRate
         wednesdayOtRate: @rapidWednesdayOtRate
         thursdayOtRate: @rapidThursdayOtRate
         fridayOtRate: @rapidFridayOtRate
         saturdayOtRate: @rapidSaturdayOtRate
      }

      @rapidAssignProjectsToUpdate = new Set()

      @benchTabIconClass = ko.pureComputed =>
         return "icon-bench" unless @showingBench()
         return "icon-left-arrow-black"

      ###------------------------------------
         Batch Editing
      ------------------------------------###
      @isBatchEditing = ko.observable(false)
      @batchSelectedAssignments = ko.observableArray()
      
      @batchEditStartDate = ko.observable(null)
      @batchEditEndDate = ko.observable(null)
      @batchEditStartTime = ko.observable(null)
      @batchEditEndTime = ko.observable(null)
      @batchEditPercentAllocated = ko.observable(null)
      @batchEditWorkDays = ko.observable(false)
      @batchSunday = ko.observable(false)
      @batchMonday = ko.observable(true)
      @batchTuesday = ko.observable(true)
      @batchWednesday = ko.observable(true)
      @batchThursday = ko.observable(true)
      @batchFriday = ko.observable(true)
      @batchSaturday = ko.observable(false)
      @batchEditSelectedStatus = ko.observable()
      @batchProjectSelection = ko.observable(null)
      @batchCostCodeSelection = ko.observable(null)
      @batchLabelSelection = ko.observable(null)
      @batchMessageType = ko.observable("dont-send")
      @batchMessageScheduleDate = ko.observable()
      @batchMessageScheduleTime = ko.observable()

      @batchAlertStatus = ko.pureComputed =>
         switch @batchMessageType()
            when "send-instantly" then "Alerts: Send Instantly"
            when "save-draft" then "Alerts: Save Drafts"
            when "schedule" then "Alerts: Scheduled"
            when "dont-send" then "Alerts: Not Sending"
            else ""

      @batchConfig = {
         startDate: @batchEditStartDate
         endDate: @batchEditEndDate
         startTime: @batchEditStartTime
         endTime: @batchEditEndTime
         percentAllocated: @batchEditPercentAllocated
         editWorkDays: @batchEditWorkDays
         sunday: @batchSunday
         monday: @batchMonday
         tuesday: @batchTuesday
         wednesday: @batchWednesday
         thursday: @batchThursday
         friday: @batchFriday
         saturday: @batchSaturday
         selectedProject: @batchProjectSelection
         selectedCostCode: @batchCostCodeSelection
         selectedLabel: @batchLabelSelection
         messageType: @batchMessageType
         messageScheduleDate: @batchMessageScheduleDate
         messageScheduleTime: @batchMessageScheduleTime
         selectedStatus: @batchEditSelectedStatus
      }

      @batchHasSelections = ko.pureComputed =>
         return @batchSelectedAssignments().length >= 1

      @batchSaveEnabled = ko.pureComputed =>
         return (
            @batchSelectedAssignments().length >= 1 and
            (
               @batchEditStartDate()? or
               @batchEditEndDate()? or
               @batchEditStartTime()? or
               @batchEditPercentAllocated()? or
               @batchEditEndTime()? or
               @batchProjectSelection()? or
               @batchEditSelectedStatus()? or
               (
                  @batchEditWorkDays() and
                  (
                     @batchSunday() or
                     @batchMonday() or
                     @batchTuesday() or
                     @batchWednesday() or
                     @batchThursday() or
                     @batchFriday() or
                     @batchSaturday()
                  )
               )
            )
         )

      # Chip Filter Properties.
      @filterChips = ko.observableArray()

      @labeledFilterOptions = ko.observable()
      @defaultChips = []

      @benchFilterChips = ko.observableArray()

      @benchLabeledFilterOptions = ko.observable()

      # Check if we want to load from saved view or not.
      if queryParams?.viewId?
         @loadSavedView(queryParams.viewId)
      # If we are specifically searching by a project we do not want to load existing filters.
      else if queryParams?.projectSearchFilter? and ValidationUtils.validateInput(queryParams.projectSearchFilter)
         @projectQuery(decodeURIComponent(queryParams.projectSearchFilter))

         @makeInitialLoadCalls()
      else
         @setupStandardView(queryParams)

      ###------------------------------------
         Popups
      ------------------------------------###
      @boardsConfigPopupBuilder = =>
         handleApplyConfig = (config) =>
            if config?
               @boardsViewConfig(config)
            else
               @boardsViewConfig(@BOARDS_VIEW_CONFIG_DEFAULTS)

            key = BrowserStorageUtils.BrowserLocalStorageKey.BOARDS_CONFIG
            BrowserStorageUtils.storeJsonValue(key, config)

            @makeInitialLoadCalls()

         return new Popup("", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new BoardsConfigPane(@boardsViewConfig(), handleApplyConfig)],
            ['gantt-toolbar__project-config', 'gantt-toolbar__text-btn__text'], ["project-gantt__config-popup"])

      @boardsConfigPopupWrapper = {
         popupBuilder: @boardsConfigPopupBuilder
         options: {triggerClasses: ['gantt-toolbar__project-config']}
      }

      @benchFilterPopupBuilder = =>
         return new Popup("Resource Filters", Popup.FrameType.BELOW,
            Popup.ArrowLocation.TOP_LEFT,
            [new BenchFilterChipsPane(@benchFilterChips, @benchLabeledFilterOptions)],
            ['resource-bench__filter-settings-btn', 'icon-gear-dark'],
            ['bench-filter-popup'])

      @benchFilterPopupWrapper = ko.observable({
         popupBuilder: @benchFilterPopupBuilder,
         options: {triggerClasses: ['icon-gear-dark']}
      })

      # Assignment Calendar
      @assignmentsCalendarPopupBuilder = =>
         return new Popup("Select Date", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new CalendarPane(@viewingDate)], ['assignments-toolbar__btn', 'icon-calendar'],
            ['assignments-page__popup--toolbar-calendar'])

      @assignmentsCalendarPopupWrapper = ko.observable({
         popupBuilder: @assignmentsCalendarPopupBuilder,
         options: {triggerClasses: ['icon-calendar']}
      })

      # Rapid Assign Message Config
      @rapidAssignMessagePopupBuilder = =>
         return new Popup("Configure Messaging", Popup.FrameType.ABOVE, Popup.ArrowLocation.BOTTOM_RIGHT,
            [new AutoMessageConfigPane(@rapidMessageConfig)], ['resource-bench__configure-message-btn', 'icon-message'],
            ['assignments-page__popup--auto-message-config'])

      @rapidAssignMessagePopupWrapper = ko.observable({
         popupBuilder: @rapidAssignMessagePopupBuilder,
         options: {triggerClasses: ['resource-bench__configure-message-btn', 'icon-message']}
      })

      # Rapid Assign Config
      @rapidAssignConfigPopupBuilder = =>
         supportData = @boardsPage.state.assignmentSupportData
         costingConfig = {
            paidShiftHours: supportData.paidShiftHours
            overtimeDayRates: supportData.overtimeDayRates
         }
         return new Popup("Configure Rapid Assign", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new RapidAssignConfigPane(@rapidAssignConfig, supportData, costingConfig)], ['resource-bench__filter-settings-btn', 'icon-rapid-assign'],
            ['assignments-page__popup--rapid-assign-config'])

      @rapidAssignConfigWrapper = ko.observable({
         popupBuilder: @rapidAssignConfigPopupBuilder,
         options: {triggerClasses: ['icon-rapid-assign']}
      })

      # Batch Config
      @batchConfigPopupBuilder = =>
         supportData = @boardsPage.state.assignmentSupportData
         return new Popup("Configure Batch Edit", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new BatchConfigPane(@batchConfig, supportData)], ['resource-bench__filter-settings-btn', 'icon-rapid-assign'],
            ['assignments-page__popup--batch-config'])

      @batchConfigWrapper = ko.observable({
         popupBuilder: @batchConfigPopupBuilder,
         options: {triggerClasses: ['icon-rapid-assign']}
      })

      @boardsPage = new BoardsPage()

   makeInitialLoadCalls: =>
      @projectAssignments({})
      @labeledAssignmentCards({})
      @projectRequests({})
      @labeledRequestCards({})

      @projectPageCursor.topIndex(0)
      @projectPageCursor.bottomIndex(0)
      @projectPageCursor.lastIndex(0)
      @projectPageCursor.hasMoreBottomRecords(true)
      @initialized = true

      if @projectLoadingMediator.isInitialized()
         @reloadProjects_(@projectPageCursor.lastIndex())
      else
         @projectLoadingMediator.isInitialized.subscribe (newVal) =>
            @reloadProjects_(@projectPageCursor.lastIndex()) if newVal
      
      if @benchLoadingMediator.isInitialized()
         @reloadBench_(@projectPageCursor.lastIndex())
      else
         @benchLoadingMediator.isInitialized.subscribe (newVal) =>
            @reloadBench_(@projectPageCursor.lastIndex()) if newVal
      
      @setupViewConfigSubscriptions()
      
      @loadSupportData()

   setupViewConfigSubscriptions: ->
      @projectQuery.subscribe (newVal) =>
         if ValidationUtils.validateInput(newVal)
            router.updateUrlQueryParam(App.RouteName.ASSIGNMENTS_PAGE, "projectQuery", encodeURIComponent(newVal))
         else
            router.removeQueryParam(App.RouteName.ASSIGNMENTS_PAGE, 'projectQuery')

      @viewingDate.subscribe(@handleViewingDateChanged_)

      @selectedResourceStateOption.subscribe(@handleSelectedResourceStateOptionChanged_)

      @selectedProjectSortBy.subscribe(@handleSelectedProjectSortByChanged_)

      @selectedCardSortBy.subscribe(@handleSelectedCardSortByChanged_)
      
      @benchSearchValue.subscribe (newVal) ->
         if ValidationUtils.validateInput(newVal)
            router.updateUrlQueryParam(App.RouteName.ASSIGNMENTS_PAGE, "benchQuery", encodeURIComponent(newVal))
         else
            router.removeQueryParam(App.RouteName.ASSIGNMENTS_PAGE, 'benchQuery')      

      @filterChips.subscribe (newVal) =>
         BrowserStorageUtils.storePageFilterChips(newVal)
         filterParams = {}
         foundOnlyShow = false
         for chip in newVal
            if chip.property == "only_show" && chip.negation == false
               @projectsOnlyShow(chip.value)
               foundOnlyShow = true

            filter = {
               property: chip.property
               filterName: chip.filterName
               type: chip.type
               negation: chip.negation
            }
            filter['classifier'] = chip.classifier if chip.classifier?
            filter['customFieldId'] = chip.customFieldId if chip.customFieldId?
            if chip.value instanceof Array
               filter['value'] = chip.value.map (i) -> return ko.unwrap(i.value)
            else
               filter['value'] = chip.value

            if filterParams[chip.filterName]?
               filterParams[chip.filterName].push(filter)
            else
               filterParams[chip.filterName] = [filter]

         @projectsOnlyShow(null) unless foundOnlyShow
         
         subscribedToRequests = fanoutManager.getSubscriptionExistence("vm.AssignmentsViewModel", FanoutManager.Channel.GROUPS_REQUESTS)
         subscribedToAssignments = fanoutManager.getSubscriptionExistence("vm.AssignmentsViewModel", FanoutManager.Channel.GROUPS_ASSIGNMENTS)

         if @projectsOnlyShow() == "requests" 
            if subscribedToAssignments
               fanoutManager.unsubscribe("vm.AssignmentsViewModel", FanoutManager.Channel.GROUPS_ASSIGNMENTS)
            if !subscribedToRequests
               fanoutManager.getSubscription("vm.AssignmentsViewModel", FanoutManager.Channel.GROUPS_REQUESTS, @handleAssignmentRealTime)
         else if @projectsOnlyShow() == "assignments" 
            if subscribedToRequests
               fanoutManager.unsubscribe("vm.AssignmentsViewModel", FanoutManager.Channel.GROUPS_REQUESTS)
            if !subscribedToAssignments
               fanoutManager.getSubscription("vm.AssignmentsViewModel", FanoutManager.Channel.GROUPS_ASSIGNMENTS, @handleAssignmentRealTime)

         @reloadProjects_(0)

      @benchFilterChips.subscribe (newVal) =>
         BrowserStorageUtils.storePageFilterChips(newVal, AssignmentsViewModel.StorageKeys.BENCH_FILTER_CHIPS)

         filterParams = {}
         for chip in newVal
            filter = {
               property: chip.property
               filterName: chip.filterName
               type: chip.type
               negation: chip.negation
            }
            filter['classifier'] = chip.classifier if chip.classifier?
            filter['customFieldId'] = chip.customFieldId if chip.customFieldId?
            if chip.value instanceof Array
               filter['value'] = chip.value.map (i) -> return ko.unwrap(i.value)
            else
               filter['value'] = chip.value

            if filterParams[chip.filterName]?
               filterParams[chip.filterName].push(filter)
            else
               filterParams[chip.filterName] = [filter]

         @reloadBench_(0)

   setupStandardView: (queryParams) =>
      savedBoardsConfig = BrowserStorageUtils.getJsonValue(BrowserStorageUtils.BrowserLocalStorageKey.BOARDS_CONFIG)
      if savedBoardsConfig?
         @boardsViewConfig(savedBoardsConfig)

      storedChips = BrowserStorageUtils.getPageFilterChips()
      if storedChips?
         @filterChips(storedChips)
         # Check stored chips for "only_show"
         for chip in storedChips
            if chip.property == "only_show"
               @refineOnlyShowFilter(@filterChips())
      
      storedBenchChips = BrowserStorageUtils.getPageFilterChips(AssignmentsViewModel.StorageKeys.BENCH_FILTER_CHIPS)
      @benchFilterChips(storedBenchChips) if storedBenchChips?

      # Setup normal query params. 
      if queryParams?.projectQuery? and ValidationUtils.validateInput(queryParams.projectQuery)
         @projectQuery(decodeURIComponent(queryParams.projectQuery))
      
      if queryParams?.dayFilter?
         newDate = DateUtils.getAttachedDate(Number(queryParams.dayFilter))
         newDate = new Date() unless 2100 >= newDate.getFullYear() >= 2000
         @viewingDate(newDate)
      
      if queryParams?.benchQuery? and ValidationUtils.validateInput(queryParams.benchQuery)
         @benchSearchValue(decodeURIComponent(queryParams.benchQuery))
      
      if queryParams?.resourceBenchState? and ValidationUtils.validateInput(queryParams.resourceBenchState)
         resourceBenchState = queryParams?.resourceBenchState
         @getSegmentItemByValue_ @resourceStateOptions, resourceBenchState, DEFAULT_RESOURCE_BENCH_STATE, (item) =>
            @selectedResourceStateOption(item)
      
      if queryParams?.projectSortBy? and ValidationUtils.validateInput(queryParams.projectSortBy)
         projectSortBy = queryParams?.projectSortBy or AssignmentsViewModel.ProjectSortBy.NAME
         @setDropDownItemByValue_ @projectSortByOptions, projectSortBy, AssignmentsViewModel.ProjectSortBy.NAME, (item) =>
            @selectedProjectSortBy(item)
      
      if queryParams?.cardSortBy? and ValidationUtils.validateInput(queryParams.cardSortBy)
         cardSortBy = queryParams?.cardSortBy or AssignmentsViewModel.AssignmentCardSortBy.POSITION
         @setDropDownItemByValue_ @cardSortByOptions, cardSortBy, AssignmentsViewModel.AssignmentCardSortBy.POSITION, (item) =>
            @selectedCardSortBy(item)
      
      @makeInitialLoadCalls()

   ###------------------------------------
      View Methods
   ------------------------------------###
   onCardDrag: (element, isDragging) =>
      yAxis = 0
      xAxis = 0
      SCROLL_SPEED = MOUSE_SCROLL_SPEED

      mouseMoveHandler = (e) -> 
         return if e.clientY == 0
         SCROLL_SPEED = MOUSE_SCROLL_SPEED
         yAxis = e.clientY
         xAxis = e.clientX

      touchMoveHandler = (e) -> 
         SCROLL_SPEED = TOUCH_SCROLL_SPEED
         yAxis = e.touches[0]?.clientY
         xAxis = e.touches[0]?.clientX

      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('touchmove', touchMoveHandler)

      scrollIfMouseIsAtTopOrBottom = () ->
         assignmentContent = $(PROJECT_CONTAINER_CLASS)
         { bottom, top, left, width } = assignmentContent[0].getBoundingClientRect()

         if (!yAxis || !xAxis)
            if isDragging()
               # Setting timeout, for purpose of continuing the event loop of checking to
               # scroll up/down. Otherwise, A card could still be dragged and without a position update.
               return setTimeout(scrollIfMouseIsAtTopOrBottom, 10)
            else
               # If we are here then the card is no longer dragging, looping complete.
               return

         topDelta = Math.abs(yAxis - top)
         bottomDelta = Math.abs(bottom - yAxis)
         isWithinContainer = xAxis > left && xAxis < (left + width)

         if isWithinContainer
            if topDelta < SCROLL_DISTANCE_FROM_CONTAINER_VIEW_PORT
               assignmentContent.scrollTop(assignmentContent.scrollTop() - SCROLL_SPEED)
            else if bottomDelta < SCROLL_DISTANCE_FROM_CONTAINER_VIEW_PORT
               assignmentContent.scrollTop(assignmentContent.scrollTop() + SCROLL_SPEED)

         if isDragging()
            setTimeout(scrollIfMouseIsAtTopOrBottom, 10)
         else 
            document.removeEventListener('mousemove', mouseMoveHandler)
            document.removeEventListener('touchmove', touchMoveHandler)

      scrollIfMouseIsAtTopOrBottom()

   getNewProjectCursor: () =>
      return new PageCursor({
         cacheId: "#{authManager.selectedGroupId()}-boards-projects"
         defaultLimit: PROJECTS_PER_REQUEST
         loader: (params, callback) =>
            return callback(null) if params.skip >= @projectPaginationTotalPossible

            params.sort_by = params.sort
            if params.sort == "project_number"
               params.sort_by = "job_number"
            if params.sort == "earliest_start_date"
               params.sort_by = "start_date"
               params.sort_direction = "ascending"
            if params.sort == "latest_start_date"
               params.sort_by = "start_date"
               params.sort_direction = "descending"
            if params.sort == "earliest_est_end_date"
               params.sort_by = "est_end_date"
               params.sort_direction = "ascending"
            if params.sort == "latest_est_end_date"
               params.sort_by = "est_end_date"
               params.sort_direction = "descending"
            if params.sort == "least_complete"
               params.sort_by = "percent_complete"
               params.sort_direction = "ascending"
            if params.sort == "most_complete"
               params.sort_by = "percent_complete"
               params.sort_direction = "descending"
            params.starting_after = @projectPaginationNextStartingAfter if @projectPaginationNextStartingAfter

            params.filters = @transformFiltersWithValueSets(params.filters)

            @appliedOnlyShowFilter(params.filters.find((f) => f.property == "only_show"))

            if authManager.selectedGroupId() != "my-groups"
               params.group_id = authManager.selectedGroupId()

            try
               results = await ProjectStore.getProjectList({
                  ...params,
                  show_resources_not_in_group: @showResourcesNotInGroup(),
                  # request_per_day is used in the 'Hide Empty Projects' filter
                  request_per_day: authManager.authedUser().preferences().requestPerDay(),
               }).payload

               @projectPaginationNextStartingAfter = results.pagination.next_starting_after
               @projectPaginationTotalPossible = results.pagination.total_possible

               projects = results.data.map (item) => new Project({
                  # for some non-admin users in production, there was bug where address_1 field was not being returned from
                  # find-projects-list-paginated, so we're adding an explicit null here so that the project model doesn't throw an error
                  # (Mar 5th, 2024) In response to WFP-2254 we're extending to also include a default of null for the other nullable fields
                  timezone: null
                  country: null,
                  state_province: null,
                  city_town: null,
                  zipcode: null,
                  address_1: null,
                  address_2: null,
                  est_end_date: null,
                  start_date: null,
                  ...item,
               })
               return callback(null, {
                  ...results.pagination,
                  projects,
               })
            catch err
               return callback(err)
         sizeOf: (data) ->
            return data?.projects.length or 0
      })

   getNewBenchCursor: () =>
      return new PageCursor({
         cacheId: "#{authManager.selectedGroupId()}-boards-bench"
         defaultLimit: BENCH_CARDS_PER_REQUEST
         loader: (params, callback) => 
            try
               result = await Assignment2Store.getContextedResourceCards(params).payload;
               resourceCards = result.data.data.map (card) ->
                  return new AssignmentCard(card, true)
               return callback(null,{totalCount: result.data.total_count, cards: resourceCards});
            catch error
               console.log "Error: ", error
               return callback(error)
         sizeOf: (data) -> data.cards.length
      })

   alertProjectsPeople: (project) =>
      explanationText = "Define which people on this project you would like to alert."
      pane1 = new SelectPeopleByAssignmentPane(project.id, @viewingDate(), "Alert People", "Edit Alert Template", explanationText, {forAlert: true})
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'message-people-by-assignments-modal--alert'}, (modal, exitStatus, observableData) =>
         if exitStatus == "finished" and observableData.data.selectedAssignmentRange?
            alertContext = observableData.data.context
            peopleToMessage = observableData.data.peopleToMessage
            defaultRecipients = observableData.data.defaultRecipients
            selectedAssignmentRange = observableData.data.selectedAssignmentRange
            if observableData.data.scheduledAlertDate?
               scheduledDay = DateUtils.getDetachedDay(observableData.data.scheduledAlertDate)
            else
               scheduledDay = null

            if observableData.data.scheduledAlertDate?
               scheduledTime = observableData.data.scheduledAlertTime
            else
               scheduledTime = null

            fetchedAlertTemplate = null

            pane1 = new ProcessingNoticePaneViewModel("Getting Alert Template", true)
            processingModal = new Modal()
            processingModal.setPanes([pane1])
            modalManager.showModal processingModal, null, {class: "processing-notice-modal"}, (modal, exitStatus) =>
               return if exitStatus == "cancelled"

               options = {
                  returnOneOffTemplate: true
                  actionBtnText: "Review Recipients"
                  negationBtnText: "Cancel"
               }

               if fetchedAlertTemplate?
                  options['existingMessage'] = fetchedAlertTemplate
               else
                  fetchedAlertTemplate = new CannedMessage(AssignmentsViewModel.DefaultAlertTemplate)
                  options['existingMessage'] = fetchedAlertTemplate

               # TODO: Add in project specific tokens like roles.
               tokens = AssignmentsViewModel.BaseAssignmentTokens
               pane1 = new CreateCannedMessagePane(null, project.id, "assignment-new", tokens, "Alert People", options)
               modal = new Modal()
               modal.setPanes([pane1])
               modalManager.showModal modal, null, {class: "create-canned-message-modal"}, (modal, exitStatus, observableData) =>
                  return if exitStatus == "cancelled"
                  fetchedAlertTemplate = new CannedMessage(observableData.data.template, true)

                  if alertContext == "open"
                     actionText = "Send"
                  else if alertContext == "drafts"
                     actionText = "Save"
                  else if alertContext == "scheduled"
                     actionText = "Schedule"

                  pane1 = new RecipientsListPane(peopleToMessage, actionText, defaultRecipients)
                  modal = new Modal()
                  modal.setPanes([pane1])
                  modalManager.showModal modal, null, {class: ""}, (modal, exitStatus, observableData) =>
                     return if exitStatus == "cancelled"
                     ignoreRecipientIds = observableData.data.removedRecipients.map (person) ->
                        return person.id

                     requestBody = {
                        context: alertContext
                        template: fetchedAlertTemplate.allToJson()
                        assignments_start_day: selectedAssignmentRange.startDay
                        assignments_end_day: selectedAssignmentRange.endDay
                        people_ids_to_ignore: ignoreRecipientIds or []
                        scheduled_day: scheduledDay or undefined
                        scheduled_time: scheduledTime or undefined
                        group_id: if authManager.selectedGroupId() == "my-groups" then undefined else authManager.selectedGroupId()
                        project_id: project.id
                     }

                     try
                        payloadRequest = {
                           body: requestBody
                        }
                        await AlertStore.sendOneOffProjectAlerts(payloadRequest).payload
                     catch err
                        console.error "Error in sendOneOffProjectAlerts: ", err

            try
               alertTemplatePayload = await ProjectStore.getProjectAlertInfo(project.id, "assignment-new").payload
               if alertTemplatePayload.data.template?
                  fetchedAlertTemplate = new CannedMessage(alertTemplatePayload.data.template, true)
               else
                  fetchedAlertTemplate = null
            catch err
               console.log "Error: ", err
               Bugsnag.notify(err, (event) =>
                  event['context'] = "getProjectAlertInfo and create a CannedMessage object"
                  event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
                  event.addMetadata('payload', alertTemplatePayload)
               )               
            finally
               modalManager.modalFinished()

   messageProjectsPeople: (project) =>
      explanationText = "Define which people on this project you would like to message."
      pane1 = new SelectPeopleByAssignmentPane(project.id, @viewingDate(), "Message People", "Create Message", explanationText)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'message-people-by-assignments-modal'}, (modal, exitStatus, observableData) =>
         if exitStatus == "finished" and observableData.data.peopleToMessage? and observableData.data.peopleToMessage.length > 0

            pane1 = new CreateMessageOrAlertPane({}, 'Message People', observableData.data.peopleToMessage, false, null, null, false)
            modal.setPanes([pane1])
            modalManager.showModal modal, null, {class: 'create-message-modal'}

   expandProjectsCategories: (project) =>
      project.placeholdersExpanded(true)
      project.uncategorizedAssignmentsOpen(true)
      idsToOpen = [
         "assignments-#{project.id}",
         "requests-#{project.id}"
      ]
      for code in project.costCodes()
         code.isOpen(true)
         idsToOpen.push(code.id)

      browserKey = AssignmentsViewModel.StorageKeys.OPEN_CATEGORIES
      storedCatIds = BrowserStorageUtils.getJsonValue(browserKey)
      if storedCatIds?
         storedCatIds = storedCatIds.concat(idsToOpen)
      else
         storedCatIds = idsToOpen
      BrowserStorageUtils.storeJsonValue(browserKey, storedCatIds)

   shutProjectCategories: (project) =>
      project.placeholdersExpanded(false)
      project.uncategorizedAssignmentsOpen(false)
      idsToRemove = [
         "assignments-#{project.id}",
         "requests-#{project.id}"
      ]
      for code in project.costCodes()
         code.isOpen(false)
         idsToRemove.push(code.id)

      browserKey = AssignmentsViewModel.StorageKeys.OPEN_CATEGORIES
      storedCatIds = BrowserStorageUtils.getJsonValue(browserKey)
      if storedCatIds?
         storedCatIds = storedCatIds.filter (id) -> return idsToRemove.indexOf(id) == -1
      else
         storedCatIds = []
      BrowserStorageUtils.storeJsonValue(browserKey, storedCatIds)

   handleInvalidRequestFillDrop: (assignmentCard, sourceData) ->
      if sourceData.costCode? and assignmentCard.costCodeId()?
         foundLabel = false
         for labelSet in sourceData.costCode.labeledAssignmentCards()
            if labelSet.labelId == assignmentCard.labelId()
               foundLabel = true
               filteredCards = labelSet.assignmentCards()
               alreadyHasCard = false
               for card in labelSet.assignmentCards()
                  if card.id? && card.id == assignmentCard.id
                     alreadyHasCard = true
                     break

               filteredCards.push(assignmentCard) unless alreadyHasCard
               labelSet.assignmentCards(filteredCards)
               break
         # If this is the first card.
         unless foundLabel
            newAssignmentCards = ko.observableArray([assignmentCard])
            newDisplayCards = ko.pureComputed =>
               # TODO: Clean this up to no return a double wrapped array.
               return ko.observableArray(newAssignmentCards())
            newLabelSet = {
               labelId: assignmentCard.labelId(),
               labelCount: 1
               assignmentCards: newAssignmentCards,
               displayCards: newDisplayCards
            }
            sourceData.costCode.labeledAssignmentCards.push(newLabelSet)
            
         sourceData.costCode.assignmentCount(sourceData.costCode.assignmentCount() + 1)

      else
         filteredCards = sourceData.project.uncategorizedAssignments()
         alreadyHasCard = false
         for card in sourceData.project.uncategorizedAssignments()
            if card.id? && card.id == assignmentCard.id
               alreadyHasCard = true
               break

         filteredCards.push(assignmentCard) unless alreadyHasCard
         sourceData.project.uncategorizedAssignments(filteredCards)

      invalidFillMessage = "You can only fill requests by dragging cards from the bench, not another project."
      pane1 = new ConfirmActionPaneViewModel("OK", invalidFillMessage, "Invalid Fill")
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'invalid-request-fill-notice'}

   handleBenchDrop: (element, endContainer, endContainerData, sourceContainer) =>
      assignmentCard = ko.dataFor(element)
      return unless assignmentCard.id?

      pane1 = new ConfirmAssignmentEndPane(assignmentCard, @viewingDate())
      confirmationModal = new Modal()
      confirmationModal.setPanes([pane1])

      modalManager.showModal confirmationModal, null, {class: "confirm-assignment-end-modal"}, (modal, modalStatus, observableData) =>
         # removes element from the resource bench where it was dropped
         element.parentNode.removeChild(element)

         if modalStatus == "cancelled"
            # Put the card back where it was approximately
            elementJobTitle = ko.dataFor(element).positionName()
            firstCardWithSameJobTitle = null
            for cardFromSource in sourceContainer.children
               if ko.dataFor(cardFromSource).positionName() == elementJobTitle
                  firstCardWithSameJobTitle = cardFromSource
                  break

            if firstCardWithSameJobTitle
               sourceContainer.insertBefore(element, firstCardWithSameJobTitle)
            else
               sourceContainer.append(element)
         else
            # We've added card back to resource bench, so reload to get updated list in proper order
            @reloadBench_(0);

         if observableData.data.notify
            alertManager.showAssignmentMessageModal(observableData.data)

   clearRapidPaneValues: ->
      @rapidAssignActive(false)
      @rapidAssignWorkingSaturdays(false)
      @rapidAssignWorkingSundays(false)
      @rapidEndDate(null)
      @rapidMessageConfig(null)
      @rapidPercentAllocated(null)
      @rapidStartDate(null)
      @rapidTbdEndDate(false)
      @selectedRapidEndTime(null)
      @selectedRapidStartTime(null)
      @rapidSelectedStatus(null)

   rapidPaneActionBtnClicked: ->
      if @rapidAssignActive()

         @rapidAssignActive(false)
         @rapidAssignWorkingSaturdays(false)
         @rapidAssignWorkingSundays(false)
         @rapidEndDate(null)
         @rapidMessageConfig(null)
         @rapidPercentAllocated(null)
         @rapidStartDate(null)
         @rapidTbdEndDate(false)
         @selectedRapidEndTime(null)
         @selectedRapidStartTime(null)
         @rapidSelectedStatus(null)
      else
         return unless @canActivateRapidAssign()
         @rapidAssignActive(true)

   handleBenchTabClick: ->
      @showingBench(!@showingBench())
      @isBatchEditing(false)
      @clearSelectedBatchAssignments()

   formatTime: (time) ->
      return DateUtils.formatTimeVal(time)

   toggleBatchEditing: ->
      if @isBatchEditing()
         @isBatchEditing(false)
         @showingBench(@showingBenchPreviousState())
         @clearSelectedBatchAssignments()
      else
         @rapidAssignActive(false)
         @showingBenchPreviousState(@showingBench())
         @showingBench(false)
         @isBatchEditing(true)

   toggleAssignmentBatchSelection: (data) =>
      # Making sure we can't select cards that are locked down.
      unless authManager.isAdmin()
         cardsGroupIds = (if authManager.usingTypedGroups() then data.assignableGroupIds() else data.groupIds()) or []
         usersGroupIds = (if authManager.usingTypedGroups() then authManager.authedUser().accessGroupIds() else authManager.authedUser().groupIds()) or []
         foundMatch = false
         for id in usersGroupIds
            if cardsGroupIds.indexOf(id) != -1
               foundMatch = true
               break
         return unless foundMatch

      if @batchSelectedAssignments().indexOf(data) != -1
         @batchSelectedAssignments.remove(data)
      else
         @batchSelectedAssignments.push(data)

   clearSelectedBatchAssignments: =>
      @batchSelectedAssignments([])
      @batchEditStartDate(null)
      @batchEditEndDate(null)
      @batchEditStartTime(null)
      @batchEditEndTime(null)
      @batchEditWorkDays(false)
      @batchSunday(false)
      @batchMonday(true)
      @batchTuesday(true)
      @batchWednesday(true)
      @batchThursday(true)
      @batchFriday(true)
      @batchSaturday(false)
      @batchEditSelectedStatus(null)
      @batchProjectSelection(null)
      @batchCostCodeSelection(null)
      @batchLabelSelection(null)
      @batchMessageType("dont-send")
      @batchMessageScheduleDate(null)
      @batchMessageScheduleTime(null)

   assignmentCardIsSelected: (data) =>
      return @batchSelectedAssignments().indexOf(data) != -1

   getBatchSelectedCount: ->
      return "#{@batchSelectedAssignments().length} Selected"

   formatDollars: (number) ->
      cents = Math.floor((number % 1) * 100)
      cents = "#{0}#{0}" if cents == 0
      dollars = "#{Math.floor(number)}"
      return "$#{dollars}.#{cents}" unless dollars.length > 3
      backwards = dollars.split("").reverse().join("")
      formated = backwards.match(/.{1,3}/g).join(",").split("").reverse().join("")
      return "$#{formated}.#{cents}"

   searchResources: (searchQuery) =>
      @benchSearchValue(searchQuery())
      @reloadBench_(0)

   searchProjects: =>
      @reloadProjects_(0)

   getProjectsGroupsString: (data) ->
      # TODO: Assess if this is being used any more
      if data.groups().length == 1
         return "#{data.groups()[0].name()}"
      else if data.groups().length == 2
         return "#{data.groups()[0].name()}, #{data.groups()[1].name()}"
      if data.groups().length > 2
         count = (data.groups().length - 1)
         return "#{data.groups()[0].name()} & #{count} others"

   getCreateAssignmentObject: (assignmentCard, project, costCode, labelId) =>
      newData = {
         project_id: project.id
         category_id: if costCode? then costCode.id else null
         cost_code_id: if costCode? then costCode.id else null # Renamed field
         subcategory_id: labelId
         label_id: labelId # Renamed field
         resource_id: ko.unwrap(assignmentCard.resourceId)
         start_day: DateUtils.getDetachedDay(@rapidStartDate())
         end_day: DateUtils.getDetachedDay(@rapidEndDate())
         work_days: {
            0: @rapidSunday()
            1: @rapidMonday()
            2: @rapidTuesday()
            3: @rapidWednesday()
            4: @rapidThursday()
            5: @rapidFriday()
            6: @rapidSaturday()
         }
         custom_fields: {},
         overtime: false,
         status_id: if @rapidSelectedStatus()? then @rapidSelectedStatus().value() else null
      }

      if @rapidPercentAllocated()?
         newData['start_time'] = null
         newData['end_time'] = null
         newData['percent_allocated'] = parseInt(@rapidPercentAllocated())
      else if @selectedRapidStartTime()? and @selectedRapidEndTime()?
         newData['start_time'] = @selectedRapidStartTime()
         newData['end_time'] = @selectedRapidEndTime()
         newData['percent_allocated'] = null
      else
         newData['start_time'] = project.dailyStartTime()
         newData['end_time'] = project.dailyEndTime()
         newData['percent_allocated'] = null

      return newData

   makeRapidAssignment: (element, assignmentCard, project, costCode, labelId) =>
      pendingId = GUID()
      assignmentCard.pendingId(pendingId)
      newData = @getCreateAssignmentObject(assignmentCard, project, costCode, labelId)
      callback = (err, newAssignment) =>
         return console.log "error: ", err if err
         @rapidAssignProjectsToUpdate.add(project.id)
         benchSubtitleSetting = authManager.authedUser().preferences().benchCardsSubtitle()
         assignmentCardMerged = {
            ...assignmentCard.baggage(),
            ...newAssignment.data,
            assignable_group_ids: assignmentCard.assignableGroupIds(),
            employee_number: assignmentCard.employeeNumber(),
            group_ids: assignmentCard.groupIds(),
            position_color: assignmentCard.resourceColor(),
            position_id: assignmentCard.positionId(),
            position_name: assignmentCard.positionName(),
            position_rate: assignmentCard.positionRate(),
            resource_initials: assignmentCard.resourceInitials(),
            resource_photo_url: assignmentCard.resourcePhotoUrl(),
            resource_rate: assignmentCard.resourceRate(),
            resource_title: assignmentCard.resourceTitle(),
            status: @rapidSelectedStatus(),
         }

         if benchSubtitleSetting == "job-title"
            assignmentCardMerged["dynamic_subtitle"] = assignmentCard.positionName()

         newAssignmentCard = await @constructAssignmentCardFromCoreResponse(assignmentCardMerged, assignmentCard.resourceTagInstances, benchSubtitleSetting, project)

         if element.parentNode?
            element.parentNode.removeChild(element)
         return unless newAssignmentCard?
         if costCode?
            foundLabel = false
            for labelSet in costCode.labeledAssignmentCards()
               if labelSet.labelId == labelId
                  foundLabel = true
                  filteredCards = labelSet.assignmentCards().filter((card) ->
                     return card.pendingId() != pendingId
                  )
                  alreadyHasCard = false
                  for card in labelSet.assignmentCards()
                     if card.id == newAssignmentCard.id
                        alreadyHasCard = true
                        break

                  filteredCards.push(newAssignmentCard) unless alreadyHasCard
                  labelSet.assignmentCards(filteredCards)
                  break
            # If this is the first card.
            unless foundLabel
               newAssignmentCards = ko.observableArray([newAssignmentCard])
               newDisplayCards = ko.pureComputed =>
                  # TODO: Clean this up to no return a double wrapped array.
                  return ko.observableArray(newAssignmentCards())
               newLabelSet = {
                  labelId: labelId,
                  labelCount: 1
                  assignmentCards: newAssignmentCards,
                  displayCards: newDisplayCards
               }
               costCode.labeledAssignmentCards.push(newLabelSet)
               
            costCode.assignmentCount(costCode.assignmentCount() + 1)

         else
            filteredCards = project.uncategorizedAssignments().filter((card) ->
               return card.pendingId() != pendingId
            )
            alreadyHasCard = false
            for card in project.uncategorizedAssignments()
               if card.id == newAssignmentCard.id
                  alreadyHasCard = true
                  break

            filteredCards.push(newAssignmentCard) unless alreadyHasCard
            project.uncategorizedAssignments(filteredCards)         

         unless @rapidMessageType() == MessageSendOption.DONT_SEND
            # TODO: Make these a direct mapping. 
            context = switch @rapidMessageType()
               when "send-instantly" then "open"
               when "schedule" then "scheduled"
               when "save-draft" then "drafts"

            messageData = {context: context}
            if context == "scheduled"
               messageData['detached_day'] = DateUtils.getDetachedDay(@rapidMessageScheduleDate())
               messageData['time'] = @rapidMessageScheduleTime()

            messageData['canned_type'] = CannedMessage.Type.ASSIGNMENT_NEW
            messageData['batch_id'] = newAssignmentCard.id
            if authManager.selectedGroupId() != "my-groups"
               messageData['group_id'] = authManager.selectedGroupId()

            try
               payloadRequest = {
                  body: messageData
               }
               await AlertStore.processDefaultAlertForProject(payloadRequest)
            catch err
               console.error "Error in processDefaultAlertForProject: ", err

      newAssignment = null
      try
         newAssignment = await Assignment2Store.createAssignment(newData).payload
      catch err
         return callback(err)
      callback(null, newAssignment)

   # TODO: Is this needed anymore after new default alerts?
   formatProjectInfoForMessage_: (projectInfo) ->
      content = "#{projectInfo.name}\n"
      if projectInfo.address1?
         content += "#{projectInfo.address1}\n"
      if projectInfo.address2?
         content += "#{projectInfo.address2}\n"
      if projectInfo.cityTown? or projectInfo.stateProvince? or projectInfo.zipcode?
         locale = ""
         if projectInfo.cityTown?
            locale += "#{projectInfo.cityTown}, "
         if projectInfo.stateProvince?
            locale += "#{projectInfo.stateProvince}, "
         if projectInfo.zipcode?
            locale += "#{projectInfo.zipcode}, "
         content += "#{locale.slice(0, -2)}\n"
      if projectInfo.jobNumber?
         content += "Project #: #{projectInfo.jobNumber}\n"
      return content

   notifySingleAssignmentTransfer: (transferData) ->
      @projectInfo = null
      @cannedMessage = null
      @recipients = ko.observableArray()

      pane1 = new ProcessingNoticePaneViewModel("Creating Assignment Notification", true)
      processingModal = new Modal()
      processingModal.setPanes([pane1])
      modalManager.showModal processingModal, null, {class: "processing-notice-modal"}, (modal, modalStatus) =>
         return if modalStatus == "cancelled"

         if @cannedMessage?
            FormatMessage.populatedMessage(@cannedMessage)
            subject = @cannedMessage.subject()
            message = @cannedMessage.content()
         else
            message = "New Assignment:\n"
            message += transferData.newAssignmentContent
            message += @formatProjectInfoForMessage_(@projectInfo)

            subject = "Assignment Transfer for #{transferData.resourceTitle}"

         messageData = {
            subject: subject
            content: message
            includeSignature: if @cannedMessage? then @cannedMessage.includeSignature() else false
            isPrivate: if @cannedMessage? then @cannedMessage.isPrivate() else true
            isGroup: if @cannedMessage? then @cannedMessage.isGroup() else false
         }
         supplementalMsgData = {
            projectId: transferData.newProjectId
            assignmentId: transferData.newAssigmentId
         }

         pane1 = new CreateMessageOrAlertPane(messageData, 'Assignment Alert', @recipients, false, null, supplementalMsgData, true)
         modal.setPanes([pane1])
         modalManager.showModal modal, null, {class: 'create-message-modal'}

      useCoreAPI = LaunchDarklyBrowser.getFlagValue("get-project-info-for-assingment-alerts-from-lc-core-api")
      if useCoreAPI 
            data = (await ProjectStore.getProjectInfoForAssignmentNotification(transferData.newProjectId, transferData.resourceId, transferData.newAssigmentId, CannedMessage.Type.ASSIGNMENT_TRANSFER)).payload;
            # Legacy store does some data formatting, but moving it here to keep the core store clean.
            info = {
                recipients: data.recipients.map (person) ->
                    return new Person(person, true)
            }

            if data.assignment_batch?
                info['assignmentBatch'] = {
                    startDay: data.assignment_batch.start_day
                    endDay: data.assignment_batch.end_day
                    startTime: data.assignment_batch.start_time
                    endTime: data.assignment_batch.end_time
                    percentAllocated: data.assignment_batch.percent_allocated
                    workDays: data.assignment_batch.work_days
                    resourceName: data.assignment_batch.resource_name
                }

            if data.message?
                info['message'] = new CannedMessage(data.message, true)
            else
                info['project'] = {
                    name: data.project.name
                    address1: data.project.address_1 or null
                    address2: data.project.address_2 or null
                    cityTown: data.project.city_town or null
                    stateProvince: data.project.state_province or null
                    zipcode: data.project.zipcode or null
                    jobNumber: data.project.job_number or null
                    siteContacts: data.project.site_contacts
                }
            @recipients(info.recipients)
            if info.message?
               @cannedMessage = info.message
            else
               @projectInfo = info.project
            modalManager.modalFinished()
      else 
         legacyProjectStore.getProjectInfoForAssignmentNotification transferData.newProjectId, transferData.resourceId, transferData.newAssigmentId, CannedMessage.Type.ASSIGNMENT_TRANSFER, (err, info) =>
            return console.log "error: ", err if err
            @recipients(info.recipients)
            if info.message?
               @cannedMessage = info.message
            else
               @projectInfo = info.project
            modalManager.modalFinished()

   handleDragTransfer: (oldAssignmentCard, newProject, newCostCodeId, newLabelId) =>
      supportData = @boardsPage.state.assignmentSupportData

      newProjectInfo = {
         projectId: newProject.id
         costCodeId: newCostCodeId
         labelId: newLabelId
         startTime: newProject.dailyStartTime()
         endTime: newProject.dailyEndTime()
      }

      costingConfig = {
         paidShiftHours: supportData.paidShiftHours
         overtimeDayRates: supportData.overtimeDayRates
      }
      pane1 = new SingleAssignmentTransferPane(oldAssignmentCard, newProjectInfo, @viewingDay(), supportData, costingConfig)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'single-transfer-modal'}, (modal, modalStatus, observableData) =>
         if modalStatus == "cancelled"
            @refreshProject(newProjectInfo.projectId)
            return
         if observableData.data.notify
            alertManager.showAssignmentMessageModal(observableData.data)

         if observableData.data.newBatch?
            oldAssignmentCard.id = observableData.data.newBatch.batchId
            oldAssignmentCard.startDay(observableData.data.newBatch.startDay)
            oldAssignmentCard.endDay(observableData.data.newBatch.endDay)

   constructAssignmentCardFromCoreResponse: (data, tagInstances, benchSubtitleSetting, project) ->
      assignmentCard = {
         assignable_group_ids: data.assignable_group_ids
         cost_code_id: data.category_id
         employee_number: data.employee_number
         end_day: data.end_day
         end_time: data.end_time ? null
         group_ids: data.group_ids
         id: data.id
         label_id: data.label_id
         percent_allocated: data.percent_allocated ? null
         position_name: data.position_name ? null
         project_id: data.project_id
         resource_color: data.position_color ? null
         resource_id: data.resource_id
         resource_initials: data.resource_initials
         resource_photo_url: data.resource_photo_url
         resource_title: data.resource_title,
         start_day: data.start_day
         start_time: data.start_time ? null
         work_days: data.work_days
      }

      boardSubtitleSetting = authManager.authedUser().preferences().boardCardsSubtitle()
      if boardSubtitleSetting == benchSubtitleSetting
         assignmentCard["dynamic_subtitle"] = data.dynamic_subtitle
      else if boardSubtitleSetting?
         if boardSubtitleSetting == "employee-number"
            assignmentCard['dynamic_subtitle'] = data.employee_number
         else if boardSubtitleSetting == "phone"
            assignmentCard['dynamic_subtitle'] = data.resource_phone
         else if boardSubtitleSetting == "email"
            assignmentCard['dynamic_subtitle'] = data.resource_email
         else if boardSubtitleSetting == "hourly-rate"
            rate = null
            if data.resourceRate?
               rate = data.resourceRate
            else if data.position_rate
               rate = data.position_rate
            assignmentCard['dynamic_subtitle'] = if rate? then "$#{rate}/hr" else null
         else if boardSubtitleSetting == "city"
            assignmentCard['dynamic_subtitle'] = data.resource_city
         else if boardSubtitleSetting == "state"
            assignmentCard['dynamic_subtitle'] = data.resource_state
         else if boardSubtitleSetting == "postal"
            assignmentCard['dynamic_subtitle'] = data.resource_zipcode
         else if boardSubtitleSetting == "batch-dates"
            formattedStart = DateUtils.formatDetachedDay(data.start_day, defaultStore.getDateFormat())
            formattedEnd = DateUtils.formatDetachedDay(data.end_day, defaultStore.getDateFormat())
            assignmentCard['dynamic_subtitle'] = "#{formattedStart} - #{formattedEnd}"
         else if boardSubtitleSetting == "assignment-status"
            assignmentCard['dynamic_subtitle'] = if data.status then data.status.name() else null
         else if boardSubtitleSetting == "job-title"
            assignmentCard['dynamic_subtitle'] = data.position_name
      else
         assignmentCard['dynamic_subtitle'] = null
      wageOverride = project.wageOverrides().find((wo) => wo.positionId() == data.position_id)?
      assignmentCard['resource_rate'] = computeHourlyRate({
         personHourlyWage: data.resource_rate ? null,
         positionHourlyRate: data.position_rate ? null,
         wageOverride: if wageOverride then wageOverride.rate() else null,
      })

      if data.status
         assignmentCard['status'] = {
            abbreviation: data.status.baggage().abbreviation,
            color: data.status.color(),
            id: data.status.value()
            name: data.status.name(),
         }
      else
         assignmentCard['status'] = null

      boardCard = new AssignmentCard(assignmentCard)
      boardCard.resourceTagInstances(tagInstances())

      return boardCard

   constructAssignmentCardFromAssignment: (data) ->
      assignmentCard = {
         id: data.id
         resource_id: data.resourceId()
         employee_number: data.baggage().employee_number or null
         position_sequence: data.baggage().position_sequence or null
         position_name: data.baggage().position_name or null
         resource_color: data.baggage().position_color or null
         resource_photo_url: data.baggage().profile_pic_url or null
         project_id: data.projectId()
         cost_code_id: data.costCodeId()
         label_id: data.labelId()
         resource_tag_instances: data.baggage().person_tags or []
         resource_initials: "#{data.baggage().person_name.first.charAt(0).toUpperCase()}#{data.baggage().person_name.last.charAt(0).toUpperCase()}"
         start_day: data.startDay()
         end_day: data.endDay()
         work_days: data.workDays()
         start_time: data.startTime() or null
         end_time: data.endTime() or null
         percent_allocated: data.percentAllocated() or null
         group_ids: data.baggage().person_group_ids
         assignable_group_ids: data.baggage().person_assignable_group_ids
      }

      firstNamesLast = authManager.authedUser().preferences().displayLastNamesFirst()
      if firstNamesLast
         assignmentCard['resource_title'] = "#{data.baggage().person_name.last}, #{data.baggage().person_name.first}"
      else
         assignmentCard['resource_title'] = "#{data.baggage().person_name.first} #{data.baggage().person_name.last}"

      subtitleSetting = authManager.authedUser().preferences().boardCardsSubtitle()
      if subtitleSetting?
         if subtitleSetting == "employee-number"
            assignmentCard['dynamic_subtitle'] = data.baggage().employee_number
         else if subtitleSetting == "phone"
            assignmentCard['dynamic_subtitle'] = data.baggage().person_phone
         else if subtitleSetting == "email"
            assignmentCard['dynamic_subtitle'] = data.baggage().person_email
         else if subtitleSetting == "hourly-rate"
            rate = null
            if data.baggage().hourly_wage?
               rate = data.baggage().hourly_wage
            else if data.baggage().position_rate?
               rate = data.baggage().position_rate
            assignmentCard['dynamic_subtitle'] = if rate? then "$#{rate}/hr" else null
         else if subtitleSetting == "city"
            assignmentCard['dynamic_subtitle'] = data.baggage().person_city
         else if subtitleSetting == "state"
            assignmentCard['dynamic_subtitle'] = data.baggage().person_sate
         else if subtitleSetting == "postal"
            assignmentCard['dynamic_subtitle'] = data.baggage().person_postal
         else if subtitleSetting == "batch-dates"
            formattedStart = DateUtils.formatDetachedDay(data.startDay(), defaultStore.getDateFormat())
            formattedEnd = DateUtils.formatDetachedDay(data.endDay(), defaultStore.getDateFormat())
            assignmentCard['dynamic_subtitle'] = "#{formattedStart} - #{formattedEnd}"
         else if subtitleSetting == "assignment-status"
            statusName = null
            if data.status()?
               statusName = data.status().name()
            assignmentCard['dynamic_subtitle'] = statusName
         else if subtitleSetting == "job-title"
            assignmentCard['dynamic_subtitle'] = data.baggage().position_name
      else
         assignmentCard['dynamic_subtitle'] = null

      if data.baggage().hourly_wage?
         assignmentCard['resource_rate'] = data.baggage().hourly_wage
      else if data.baggage().position_rate?
         assignmentCard['resource_rate'] = data.baggage().position_rate
      else
         assignmentCard['resource_rate'] = null

      if data.status()
         assignmentCard['status'] = {
            id: data.status().id
            name: data.status().name()
            abbreviation: data.status().abbreviation()
            color: data.status().color()
         }
      else
         assignmentCard['status'] = null

      return new AssignmentCard(assignmentCard)

   editPendingAssignment: (element, endContainer, endContainerData, sourceContainer) =>
      # Prevent assigned resource from leaving resource bench. Dragula copy will not work as
      # it does not take the KO bound data with the element it clones.
      for className in sourceContainer.classList
         if className == "resource-bench__card-container--assigned"
            oldAr = @assignedResources()
            @assignedResources([])
            @assignedResources(oldAr)

      project = endContainerData.project
      costCode = endContainerData.costCode or null
      costCodeId = if costCode? then costCode.id else null
      labelId = endContainerData.labelId
      assignmentCard = ko.dataFor(element)

      # if assignmentCard? and assignmentCard.instanceId()?
      if assignmentCard? and assignmentCard.id?
         return @handleDragTransfer(assignmentCard, project, costCodeId, labelId)

      if @rapidAssignActive()
         return @makeRapidAssignment(element, assignmentCard, project, costCode, labelId)

      showADM = =>
         resourceId = ko.unwrap(assignmentCard.resourceId)
         assignmentDay = DateUtils.getDetachedDay(@viewingDate())
         pendingId = GUID()
         assignmentCard.pendingId(pendingId)
         pendingAssignment = {
            pendingId: pendingId
            projectId: project.id
            costCodeId: costCodeId
            labelId: labelId
            resourceId: resourceId
            startTime: endContainerData.project.dailyStartTime()
            endTime: endContainerData.project.dailyEndTime()
            startDay: assignmentDay
            endDay: assignmentDay
            isPending: true
            workDays: {
               0: false
               1: true
               2: true
               3: true
               4: true
               5: true
               6: false
            }
         }
         supportData = @boardsPage.state.assignmentSupportData

         costingConfig = {
            paidShiftHours: supportData.paidShiftHours
            overtimeDayRates: supportData.overtimeDayRates
         }

         # This is used when you're opening a pane to create a new assignment
         pane1 = new GanttPane(resourceId, @viewingDay(), pendingAssignment, supportData, costingConfig)

         modal = new Modal()
         modal.setPanes([pane1])
         modalManager.showModal modal, null, {class: 'assignments-details-modal'}, (modal, modalStatus, observableData) =>
            if modalStatus == "cancelled"
               @reloadBench_(0)
               return
            if observableData.data.deletedAssignments? and observableData.data.deletedAssignments().length > 0
               @handleDeletedAssignmentsRemoval(observableData.data.deletedAssignments())

            if observableData.data.newAssignments?
               newAssignmentCards = []
               for assignment in observableData.data.newAssignments
                  newAssignmentCards.push(@constructAssignmentCardFromAssignment(assignment))

               for newAssignmentCard in newAssignmentCards
                  if costCode?
                     foundLabel = false
                     for labelSet in costCode.labeledAssignmentCards()
                        if labelSet.labelId == labelId
                           foundLabel = true
                           filteredCards = labelSet.assignmentCards().filter((card) ->
                              return card.pendingId() != pendingId
                           )
                           alreadyHasCard = false
                           for card in labelSet.assignmentCards()
                              if card.id == newAssignmentCard.id
                                 alreadyHasCard = true
                                 break
                           filteredCards.push(newAssignmentCard) unless alreadyHasCard
                           labelSet.assignmentCards(filteredCards)
                           break
                     # If this is the first card.
                     unless foundLabel
                        newAssignmentCards = ko.observableArray([newAssignmentCard])
                        newDisplayCards = ko.pureComputed =>
                           # TODO: Clean this up to no return a double wrapped array.
                           return ko.observableArray(newAssignmentCards())
                        newLabelSet = {
                           labelId: labelId,
                           labelCount: 1
                           assignmentCards: newAssignmentCards,
                           displayCards: newDisplayCards
                        }
                        costCode.labeledAssignmentCards.push(newLabelSet)
                     costCode.assignmentCount(costCode.assignmentCount() + 1)

                  else
                     alreadyHasCard = false
                     filteredCards = project.uncategorizedAssignments().filter((card) ->
                        return card.pendingId() != pendingId
                     )
                     for card in project.uncategorizedAssignments()
                        if card.id == newAssignmentCard.id
                           alreadyHasCard = true
                           break
                     filteredCards.push(newAssignmentCard) unless alreadyHasCard
                     project.uncategorizedAssignments(filteredCards)

            if observableData.data.notify
               alertManager.showAssignmentMessageModal(observableData.data)

         element.parentNode.removeChild(element)
      showADM()

   toggleCostCodeExpansion: (costCode) =>
      browserKey = AssignmentsViewModel.StorageKeys.OPEN_CATEGORIES
      if costCode.isOpen()
         costCode.isOpen(false)
         storedCatIds = BrowserStorageUtils.getJsonValue(browserKey)
         if storedCatIds?
            filteredCatIds = storedCatIds.filter (id) -> return id != costCode.id
            BrowserStorageUtils.storeJsonValue(browserKey, filteredCatIds)
      else
         costCode.isOpen(true)
         storedCatIds = BrowserStorageUtils.getJsonValue(browserKey)
         if storedCatIds?
            storedCatIds.push(costCode.id)
         else
            storedCatIds = [costCode.id]
         BrowserStorageUtils.storeJsonValue(browserKey, storedCatIds)

   toggleUncategorizedAssignments: (project) =>
      browserKey = AssignmentsViewModel.StorageKeys.OPEN_CATEGORIES
      if project.uncategorizedAssignmentsOpen()
         project.uncategorizedAssignmentsOpen(false)
         storedCatIds = BrowserStorageUtils.getJsonValue(browserKey)
         if storedCatIds?
            storedCatIds = storedCatIds.filter (id) -> return id != "assignments-#{project.id}"
         else
            storedCatIds = []
      else
         project.uncategorizedAssignmentsOpen(true)
         storedCatIds = BrowserStorageUtils.getJsonValue(browserKey)
         if storedCatIds?
            storedCatIds.push("assignments-#{project.id}")
         else
            storedCatIds = ["assignments-#{project.id}"]

      BrowserStorageUtils.storeJsonValue(browserKey, storedCatIds)

   togglePlaceholderExpansion: (project) =>
      # Only used if old request viewing is enabled.
      browserKey = AssignmentsViewModel.StorageKeys.OPEN_CATEGORIES
      if project.placeholdersExpanded()
         project.placeholdersExpanded(false)
         storedCatIds = BrowserStorageUtils.getJsonValue(browserKey)
         if storedCatIds?
            storedCatIds = storedCatIds.filter (id) -> return id != "requests-#{project.id}"
         else
            storedCatIds = []
      else
         project.placeholdersExpanded(true)
         storedCatIds = BrowserStorageUtils.getJsonValue(browserKey)
         if storedCatIds?
            storedCatIds.push("requests-#{project.id}")
         else
            storedCatIds = ["requests-#{project.id}"]

      BrowserStorageUtils.storeJsonValue(browserKey, storedCatIds)

   getPlaceholdersExpanded: (projectId) =>
      return @expandedPlaceholderprojectIds().indexOf(projectId) != -1

   sortCards: (a, b) =>
      if @selectedCardSortBy().value() == AssignmentsViewModel.AssignmentCardSortBy.POSITION
         return -1 unless b.positionSequence()?
         return 1 unless a.positionSequence()?

         if a.positionSequence() == b.positionSequence() 
            return if a.resourceTitle().toLowerCase() > b.resourceTitle().toLowerCase() then 1 else  -1
         else 
            return if a.positionSequence() > b.positionSequence() then 1 else  -1
      else
         return if a.resourceTitle().toLowerCase() > b.resourceTitle().toLowerCase() then 1 else  -1

   sortLabels: (a, b) =>
      return a.sequence() - b.sequence()

   getLabelCards: (label, costCode) =>
      labelId = if label? then label.id else null
      for item in costCode.labeledAssignmentCards()
         item.labelId = null if item.labelId == undefined
         if item.labelId == labelId
            sortedCards = ko.unwrap(item.displayCards).sort(@sortCards)
            if @selectedCardSortBy().value() == AssignmentsViewModel.AssignmentCardSortBy.POSITION
               ordedPositionKeys = []
               positionedCards = {}
               for card in sortedCards
                  cardPosSequence = card.positionSequence()
                  if positionedCards[cardPosSequence]?
                     positionedCards[cardPosSequence].push(card)
                  else
                     positionedCards[cardPosSequence] = [card]
                     ordedPositionKeys.push(cardPosSequence)
               for key, val of positionedCards
                  positionedCards[key] = val.sort (a, b) -> return a.resourceTitle().localeCompare(b.resourceTitle())

               secondarySortedCards = []
               for key in ordedPositionKeys
                  secondarySortedCards = secondarySortedCards.concat(positionedCards[key])

               return secondarySortedCards
            else
               return sortedCards
      return []

   checkLabelHasRequests: (label, costCode) =>
      labelId = if label? then label.id else null
      for item in costCode.labeledRequestCards()
         if item.labelId == labelId
            displayCards = ko.unwrap(item.displayCards)
            return if ko.isObservable(displayCards) then displayCards().length > 0 else displayCards.length > 0

      return false

   getLabelRequestCards: (label, costCode) =>
      labelId = if label? then label.id else null
      for item in costCode.labeledRequestCards()
         if item.labelId == labelId
            return ko.unwrap(item.displayCards)
      return []

   getCategoryHeaderTitle: (project, category) ->
      title = ""
      isLoading = @projectsStillLoadingAssignmentsRequests().flat().find((id) => id == project.id)
      if @projectsOnlyShow() != "requests"
         assignmentCount = if isLoading then " ... " else category.assignmentCount()
         title = "(#{assignmentCount}) "
      title += "#{category.name()}"

      if @canViewRequests
         if @projectsOnlyShow() != "assignments" and category.requestCount() > 0
            requestCount = if isLoading then " ... " else category.requestCount()
            title += "<span class='project-cell__cost-code-name__request'> - (#{requestCount}) #{if category.requestCount() == 1 then 'Request' else 'Requests'}</span>"
      return title

   getProjectUncategorizedHeaderTitle: (project) =>
      title = ""
      isLoading = @projectsStillLoadingAssignmentsRequests().flat().find((id) => id == project.id)
      count = if isLoading then " ... " else project.uncategorizedAssignments().length
      if @projectsOnlyShow() != "requests"
         title = "(#{count})&nbsp;&nbsp;&nbsp;"

      if @canViewRequests
         if @projectsOnlyShow() != "assignments" and @showRequestCategorized and project.uncategorizedRequests().length > 0
            if @projectsOnlyShow() == "requests"
               title += "<span class='project-cell__cost-code-name__request'>(#{project.uncategorizedRequests().length}) #{if project.uncategorizedRequests().length == 1 then 'Request' else 'Requests'}</span>"
            else
               title += "<span class='project-cell__cost-code-name__request'> - (#{project.uncategorizedRequests().length}) #{if project.uncategorizedRequests().length == 1 then 'Request' else 'Requests'}</span>"
      return title

   getSortedUncategorizedCards: (cards) =>
      return cards if cards()[0]?.positionSequence == undefined
      sortedCards = ko.unwrap(cards).sort(@sortCards)
      if @selectedCardSortBy().value() == AssignmentsViewModel.AssignmentCardSortBy.POSITION
         ordedPositionKeys = []
         positionedCards = {}
         for card in sortedCards
            cardPosSequence = card.positionSequence()
            if positionedCards[cardPosSequence]?
               positionedCards[cardPosSequence].push(card)
            else
               positionedCards[cardPosSequence] = [card]
               ordedPositionKeys.push(cardPosSequence)
         for key, val of positionedCards
            positionedCards[key] = val.sort (a, b) -> return a.resourceTitle().localeCompare(b.resourceTitle())

         secondarySortedCards = []
         for key in ordedPositionKeys
            secondarySortedCards = secondarySortedCards.concat(positionedCards[key])

         return secondarySortedCards
      else
         return sortedCards

   getLabelCount: (label, costCode) =>
      for item in costCode.labeledAssignmentCards()
         if item.labelId == label.id
            return item.labelCount
      return null

   getLoadMoreText: (label, costCode) =>
      for item in costCode.labeledAssignmentCards()
         if item.labelId == label.id
            delta = item.labelCount - ko.unwrap(ko.unwrap(item.displayCards)).length
            return "" if delta == 0
            entity = if delta == 1 then "Assignment" else "Assignments"
            return "Load #{delta} More #{entity}"
      return ''

   navigateToProject: (project) ->
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/projects/#{project.id}")

   clearBenchFilters: ->
      @benchFilterChips([])

   exprClassCheck: (tagInstance) =>
      return "" unless tagInstance.exprDate()?
      detachedNow = DateUtils.getDetachedDay(new Date)
      if  tagInstance.exprDate() <= detachedNow
         return "icon-warning-triangle"
      else
         tagInfo = null
         for info in @tagExprInfo()
            if info.id == tagInstance.tagId()
               tagInfo = info
               break
         return "" unless tagInfo?
         daysBetween = DateUtils.getDaysBetweenDetachedDays(detachedNow, tagInstance.exprDate())
         if daysBetween <= tagInfo.exprDaysWarning
            return "icon-caution-triangle"
         return ""

   getUpdateAssignmentObject: ->
      batchOverrideData = {}
      if @batchEditStartDate()?
         batchOverrideData['start_day'] = DateUtils.getDetachedDay(@batchEditStartDate())

      if @batchEditEndDate()?
         batchOverrideData['end_day'] = DateUtils.getDetachedDay(@batchEditEndDate())

      if @batchEditPercentAllocated()?
         batchOverrideData['percent_allocated'] = Number(@batchEditPercentAllocated())
         batchOverrideData['start_time'] = null
         batchOverrideData['end_time'] = null
      else if @batchEditStartTime()?
         batchOverrideData['start_time'] = Number(@batchEditStartTime())
         batchOverrideData['end_time'] = Number(@batchEditEndTime()) if @batchEditEndTime()?
         batchOverrideData['percent_allocated'] = null
      else if @batchEditEndTime()?
         batchOverrideData['end_time'] = Number(@batchEditEndTime())
         batchOverrideData['percent_allocated'] = null
      
      if @batchEditWorkDays()
         batchOverrideData['work_days'] = {
            0: @batchSunday()
            1: @batchMonday()
            2: @batchTuesday()
            3: @batchWednesday()
            4: @batchThursday()
            5: @batchFriday()
            6: @batchSaturday()
         }

      batchOverrideData['status_id'] = @batchEditSelectedStatus().value() if @batchEditSelectedStatus()?
      
      if @batchProjectSelection()?
         batchOverrideData['project_id'] = @batchProjectSelection().id
         batchOverrideData['category_id'] = @batchCostCodeSelection().value() if @batchCostCodeSelection()?
         batchOverrideData['cost_code_id'] = @batchCostCodeSelection().value() if @batchCostCodeSelection()? # Renamed field
         batchOverrideData['subcategory_id'] = @batchLabelSelection().value() if @batchLabelSelection()?
         batchOverrideData['label_id'] = @batchLabelSelection().value() if @batchLabelSelection()? # Renamed field

      return batchOverrideData

   updateAssignments: (callback) ->
      @isProcessingUpdate(true)
      batchOverrideData = @getUpdateAssignmentObject()
      assignments = []

      batchUpdates = @batchSelectedAssignments().map((item) => ({
         ...batchOverrideData,
         id: item.id,
      }))

      try
         stream = await Assignment2Store.updateAssignmentsStream(batchUpdates).stream
         for await assignment from stream
            assignments.push(assignment)
      catch err
         return callback(err)
      callback(null, assignments)

   saveBatchEdits: ->
      return unless @batchSaveEnabled()
      return unless @validateBatchEdits()
      return unless !@isProcessingUpdate()

      @updateAssignments((err, newAssignments) =>
         return console.log "error: ", err if err
         # TODO: This needs to be just the Impacted cost codes
         @reloadProjects_(@projectPageCursor.lastIndex())
         @isBatchEditing(false)
         @showingBench(true)

         unless @batchMessageType() == MessageSendOption.DONT_SEND
            # TODO: Make these a direct mapping. 
            context = switch @batchMessageType()
               when "send-instantly" then "open"
               when "schedule" then "scheduled"
               when "save-draft" then "drafts"

            messageData = {context: context}
            if context == "scheduled"
               messageData['detached_day'] = DateUtils.getDetachedDay(@batchMessageScheduleDate())
               messageData['time'] = @batchMessageScheduleTime()

            messageData['canned_type'] = CannedMessage.Type.ASSIGNMENT_NEW

            if authManager.selectedGroupId() != "my-groups"
               messageData['group_id'] = authManager.selectedGroupId()

            for assignment in newAssignments
               messageData['batch_id'] = assignment.id
               try
                  payloadRequest = {
                     body: messageData
                  }
                  await AlertStore.processDefaultAlertForProject(payloadRequest)
               catch err
                  console.error "Error in processDefaultAlertForProject: ", err

         @clearSelectedBatchAssignments()
         @isProcessingUpdate(false)
      )

   validateBatchEdits: () ->
      invalidAssignmentCards = []

      if @batchEditStartDate() and !@batchEditEndDate()
         newStartDate = DateUtils.getDetachedDay(@batchEditStartDate())
         invalidAssignmentCards = @batchSelectedAssignments().filter (assignment) => assignment.endDay() < newStartDate
      else if !@batchEditStartDate() and @batchEditEndDate()
         newEndDate = DateUtils.getDetachedDay(@batchEditEndDate())
         invalidAssignmentCards = @batchSelectedAssignments().filter (assignment) => assignment.startDay() > newEndDate

      if invalidAssignmentCards.length
         invalidAssignments = invalidAssignmentCards.map (assignment) => 
            project = @projects().find((project) => project.id == assignment.projectId())
            return new InvalidAssignment({
               resourceTitle: assignment.resourceTitle(),
               projectName: project?.name(),
               startDay: assignment.startDay(),
               endDay: assignment.endDay(),
            })

         modal = new Modal()
         modal.setPanes([new InvalidBatchEditDatesPane(invalidAssignments, @batchEditStartDate(), @batchEditEndDate())])
         modalManager.showModal(modal, null, {class: 'invalid-batch-edit-dates-modal'})
         return false

      return true

   # TODO: Pull this our into a shared utility
   computeInstances: (startDate, endDate, workDays, next) ->
      instances = []
      endDay = DateUtils.getDetachedDay(endDate)

      processInstance = (instanceStartDate) ->
         validDetachedDays = []
         processingDate = instanceStartDate
         foundInstanceStart = false
         processingInstance = true
         processingDay = null

         while processingInstance
            working = workDays[processingDate.getDay()]
            if !working and foundInstanceStart
               processingInstance = false
               break
            else if working and !foundInstanceStart
               foundInstanceStart = true

            processingDay = DateUtils.getDetachedDay(processingDate)
            validDetachedDays.push(processingDay) if working

            processingDate = DateUtils.incrementDate(processingDate, 1)
            if processingDay >= endDay
               processingInstance = false
               break

         if validDetachedDays.length > 0
            instances.push({
               start_day: validDetachedDays[0]
               end_day: validDetachedDays[validDetachedDays.length - 1]
               days: validDetachedDays
            })

         if DateUtils.getDetachedDay(processingDate) > endDay
            next(instances)
         else
            return processInstance(processingDate)


      processInstance(new Date(startDate.getTime()))

   deleteBatchSelections: ->
      return unless @batchHasSelections()
      return unless !@isProcessingDelete()
      @isProcessingDelete(true)
      assignmentsToDelete = @batchSelectedAssignments()
      batchIdsToDelete = assignmentsToDelete.map (assignmentCard) ->
         return assignmentCard.id

      callback = (err) =>
         return console.log "error: ", err if err
         @handleDeletedAssignmentCardsRemoval(assignmentsToDelete, true)
         @isBatchEditing(false)

         unless @batchMessageType() == MessageSendOption.DONT_SEND
            # TODO: Make these a direct mapping. 
            context = switch @batchMessageType()
               when "send-instantly" then "open"
               when "schedule" then "scheduled"
               when "save-draft" then "drafts"

            messageData = {context: context}
            if context == "scheduled"
               messageData['detached_day'] = DateUtils.getDetachedDay(@batchMessageScheduleDate())
               messageData['time'] = @batchMessageScheduleTime()

            messageData['canned_type'] = CannedMessage.Type.ASSIGNMENT_NEW

            if authManager.selectedGroupId() != "my-groups"
               messageData['group_id'] = authManager.selectedGroupId()

            for id in batchIdsToDelete
               messageData['batch_id'] = id
               try
                  payloadRequest = {
                     body: messageData
                  }
                  await AlertStore.processDefaultAlertForProject(payloadRequest)
               catch err
                  console.error "Error in processDefaultAlertForProject: ", err
                  
         @clearSelectedBatchAssignments()
         @isProcessingDelete(false)

      try
         await Promise.all(
            batchIdsToDelete.map((id) => Assignment2Store.deleteAssignment(id).payload)
         )
      catch err
         return callback(err)
      callback(null)

   showNewPlaceholderModal: (project) =>
      project = (await ProjectStore.getProject(project.id).payload).data
      @boardsPage.legacyOnCreateOrEditRequest({
         newRequestSeedData: {
            project_id: project.id,
            project: project,
         },
      })

   showShiftProjectModal: (project) =>
      renderShiftProjectModal({
         projectId: project.id,
         projectName: project.name(),
         startDate: project.startDate(),
         refreshRootPage: @updateAssignmentsRealTime
      })

   navigateToGantt: ->
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/gantt?dayFilter=#{@viewingDay()}")

   refineOnlyShowFilter: (filterChips) =>
      onlyShowFilter = null;
      filteredFilterChips = filterChips.filter((chip) => 
         if chip.property == "only_show"
            onlyShowFilter = chip
            return false
         else
            return true
      )
      filteredFilterChips.push(onlyShowFilter) unless onlyShowFilter == null
      @filterChips(filteredFilterChips) unless filterChips.length == filteredFilterChips.length
      @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))

   getFilterParams: =>
      params = {}
      @refineOnlyShowFilter(@filterChips())
      for chip in @filterChips()
         filter = {
            property: chip.property
            type: chip.type
            negation: chip.negation
         }

         if chip.customFieldId?
            filter['customFieldId'] = chip.customFieldId
            filter['filterName'] = chip.customFieldId
            filterName = chip.customFieldId
         else 
            if chip.filterName.indexOf(".") != -1
               filter['filterName'] = chip.property
               filterName = chip.property
            else
               filter['filterName'] = chip.filterName
               filterName = chip.filterName

         filter['classifier'] = chip.classifier if chip.classifier? and chip.classifier != ANY

         if chip.enableRelativeDate
            filter.value = JSON.stringify(chip.value)
         else if chip.value instanceof Array
            filter['value'] = chip.value.map (i) -> return ko.unwrap(i.value)
         else if chip.property == 'project_roles'
            val = [chip.classifier, chip.value].map (id) ->
               return if id == ANY then null else id
            filter['value'] = val
         else
            filter['value'] = chip.value

         if params[filterName]?
            params[filterName].push(filter)
         else
            params[filterName] = [filter]
      return params

   # TODO: Make the above function shared for this to keep DRY.
   getBenchFilterParams: =>
      params = {}
      for chip in @benchFilterChips()
         filter = {
            property: chip.property
            filterName: chip.filterName
            type: chip.type
            negation: chip.negation
         }
         filter['classifier'] = chip.classifier if chip.classifier?
         filter['customFieldId'] = chip.customFieldId if chip.customFieldId?
         if chip.enableRelativeDate
            filter.value = JSON.stringify(chip.value)
         else if chip.value instanceof Array
            filter['value'] = chip.value.map (i) -> return ko.unwrap(i.value)
         else
            filter['value'] = chip.value

         if params[chip.filterName]?
            params[chip.filterName].push(filter)
         else
            params[chip.filterName] = [filter]

      return params

   ###------------------------------------
      Data Loading
   ------------------------------------###
   buildProjectSearchParameters: () => {
      search: @projectQuery() or null
      dayFilter: DateUtils.getDetachedDay(@viewingDate())
      sort: @selectedProjectSortBy().value()
      filters: @getFilterParams()
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
   }

   updateProjectUiState: (projects = []) => 
      storedCatIds = BrowserStorageUtils.getJsonValue(AssignmentsViewModel.StorageKeys.OPEN_CATEGORIES)
      if storedCatIds?
         for project in projects
            if storedCatIds.indexOf("assignments-#{project.id}") != -1
               project.uncategorizedAssignmentsOpen(true)
            if storedCatIds.indexOf("requests-#{project.id}") != -1
               project.placeholdersExpanded(true)

            if storedCatIds?
               for code in project.costCodes()
                  if storedCatIds.indexOf(code.id) != -1
                     code.isOpen(true)

   filterDuplicates: (projects) => 
      return projects.filter (data) =>
            isNotLoaded = !@currentLoadedProjectKeys.has(data.id)
            if isNotLoaded
               @currentLoadedProjectKeys.add(data.id)
            return isNotLoaded

   loadTopData: => 
      return if @currentProjectRequest() or !@projectPageCursor.hasMoreTopRecords()
      @projectTopLoadingMediator.showLoader()
      @projectTopLoadingMediator.startProgress()
      @currentProjectRequest @projectPageCursor.decrement(
         @buildProjectSearchParameters(), 
         (err, data) =>
            return console.log "Error: ", err if err
            # The API can return duplicates because it ignores the limit. 
            filteredProjects = @filterDuplicates(data.projects)
            @updateProjectUiState(filteredProjects)
            @projects(
               filteredProjects.concat(@projects())
            )
            @projectTopLoadingMediator.hideLoader()
            @currentProjectRequest(null)

            if filteredProjects.length == 0 and @projectPageCursor.topIndex() > 0
               @loadTopData() 
            else 
               @maybeFillProjectView() if @projectPageCursor.hasMoreTopRecords()
      )

   loadData: =>
      return if @currentProjectRequest() or !@projectPageCursor.hasMoreBottomRecords()
      @projectLoadingMediator.showLoader()
      @projectLoadingMediator.startProgress()

      @currentProjectRequest @projectPageCursor.increment(
         @buildProjectSearchParameters(), 
         (err, data) =>
            return console.log "Error: ", err if err
            # They API CAN ignore our limit request and returns toSkip as the new position 
            # In the result set, so we must update the bottom index with the API's position
            # Filtering is only necessary to record for when loading the top
            if data
               filteredProjects = @filterDuplicates(data.projects)
               projectIds = filteredProjects.map((p) => p.id)
               @projectPageCursor.bottomIndex(data.toSkip) if data.toSkip?
               @currentProjectRequest(null)
               @updateProjectUiState(filteredProjects)

               # these next 2 lines are essential for the lazy-loading experience
               @projectsStillLoadingAssignmentsRequests.push(projectIds)
               @loadPhase2Data(projectIds)

               ko.utils.arrayPushAll(@projects, filteredProjects)
               @projectLoadingMediator.hideLoader()
               @maybeFillProjectView() if @projectPageCursor.hasMoreBottomRecords()
            else
               # @currentProjectRequest().cancel()
               @currentProjectRequest(null)
               @projectLoadingMediator.hideLoader()
      )

   loadPhase2Data: (projectIds) =>
      if projectIds.length > 0
         # assignments and requests need to be retrieved before we update costCodes/categories with their data
         showOnlyValue = @appliedOnlyShowFilter()?.value_sets[0].value ? null

         if showOnlyValue == null
            await Promise.all([
               @getProjectAssignments(projectIds),
               @getProjectRequests(projectIds)
            ])
         else
            await Promise.all([
               if showOnlyValue == "assignments" then @getProjectAssignments(projectIds) else null,
               if showOnlyValue == "requests" then @getProjectRequests(projectIds) else null,
            ])
         await @addDataToProjectCategories(projectIds)

      @projectsStillLoadingAssignmentsRequests.remove((idSet) => idSet[0] == projectIds[0])

   # This method will create an object with all project assignments grouped like so: assignments[project_id][category_id][subcategory_id].
   # After creation it will be saved to the observable "@projectAssignments" for use in later methods.
   #
   # Previously, this data-structure was slighlty different and was merged onto the Project model itself when returned from the lc-api endpoint getBoardsProjects,
   # but after refactoring to use lc-core-api's lighter-weight findProjectsStream, we now have to derive this assignment data ourself on the client-side (frontend).
   # This allows us to get a quicker response time from the network and to use a more efficient means of collecting the assignments data and mapping it to the front-end.
   getProjectAssignments: (projectIds) =>
      assignments = {}
      # Instead of an array of labeledAssignmentCards here, we're using an object with { [labelId]: { labelId: string, labelCount: number, assignmentCards: [], displayCards: [] }}.
      # The reason for this seemingly redundant data-structure (ie. having label id as a key, just to have it as another field in the value object) is becuase it will make insertion much more
      # performant as we loop through the assignment stream and add cards to the nested assignmentCards and displayCards fields.
      # If this was just an array, then we would have to map through the array each time to find where the labelId is a match before accessing the nested assignmentCards or displayCards fields
      # for insertion. But with this nested-object structure, we can just access the proper target object by key and then access the target property we want to insert to.
      labeledAssignmentCards = @labeledAssignmentCards() || {}

      try
         dayFilter = DateUtils.getDetachedDay(@viewingDate())
         dayFilterWeekDay = String(DateUtils.getAttachedDate(dayFilter).getDay())
         lastNameFirst = authManager.authedUser()?.preferences()?.displayLastNamesFirst() || false
         # Used for setting the subtitle on each assignment card
         boardSubtitleSetting = authManager.authedUser().preferences().boardCardsSubtitle()
         benchSubtitleSetting = authManager.authedUser().preferences().benchCardsSubtitle()
         batchedAssignments = await ProjectStore.batchProjectAssignments({
            projectIds,
            showResourcesNotInGroup: @showResourcesNotInGroup(),
            dayFilter,
         }).payload

         for await a from batchedAssignments.data
            if !projectIds.includes(a.project_id)
               continue

            # Before we transform the assignment data to an AssignmentCard, let's hand it off to calculate its totalBurn & actualRate. Each assignment will add to its project's
            # cumulative totalBurn & actualRate, so to make the process of getting the cumulative values more efficient, we're going to do it little by little as we pull each
            # assignment from the stream
            @addProjectRateAndBurn(a, dayFilterWeekDay)
            
            # There are instances of assignments having category_ids and/or subcategoryIds that do not exist on the project they are assigned to.
            # This check will ensure that the assigment still gets displayed in these cases.
            # When the category or subcategory does not exist on the project, then the assignment will be listed at the top level of the project.
            projectCategoryIds = a.project.categories.map((c) => return c.id)
            projectSubcategoryIds = []
            for cat in a.project.categories
               for subCat in cat.subcategories
                  projectSubcategoryIds.push(subCat.id)

            categoryId = if projectCategoryIds.indexOf(a.category_id) != -1 then a.category_id else null
            subcategoryId = if projectSubcategoryIds.indexOf(a.subcategory_id) != -1 then a.subcategory_id else null

            assignmentCard = new AssignmentCard({
               ...a,
               dynamic_subtitle: @getAssignmentSubtitle(a, boardSubtitleSetting, benchSubtitleSetting),
               employee_number: a.person.employee_number,
               group_ids: a.person.group_ids,
               resource_initials: a.person.name.first.slice(0,1) + a.person.name.last.slice(0,1),
               resource_color: a.person.job_title?.color or null,
               resource_photo_url: a.person.profile_pic_url,
               resource_rate: a.person.hourly_wage or null,
               resource_title: if lastNameFirst then "#{a.person.name.last}, #{a.person.name.first}" else "#{a.person.name.first} #{a.person.name.last}",
               resource_tag_instances: a.person.tag_instances,
               position_sequence: a.person.job_title?.sequence or 100000,
               position_name: a.person.job_title?.name or null,
               position_rate: a.person.job_title?.hourly_rate or null,
               dob: a.person.dob,
               phone: a.person.phone,
               baggage: {
                  resource_address_1: a.person.address_1,
                  resource_address_2: a.person.address_2,
                  resource_city: a.person.city_town,
                  resource_state: a.person.state_province,
                  resource_zipcode: a.person.zipcode,
               }
            })

            labeledCard = {
               labelId: subcategoryId,
               # I have no clue what the differences are between assignmentCards & displayCards. My console logs showed these holding the same value, so that's how I'm treating them.
               assignmentCards: ko.observableArray([assignmentCard]),
               displayCards: ko.observableArray([assignmentCard]),
               # My console logs showed this as the value of "a.label_count", which isn't defined anywhere so this is another irrelevant field that was always undefined
               # Despite this typically being undefined, I figured I might as well assign it a value and increment it below since we have the ability to do so
               labelCount: 1
            }

            if assignments[a.project_id] == undefined
               assignments[a.project_id] = {
                  [categoryId]: {
                     [subcategoryId]: [assignmentCard],
                  }
               }
               labeledAssignmentCards[a.project_id] = {
                  [categoryId]: {
                     [subcategoryId]: labeledCard,
                  }
               }
            else if assignments[a.project_id][categoryId] == undefined
               assignments[a.project_id][categoryId] = {
                  [subcategoryId]: [assignmentCard],
               }
               labeledAssignmentCards[a.project_id][categoryId] = {
                  [subcategoryId]: labeledCard,
               }
            else if assignments[a.project_id][categoryId][subcategoryId] == undefined
               assignments[a.project_id][categoryId][subcategoryId] = [assignmentCard]
               labeledAssignmentCards[a.project_id][categoryId][subcategoryId] = labeledCard
            else
               assignments[a.project_id][categoryId][subcategoryId].push(assignmentCard)
               labeledAssignmentCards[a.project_id][categoryId][subcategoryId].assignmentCards.push(assignmentCard)
               labeledAssignmentCards[a.project_id][categoryId][subcategoryId].displayCards.push(assignmentCard)
               labeledAssignmentCards[a.project_id][categoryId][subcategoryId].labelCount++

         # Join the already loaded projectAssignments with the new assignments that are returned from next pagination
         @projectAssignments(Object.assign(@projectAssignments() || {}, assignments))
         @labeledAssignmentCards(labeledAssignmentCards)
      catch err
         console.log("assignment stream error:", err)

   # This method will create an object with all project requests grouped like so: requests[project_id][category_id][subcategory_id].
   # After creation it will be saved to the observable "@projectRequests" for use in later methods.
   #
   # Previously, this data-structure was slighlty different and was merged onto the Project model itself when returned from the lc-api endpoint getBoardsProjects,
   # but after refactoring to use lc-core-api's lighter-weight findProjectsStream, we now have to derive this assignment data ourself on the client-side (frontend).
   # This allows us to get a quicker response time from the network and to use a more efficient means of collecting the requests data and mapping it to the front-end.
   getProjectRequests: (projectIds) =>
      requests = {}

      labeledRequestCards = @labeledRequestCards() || {}

      try
         dayFilter = DateUtils.getDetachedDay(@viewingDate())
         requestPerDay = authManager.authedUser().preferences().requestPerDay()

         batchedRequests = await ProjectStore.batchProjectRequests({
            projectIds,
            showResourcesNotInGroup: @showResourcesNotInGroup(),
            dayFilter,
            requestPerDay,
         }).payload

         for await r from batchedRequests.data
            if !projectIds.includes(r.project_id)
               continue

            categoryId = r.category_id ? r.cost_code_id ? null
            subcategoryId = r.subcategory_id ? r.label_id ? null

            requestCard = new Placeholder({
               ...r,
               cost_code_id: r.category_id,
               label_id: r.subcategory_id,
               position: r.job_title
            })

            labeledCard = {
               labelId: subcategoryId,
               # I have no clue what the differences are between assignmentCards & displayCards. My console logs showed these holding the same value, so that's how I'm treating them.
               requestCards: ko.observableArray([requestCard]),
               displayCards: ko.observableArray([requestCard]),
               # My console logs showed this as the value of "a.label_count", which isn't defined anywhere so this is another irrelevant field that was always undefined
               # Despite this typically being undefined, I figured I might as well assign it a value and increment it below since we have the ability to do so
               labelCount: 1
            }

            if requests[r.project_id] == undefined
               requests[r.project_id] = {
                  [categoryId]: {
                     [subcategoryId]: [requestCard],
                  }
               }
               labeledRequestCards[r.project_id] = {
                  [categoryId]: {
                     [subcategoryId]: labeledCard,
                  }
               }
            else if requests[r.project_id][categoryId] == undefined
               requests[r.project_id][categoryId] = {
                  [subcategoryId]: [requestCard],
               }
               labeledRequestCards[r.project_id][categoryId] = {
                  [subcategoryId]: labeledCard,
               }
            else if requests[r.project_id][categoryId][subcategoryId] == undefined
               requests[r.project_id][categoryId][subcategoryId] = [requestCard]
               labeledRequestCards[r.project_id][categoryId][subcategoryId] = labeledCard
            else
               requests[r.project_id][categoryId][subcategoryId].push(requestCard)
               labeledRequestCards[r.project_id][categoryId][subcategoryId].requestCards.push(requestCard)
               labeledRequestCards[r.project_id][categoryId][subcategoryId].displayCards.push(requestCard)
               labeledRequestCards[r.project_id][categoryId][subcategoryId].labelCount++

         # Join the already loaded projectAssignments with the new assignments that are returned from next pagination
         @projectRequests(Object.assign(@projectRequests() || {}, requests))
         @labeledRequestCards(labeledRequestCards)
      catch err
         console.log("request stream error:", err)

   # Anything that is using @projectAssignments() or @projectRequests() is from the new data structure that we're attempting to use for lazy-loading. Those observables did not exist before.
   #
   # On the frontend Project model, the observable is still called costCodes, but in the modern world, this object is refered to as "categories".
   # Perhaps we'll refactor later to eliminate the use of "cost_code" terminology, but the current focus is on optimizing for boards page performance.
   #
   # Previously, this cost code data was returned fully from the backend endpoint getBoardsProjects, but are now using the lighter weight
   # findProjectsStream endpoint to get a subset of the Boards data so that the UI can load quicker, and now have to manually derive these
   # additional cost code fields. This takes responsibility off of the server and places it on the client, which will help bring down our network
   # response times and enable a snappier front-end experience.
   addDataToProjectCategories: (projectIds) =>
      @projects().filter((p) => projectIds.includes(p.id)).map (project) =>
         # Accessing `.null.null` values looks strange, but this is by design. See how we build the @projectAssignments and @projectRequests
         # objects in the 2 above methods (ie. getProjectAssignments & getProjectRequests). When categoryId and subcategoryId are null, we're using
         # that null value to create a key in the object like so { [nullCategoryId]: { [nullSubcategoryId]: [uncategorizedAssignmentOrRequest] }},
         # so to pull those uncategorized items out, we look in the project's "null" keys
         uncategorizedAssignments = @projectAssignments()[project.id]?.null?.null or []
         if uncategorizedAssignments
            project.uncategorizedAssignments(uncategorizedAssignments)
         categorizedAssignments = @projectAssignments()[project.id] ? {}
         
         uncategorizedRequests = @projectRequests()[project.id]?.null?.null or []
         if uncategorizedRequests
            project.uncategorizedRequests(uncategorizedRequests)
         categorizedRequests = @projectRequests()[project.id] ? {}

         updatedCostCodes = project.costCodes().map (code) =>
            assignmentCount = 0
            requestCount = 0

            if categorizedAssignments[code.id]?
               assignmentCount += Object.values(categorizedAssignments[code.id]).reduce(
                  (accumulator, currentValue) =>
                     accumulator += currentValue.length
                  0
               );

            if categorizedRequests[code.id]?
               requestCount += Object.values(categorizedRequests[code.id]).reduce(
                  (accumulator, currentValue) =>
                     accumulator += currentValue.length
                  0
               );

            lac = []
            if @labeledAssignmentCards()[project.id] and @labeledAssignmentCards()[project.id][code.id]
               lac = Object.values(@labeledAssignmentCards()[project.id][code.id])
            code.assignmentCount(assignmentCount)
            code.labeledAssignmentCards(lac)
            
            lrc = []
            if @labeledRequestCards()[project.id] and @labeledRequestCards()[project.id][code.id]
               lrc = Object.values(@labeledRequestCards()[project.id][code.id])
            code.requestCount(requestCount)
            code.labeledRequestCards(lrc)

            return code

         project.costCodes(updatedCostCodes)

         # This method is focused on updating the costCodes field, but since we already have this projects loop, we're
         # going to go ahead and use it to add on the project's totalBurn and actualRate as well, so we don't have to do
         # another loop elsewhere
         totalBurn = @projectTotalBurns[project.id]
         cumulativeHours = @projectCumulativeHours[project.id]
         project.totalBurn(totalBurn)
         project.actualRate(totalBurn / cumulativeHours)

         return project
   
   # A project's "Daily Rate" and "Daily Burn" is calculated from all of its assignment's cumulative hours and total burns.
   # For example, each assignment will contribute to cumulative values like so:
   #
   #              totalBurn += assignmentHours * assignmentRate
   #              cumulativeHours += assignmentHours
   #
   # And once we've completed our assignment loop, we take those cumulative totals to calculate total_burn (ie. "Daily Burn")
   # and actual_rate (ie. "Daily Rate") like so:
   #
   #              project['total_burn'] = totalBurn
   #              project['actual_rate'] = totalBurn / cumulativeHours
   #
   # These project values will be used for populating the "Daily Rate" and "Daily Burn" values on the project's header.
   # If available, you may view Jira ticket WFP-916 for reference.
   #
   # (logic adapted from the executeGetProjectsAssignments function in project-manager.coffee)
   addProjectRateAndBurn: (assignment, dayFilterWeekDay) =>
      project = @projects().find((p) => p.id == assignment.project_id)
      if project == undefined
         console.error("can not find project associated with assignment #{assignment.id}")
         return

      projectsOverrides = {}
      for override in project?.wageOverrides() or []
         projectsOverrides[override.position_id] = override.rate

      rate = assignment.person.hourly_wage

      # Checking for a position rate.
      # NOTE: If a person's assignment rate is 0, treat it as if it was null.
      if rate == null or rate == 0
         rate = assignment.person.job_title.hourly_rate or null if assignment.person.job_title?.hourly_rate?
      # Ignoring resources who don't have rates so salaried doesn't mess up avgs.
      positionId = assignment.person.job_title?.id or null
      if positionId? and projectsOverrides[positionId]?
         rate = projectsOverrides[positionId]

      return if !rate? or rate == 0

      overtimeDayRates = @boardsPage.state.assignmentSupportData.overtimeDayRates()
      paidShiftHours = @boardsPage.state.assignmentSupportData.paidShiftHours()

      if assignment.overtime and overtimeDayRates?
         if assignment.overtime_rates?
            overtimeRate = assignment.overtime_rates[String(dayFilterWeekDay)]
         else
            dayKeys = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
            overtimeRate = overtimeDayRates[dayKeys[dayFilterWeekDay]]

         if @projectTotalBurns[project.id] == undefined
            @projectTotalBurns[project.id] = (assignment.pay_split.straight * rate) + ((assignment.pay_split.overtime * rate) * overtimeRate)
            @projectCumulativeHours[project.id] = (assignment.pay_split.straight + assignment.pay_split.overtime)
         else
            @projectTotalBurns[project.id] += (assignment.pay_split.straight * rate) + ((assignment.pay_split.overtime * rate) * overtimeRate)
            @projectCumulativeHours[project.id] += (assignment.pay_split.straight + assignment.pay_split.overtime)
      else
         maxPaidHours = paidShiftHours
         if assignment.percent_allocated?
            hours = maxPaidHours * (assignment.percent_allocated / 100)
         else
            if assignment.start_time < assignment.end_time
               hours = (assignment.end_time - assignment.start_time)
               if hours > maxPaidHours
                  hours = maxPaidHours
            else
               # Overnight
               hours = ((24 - assignment.start_time) + assignment.end_time)
               if hours > maxPaidHours
                  hours = maxPaidHours

         if @projectTotalBurns[project.id] == undefined
            @projectTotalBurns[project.id] = hours * rate
            @projectCumulativeHours[project.id] = hours
         else
            @projectTotalBurns[project.id] += hours * rate
            @projectCumulativeHours[project.id] += hours


   getAssignmentSubtitle: (data, boardSubtitleSetting = null, benchSubtitleSetting = null) =>
      if boardSubtitleSetting == null
         boardSubtitleSetting = authManager.authedUser().preferences().boardCardsSubtitle()
      if benchSubtitleSetting == null
         benchSubtitleSetting = authManager.authedUser().preferences().benchCardsSubtitle()

      subtitle = null

      if boardSubtitleSetting == benchSubtitleSetting && data.dynamic_subtitle?
         subtitle = data.dynamic_subtitle
      else if boardSubtitleSetting?
         if boardSubtitleSetting == "employee-number"
            subtitle = data.person.employee_number
         else if boardSubtitleSetting == "phone"
            subtitle = data.person.phone
         else if boardSubtitleSetting == "email"
            subtitle = data.person.email
         else if boardSubtitleSetting == "hourly-rate"
            rate = null
            if data.person.hourly_wage?
               rate = data.person.hourly_wage
            else if data.person.job_title.hourly_rate?
               rate = data.person.job_title.hourly_rate
            subtitle = if rate? then "$#{rate}/hr" else null
         else if boardSubtitleSetting == "city"
            subtitle = data.person.city_town
         else if boardSubtitleSetting == "state"
            subtitle = data.person.state_province
         else if boardSubtitleSetting == "postal"
            subtitle = data.person.zipcode
         else if boardSubtitleSetting == "batch-dates"
            formattedStart = DateUtils.formatDetachedDay(data.start_day, defaultStore.getDateFormat())
            formattedEnd = DateUtils.formatDetachedDay(data.end_day, defaultStore.getDateFormat())
            subtitle = "#{formattedStart} - #{formattedEnd}"
         else if boardSubtitleSetting == "assignment-status"
            subtitle = if data.status then data.status.name else null
         else if boardSubtitleSetting == "job-title"
            try
               subtitle = data.person.job_title.name
            catch err
               subtitle = ""
               console.error({ err, data });
               Bugsnag.notify("trying to access 'job_title.name' when 'job_title' is null", (event) =>
                     event.context = "AssignmentsViewModel.getAssignmentSubtitle";
                     event.addMetadata(
                        BUGSNAG_META_TAB.USER_DATA,
                        buildUserData(authManager.authedUser(), authManager.activePermission),
                     );
                     event.addMetadata("error", { err });
                     event.addMetadata("data", { data });
               );

      return subtitle

   reloadProjects_: (skip) =>
      currentProjectRequest = @currentProjectRequest()
      if currentProjectRequest
         currentProjectRequest.cancel()
         @currentProjectRequest(null)

      if skip?
         @currentLoadedProjectKeys.clear()
         @projectPageCursor.startingIndex(skip)

      if !skip? || skip == 0
         @projectPaginationNextStartingAfter = undefined
         @projectPaginationTotalPossible = undefined

      @projects.removeAll()
      @loadData()
      @loadTopData()

   maybeFillProjectView: =>
      parent = document.getElementsByClassName(AssignmentsViewModel.ElementClass.CONTENT_CONTAINER)[0]
      child = document.getElementsByClassName(AssignmentsViewModel.ElementClass.SCROLLING_CONTENT)[0]
      requestAnimationFrame () =>
         if parent? and child?
            parentHeight = parent.offsetHeight
            childHeight = child.offsetHeight
            if childHeight < parentHeight
               @loadData() 
               @loadTopData()
   
   showSaveViewModal: () =>
      modal = new Modal();
      pane = new SaveViewPane(
         App.PageName.ASSIGNMENTS_PAGE,
         {
            search: @projectQuery(),
            filters: @filterChips(),
            sortBy: @selectedProjectSortBy().value(),
            pageSpecific: {
               bench_search: @benchSearchValue(),
               bench_type: @selectedResourceStateOption().value(),
               card_sort_by: @selectedCardSortBy().value(),
               bench_chip_filters: @benchFilterChips().map (item) ->
                  return FormatUtils.camelCaseObjectToSnakeCase(item)
            }
         }
      );
      modal.setPanes([pane]);
      modalManager.showModal(
         modal,
         null,
         { class: "save-view-modal" },
      )
   
   
   loadSavedView: (savedViewId) =>
      savedViewPayload = await SavedViewStore.getSavedView(savedViewId).payload
      savedView = savedViewPayload.data

      return unless savedView?

      @setTitle(savedView.name)

      if savedView.chip_filters?
         chipFilters = savedView.chip_filters.map (item) -> 
            return FormatUtils.snakeCaseObjectToCamelCase(item)
            
         if chipFilters?
            @filterChips(chipFilters)
            # Check stored chips for "only_show"
            for chip in chipFilters
               if chip.property == "only_show"
                  @refineOnlyShowFilter(@filterChips())
            setTimeout( () => 
               @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))
            , 0)

      if savedView.page_specific?.bench_chip_filters?
         benchChipFilters = savedView.page_specific.bench_chip_filters.map (item) -> 
            return FormatUtils.snakeCaseObjectToCamelCase(item)
         @benchFilterChips(benchChipFilters) if benchChipFilters?

      if savedView.search? and ValidationUtils.validateInput(savedView.search)
         @projectQuery(decodeURIComponent(savedView.search))
      
      if savedView.page_specific?.bench_search? and ValidationUtils.validateInput(savedView.page_specific.bench_search)
         @benchSearchValue(decodeURIComponent(savedView.page_specific.bench_search))
      
      if savedView.page_specific?.bench_type? and ValidationUtils.validateInput(savedView.page_specific.bench_type)
         resourceBenchState = savedView.page_specific.bench_type
         @getSegmentItemByValue_ @resourceStateOptions, resourceBenchState, DEFAULT_RESOURCE_BENCH_STATE, (item) =>
            @selectedResourceStateOption(item)
      
      if savedView.view_config.sort_by? and ValidationUtils.validateInput(savedView.view_config.sort_by)
         projectSortBy = savedView.view_config.sort_by or AssignmentsViewModel.ProjectSortBy.NAME
         @setDropDownItemByValue_ @projectSortByOptions, projectSortBy, AssignmentsViewModel.ProjectSortBy.NAME, (item) =>
            @selectedProjectSortBy(item)
      
      if savedView.page_specific?.card_sort_by? and ValidationUtils.validateInput(savedView.page_specific.card_sort_by)
         cardSortBy = savedView.page_specific.card_sort_by or AssignmentsViewModel.AssignmentCardSortBy.POSITION
         @setDropDownItemByValue_ @cardSortByOptions, cardSortBy, AssignmentsViewModel.AssignmentCardSortBy.POSITION, (item) =>
            @selectedCardSortBy(item)
      
      @makeInitialLoadCalls()

   loadSupportData: =>
      tagStream = await TagStore.findTagsStream({}).stream
      tagExprInfo = []
      @tagsData.removeAll();
      for await tag from tagStream
         @tagsData.push(tag)
         if tag.require_expr_date
            tagExprInfo.push({
               id: tag.id,
               exprDaysWarning: tag.expr_days_warning,
            })
      @tagExprInfo(tagExprInfo)

      entities = ['positions', 'projects', 'people']
      if @tagCategoriesEnabled
         entities.push('categorized-tags')
      else
         entities.push('tags')

      if @customFieldModuleEnabled
         entities.push('people_custom_field_filters')
         entities.push('projects_custom_field_filters')

      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities authManager.selectedGroupId(), entities, (err, data) =>
         return console.log "get group entity options err: ", err if err?
         
         projectsFilterOptions = {
            "Hide Empty Projects": ko.observable({
               property: "only_projects_with_data"
               values: [
                  new ValueSet({name: "True", value: true})
               ]
            })
            "Percent Complete": ko.observable({
               property: "percent_complete"
               disableSearch: true
               classifiers: [
                  {listLabel: "< (Less Than)", chipLabel: "<", value: "<"}, 
                  {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="}, 
                  {listLabel: "= (Equal To)", chipLabel: "=", value: '='}, 
                  {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                  {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
               ]
               type: "number"
            })
            "Start Date": ko.observable(buildDateFilterInstance('start_date'))
            "Est. End Date": ko.observable(buildDateFilterInstance('est_end_date'))
         }

         if @canViewProjectFinancials
            projectsFilterOptions["Bid Rate"] = ko.observable({
               property: "bid_rate"
               disableSearch: true
               classifiers: [
                  {listLabel: "< (Less Than)", chipLabel: "<", value: "<"}, 
                  {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="}, 
                  {listLabel: "= (Equal To)", chipLabel: "=", value: '='}, 
                  {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                  {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
               ]
               type: "number"
            })

         if @canViewProjectTags
            if data.tagOptions? and data.tagOptions.length > 0
               projectsFilterOptions["Tags"] = ko.observable({
                  property: "tag_instances"
                  type: "multi-select"
                  values: FormatUtils.keyableSort(data.tagOptions, 'name')
               })
            else if data.categorizedTagOptions?
               classifiers = []
               for key of data.categorizedTagOptions
                  classifiers.push({listLabel: key, chipLabel: null, value: key})
               classifiers.sort (a, b) ->
                  if a.listLabel.toLowerCase() < b.listLabel.toLowerCase()
                     return -1
                  else if a.listLabel.toLowerCase() > b.listLabel.toLowerCase()
                     return 1
                  else
                     return 0

               projectsFilterOptions["Tags"] = ko.observable({
                  property: "tag_instances"
                  type: "multi-select"
                  classifiers: classifiers
                  classifierPaneName: "Tag Category"
                  values: data.categorizedTagOptions
                  backEnabled: true
               })

         if @canViewRequests
            projectsFilterOptions["Only Show"] = ko.observable({
               property: "only_show"
               values: [
                  new ValueSet({name: "Assignments", value: 'assignments'})
                  new ValueSet({name: "Requests", value: 'requests'})
               ]
            })

         if data.projectsCustomFieldFilters?
            for field in data.projectsCustomFieldFilters
               unless @canViewProjectSensitive
                  continue if authManager.projectsSensitiveFields().indexOf(field.integration_name) != -1

               if field.type == "multi-select"
                  projectsFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: field.values.map (i) -> return new ValueSet({name: i, value: i})
                  })
               else if field.type == "select"
                  projectsFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: field.values.map (i) -> return new ValueSet({name: i, value: i})
                  })
               else if field.type == "bool"
                  projectsFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: [
                        new ValueSet({name: "TRUE", value: true})
                        new ValueSet({name: "FALSE", value: false})
                     ]
                  })
               else if (@canViewProjectFinancials and field.type == "currency") or field.type == "number"
                  projectsFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     disableSearch: true
                     classifiers: [
                        {listLabel: "< (Less Than)", chipLabel: "<", value: "<"}, 
                        {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="}, 
                        {listLabel: "= (Equal To)", chipLabel: "=", value: '='}, 
                        {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                        {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
                     ]
                  })
               else if field.type == "date"
                  projectsFilterOptions[field.name] = ko.observable(buildDateFilterInstance('custom_fields', { customFieldId: field.id }))
               else if field.type == "text"
                  projectsFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     disableSearch: true
                  })

         if data.positionOptions.length > 0
            classifiers = (data.positionOptions or []).map (option) ->
               return { listLabel: option.name(), chipLabel: option.name(), value: option.value() }
            classifiers.unshift( { listLabel: 'Any', chipLabel: 'Any', value: ANY } )
            valueOptions = data.peopleOptions.map (i) ->
               return new ValueSet({name: i.name(), value: i.value()})
            valueOptions = FormatUtils.keyableSort(valueOptions, 'name')
            valueOptions.unshift( new ValueSet({ name: 'Any', value: ANY }) )

            projectsFilterOptions["Project Roles"] = ko.observable({
               property: "project_roles"
               classifiers: classifiers
               type: "select"
               classifierPaneName: "Job Title"
               values: valueOptions
               backEnabled: true
            })

         @labeledFilterOptions(projectsFilterOptions)

         # People Filters

         peopleFilterOptions = {}

         if @canViewPeopleFinancials
            peopleFilterOptions["Wage"] = ko.observable({
               property: "hourly_wage"
               disableSearch: true
               classifiers: [
                  {listLabel: "< (Less Than)", chipLabel: "<", value: "<"}, 
                  {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="}, 
                  {listLabel: "= (Equal To)", chipLabel: "=", value: '='}, 
                  {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                  {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
               ]
               type: "number"
            })

         if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf('is_male') == -1
            peopleFilterOptions["Gender"] = ko.observable({
               property: "is_male"
               values: [
                  new ValueSet({name: "Female", value: false})
                  new ValueSet({name: "Male", value: true})
               ]
            })

         if data.positionOptions.length > 0
            peopleFilterOptions["Job Titles"] = ko.observable({
               property: "position_id"
               values: data.positionOptions
            })
         
         if @canViewPeopleTags
            if data.tagOptions? and data.tagOptions.length > 0
               peopleFilterOptions["Tags"] = ko.observable({
                  property: "tag_instances"
                  type: "multi-select"
                  values: FormatUtils.keyableSort(data.tagOptions, 'name')
               })
            else if data.categorizedTagOptions?
               classifiers = []
               for key of data.categorizedTagOptions
                  classifiers.push({listLabel: key, chipLabel: null, value: key})
               classifiers.sort (a, b) ->
                  if a.listLabel.toLowerCase() < b.listLabel.toLowerCase()
                     return -1
                  else if a.listLabel.toLowerCase() > b.listLabel.toLowerCase()
                     return 1
                  else
                     return 0

               peopleFilterOptions["Tags"] = ko.observable({
                  property: "tag_instances"
                  type: "multi-select"
                  classifiers: classifiers
                  classifierPaneName: "Tag Category"
                  values: data.categorizedTagOptions
                  backEnabled: true
               })

         if data.peopleCustomFieldFilters?
            for field in data.peopleCustomFieldFilters
               unless @canViewPeopleSensitive
                  continue if authManager.peopleSensitiveFields().indexOf(field.integration_name) != -1

               if field.type == "multi-select"
                  peopleFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: field.values.map (i) -> return new ValueSet({name: i, value: i})
                  })
               else if field.type == "select"
                  peopleFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: field.values.map (i) -> return new ValueSet({name: i, value: i})
                  })
               else if field.type == "bool"
                  peopleFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: [
                        new ValueSet({name: "TRUE", value: true})
                        new ValueSet({name: "FALSE", value: false})
                     ]
                  })
               else if (@canViewPeopleFinancials and field.type == "currency") or field.type == "number"
                  peopleFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     disableSearch: true
                     classifiers: [
                        {listLabel: "< (Less Than)", chipLabel: "<", value: "<"}, 
                        {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="}, 
                        {listLabel: "= (Equal To)", chipLabel: "=", value: '='}, 
                        {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                        {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
                     ]
                  })
               else if field.type == "date"
                  peopleFilterOptions[field.name] = ko.observable(buildDateFilterInstance('custom_fields', { customFieldId: field.id}))
               else if field.type == "text"
                  peopleFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     disableSearch: true
                  })

         @benchLabeledFilterOptions(peopleFilterOptions)


   # Utility method for easily refreshing a project on the boards page.
   refreshProject: (projectId) =>
      @handleAssignmentRealTime({
         data: {
            project_id: projectId
         },
         userId: authManager.authedUserId(),
      })

   handleAssignmentRealTime: (message) =>
      foundProject = false
      for project in @projects()
         if project.id == message.data.project_id
            foundProject = true
            break
      if foundProject == true && @isProcessingUpdate() == false && @rapidAssignActive() == false
         if message.userId == authManager.authedUserId()
            projectId = message.data.project_id
            options = {
               dayFilter: DateUtils.getDetachedDay(@viewingDate())
               # Need to send filters so we know if we are only showing a subset of data.
               filters: @getFilterParams()
            }


            if @CALL_GET_PROJECT_SINGLE_BOARDS_IN_LC_CORE_API
               options.filters = @transformFiltersWithValueSets(options.filters)
               newProjectFromProjectStoreCore = ((await ProjectStore.getProjectSingleBoards(projectId, options).payload).data)[0]

               # if the tag is not attached on each instance, lookup each tag from our stored support data and add it to the instance
               newProjectFromProjectStoreCore.tag_instances = newProjectFromProjectStoreCore.tag_instances.map((instance) =>
                  instance.tag = @tagsData().find((t) => t.id == instance.tag_id) if !instance.tag
                  return instance
               )

               newProject = new Project(newProjectFromProjectStoreCore)
               for project in @projects()
                  if project.id == message.data.project_id
                     storedCatIds = BrowserStorageUtils.getJsonValue(AssignmentsViewModel.StorageKeys.OPEN_CATEGORIES)
                     if storedCatIds?
                        if storedCatIds.indexOf("assignments-#{newProject.id}") != -1
                           newProject.uncategorizedAssignmentsOpen(true)
                        if storedCatIds.indexOf("requests-#{newProject.id}") != -1
                           newProject.placeholdersExpanded(true)

                        if storedCatIds?
                           for code in newProject.costCodes()
                              if storedCatIds.indexOf(code.id) != -1
                                 code.isOpen(true)
                     project.mapProperties(newProject)
            else
               legacyProjectStore.getSingleProjectBoards projectId, options, (err, newProject) =>
                  return console.log "Error: ", err if err
                  for project in @projects()
                     if project.id == message.data.project_id
                        storedCatIds = BrowserStorageUtils.getJsonValue(AssignmentsViewModel.StorageKeys.OPEN_CATEGORIES)
                        if storedCatIds?
                           if storedCatIds.indexOf("assignments-#{newProject.id}") != -1
                              newProject.uncategorizedAssignmentsOpen(true)
                           if storedCatIds.indexOf("requests-#{newProject.id}") != -1
                              newProject.placeholdersExpanded(true)

                           if storedCatIds?
                              for code in newProject.costCodes()
                                 if storedCatIds.indexOf(code.id) != -1
                                    code.isOpen(true)
                        project.mapProperties(newProject)  

         else if @refreshNotification() == null
            showNotification = @canViewAssignmentWithStatusId(message.data.old_status_id) or @canViewAssignmentWithStatusId(message.data.new_status_id)
            return if showNotification == false
            @assignmentProjectsUpdatesAvailable(true)
            @refreshNotification(new Notification({
                  text: "New Assignment Information Available",
                  actions: [
                     new Action({
                        text: "Refresh",
                        type: Action.Type.BLUE,
                        onClick: () => @updateAssignmentsRealTime()
                     })
                  ],
                  onDismiss: () => @refreshNotification(null)
               }))
            notificationManagerInstance.show(@refreshNotification(), App.RouteName.ASSIGNMENTS_PAGE);

   transformFiltersWithValueSets: (filters) =>
      transformClassifier = (legacyFilter) =>
         listOfValidClassifiers = ["=", "<", "<=", ">", ">=", "<x<", "<=x<=", "<=x<", "<x<="];

         if listOfValidClassifiers.includes(legacyFilter.classifier)
            return legacyFilter.classifier
         else
            return "="

      transformType = (legacyFilter) =>
         if legacyFilter.property == "only_projects_with_data"
            return "date"
         else if legacyFilter.property == "only_show"
            return "select"
         else
            return legacyFilter.type

      transformedFilters = [
         {
            name: "Status",
            property: "status",
            type: "select",
            value_sets: [
               {
                  negation: false,
                  value: "active",
               },
            ]
         },
         # This used to be a simple Object.values().map(), but I realized that there was a bug when adding filters of the same property overwriting
         # each other instead of adding another item to the value_set of the first! This new reduce method will ensure that filters with the same
         # property merge and increase the size of their value_set!
         ...Object.values(filters).flat().reduce((acc, cur) =>
            existingFilter = if cur.property == "custom_fields" then acc.find((v) => v.custom_field_id == cur.customFieldId) else acc.find((v) => v.property == cur.property);

            valueSet = {
               negation: cur.negation ? false,
               value: if cur.property == "only_projects_with_data" then DateUtils.getDetachedDay(@viewingDate()) else cur.value,
               classifier: transformClassifier(cur),
            }

            if existingFilter
               existingFilter.value_sets.push(valueSet);
               return acc
            else
               acc.push({
                  name: cur.filterName,
                  property: cur.property,
                  type: transformType(cur),
                  value_sets: [valueSet],
                  # If it's a custom field filter, the custom_field_id property is required or the reql-builder will throw an error
                  ...(if cur.property == "custom_fields" then {custom_field_id: cur.filterName} else {})
               })
               return acc
         , []),
      ]

      # sometimes an undefined value is left in our filters array. check for it and remove if so
      undefinedAt = transformedFilters.indexOf(undefined)
      if (undefinedAt != -1) then transformedFilters.splice(undefinedAt, 1);

      return transformedFilters

   canViewAssignmentWithStatusId: (statusId) =>
      if @canViewAllStatuses == true
         return true
      else if @visibleStatusIds.has(statusId)
         return true
      else
         return false

   updateAssignmentsRealTime: () =>
      @assignmentProjectsUpdatesAvailable(false)
      @reloadProjects_(@projectPageCursor.lastIndex())           
      notificationManagerInstance.dismiss(@refreshNotification());
      @refreshNotification(null)
   
   buildBenchSearchParameters: () => {
         context: @selectedResourceStateOption().value()
         search: if ValidationUtils.validateInput(@benchSearchValue()) then @benchSearchValue() else null
         sort: @selectedCardSortBy().value()
         dayFilter: DateUtils.getDetachedDay(@viewingDate())
         options: {
            last_names_first: authManager.authedUser().preferences().displayLastNamesFirst()
         }
         filters: @getBenchFilterParams()
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
   }

   loadBenchTopData: () =>
      return unless @initialized
      return if @currentBenchRequest() or !@benchPageCursor.hasMoreTopRecords()
      @topBenchLoadingMediator.showLoader()
      @topBenchLoadingMediator.startProgress()

      @currentBenchRequest @benchPageCursor.decrement(
         @buildBenchSearchParameters(),
         (err, data) =>
            if err
               @currentBenchRequest(null)
               return console.log "err: ", err

            @topBenchLoadingMediator.appendData(=>
               @currentBenchRequest(null)
               @benchResourceCount(data.totalCount)
               @benchResources(data.cards.concat(@benchResources()))
               @benchScrollbarMediator.checkContentSize()
            , true)
      )


   loadBenchBottomData: =>
      return unless @initialized
      return if @currentBenchRequest() or !@benchPageCursor.hasMoreBottomRecords()
      @benchLoadingMediator.showLoader()
      @benchLoadingMediator.startProgress()

      @currentBenchRequest @benchPageCursor.increment(
         @buildBenchSearchParameters(), 
         (err, data) =>
            if err
               @currentBenchRequest(null)
               return console.log "err: ", err
            @benchLoadingMediator.appendData =>
               @currentBenchRequest(null)
               @benchResourceCount(data.totalCount)
               ko.utils.arrayPushAll(@benchResources, data.cards)
               @benchScrollbarMediator.checkContentSize()
      )

   reloadBench_: (skip) =>
      currentBenchRequest = @currentBenchRequest()
      if currentBenchRequest
         currentBenchRequest.cancel()
         @currentBenchRequest(null)
      @benchPageCursor.startingIndex(skip) if skip?
      @benchResourceCount("...")
      @benchResources.removeAll()
      @loadBenchBottomData()

   handleGroupChange: (newGroupId) =>
      # Clean up any old subscriptions & create new.
      return unless newGroupId?

      @projectPageCursor.dispose()
      @benchPageCursor.dispose()
      @projectPageCursor = @getNewProjectCursor()
      @benchPageCursor = @getNewBenchCursor()
      @reloadProjects_(@projectPageCursor.lastIndex())
      @reloadBench_(@benchPageCursor.lastIndex())
      @clearBenchFilters()
      @boardsPage.state.reload()

   dispose: (next) ->
      assertArgs(arguments, Function)
      @groupIdSubscription.dispose()

      fanoutManager.unsubscribe("vm.AssignmentsViewModel", FanoutManager.Channel.PEOPLE_ASSIGNMENTS)
      fanoutManager.unsubscribe("vm.AssignmentsViewModel", FanoutManager.Channel.GROUPS_ASSIGNMENTS)
      fanoutManager.unsubscribe("vm.AssignmentsViewModel", FanoutManager.Channel.GROUPS_REQUESTS)
      dragManager.clearData()

      try
         @currentProjectRequest().cancel() if @currentProjectRequest()
      catch err
         console.log(err)
      @currentProjectRequest(null)
      next()

   ###------------------------------------
      Modal Handlers
   ------------------------------------###
   assignmentCardSettingsClicked: (data) =>
      oldAssignmentCard = data.data
      resourceId = oldAssignmentCard.resourceId()

      showADM = =>
         supportData = @boardsPage.state.assignmentSupportData
         costingConfig = {
            paidShiftHours: supportData.paidShiftHours
            overtimeDayRates: supportData.overtimeDayRates
         }

         # This is used when you're opening a pane to look at an existing assignment
         pane1 = new GanttPane(resourceId, @viewingDay(), null, supportData, costingConfig)

         modal = new Modal()
         modal.setPanes([pane1])
         modalManager.showModal modal, null, {class: 'assignments-details-modal'}, (modal, modalStatus, observableData) =>
            if observableData.data.deletedAssignments? and observableData.data.deletedAssignments().length > 0
               @handleDeletedAssignmentsRemoval(observableData.data.deletedAssignments())

            if observableData.data.newAssignments?
               newAssignmentIds = []
               newAssignmentCards = []
               for assignment in observableData.data.newAssignments
                  newAssignmentCards.push(@constructAssignmentCardFromAssignment(assignment))
                  newAssignmentIds.push(assignment.id)

               if newAssignmentIds.indexOf(oldAssignmentCard.id) != -1
                  # Remove the old assignment.
                  for project in @projects()
                     if project.id == oldAssignmentCard.projectId()
                        if oldAssignmentCard.costCodeId()?
                           for costCode in project.costCodes()
                              foundLabel = false
                              if costCode.id == oldAssignmentCard.costCodeId()
                                 for labelSet in costCode.labeledAssignmentCards()
                                    if labelSet.labelId == oldAssignmentCard.labelId()
                                       foundLabel = true
                                       filteredCards = labelSet.assignmentCards().filter (card) ->
                                          return card.id != oldAssignmentCard.id

                                       labelSet.assignmentCards(filteredCards)

                                 break
                        else
                           remainingCards = project.uncategorizedAssignments().filter (card) ->
                              card.id != oldAssignmentCard.id

                           project.uncategorizedAssignments(remainingCards)

                        break

               for newCard in newAssignmentCards
                  for project in @projects()
                     if project.id == newCard.projectId()
                        if newCard.costCodeId()?
                           for costCode in project.costCodes()
                              foundLabel = false
                              if costCode.id == newCard.costCodeId()
                                 for labelSet in costCode.labeledAssignmentCards()
                                    if labelSet.labelId == newCard.labelId()
                                       foundLabel = true
                                       filteredCards = labelSet.assignmentCards().filter (card) ->
                                          return card.id != newCard.id

                                       filteredCards.push(newCard)

                                       labelSet.assignmentCards(filteredCards)

                                 unless foundLabel
                                    newDisplayCards = ko.pureComputed =>
                                       return ko.observableArray([newCard])

                                    newLabelSet = {
                                       labelId: newCard.labelId(),
                                       labelCount: 1
                                       assignmentCards: [newCard],
                                       displayCards: newDisplayCards
                                    }
                                    costCode.labeledAssignmentCards.push(newLabelSet)
                                 break
                        else
                           remainingCards = project.uncategorizedAssignments().filter (card) ->
                              card.id != newCard.id

                           remainingCards.push(newCard)

                           project.uncategorizedAssignments(remainingCards)

                        break

            return if modalStatus == "cancelled"
            if observableData.data.notify
               alertManager.showAssignmentMessageModal(observableData.data)
      showADM()

   handlePlaceholderFill: (placeholder, resourceId) =>
      showADM = =>
         pendingAssignment = {
            pendingId: GUID()
            projectId: placeholder.projectId()
            costCodeId: placeholder.costCodeId() or null
            labelId: placeholder.labelId() or null
            resourceId: resourceId
            startTime: placeholder.startTime()
            endTime: placeholder.endTime()
            percentAllocated: placeholder.percentAllocated()
            startDay: placeholder.startDay()
            endDay: placeholder.endDay()
            isPending: true
            workDays: placeholder.workDays()
            statusId: placeholder.status()?.id or null
            fillingRequest: true
         }
         supportData = @boardsPage.state.assignmentSupportData

         costingConfig = {
            paidShiftHours: supportData.paidShiftHours
            overtimeDayRates: supportData.overtimeDayRates
         }

         # This is used when you're opening a pane to fill a request
         pane1 = new GanttPane(resourceId, placeholder.startDay(), pendingAssignment, supportData, costingConfig, placeholder.id)

         modal = new Modal()
         modal.setPanes([pane1])
         modalManager.showModal modal, null, {class: 'assignments-details-modal'}, (modal, modalStatus, observableData) =>
            @reloadBench_(0)
            # TODO: Improve this check to be entirely safe that they actually filed the request,
            # not canceled it and saved something else while the ADM was open. 

            if observableData.data.savedBatchIds()?.length
               if @showRequestCategorized
                  foundMatch = false
                  for project in @projects()
                     if project.id == placeholder.projectId()
                        for projectPlaceholder in project.uncategorizedRequests()
                           if projectPlaceholder.id == placeholder.id
                              project.uncategorizedRequests.remove(projectPlaceholder)
                              foundMatch = true
                              break
                        unless foundMatch
                           for costCode in project.costCodes()
                              for set in costCode.labeledRequestCards()
                                 for card in set.requestCards()
                                    if card.id == placeholder.id
                                       set.requestCards.remove(card)
                                       foundMatch = true
                                       break
                                 break if foundMatch
                              break if foundMatch
                        break
               else
                  for project in @projects()
                     if project.id == placeholder.projectId()
                        for projectPlaceholder in project.placeholders()
                           if projectPlaceholder.id == placeholder.id
                              project.placeholders.remove(projectPlaceholder)
                              break
                        break

            if observableData.data.newAssignments?
               newAssignmentIds = []
               newAssignmentCards = []
               for assignment in observableData.data.newAssignments
                  newAssignmentCards.push(@constructAssignmentCardFromAssignment(assignment))
                  newAssignmentIds.push(assignment.id)

               for newCard in newAssignmentCards
                  for project in @projects()
                     if project.id == newCard.projectId()
                        if newCard.costCodeId()?
                           for costCode in project.costCodes()
                              foundLabel = false
                              if costCode.id == newCard.costCodeId()
                                 for labelSet in costCode.labeledAssignmentCards()
                                    if labelSet.labelId == newCard.labelId()
                                       foundLabel = true
                                       filteredCards = labelSet.assignmentCards().filter (card) ->
                                          return card.id != newCard.id

                                       filteredCards.push(newCard)

                                       labelSet.assignmentCards(filteredCards)

                                 unless foundLabel
                                    newDisplayCards = ko.pureComputed =>
                                       return ko.observableArray([newCard])

                                    newLabelSet = {
                                       labelId: newCard.labelId(),
                                       labelCount: 1
                                       assignmentCards: [newCard],
                                       displayCards: newDisplayCards
                                    }
                                    costCode.labeledAssignmentCards.push(newLabelSet)
                                 break
                        else
                           remainingCards = project.uncategorizedAssignments().filter (card) ->
                              card.id != newCard.id

                           remainingCards.push(newCard)

                           project.uncategorizedAssignments(remainingCards)

                        break

            return if modalStatus == "cancelled"
            if observableData.data.notify
               alertManager.showAssignmentMessageModal(observableData.data, placeholder)
      showADM()

   editPlacholder: (placeholder) =>
      @boardsPage.legacyOnCreateOrEditRequest({
         request: placeholder,
      })

   ###------------------------------------
      Helpers
   ------------------------------------###
   handleDeletedAssignmentsRemoval: (assignments) =>
      for assignment in assignments
         for project in @projects()
            if project.id == assignment.projectId
               if assignment.costCodeId?
                  for code in project.costCodes()
                     if code.id == assignment.costCodeId
                        for labelSet in code.labeledAssignmentCards()
                           if labelSet.labelId == assignment.labelId
                              filteredAssignments = labelSet.assignmentCards().filter (item) =>
                                 if assignment.deletedBatch
                                    return item.id != assignment.batchId
                                 else
                                    return item.instanceId() != assignment.instanceId
                              labelSet.assignmentCards(filteredAssignments)
                              break
                        break
                  break
               else
                  filteredAssignments = project.uncategorizedAssignments().filter (item) =>
                     if assignment.deletedBatch
                        return item.id != assignment.batchId
                     else
                        return item.instanceId() != assignment.instanceId
                  project.uncategorizedAssignments(filteredAssignments)
                  break

   # Need to normalize between the one above.
   handleDeletedAssignmentCardsRemoval: (assignmentCards, deletedBatches) =>
      for assignmentCard in assignmentCards
         for project in @projects()
            if project.id == assignmentCard.projectId()
               if assignmentCard.costCodeId()?
                  for code in project.costCodes()
                     if code.id == assignmentCard.costCodeId()
                        for labelSet in code.labeledAssignmentCards()
                           if labelSet.labelId == assignmentCard.labelId()
                              filteredAssignments = labelSet.assignmentCards().filter (item) =>
                                 if deletedBatches
                                    return item.id != assignmentCard.id
                                 else
                                    return item.instanceId() != assignmentCard.instanceId()
                              labelSet.assignmentCards(filteredAssignments)
                              break
                        break
                  break
               else
                  filteredAssignments = project.uncategorizedAssignments().filter (item) =>
                     if deletedBatches
                        return item.id != assignmentCard.id
                     else
                        return item.instanceId() != assignmentCard.instanceId()
                  project.uncategorizedAssignments(filteredAssignments)
                  break


   handleSelectedProjectSortByChanged_: (newValue) =>
      router.updateUrlQueryParam(App.RouteName.ASSIGNMENTS_PAGE, "projectSortBy", newValue.value())
      @reloadProjects_(0)

   handleSelectedCardSortByChanged_: (newValue) =>
      router.updateUrlQueryParam(App.RouteName.ASSIGNMENTS_PAGE, "cardSortBy", newValue.value())
      @reloadBench_(0)

   handleViewingDateChanged_: (newValue) =>
      @projectAssignments({})
      @projectRequests({})
      @labeledAssignmentCards([])
      @labeledRequestCards([])
      @batchSelectedAssignments([]) if @batchSelectedAssignments?
      dayFilter = DateUtils.getDetachedDay(newValue)
      router.updateUrlQueryParam(App.RouteName.ASSIGNMENTS_PAGE, "dayFilter", dayFilter)
      @reloadProjects_(0)
      @reloadBench_(0)

   handleSelectedResourceStateOptionChanged_: (newValue) =>
      router.updateUrlQueryParam(App.RouteName.ASSIGNMENTS_PAGE, "resourceBenchState", newValue.value())
      @reloadBench_(0)

   # TODO: Move to base class or util
   setDropDownItemByValue_: (dropDownItems, value, defaultValue, callback) =>
      assertArgs(arguments, arrayOf(DropDownItem), String, String, Function)
      defaultItem = null
      for item in dropDownItems
         currentValue = item.value()
         if currentValue == value
            return callback(item)
         if currentValue == defaultValue
            defaultItem = item
      return callback(defaultItem)

   # TODO: Move to base class or util
   getSegmentItemByValue_: (segmentItems, value, defaultValue, callback) =>
      assertArgs(arguments, arrayOf(SegmentedControllerItem), String, String, Function)
      defaultItem = null
      for item in segmentItems
         currentValue = item.value()
         if currentValue == value
            return callback(item)
         if currentValue == defaultValue
            defaultItem = item
      return callback(defaultItem)


   trackTopElement: (index) =>
      @projectPageCursor.save(index)

   trackTopBenchIndex: (index) =>
      @benchPageCursor.save(index)

AssignmentsViewModel.ProjectSortBy = {
   NAME: "name"
   PROJECT_NUMBER: "project_number"
   EARLIEST_START_DATE: "earliest_start_date"
   LATEST_START_DATE: "latest_start_date"
   EARLIEST_EST_END_DATE: "earliest_est_end_date"
   LATEST_EST_END_DATE: "latest_est_end_date"
   RESOURCE_COUNT: "assignment_ids"
   MOST_COMPLETE: "most_complete"
   LEAST_COMPLETE: "least_complete"
}

AssignmentsViewModel.AssignmentCardSortBy = {
   NAME: "name"
   POSITION: "position"
}

AssignmentsViewModel.RecievableError = {
   NO_CHANGES_MADE: 'noChangesMade'
}

AssignmentsViewModel.ElementClass = {
   CONTENT_CONTAINER: "assigments-content"
   SCROLLING_CONTENT: "assigments-content__projects-container"
}

# TODO: Should this token list be standardized across all views?
AssignmentsViewModel.BaseAssignmentTokens = [
   {
      name: "Assignee's Name"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "name"
      subject2Type: "people"
   }
   {
      name: "Assignee's Email"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "email"
      subject2Type: "people"
   }
   {
      name: "Assignee's Phone"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "phone"
      subject2Type: "people"
   }
   {
      name: "Assignee's Job Title"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "position_id"
      subject2Type: "people"
      subject3Key: "name"
      subject3Type: "positions"
   }
   {
      name: "Assignment Start Date"
      subject1Key: "start_day"
      subject1Type: "assignments"
   }
   {
      name: "Assignment End Date"
      subject1Key: "end_day"
      subject1Type: "assignments"
   }
   {
      name: "Assignment Start Time"
      subject1Key: "start_time"
      subject1Type: "assignments"
   }
   {
      name: "Assignment End Time"
      subject1Key: "end_time"
      subject1Type: "assignments"
   }
   {
      name: "Project Name"
      subject1Key: "name"
      subject1Type: "projects"
   }
   {
      name: "Project Address"
      subject1Key: "address_1"
      subject1Type: "projects"
   }
   {
      name: "Project Address 2"
      subject1Key: "address_2"
      subject1Type: "projects"
   }
   {
      name: "Project City"
      subject1Key: "city_town"
      subject1Type: "projects"
   }
   {
      name: "Project State"
      subject1Key: "state_province"
      subject1Type: "projects"
   }
   {
      name: "Project Postal Code"
      subject1Key: "zipcode"
      subject1Type: "projects"
   }
   {
      name: "Project Country"
      subject1Key: "country"
      subject1Type: "projects"
   }
   {
      name: "Project Number"
      subject1Key: "job_number"
      subject1Type: "projects"
   }
   {
      name: "Assignment Work Days"
      subject1Key: "work_days"
      subject1Type: "assignments"
   }
]

AssignmentsViewModel.BaseAssignmentTokens.push({
   name: "Assignment Status"
   subject1Key: "status_id"
   subject1Type: "assignments"
   subject2Key: "name"
   subject2Type: "statuses"
})

# Used for one off alerts on projects.
AssignmentsViewModel.DefaultAlertTemplate = {
   id: "one-off"
   owner_id: "not-set"
   subject: "Assignment Alert for LC-DT<{1}>",
   content: "Assignment Info:\nLC-DT<{2}> - LC-DT<{3}>\nLC-DT<{4}> - LC-DT<{5}>\nLC-DT<{6}>\nProject #: LC-DT<{7}>",
   dynamic_tokens: [
      {
         id: "1",
         name: "Assignee's Name",
         subject_1_id: null,
         subject_1_key: "resource_id",
         subject_1_type: "assignments",
         subject_2_key: "name",
         subject_2_type: "people"
      },
      {
         id: "2",
         name: "Start Date",
         subject_1_id: null,
         subject_1_key: "start_day",
         subject_1_type: "assignments",
      },
      {
         id: "3",
         name: "End Date",
         subject_1_id: null,
         subject_1_key: "end_day",
         subject_1_type: "assignments",
      },
      {
         id: "4",
         name: "Daily Start Time",
         subject_1_id: null,
         subject_1_key: "start_time",
         subject_1_type: "assignments",
      },
      {
         id: "5",
         name: "Daily End Time",
         subject_1_id: null,
         subject_1_key: "end_time",
         subject_1_type: "assignments",
      },
      {
         id: "6",
         name: "Project Name",
         subject_1_id: null,
         subject_1_key: "name",
         subject_1_type: "projects",
      },
      {
         id: "7",
         name: "Project Number",
         subject_1_id: null,
         subject_1_key: "job_number",
         subject_1_type: "projects",
      }
   ],
   include_signature: true,
   is_group: false,
   is_private: true,
   type: "one-off-alert"

}

AssignmentsViewModel.StorageKeys = {
   # Gets an array of category IDs as well as "assignments-<project-id>", "requests-<project-id>".
   OPEN_CATEGORIES: "assignmentsvm-open-categories"
   BENCH_FILTER_CHIPS: "assignmentsvm-bench-filter-chips"
}
