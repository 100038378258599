#region Imports
import template from "./gantt-2.pug"
import { App } from "@/views/app"
import { Guid as GUID } from "@/lib/utils/guid"
import { ValidationUtils } from "@/lib/utils/validation"
import { router } from "@/lib/router"
import { DateUtils } from "@/lib/utils/date"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { Format as FormatUtils } from "@/lib/utils/format"
import * as BrowserStorageUtils from "@/lib/utils/browser-storage"
import { PageCursor } from "@/lib/utils/page-cursor"
import { ANY } from "@/lib/components/chip-filter/chip-filter"
import ko from "knockout"
import $ from "jquery"
import * as d3 from "d3"
import { buildDateFilterInstance, } from '@/lib/utils/chip-filter-helper';
import Bugsnag from "@bugsnag/js"
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

### React ###
import renderShiftProjectModal from "@/react/render-shift-project-modal"

### Auth, Real-Time & Stores ###
import { alertManager } from "@/lib/managers/alert-manager"
import { authManager } from "@/lib/managers/auth-manager"
import { Assignment2Store } from "@/stores/assignment-2-store.core"
import { defaultStore } from "@/stores/default-store"
import { groupStore } from "@/stores/group-store"
import { ProjectStore } from "@/stores/project-store.core"
import { AlertStore } from "@/stores/alert-store.core"
import { TimeOffStore } from "@/stores/time-off-store.core";
import {
   Notification,
   notificationManagerInstance,
   Action
} from "@/lib/managers/notification-manager";
import { FanoutManager, fanoutManager } from "@/lib/managers/fanout-manager"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"
import { GanttExportModalPane } from "@/lib/components/modals/gantt-export-modal-pane"
import { ProcessingNoticePaneViewModel } from "@/lib/components/modals/processing-notice-pane"
import { CreateMessageOrAlertPane } from "@/lib/components/modals/create-message-or-alert-pane"
import { FyiInfoPaneViewModel } from "@/lib/components/modals/fyi-info-pane"
import { GanttPane } from "@/lib/components/modals/gantt-pane/gantt-pane"
import { SelectPeopleByAssignmentPane } from "@/lib/components/modals/select-people-by-assignments-pane"
import { CreateCannedMessagePane } from "@/lib/components/modals/create-canned-message-pane"
import { RecipientsListPane } from "@/lib/components/modals/recipients-list-pane"
import { SaveViewPane } from "@/lib/components/modals/save-view-pane/save-view-pane";

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupListPane } from "@/lib/components/popup/popup-list-pane"
import { PopupListItem } from "@/lib/components/popup/popup-list-item"
import { ProjectGanttConfigPane } from "@/lib/components/popup/project-gantt-config-pane"
import { PeopleGanttConfigPane } from "@/lib/components/popup/people-gantt-config-pane"
import { FillPlaceholderPane } from "@/lib/components/popup/fill-placeholder-pane"

### Models ###
import { ValueSet } from "@/models/value-set"
import { PermissionLevel } from "@/models/permission-level"
import { CannedMessage } from "@/models/canned-message"

### Mediators ###
import { GanttMediator } from "@/lib/mediators/gantt-mediator"
import { PageableLoadingMediator } from "@/lib/mediators/pageable-loading-mediator"
import { ChipFilterMediator } from "@/lib/mediators/chip-filter-mediator"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

import { GanttPage } from "@/views/gantt-2/gantt-page"
#endregion

PROJECT_ITEMS_PER_REQUEST = 20
PEOPLE_ITEMS_PER_REQUEST = 50

SHORT_FORMAT_OPTIONS = {
   yearFormat: DateUtils.YearFormat.FULL
   monthFormat: DateUtils.MonthFormat.ABBREV
   dayFormat: DateUtils.DayFormat.ONE_DIGIT
   weekdayFormat: DateUtils.WeekDayFormat.ABBREV
}

LoadDirection = {
   PREPEND: 'prepend'
   APPEND: 'append'
}

# TODO: This viewmodel needs to be split into children. 
export class Gantt2ViewModel extends PageContentViewModel
   constructor: (queryParams, templateOverride, title) ->
      super(templateOverride or template(), title or "Gantt")
      console.log("Old Gantt constructor called")
      #region Setting up permissions
      @canViewPeople = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE)
      @canViewProject = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT)
      @canViewRequests = authManager.checkAuthAction(PermissionLevel.Action.VIEW_REQUESTS)
      @canManageRequests = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS)
      @canManageOthersRequests = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_OTHERS_REQUESTS)
      @canManageAssignments = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ASSIGNMENTS)
      @canViewProjectFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)
      @canViewPeopleFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_FINANCIALS)
      @canViewProjectTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_TAGS)
      @canViewPeopleTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS)
      @canViewPeopleTimeoff = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TIMEOFF)
      @canEditPeopleTimeoff = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_TIMEOFF)
      @canViewPeopleSensitive = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_SENSITIVE)
      @canViewProjectSensitive = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_SENSITIVE)
      @canCreateMessages = authManager.checkAuthAction(PermissionLevel.Action.CREATE_MESSAGES)
      @canManageAlerts = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ALERTS)
      @allowExportingData = authManager.checkAuthAction(PermissionLevel.Action.ALLOW_EXPORTING_DATA)
      @canViewAllStatuses = authManager.checkAuthAction(PermissionLevel.Action.CAN_VIEW_ALL_STATUSES)
      @visibleStatusIds = authManager.authedUser()?.permissionLevel()?.visibleStatusIds()
      @canEditProjectDetails = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_DETAILS)

      @canFillRequest = (request) =>
         return false unless @canManageAssignments
         return false unless @canManageRequests
         if @canManageOthersRequests
            return true
         else
            return ko.unwrap(request.creatorId) == authManager.authedUserId()

      @canViewAssignmentWithStatusId = (statusId) =>
         if @canViewAllStatuses == true
            return true
         else if @visibleStatusIds.has(statusId)
            return true
         return false

      @permissions = {
         canViewPeople: @canViewPeople
         canViewProject: @canViewProject
         canManageRequests: @canManageRequests
         canManageOthersRequests: @canManageOthersRequests
         canManageAssignments: @canManageAssignments
         canCreateMessages: @canCreateMessages
         canManageAlerts: @canManageAlerts
         canEditProjectDetails: @canEditProjectDetails
      }
      #endregion

      #region UI observables to be updated as load a saved view or a custom view
      # Selected entity
      @viewingByEntityOptions = [
         new SegmentedControllerItem("Projects", 'projects')
         new SegmentedControllerItem("People", 'people')
      ]
      @selectedViewingByEntity = (() =>
         showPeopleGanttOnPageLoad = if queryParams.savedView then queryParams.savedView.view_config.viewing_entity == "people" else queryParams.entity == "people"
         return ko.observable(if showPeopleGanttOnPageLoad then @viewingByEntityOptions[1] else @viewingByEntityOptions[0])
      )()
      # We use showPeopleGantt for help with conditionals throughout this class, but you'll see
      # that we don't use showProjectGantt the same way. That's just because we only really need one of them
      # since the boolean value of one tells you the value of the other. However, we still need to create
      # the showProjectGantt observable because if you look at the pug file for this class, you'll see that
      # their observables are passed to the "showing" parameters on the custom gantt components. This is
      # necessary for the gantts to be able to re-draw themselves when you resize the screen.
      @showPeopleGantt = ko.computed(() =>
         return @selectedViewingByEntity().value() == "people"
      )
      @showProjectGantt = ko.computed(() =>
         return !@showPeopleGantt()
      )
      # Config menu values
      @projectGanttViewConfig = ko.observable(Gantt2ViewModel.PROJECT_VIEW_DEFAULTS)
      @peopleGanttViewConfig = ko.observable(Gantt2ViewModel.PEOPLE_VIEW_DEFAULTS)
      # Search
      @peopleSearch = ko.observable()
      @projectSearch = ko.observable()
      # Filters
      @filterChips = ko.observableArray()
      @projectsViewFilterOptions = ko.observable()
      @peopleViewFilterOptions = ko.observable()
      @labeledFilterOptions = ko.observable(if @showPeopleGantt() then @peopleViewFilterOptions() else @projectsViewFilterOptions())
      # View start day
      @disposableViewStartBlock = false # no clue what the heck this "disposable" pattern is all about yet
      @viewStartDate = (() =>
         today = new Date()
         todayFloored = new Date(today.getFullYear(), today.getMonth(), today.getDate())
         return ko.observable(todayFloored)
      )()
      @viewStartDay = ko.pureComputed =>
         return DateUtils.getDetachedDay(@viewStartDate())
      # View range
      @selectedRangeDays = ko.observable(28)
      #endregion

      #region Other miscellaneous properties, methods, and observables
      @currentRequest = ko.observable()
      @rawProjectRows = ko.observableArray() # This is just for reloads without networking.
      @rows = ko.observableArray() # This is just for display.
      @rowKeys = new Set(); # Used in filterDuplicateRecordsSupplier
      @isFirstDraw = ko.observable(true)

      @isLoading = ko.pureComputed => 
         return @currentRequest()?

      @noResultsFound = ko.pureComputed =>
         return !@isFirstDraw() and !@isLoading() and @rows().length == 0

      @exportsDisabled = ko.pureComputed => @rows().length == 0
      @tagCategoriesEnabled = authManager.companyModules()?.tagCategories
      @isAdmin = authManager.isAdmin()
      @lastNamesFirst = authManager.authedUser().preferences().displayLastNamesFirst()
      @customFieldModuleEnabled = authManager.companyModules()?.customFields

      @projectPageCursor = @getNewProjectPageCursor()
      @peoplePageCursor = @getNewPeoplePageCursor()

      @ganttMediator = new GanttMediator()
      @projectGanttMediator = new GanttMediator()
      @topPeopleLoadingMediator = new PageableLoadingMediator()
      @peopleLoadingMediator = new PageableLoadingMediator()
      @topProjectLoadingMediator = new PageableLoadingMediator()
      @projectLoadingMediator = new PageableLoadingMediator()
      @chipFilterMediator = new ChipFilterMediator()

      # For modal support data.
      @loadingSupportData = ko.observable(true)
      @projectOptions = ko.observableArray()
      @resourceOptions = ko.observableArray()
      @statusOptions = ko.observableArray()
      @groupedCostCodeOptions = ko.observable()
      @groupedLabelOptions = ko.observable()
      @companyTbdWeeks = ko.observable()

      @costingConfig = ko.observable({
         paidShiftHours: 8
         overtimeDayRates: {
            friday: 1.5
            monday: 1.5
            saturday: 1.5
            sunday: 2
            thursday: 1.5
            tuesday: 1.5
            wednesday: 1.5
         }
      })
      # This gets recreated in the handleRedraw method
      @defaultRangeData = {
         startDate: @viewStartDate(),
         days: @selectedRangeDays()
         projectViewConfig: @projectGanttViewConfig()
         peopleViewConfig: @peopleGanttViewConfig()
         paidShiftHours: if @costingConfig?()? then @costingConfig().paidShiftHours else 8
         # Only used by people gantt binding.
         graphBaseId: "gantt"
      }
      @rangeTitle = ko.pureComputed =>
         return switch @selectedRangeDays()
            when 1 then "1 Day"
            when 7 then "1 Week"
            when 14 then "2 Weeks"
            when 28 then "4 Weeks"
            when 56 then "8 Weeks"
            when 91 then "13 Weeks"
            when 182 then "6 Months"
            when 364 then "1 Year"
            when 730 then "2 Years"
            when 1095 then "3 Years"

      #region Popups
      @activePopupAssignment = ko.observable(null)
      @activePopupRequest = ko.observable(null)
      @activePopupPerson = ko.observable(null)

      @rangePopupBuilder = =>
         handleRangeSelection = (days) =>
            @selectedRangeDays(days)

         items = [
            new PopupListItem({title: "1 Day", clickCallback: handleRangeSelection, callbackData: 1})
            new PopupListItem({title: "1 Week", clickCallback: handleRangeSelection, callbackData: 7})
            new PopupListItem({title: "2 Weeks", clickCallback: handleRangeSelection, callbackData: 14})
            new PopupListItem({title: "4 Weeks", clickCallback: handleRangeSelection, callbackData: 28})
            new PopupListItem({title: "8 Weeks", clickCallback: handleRangeSelection, callbackData: 56})
            new PopupListItem({title: "13 Weeks", clickCallback: handleRangeSelection, callbackData: 91})
            new PopupListItem({title: "6 Months", clickCallback: handleRangeSelection, callbackData: 182})
            new PopupListItem({title: "1 Year", clickCallback: handleRangeSelection, callbackData: 364})
            new PopupListItem({title: "2 Years", clickCallback: handleRangeSelection, callbackData: 730})
            new PopupListItem({title: "3 Years", clickCallback: handleRangeSelection, callbackData: 1095})
         ]
         return new Popup("", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new PopupListPane("", items, {showArrows: false})],
            ['gantt-toolbar__range-selector', 'gantt-toolbar__range-title'], ["gantt-chart__popup--range"])

      @rangePopupWrapper = {
         popupBuilder: @rangePopupBuilder
         options: {triggerClasses: ['gantt-toolbar__range-selector']}
      }

      @projectGanttConfigPopupBuilder = =>
         handleApplyConfig = (config) =>
            if config?
               @projectGanttViewConfig(config)
            else
               @projectGanttViewConfig(Gantt2ViewModel.PROJECT_VIEW_DEFAULTS)

            key = BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PROJECT_CONFIG
            BrowserStorageUtils.storeJsonValue(key, config)

            # TODO: Don't need to always do this. 
            @loadAssignments(0)

         return new Popup("", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new ProjectGanttConfigPane(@projectGanttViewConfig(), handleApplyConfig)],
            ['gantt-toolbar__project-config', 'gantt-toolbar__text-btn__text'], ["project-gantt__config-popup"])

      @projectGanttConfigPopupWrapper = {
         popupBuilder: @projectGanttConfigPopupBuilder
         options: {triggerClasses: ['gantt-toolbar__project-config']}
      }

      @peopleGanttConfigPopupBuilder = =>
         handleApplyConfig = (config) =>
            if config?
               @peopleGanttViewConfig(config)
            else
               @peopleGanttViewConfig(Gantt2ViewModel.PEOPLE_VIEW_DEFAULTS)

            key = BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PEOPLE_CONFIG
            BrowserStorageUtils.storeJsonValue(key, config)

            # TODO: Don't need to always do this. 
            @loadAssignments(0)

         return new Popup("", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new PeopleGanttConfigPane(@peopleGanttViewConfig(), handleApplyConfig)],
            ['gantt-toolbar__project-config', 'gantt-toolbar__text-btn__text'], ["project-gantt__config-popup"])

      @peopleGanttConfigPopupWrapper = {
         popupBuilder: @peopleGanttConfigPopupBuilder
         options: {triggerClasses: ['gantt-toolbar__project-config']}
      }

      @fillRequestPopupBuilder = () =>
         return new Popup("Select Person", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new FillPlaceholderPane(@resourceOptions, @fillRequest)],
            ['gantt-popup__fill-request-btn'],
            ['placeholder-card__info-popup'])

      @fillRequestPopupWrapper = ko.observable({
         popupBuilder: @fillRequestPopupBuilder,
         options: {triggerClasses: ['gantt-popup__fill-request-btn']}
         dynamicPositioning: true
      })
      #endregion

      # Chip Filter Properties.
      @filterPopupTitle = ko.pureComputed =>
         return if @showPeopleGantt() then "People Filters" else "Project Filters"
      # Need this for passing to component.
      @defaultChips = ko.pureComputed =>
         if @showPeopleGantt()
            return Gantt2ViewModel.DEFAULT_PEOPLE_FILTER_CHIPS
         else
            return Gantt2ViewModel.DEFAULT_PROJECTS_FILTER_CHIPS

      @assignmentProjectsUpdatesAvailable = ko.observable(false)
      @refreshNotification = ko.observable(null)

      @showRequestNotification = ko.pureComputed =>
         !(@filterChips().find((filter) => filter.property == "only_show" && filter.value == "assignments"))
      @showAssignmentNotification = ko.pureComputed =>
         !(@filterChips().find((filter) => filter.property == "only_show" && filter.value == "requests"))

      @ganttPage = new GanttPage({
         legacyCreateEditDeleteAssignmentCallback: (addedAssignments, removedAssignmentsData) =>
            @maybeUpdateGantt({
               addedAssignments,
               removedAssignmentsData
            })
            @closeGanttPopup()
         legacyCreateEditDeleteRequestCallback: (addedRequests, removedRequestsData) =>
            @maybeUpdateGantt({
               addedRequests,
               removedRequestsData
            })
            @closeGanttPopup()
      })

      @callbacks = {
         infoSectionClicked: @handleInfoSectionClick
         assignmentClicked: @handleAssignmentClick
         requestClicked: @handleRequestClick
         timeOffClicked: @handleTimeOffClick
         navigateToPerson: @navigateToPerson
         navigateToProject: @navigateToProject
         showNewAssignmentModal: @createAssignment
         showNewPlaceholderModal: @createRequest
         sendAssignmentAlerts: @sendAssignmentAlerts
         messagePeople: @messagePeople
         showGenerateProjectReportModal: @showGenerateProjectReportModal
         showGeneratePersonReportModal: @showGeneratePersonReportModal
         showShiftProjectModal: @showShiftProjectModal,
         openLaborPlansPage: @openLaborPlansPage
      }
      #endregion

      #region The good stuff to actually load the page
      if queryParams.savedView?
         @setupSavedView(queryParams.savedView)
      else
         @setupCustomView(queryParams)

      # If this gets initialized with an error message, it will be rendered on the page.
      @pageLoadFailure = ko.observable(null)
      # If the page fails to load due to a missing permission, we will hide the gantt UI
      @hideGanttControls = ko.pureComputed(() =>
         return String(this.pageLoadFailure()).includes("Missing required permission")
      )

      @ganttPage.state.isReady.subscribe((newVal) =>
         if newVal
            @loadSupportData()
            @makeInitialLoadCalls()
      )

      # Now that we've finished initialized our observable, we can create subscriptions that will fire whenever they're updated
      @subscriptions = []
      @groupIdSubscription = authManager.selectedGroupId.subscribeChange(@handleGroupChange)
      @setupViewConfigSubscriptions()
      #endregion

   #region View & Subscription helpers
   ###
   # At any point in time you can be viewing 1 of 4 states:
   #
   # 1. A Project saved view
   # 2. A Project custom view
   # 3. A People saved view
   # 4. A People custom view
   #
   # The two saved-view states can only be reached by clicking a saved-view from the "Assignments" dropdown. As soon as the user changes any observable value (eg. search value, applied filters, applied start day, viewing range), they will no
   # longer be looking at a saved view, but instead now a custom view.
   #
   # The setupCustomView method is only used on the initial page load, and then the handleViewingEntityChange event listener will handle every project/people view flip thereafter.
   ###
   setupCustomView: (queryParams = {}) =>
      @setTitle("Gantt")
      router.removeQueryParam(App.RouteName.GANTT, "viewId")

      #region Updating config values from whatever is saved in local cache
      savedProjectConfig = BrowserStorageUtils.getJsonValue(BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PROJECT_CONFIG)
      if savedProjectConfig?
         @projectGanttViewConfig(savedProjectConfig)

      savedPeopleConfig = BrowserStorageUtils.getJsonValue(BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PEOPLE_CONFIG)
      if savedPeopleConfig?
         @peopleGanttViewConfig(savedPeopleConfig)
      #endregion

      #region Updating search values from whatever is saved in local cache
      peopleSearchKey = BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PEOPLE_SEARCH
      storedPeopleSearch = BrowserStorageUtils.getBasicValue(peopleSearchKey)
      @peopleSearch(storedPeopleSearch) if storedPeopleSearch?

      projectSearchKey = BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PROJECT_SEARCH
      storedProjectSearch = BrowserStorageUtils.getBasicValue(projectSearchKey)
      @projectSearch(storedProjectSearch) if storedProjectSearch?
      #endregion

      #region Updating start day and viewing range
      if queryParams.dayFilter
         newDate = DateUtils.getAttachedDate(Number(queryParams.dayFilter))
         newDate = new Date() unless 2100 >= newDate.getFullYear() >= 2000
         @viewStartDate(newDate)

      if queryParams.range
         rangeVal = Number(queryParams.range)
         if Gantt2ViewModel.VALID_RANGE_OPTIONS.indexOf(rangeVal) != -1
            @selectedRangeDays(rangeVal)
      #endregion

      #region Updating filter options
      if @showPeopleGantt()
         @labeledFilterOptions(@peopleViewFilterOptions())
      else
         @labeledFilterOptions(@projectsViewFilterOptions())
      #endregion

      #region Updating visible/active chip filters
      if queryParams?.notificationFilter?
         parsedQueryFilter = JSON.parse(decodeURIComponent(queryParams.notificationFilter))
         @filterChips([{
            classifier: null,
            classifierLabel: null,
            customFieldId: null,
            filterName: parsedQueryFilter.filterName,
            property: parsedQueryFilter.property,
            value: parsedQueryFilter.value,
            valueName: parsedQueryFilter.valueName
         }])
         # We don't want this sticking around in the URL.
         router.removeQueryParam(App.RouteName.GANTT, "notificationFilter")
         setTimeout( () =>
            @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))
         , 0)
      # If we are specifically searching by a project we do not want to load existing filters.
      else if queryParams?.projectSearchFilter? and ValidationUtils.validateInput(queryParams.projectSearchFilter)
         # This parameter was added to support actions which want to take you directly to a single project on the gantt page (eg. coming from the project's detail page)
         @projectSearch(decodeURIComponent(queryParams.projectSearchFilter).replace(/"/g, ""))
      else
         chipKey = if @showPeopleGantt() then "people-chip-filters" else "project-chip-filters"
         storedChips = BrowserStorageUtils.getPageFilterChips(chipKey)
         if storedChips?
            @filterChips(storedChips)
         else
            if @showPeopleGantt()
               @filterChips(Gantt2ViewModel.DEFAULT_PEOPLE_FILTER_CHIPS)
            else
               @filterChips(Gantt2ViewModel.DEFAULT_PROJECTS_FILTER_CHIPS)
         setTimeout( () =>
            @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))
         , 0)
      #endregion

   setupSavedView: (savedView) =>
      @setTitle(savedView.name)

      # This has both people and project values merged as there is some overlap.
      savedConfig = {
         totalsUnit: savedView.page_specific.total_cell_units
         barSplit: savedView.page_specific.bar_types
         barColor: savedView.page_specific.assignment_bar_colors_by
         showResourcesNotInGroup: savedView.page_specific.show_resources_not_in_group
         # projects
         projectSort: savedView.page_specific.project_sort_by
         # This is supposed to be mismatched as this view needs cleanup.
         showJobNumbers: savedView.page_specific.show_project_numbers or false
         showStatus: savedView.page_specific.show_project_status or false
         # people
         peopleSort: savedView.page_specific.people_sort_by
         showEmployeeNumber: savedView.page_specific.show_employee_numbers or false
         showPositionName: savedView.page_specific.show_position_names or false
         showProjectNumbers: savedView.page_specific.show_project_numbers or false
      }

      # Load standard view.
      if @showPeopleGantt()
         @labeledFilterOptions(@peopleViewFilterOptions())
         @peopleGanttViewConfig(savedConfig)
         if savedView.search?
            @peopleSearch(savedView.search)
      else
         @labeledFilterOptions(@projectsViewFilterOptions())
         @projectGanttViewConfig(savedConfig)
         if savedView.search?
            @projectSearch(savedView.search)

      if savedView.view_config.viewing_range?
         rangeVal = Number(savedView.view_config.viewing_range)
         if Gantt2ViewModel.VALID_RANGE_OPTIONS.indexOf(rangeVal) != -1
            @selectedRangeDays(rangeVal)

      if savedView.chip_filters?
         chipFilters = savedView.chip_filters.map (item) ->
            return FormatUtils.snakeCaseObjectToCamelCase(item)

         if chipFilters?
            @filterChips(chipFilters)
            # Check stored chips for "only_show"
            for chip in chipFilters
               if chip.property == "only_show"
                  @refineOnlyShowFilter(@filterChips())
            setTimeout( () =>
               @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))
            , 0)

   setupViewConfigSubscriptions: ->
      peopleQueryKey = BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PEOPLE_SEARCH
      @subscriptions.push @peopleSearch.subscribe (newVal) =>
         BrowserStorageUtils.storeBasicValue(peopleQueryKey, newVal)

      projectQueryKey = BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PROJECT_SEARCH
      @subscriptions.push @projectSearch.subscribe (newVal) =>
         BrowserStorageUtils.storeBasicValue(projectQueryKey, newVal)

      @subscriptions.push @filterChips.subscribe (newVal) =>
         @setTitle("Gantt")
         key = if @showPeopleGantt() then "people-chip-filters" else "project-chip-filters"
         BrowserStorageUtils.storePageFilterChips(newVal, key)
         @loadAssignments(0)

      @subscriptions.push @selectedViewingByEntity.subscribe(@handleViewingEntityChange)
      @subscriptions.push @viewStartDate.subscribe(@handleViewingDateChange)
      @subscriptions.push @selectedRangeDays.subscribe(@handleViewingRangeChange)

   # This will fire when the new search value is submitted, instead of
   # happening on keystroke every time the search value observable changes
   searchCallback: ->
      @setTitle("Gantt")
      @loadAssignments(0)

   dispose: (next) ->
      fanoutManager.unsubscribe("vm.GanttViewModel", FanoutManager.Channel.GROUPS_REQUESTS)
      fanoutManager.unsubscribe("vm.GanttViewModel", FanoutManager.Channel.GROUPS_ASSIGNMENTS)
      @groupIdSubscription.dispose()
      next()
   #endregion

   #region Data Load/Query Helpers
   loadSupportData: () ->
      @loadingSupportData(true)

      supportData = @ganttPage.state.assignmentSupportData
      @costingConfig({
         paidShiftHours: supportData.paidShiftHours()
         overtimeDayRates: supportData.overtimeDayRates()
      })
      @projectOptions(supportData.projectOptions())
      @resourceOptions(supportData.resourceOptions())
      @statusOptions(supportData.statusOptions())
      @groupedCostCodeOptions(supportData.groupedCostCodeOptions())
      @groupedLabelOptions(supportData.groupedLabelOptions())
      @companyTbdWeeks(supportData.companyTbdWeeks())
      @loadingSupportData(false)

      entities = ['positions', 'projects', 'people']
      if @tagCategoriesEnabled
         entities.push('categorized-tags')
      else
         entities.push('tags')

      if @customFieldModuleEnabled
         entities.push('people_custom_field_filters')
         entities.push('projects_custom_field_filters')

      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities authManager.selectedGroupId(), entities, (err, data) =>
         return console.error "get group entity options err: ", err if err?

         projectsViewFilterOptions = {
            "Hide Empty Projects": ko.observable({
               property: "only_projects_with_data"
               values: [
                  new ValueSet({name: "True", value: true})
               ]
            })
            "Status": ko.observable({
               property: "status"
               values: [
                  new ValueSet({name: "Active", value: "active"})
                  new ValueSet({name: "Pending", value: "pending"})
                  new ValueSet({name: "Inactive", value: "inactive"})
               ]
            })
            "Bid Rate": ko.observable({
               property: "bid_rate"
               disableSearch: true
               classifiers: [
                  {listLabel: "< (Less Than)", chipLabel: "<", value: "<"},
                  {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="},
                  {listLabel: "= (Equal To)", chipLabel: "=", value: '='},
                  {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                  {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
               ]
               type: "number"
            })
            "Percent Complete": ko.observable({
               property: "percent_complete"
               disableSearch: true
               classifiers: [
                  {listLabel: "< (Less Than)", chipLabel: "<", value: "<"},
                  {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="},
                  {listLabel: "= (Equal To)", chipLabel: "=", value: '='},
                  {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                  {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
               ]
               type: "number"
            })
            "Start Date": ko.observable(buildDateFilterInstance('start_date'))
            "Est. End Date": ko.observable(buildDateFilterInstance('est_end_date'))
         }

         if @canViewRequests
            projectsViewFilterOptions["Only Show"] = ko.observable({
               property: "only_show"
               values: [
                  new ValueSet({name: "Assignments", value: 'assignments'})
                  new ValueSet({name: "Requests", value: 'requests'})
               ]
            })

         if data.projectsCustomFieldFilters?
            for field in data.projectsCustomFieldFilters
               unless @canViewProjectSensitive
                  continue if authManager.projectsSensitiveFields().indexOf(field.integration_name) != -1

               if field.type == "multi-select"
                  projectsViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: field.values.map (i) -> return new ValueSet({name: i, value: i})
                  })
               else if field.type == "select"
                  projectsViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: field.values.map (i) -> return new ValueSet({name: i, value: i})
                  })
               else if field.type == "bool"
                  projectsViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: [
                        new ValueSet({name: "TRUE", value: true})
                        new ValueSet({name: "FALSE", value: false})
                     ]
                  })
               else if (@canViewProjectFinancials and field.type == "currency") or field.type == "number"
                  projectsViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     disableSearch: true
                     classifiers: [
                        {listLabel: "< (Less Than)", chipLabel: "<", value: "<"},
                        {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="},
                        {listLabel: "= (Equal To)", chipLabel: "=", value: '='},
                        {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                        {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
                     ]
                  })
               else if field.type == "date"
                  projectsViewFilterOptions[field.name] = ko.observable(buildDateFilterInstance('custom_fields', { customFieldId: field.id }))
               else if field.type == "text"
                  projectsViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     disableSearch: true
                  })

         peopleViewFilterOptions = {
            "Hide Empty People": ko.observable({
               property: "only_people_with_data"
               values: [
                  new ValueSet({name: "True", value: true})
               ]
            })
         }

         if data.peopleCustomFieldFilters?
            for field in data.peopleCustomFieldFilters
               unless @canViewPeopleSensitive
                  continue if authManager.peopleSensitiveFields().indexOf(field.integration_name) != -1

               if field.type == "multi-select"
                  peopleViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: field.values.map (i) -> return new ValueSet({name: i, value: i})
                  })
               else if field.type == "select"
                  peopleViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: field.values.map (i) -> return new ValueSet({name: i, value: i})
                  })
               else if field.type == "bool"
                  peopleViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     values: [
                        new ValueSet({name: "TRUE", value: true})
                        new ValueSet({name: "FALSE", value: false})
                     ]
                  })
               else if (@canViewPeopleFinancials and field.type == "currency") or field.type == "number"
                  peopleViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     disableSearch: true
                     classifiers: [
                        {listLabel: "< (Less Than)", chipLabel: "<", value: "<"},
                        {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="},
                        {listLabel: "= (Equal To)", chipLabel: "=", value: '='},
                        {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                        {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
                     ]
                  })
               else if field.type == "date"
                  peopleViewFilterOptions[field.name] = ko.observable(buildDateFilterInstance('custom_fields', { customFieldId: field.id }))
               else if field.type == "text"
                  peopleViewFilterOptions[field.name] = ko.observable({
                     property: "custom_fields"
                     customFieldId: field.id
                     type: field.type
                     disableSearch: true
                  })

         if data.positionOptions.length > 0
            peopleViewFilterOptions["Job Titles"] = ko.observable({
               property: "position_id"
               values: data.positionOptions
            })

         if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf('is_male') == -1
            peopleViewFilterOptions["Gender"] = ko.observable({
               property: "is_male"
               values: [
                  new ValueSet({name: "Female", value: false})
                  new ValueSet({name: "Male", value: true})
               ]
            })

         if @canViewPeopleTimeoff
            peopleViewFilterOptions["Only Show"] = ko.observable({
               property: "only_show"
               values: [
                  new ValueSet({name: "Assignments", value: 'assignments'})
                  new ValueSet({name: "Time Off", value: 'timeoff'})
               ]
            })

         if data.projectOptions.length > 0
            peopleViewFilterOptions["Projects"] = ko.observable({
               property: "project_id"
               values: data.projectOptions.map (item) ->
                  # TODO: Clean this up after it's normalized site wide.
                  return new ValueSet({id: item.id, name: item.name(), value: item.id, color: item.value()})
            })

         if data.tagOptions? and data.tagOptions.length > 0
            peopleViewFilterOptions["Tags (People)"] = ko.observable({
               property: "tag_instances"
               type: "multi-select"
               values: FormatUtils.keyableSort(data.tagOptions, 'name')
            })

            projectsViewFilterOptions["Tags (Projects)"] = ko.observable({
               property: "tag_instances"
               type: "multi-select"
               values: FormatUtils.keyableSort(data.tagOptions, 'name')
            })
         else if data.categorizedTagOptions?
            classifiers = []
            for key of data.categorizedTagOptions
               classifiers.push({listLabel: key, chipLabel: null, value: key})
            classifiers.sort (a, b) ->
               if a.listLabel.toLowerCase() < b.listLabel.toLowerCase()
                  return -1
               else if a.listLabel.toLowerCase() > b.listLabel.toLowerCase()
                  return 1
               else
                  return 0

            peopleViewFilterOptions["Tags (People)"] = ko.observable({
               property: "tag_instances"
               type: "multi-select"
               classifiers: classifiers
               classifierPaneName: "Tag Category"
               values: data.categorizedTagOptions
               backEnabled: true
            })

            projectsViewFilterOptions["Tags (Projects)"] = ko.observable({
               property: "tag_instances"
               type: "multi-select"
               classifiers: classifiers
               classifierPaneName: "Tag Category"
               values: data.categorizedTagOptions
               backEnabled: true
            })

         if data.positionOptions.length > 0
            classifiers = (data.positionOptions or []).map (option) ->
               return { listLabel: option.name(), chipLabel: option.name(), value: option.value() }
            classifiers.unshift( { listLabel: 'Any', chipLabel: 'Any', value: ANY } )
            valueOptions = data.peopleOptions.map (i) ->
               return new ValueSet({name: i.name(), value: i.value()})
            valueOptions = FormatUtils.keyableSort(valueOptions, 'name')
            valueOptions.unshift( new ValueSet({ name: 'Any', value: ANY }) )

            projectsViewFilterOptions["Project Roles"] = ko.observable({
               property: "project_roles"
               classifiers: classifiers
               type: "select"
               classifierPaneName: "Job Title"
               values: valueOptions
               backEnabled: true
            })

            projectsViewFilterOptions["Job Titles"] = ko.observable({
               property: "position_id"
               values: data.positionOptions
            })

         # TODO: Need to handle custom fields here like on list pages.
         @peopleViewFilterOptions(peopleViewFilterOptions)
         @projectsViewFilterOptions(projectsViewFilterOptions)

         if @showPeopleGantt()
            @labeledFilterOptions(peopleViewFilterOptions)
         else
            @labeledFilterOptions(projectsViewFilterOptions)

   handleAssignmentRealTime: (message) =>
      return if message.data.resource_id && @showAssignmentNotification() != true
      return if !message.data.resource_id && @showRequestNotification() != true
      foundProject = false
      for project in @projectOptions()
         if project.id == message.data.project_id
            foundProject = true
            break

      if foundProject == true && @refreshNotification() == null && authManager.authedUserId() != message.userId
         showNotification = (@canViewAssignmentWithStatusId(message.data.old_status_id) or @canViewAssignmentWithStatusId(message.data.new_status_id))
         return if showNotification == false
         @assignmentProjectsUpdatesAvailable(true)
         @refreshNotification(new Notification({
               text: "New Assignment Information Available",
               actions: [
                  new Action({
                     text: "Refresh",
                     type: Action.Type.BLUE,
                     onClick: () => @updateAssignmentsRealTime()
                  })
               ],
               onDismiss: () => @refreshNotification(null)
            }))
         notificationManagerInstance.show(@refreshNotification(), App.RouteName.GANTT);

   updateAssignmentsRealTime: () =>
      @assignmentProjectsUpdatesAvailable(false)
      @projectPageCursor.dispose()
      @projectPageCursor = @getNewProjectPageCursor()
      @peoplePageCursor.dispose()
      @peoplePageCursor = @getNewPeoplePageCursor()
      @loadSupportData()

      if @selectedViewingByEntity().value() == 'people'
         @loadAssignments(@peoplePageCursor.lastIndex())
      else
         @loadAssignments(@projectPageCursor.lastIndex())
      notificationManagerInstance.dismiss(@refreshNotification());
      @refreshNotification(null)

   makeInitialLoadCalls: =>
      # INIT Assignment Load Calls.
      if @peopleLoadingMediator.isInitialized()
         if @selectedViewingByEntity().value() == 'people'
            @loadAssignments(@peoplePageCursor.lastIndex())
      else
         @peopleLoadingMediator.isInitialized.subscribe (newVal) =>
            if @selectedViewingByEntity().value() == 'people'
               @loadAssignments(@peoplePageCursor.lastIndex()) if newVal

      if @projectLoadingMediator.isInitialized()
         if @selectedViewingByEntity().value() == 'projects'
               @loadAssignments(@projectPageCursor.lastIndex())
      else
         @projectLoadingMediator.isInitialized.subscribe (newVal) =>
            if @selectedViewingByEntity().value() == 'projects'
               @loadAssignments(@projectPageCursor.lastIndex()) if newVal

   # Used to pass in a skip variable here, but removing while no-longer in use. Se comments within function for more context
   loadAssignments: () =>
      # Clear any existing data from either gantt view.
      @rowKeys.clear()
      @rows([])
      @rawProjectRows([])
      $("#project-gantt-chart").empty() # For some reason we only have an ID of project-gantt-chart that's used for both project & people gantt
      $("#gantt-chart").empty()
      $("#gantt-x-axis").empty()

      # Choosing to ignore skip paramter and always load from the beginning.
      # Perhaps in the new Gantt we will restore the logic to save last scroll cursor position on page reload
      if @showPeopleGantt()
         @peoplePageCursor.startingIndex(0) # 'skip' would normally get passed in here
         @loadPeopleAssignmenments()
      else
         @projectPageCursor.startingIndex(0) # 'skip' would normally get passed in here
         @loadProjectAssignments()

      # New subscription for assignments.
      fanoutManager.getSubscription("vm.GanttViewModel",
         FanoutManager.Channel.GROUPS_ASSIGNMENTS, @handleAssignmentRealTime)

      # New subscription for requests.
      if @canViewRequests
         fanoutManager.getSubscription("vm.GanttViewModel",
            FanoutManager.Channel.GROUPS_REQUESTS, @handleAssignmentRealTime)

   loadProjectAssignments: =>
      return if @isLoading() or !@projectPageCursor.hasMoreBottomRecords()
      @projectLoadingMediator.showLoader()
      @projectLoadingMediator.startProgress()
      @currentRequest @projectPageCursor.increment(
         @buildProjectSearchParameters(),
         (err, data) =>
            return console.error "error: ", err if err
            filteredRows = @filterDuplicateRecordsSupplier('projectId')(data.rows)

            @projectPageCursor.bottomIndex(data.toSkip) if data.toSkip?
            @projectLoadingMediator.appendData =>
               @onProjectLoad filteredRows, LoadDirection.APPEND, () =>
                  @projectLoadingMediator.hideLoader()
                  @currentRequest(null)
      )

   loadPeopleAssignmenments: =>
      return if @isLoading() or !@peoplePageCursor.hasMoreBottomRecords()
      @peopleLoadingMediator.showLoader()
      @peopleLoadingMediator.startProgress()

      @currentRequest @peoplePageCursor.increment(
         @buildPeopleSearchParameters(),
         (err, data) =>
            return console.error "error: ", err if err
            filteredRows = @filterDuplicateRecordsSupplier('personId')(data.rows)
            @peoplePageCursor.bottomIndex(data.toSkip) if data.toSkip?
            @peopleLoadingMediator.appendData =>
               @onPeopleLoad filteredRows, LoadDirection.APPEND, () =>
                  @currentRequest(null)
                  @peopleLoadingMediator.hideLoader()
         )

   onProjectLoad: (rows = [], direction, next) =>
      # Decoupling.
      decoupledData = JSON.parse(JSON.stringify(rows))
      isPrepending = direction == LoadDirection.PREPEND
      if isPrepending
         @rawProjectRows(decoupledData.concat(@rawProjectRows()))
      else
         @rawProjectRows(@rawProjectRows().concat(decoupledData))

      # Due to change of filter sequence in API, this now can return empty.
      if rows.length > 0
         @processRows rows, (newRows) =>
            lengthBefore = @rows().length

            if isPrepending
               @rows(newRows.concat(@rows()))
            else
               @rows(@rows().concat(newRows))

            if lengthBefore == 0
               @handleRedraw()
            else
               @handleAppendDraw(newRows, direction)

      @isFirstDraw(false)
      next() if next?

   onPeopleLoad: (rows, direction, next) =>
      isPrepending = direction == LoadDirection.PREPEND
      # Due to change of filter sequence in API, this now can return empty.
      if rows.length > 0
         @processRows rows, (newRows) =>
            lengthBefore = @rows().length
            if isPrepending
               @rows(newRows.concat(@rows()))
            else
               @rows(@rows().concat(newRows))
            
            if lengthBefore == 0
               @handleRedraw()
            else
               @handleAppendDraw(newRows, direction)
      
      @isFirstDraw(false)
      next() if next?

   buildProjectSearchParameters: () =>
      startDay = DateUtils.getDetachedDay(@viewStartDate())
      if @selectedRangeDays() == 1
         endDay = startDay
      else
         endDate = new Date(@viewStartDate().getTime())
         endDate.setDate(endDate.getDate() + (@selectedRangeDays() - 1))
         endDay = DateUtils.getDetachedDay(endDate)

      options = {
         projectSort: @projectGanttViewConfig().projectSort
         visibleData: @projectGanttViewConfig().visibleData
         startDay: startDay
         endDay: endDay
         filters: @getFilterParams()
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }

      if ValidationUtils.validateInput(@projectSearch())
         options['search'] = @projectSearch()

      return options

   buildPeopleSearchParameters: () =>
      startDay = DateUtils.getDetachedDay(@viewStartDate())
      if @selectedRangeDays() == 1
         endDay = startDay
      else
         endDate = new Date(@viewStartDate().getTime())
         endDate.setDate(endDate.getDate() + (@selectedRangeDays() - 1))
         endDay = DateUtils.getDetachedDay(endDate)

      options = {
         sort: @peopleGanttViewConfig().peopleSort
         startDay: startDay
         endDay: endDay
         filters: @getFilterParams()
      }
      options['search'] = @peopleSearch() if ValidationUtils.validateInput(@peopleSearch())

      return options
   #endregion

   #region Pagination/Scroll position helpers
   getNewProjectPageCursor: () =>
      return new PageCursor({
         cacheId: "#{authManager.selectedGroupId()}-gantt-projects"
         defaultLimit: PROJECT_ITEMS_PER_REQUEST
         loader: (params, callback) =>
            viewConfig = if @showPeopleGantt() then @peopleGanttViewConfig() else @projectGanttViewConfig()
            # We're defaulting to true, because that was the traditional behavior before we added a config option for the user
            showResourcesNotInGroup = if LaunchDarklyBrowser.getFlagValue("gantt-group-filter-by-default") then viewConfig.showResourcesNotInGroup else true

            # I put this behind a 100ms timeout, because on subsequent page load (eg. you load the gantt page, click away and then come back)
            # the page was loading so fast that the tagsStream query wasn't completing yet and populating this.ganttPage.state.tags and as a
            # result the tags where all blacked out. I thought about using browser caching/storage to hold on to the tag values after first load,
            # but this brief wait is simpler and more safe, easily covering edge cases like what if they change the group/filter/etc.
            await setTimeout((() =>
               try
                  params.group_id = authManager.selectedGroupId();
                  projectAssignmentsAndRequests =  await Assignment2Store.getProjectsGanttData(this.ganttPage.state, { ...params, showResourcesNotInGroup });

                  return callback(null, projectAssignmentsAndRequests);
               catch error
                  console.error("Error from getNewProjectPageCursor:", error)
                  @pageLoadFailure(error)
            ), 100)

         sizeOf: (data) -> data.totalPossible
      })

   getNewPeoplePageCursor: () =>
      return new PageCursor({
         cacheId: "#{authManager.selectedGroupId()}-gantt-people"
         defaultLimit: PEOPLE_ITEMS_PER_REQUEST
         loader: (params, callback) =>
            viewConfig = if @showPeopleGantt() then @peopleGanttViewConfig() else @projectGanttViewConfig()
            # We're defaulting to true, because that was the traditional behavior before we added a config option for the user
            showResourcesNotInGroup = if LaunchDarklyBrowser.getFlagValue("gantt-group-filter-by-default") then viewConfig.showResourcesNotInGroup else true

            try
               params["group_id"] = authManager.selectedGroupId();
               newPeopleGanttData = await Assignment2Store.getPeopleGanttData({ ...params, showResourcesNotInGroup });
               return callback(null,newPeopleGanttData);
            catch error  
               console.error("Error from getNewProjectPageCursor - PeopleGantt:", error)
               @pageLoadFailure(error)


         sizeOf: (data) -> data.rows.length
      })
   #endregion

   #region Rendering/Drawing helpers
   handleRedraw: (scrollPosition) =>
      days = @selectedRangeDays()
      if 91 <= days < 182
         @viewStartDate().setDate(@viewStartDate().getDate() - @viewStartDate().getDay())
         @disposableViewStartBlock = true
         @viewStartDate(@viewStartDate())
      else if 182 <= days
         @viewStartDate().setDate(1)
         @disposableViewStartBlock = true
         @viewStartDate(@viewStartDate())

      if @showPeopleGantt()
         rangeData = {
            startDate: @viewStartDate(),
            days: days,
            graphBaseId: "gantt",
            peopleViewConfig: @peopleGanttViewConfig()
            paidShiftHours: if @costingConfig?()? then @costingConfig().paidShiftHours else 8
         }

         @ganttMediator.redraw(@rows(), rangeData)
         if scrollPosition?
            setTimeout ->
               $("#gantt-chart-wrapper").scrollTop(scrollPosition)
      else
         rangeData = {
            startDate: @viewStartDate(),
            days: days,
            projectViewConfig: @projectGanttViewConfig()
            paidShiftHours: if @costingConfig?()? then @costingConfig().paidShiftHours else 8
         }

         @projectGanttMediator.redraw(@rows(), rangeData)
         if scrollPosition?
            setTimeout ->
               $("#project-gantt-chart-wrapper").scrollTop(scrollPosition)

   handleAppendDraw: (newRows, direction) =>
      days = @selectedRangeDays()
      if 91 <= days < 182
         @viewStartDate().setDate(@viewStartDate().getDate() - @viewStartDate().getDay())
         @disposableViewStartBlock = true
         @viewStartDate(@viewStartDate())
      else if 182 <= days
         @viewStartDate().setDate(1)
         @disposableViewStartBlock = true
         @viewStartDate(@viewStartDate())

      addRowSupplier = (mediator) ->
         if direction == LoadDirection.PREPEND
            return mediator.prependData
         return mediator.appendData

      if @showPeopleGantt()
         rangeData = {
            startDate: @viewStartDate(),
            days: days,
            graphBaseId: "gantt",
            peopleViewConfig: @peopleGanttViewConfig()
            paidShiftHours: if @costingConfig?()? then @costingConfig().paidShiftHours else
         }
         addRow = addRowSupplier(@ganttMediator)
         addRow(newRows, rangeData)
      else
         rangeData = {
            startDate: @viewStartDate(),
            days: days,
            projectViewConfig: @projectGanttViewConfig()
            paidShiftHours: if @costingConfig?()? then @costingConfig().paidShiftHours else 8
         }
         addRow = addRowSupplier(@projectGanttMediator)
         addRow(newRows, rangeData)
   #endregion

   #region Navigate to/from helpers
   navigateToPersonFromPopup: () =>
      return unless @canViewPeople
      return unless @activePopupPerson()?
      personId = @activePopupPerson().personId
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/people/#{personId}")

   navigateToProjectFromPopup: () =>
      return unless @canViewProject
      return unless @activePopupPerson()?
      projectId = @activePopupPerson().projectId
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/projects/#{projectId}")

   navigateToPerson: (personId) =>
      return unless @canViewPeople
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/people/#{personId}")

   navigateToProject: (projectId) =>
      return unless @canViewProject
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/projects/#{projectId}")

   navigateToBoards: ->
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/assignments?dayFilter=#{@viewStartDay()}")

   navigateToAssignmentsProject: =>
      return unless @canViewProject
      return unless @activePopupAssignment()? || @activePopupRequest()?
      projectId = @activePopupAssignment()?.projectId || @activePopupRequest()?.projectId
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/projects/#{projectId}")
   #endregion

   #region Modal helpers
   showAssignmentMessageModal: (lookupData, placeholder) ->
      alertManager.showAssignmentMessageModal(lookupData, placeholder)

   showProcessingSupportDataModal: (callback) =>
      loadedSubscription = @loadingSupportData.subscribe (newValue) ->
         modalManager.modalFinished() if !newValue
         loadedSubscription.dispose()

      pane1 = new ProcessingNoticePaneViewModel("Loading Suppoting Data", true)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: "processing-notice-modal"}, (modal, modalStatus) =>
         return if modalStatus == "cancelled"
         callback()

   showShiftProjectModal: (projectId, startDate, projectName) =>
      renderShiftProjectModal({
         projectId: projectId,
         startDate: startDate,
         projectName: projectName,
         refreshRootPage: @updateAssignmentsRealTime
      })

   showAccessBlockedModal: ->
      message = "You do not have access to the group(s) this assignment belongs to."
      pane1 = new FyiInfoPaneViewModel("Access Blocked", message)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal(modal, null, {class: 'confirm-action-modal'})

   showSaveViewModal: () =>
      # TODO: I don't like that saving this out isn't the same shape as how it's stored.
      if @showPeopleGantt()
         config = @peopleGanttViewConfig()
      else
         config = @projectGanttViewConfig()

      pageSpecific = {
         total_cell_units: config.totalsUnit,
         bar_types: config.barSplit,
         assignment_bar_colors_by: config.barColor,
         show_resources_not_in_group: config.showResourcesNotInGroup,
         # projects
         project_sort_by: config.projectSort or null,
         # TODO: This is awful, rectify.
         show_project_numbers: if @showPeopleGantt() then config.showProjectNumbers else config.showJobNumbers,
         show_project_status: config.showStatus or null,
         # people
         people_sort_by: config.peopleSort or null,
         show_employee_numbers: config.showEmployeeNumber or null,
         show_position_names: config.showPositionName or null,
      }

      modal = new Modal();
      pane = new SaveViewPane(
         App.PageName.GANTT,
         {
            search: if @showPeopleGantt() then @peopleSearch() else @projectSearch(),
            filters: @filterChips(),
            viewingRange: @selectedRangeDays(),
            viewingEntity: if @showPeopleGantt() then "people" else "projects",
            pageSpecific: pageSpecific
         }
      );
      modal.setPanes([pane]);
      modalManager.showModal(
         modal,
         null,
         { class: "save-view-modal" },
      )

   showGeneratePersonReportModal: (person) =>
      @showExportModal("people", GanttExportModalPane.Mode.SINGLE, @buildPeopleExportPayload(person.personId))

   showGenerateProjectReportModal: (project) =>
      @showExportModal("projects", GanttExportModalPane.Mode.SINGLE, @buildProjectExportPayload(project.projectId))
   #endregion

   #region Export helpers
   showExportModal: (entity, mode, payload) =>
      panel = new GanttExportModalPane(entity, mode, payload)
      modal = new Modal()
      modal.setPanes([panel])
      modalManager.showModal modal, null, {class: 'gantt-export-modal-pane'}, (modal, exitStatus) =>
         return exitStatus == "finished"

   showExportManyModal: () =>
      return if @exportsDisabled()
      entity = @selectedViewingByEntity().value()
      payload = if entity == "people" then @buildPeopleExportPayload() else @buildProjectExportPayload()
      @showExportModal(entity, GanttExportModalPane.Mode.MULTIPLE, payload)

   buildPeopleExportPayload: (personId) =>
      config = @peopleGanttViewConfig()
      return @mergePayloadWithBase({
         # person ID is for individual requests
         person_id: (personId or null)
         people_sort: config.peopleSort
         project_sort: config.projectSort
         bar_split: config.barSplit
         bar_color: config.barColor
         show_position_name: config.showPositionName
         show_employee_numbers: config.showEmployeeNumber
         show_resources_not_in_group: config.showResourcesNotInGroup
         show_project_numbers: config.showProjectNumbers
         totals_unit: config.totalsUnit
         search: @peopleSearch() || null
      })

   buildProjectExportPayload: (projectId) =>
      config = @projectGanttViewConfig()
      return @mergePayloadWithBase({
         # project ID is for individual requests
         project_id: (projectId or null)
         project_sort: config.projectSort
         assignment_sort: config.peopleSort
         totals_unit: config.totalsUnit
         bar_split: config.barSplit
         bar_color: config.barColor
         show_status: config.showStatus
         show_job_numbers: config.showJobNumbers
         show_resources_not_in_group: config.showResourcesNotInGroup
         show_position_name: config.showPositionName
         search: @projectSearch() || null
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         can_view_project_tags: authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_TAGS),
         can_view_project_financials: authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)
         can_view_people_timeoff: authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TIMEOFF)
      })
   #endregion

   #region Event helpers
   handleInfoSectionClick: (d, event) =>
      d.startDate = new Date(d.startDate) if d.startDate
      d.estEndDate = new Date(d.estEndDate) if d.estEndDate

      # Clear any previously selected.
      @activePopupPerson(null)
      @activePopupAssignment(null)
      $(".gantt-bar--selected").each -> @.classList.remove("gantt-bar--selected")
      $(".gantt-popup-content").html('')

      @activePopupPerson(d)

      $frame = $(".page-content")
      frameHeight = $frame.height()
      toolTipWidth = 400

      $toolTip = $("#tool-tip")
      $toolTip.css("display", "block")
      $toolTip.animate({opacity: 1}, 200)

      if d.projectId?
         popupHtml = "<div class='tool-tip__content'>"
         if @canViewProject
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label gantt-popup-link' data-bind='click: navigateToProjectFromPopup'>#{d.projectName}</span></div>"
         else
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>#{d.projectName}</span></div>"
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{d.jobNumber}</span></div>" if ValidationUtils.validateInput(d.jobNumber)
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Groups:</span></div>"
         popupHtml += "<div class='gant-popup-groups-container'>"
         popupHtml += "<span class='gantt-popup-data'>"
         firstNameInt = true
         for name in d.groupNames
            name = ", #{name}" unless firstNameInt
            popupHtml += "<span class='gantt-popup-data'>#{name}</span>"
            firstNameInt = false
         popupHtml += "</span>"
         popupHtml += "</div>"

         if d.startDate? or d.estEndDate?
            if d.startDate?.getTimezoneOffset() > 0
               d.startDate?.setHours(d.startDate.getHours() + d.startDate.getTimezoneOffset() / 60)
            if d.estEndDate?.getTimezoneOffset() > 0
               d.estEndDate?.setHours(d.estEndDate.getHours() + d.estEndDate.getTimezoneOffset() / 60)
            formattedStartDate = if d.startDate? then DateUtils.getShortNumericDate(d.startDate, defaultStore.getDateFormat()) else "TBD"
            formattedEndDate = if d.estEndDate? then DateUtils.getShortNumericDate(d.estEndDate, defaultStore.getDateFormat()) else "TBD"
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Project Dates:</span></div>"
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{formattedStartDate} - #{formattedEndDate}</span></div>"

         formattedStartTime = DateUtils.formatTimeVal(d.dailyStartTime)
         formattedEndTime = DateUtils.formatTimeVal(d.dailyEndTime)
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Daily Times:</span></div>"
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{formattedStartTime} - #{formattedEndTime}</span></div>"

         if @canViewProjectTags
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Tags:</span></div><div>"
            for tag in d.tagInstances
               tagBoxHtml = "<div class='assignment-card__cert-box' style='background-color:" +
               tag.color + ";'><span class='assignment-card__cert-box-abbrv'>#{tag.abbreviation}</span></div>"
               popupHtml += tagBoxHtml
         popupHtml += "</div></div>"

      else
         popupHtml = "<div class='tool-tip__content'>"
         if @canViewPeople
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label gantt-popup-link' data-bind='click: navigateToPersonFromPopup'>#{d.personName}</span></div>"
         else
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>#{d.personName}</span></div>"
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{d.employeeNumber}</span></div>" if ValidationUtils.validateInput(d.employeeNumber)
         popupHtml += "<div class='gantt-popup-info-row gantt-popup-info-row--single-line'><span class='gantt-popup-label'>Hourly Rate: </span><span class='gantt-popup-data'>$#{d.hourlyWage}/hr</span></div>" if d.hourlyWage? and @canViewPeopleFinancials
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Groups:</span></div>"
         popupHtml += "<div class='gant-popup-groups-container'>"
         if d.permission == "admin"
            popupHtml += "<span>Available to all Groups</span>"
         else
            popupHtml += "<span class='gantt-popup-data'>"
            firstNameInt = true
            for name in d.groupNames
               name = ", #{name}" unless firstNameInt
               popupHtml += "<span class='gantt-popup-data'>#{name}</span>"
               firstNameInt = false
            popupHtml += "</span>"
         popupHtml += "</div>"

         if @canViewPeopleTags
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Tags:</span></div><div>"
            for tag in d.tagInstances
               tagBoxHtml = "<div class='assignment-card__cert-box' style='background-color:" +
               tag.color + ";'><span class='assignment-card__cert-box-abbrv'>#{tag.abbreviation}</span></div>"
               popupHtml += tagBoxHtml
         popupHtml += "</div></div>"

      $content = $(".gantt-popup-content").html(popupHtml)
      # TODO: This is off for the first click of the page. Figure out why
      contentHeight = $content.height()

      toolTipVerticalOffset = if d3.event.pageY > (frameHeight / 2) then d3.event.pageY - (contentHeight + 80) else d3.event.pageY - 30

      # Prevent it from getting in the toopbar.
      if toolTipVerticalOffset <= 60
         toolTipVerticalOffset = 65

      $toolTip.css("top", toolTipVerticalOffset + "px")
      $toolTip.css("left", (event.pageX + 10) + "px")
      $toolTip.css("height", (contentHeight + 20) + "px")
      $toolTip.css("width", toolTipWidth + "px")

      ko.cleanNode($toolTip[0])
      ko.applyBindings(@, $toolTip[0])

   handleTimeOffClick: (d, event) =>
      # Clear any previously selected.
      @activePopupAssignment(null)
      @activePopupPerson(null)
      $(".gantt-bar--selected").each -> @.classList.remove("gantt-bar--selected")

      event.target.classList.add("gantt-bar--selected")

      $frame = $(".page-content")
      frameWidth = $frame.width()
      frameHeight = $frame.height()
      assignmentToolTipHeight = 140
      toolTipWidth = 320
      eventClickOffset = 30

      $toolTip = $("#tool-tip")
      $toolTip.css("display", "block")
      $toolTip.animate({opacity: 1}, 200)

      popupHtml = "<div class='tool-tip__content'>"
      popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Reason:</span><span class='gantt-popup-data'>#{d.reason}</span></div>"
      if d.isPaid
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>Paid</span></div>"
      else
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>Unpaid</span></div>"

      if d.repeat
         frequency = d.repeat.charAt(0).toUpperCase() + d.repeat.slice(1)
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Repeats:</span><span class='gantt-popup-data'>#{frequency}</span></div>"
      else
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>Single Occurence</span></div>"

      formattedStartDate = DateUtils.formatDetachedDay(d.startDay, defaultStore.getDateFormat(), SHORT_FORMAT_OPTIONS)
      formattedEndDate = DateUtils.formatDetachedDay(d.endDay, defaultStore.getDateFormat(), SHORT_FORMAT_OPTIONS)
      popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{formattedStartDate} - #{formattedEndDate}</span></div>"

      if d.percentAllocated?
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{d.percentAllocated}% Allocated</span></div>"
      else
         startTime = DateUtils.formatTimeVal(d.startTime)
         endTime = DateUtils.formatTimeVal(d.endTime)
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{startTime} - #{endTime}</span></div>"
      popupHtml += "</div>"

      # TODO: Pull this into shared.
      if event.pageY > (frameHeight / 2)
         # Bottom half of page.
         if (event.pageY - eventClickOffset) <= assignmentToolTipHeight
            verticalAddative = ((assignmentToolTipHeight - event.pageY) + eventClickOffset)
            toolTipVerticalOffset = (event.pageY + verticalAddative) - assignmentToolTipHeight
         else
            toolTipVerticalOffset = event.pageY - (assignmentToolTipHeight + 70)
      else
         # Top half of the page.
         if event.pageY + assignmentToolTipHeight >= frameHeight
            verticalAddative = (((event.pageY + assignmentToolTipHeight) - frameHeight) + eventClickOffset)
            toolTipVerticalOffset =  event.pageY - verticalAddative
         else
            toolTipVerticalOffset =  event.pageY - eventClickOffset

      if event.pageX > (frameWidth / 2)
         # Right side of the page.
         if (event.pageX - 20) <= toolTipWidth
            horizontalAddative = (toolTipWidth - event.pageX)
            toolTipHorizontalOffset = event.pageX - (toolTipWidth - 20 - horizontalAddative)
         else
            toolTipHorizontalOffset = event.pageX - (toolTipWidth + 20)

         # Make sure it doesn't collide with right controls (120px).
         if toolTipHorizontalOffset + toolTipWidth >= frameWidth - 120
            toolTipHorizontalOffset = (toolTipHorizontalOffset - ((toolTipHorizontalOffset + toolTipWidth) - (frameWidth - 120))) - 20
      else
         # Left side of the page.
         if event.pageX + toolTipWidth >= frameWidth
            horizontalAddative = ((event.pageX + toolTipWidth) - frameWidth)
            toolTipHorizontalOffset = (event.pageX - horizontalAddative) - 30
         else
            toolTipHorizontalOffset = event.pageX - 30

      # Prevent it from getting in the toopbar.
      if toolTipVerticalOffset <= 60
         toolTipVerticalOffset = 65

      $(".gantt-popup-content").html(popupHtml)
      $toolTip.css("top", toolTipVerticalOffset + "px")
      $toolTip.css("left", toolTipHorizontalOffset + "px")
      $toolTip.css("height", assignmentToolTipHeight + "px")
      $toolTip.css("width", toolTipWidth + "px")

      ko.cleanNode($toolTip[0])
      ko.applyBindings(@, $toolTip[0])

   handleRequestClick: (d, event) =>
      return unless @canViewRequests
      # TODO: eval other people's here
      @handleAssignmentClick(d, event)

   handleAssignmentClick: (d, event) =>
      # Clear any previously selected.
      @activePopupAssignment(null)
      @activePopupRequest(null)
      @activePopupPerson(null)
      $(".gantt-bar--selected").each -> @.classList.remove("gantt-bar--selected")

      event.target.classList.add("gantt-bar--selected")

      $frame = $(".page-content")

      frameWidth = $frame.width()
      frameHeight = $frame.height()
      assignmentToolTipHeight = 210
      toolTipWidth = 520
      eventClickOffset = 30

      $toolTip = $("#tool-tip")
      $toolTip.css("display", "block")
      $toolTip.animate({opacity: 1}, 200)

      popupHtml = "<div class='tool-tip__content'>"

      hasGroupAccess = authManager.isAdmin() or d.groupIds?.some((groupId) =>
         authManager.getContextAccessibleGroupIds().has(groupId)
      )

      unless d.resourceId?
         # Is a request.
         assignmentToolTipHeight += 35
         requestTitle = if d.positionName? then "Workforce Request (#{d.positionName})" else 'Workforce Request'
         popupHtml += "<div class='gantt-popup-info-row'>"
         popupHtml += "<span class='gantt-popup-label gantt-popup__request-title'>#{requestTitle}</span>"
         if d.projectStatus == "active" and @canFillRequest(d)
            # Can only fill them on active jobs.
            popupHtml += "<a class='gantt-popup__fill-request-btn' data-bind='popup: fillRequestPopupWrapper'>Fill Request</a>"
         popupHtml += "</div>"

      if @canViewProject && !d.isRestrictedProject && hasGroupAccess
         popupHtml += "<div class='gantt-popup-info-row gantt-popup-info-row--single-line'><span class='gantt-popup-label'>Project: </span><span class='gantt-popup-data gantt-popup-link' data-bind='click: navigateToAssignmentsProject'>#{d.projectName}</span></div>"
      else
         popupHtml += "<div class='gantt-popup-info-row gantt-popup-info-row--single-line'><span class='gantt-popup-label'>Project: </span><span class='gantt-popup-data' style='font-style:italic'>#{d.projectName}</span></div>"
      popupHtml += "<div class='gantt-popup-info-row gantt-popup-info-row--single-line'><span class='gantt-popup-label'>Project Number: </span><span class='gantt-popup-data'>#{d.jobNumber}</span></div>" if d.jobNumber?
      if d.costCodeName? and d.costCodeName != "000000"
         assignmentToolTipHeight += 30
         popupHtml += "<div class='gantt-popup-info-row'><div class='gantt-popup-info-row--half'>"
         popupHtml += "<div class='gantt-popup-info-row gantt-popup-info-row--single-line'><span class='gantt-popup-label'>Category: </span><span class='gantt-popup-data'>#{d.costCodeName}</span></div>"
         if d.labelName? and d.labelName != "000000"
            popupHtml += "</div><div class='gantt-popup-info-row--half'>"
            popupHtml += "<div class='gantt-popup-info-row gantt-popup-info-row--single-line'><span class='gantt-popup-label'>Subcategory: </span><span class='gantt-popup-data'>#{d.labelName}</span></div>"
            popupHtml += "</div></div>"
         else
            popupHtml += "</div></div>"

      if d.status?
         assignmentToolTipHeight += 50
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Status:</span>"
         popupHtml += "<div class='gantt-popup-status' data-bind='style: {backgroundColor: " + '"' + ko.unwrap(d.status.color) + '"' + "},"
         popupHtml += "borderColor: " + '"' + ko.unwrap(d.status.color) + '"' + "'>"
         popupHtml += "<div class='gantt-popup-status-text' data-bind='"
         popupHtml += "visibleTextColor: " + '"' + ko.unwrap(d.status.color) + '"' + "'>"
         popupHtml += "#{ko.unwrap(d.status.abbreviation)}</div></div>"
         popupHtml += "</div>"

      if d.percentAllocated?
         paidHours = @costingConfig().paidShiftHours * (d.percentAllocated / 100)
      else
         startTime = DateUtils.formatTimeVal(d.startTime)
         endTime = DateUtils.formatTimeVal(d.endTime)
         if d.overtime
            paidHours = d.paySplit.straight + d.paySplit.overtime
         else
            if d.startTime < d.endTime
               hourDuration = (d.endTime - d.startTime)
            else
               # Overnight
               hourDuration = d.endTime + (24 - d.startTime)
            paidHours = if hourDuration <= @costingConfig().paidShiftHours then hourDuration else @costingConfig().paidShiftHours
      if !d.isRestrictedProject
         popupHtml += "<div class='gantt-popup-info-row'><div class='gantt-popup-info-row--half'>"
         if d.percentAllocated?
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Assignment Allocation:</span></div>"
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{d.percentAllocated}% (#{paidHours} hours)</span></div>"
         else
            hourCount = if d.resourceId? then "(#{paidHours} paid hours)" else "(#{paidHours} hours)"
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Assignment Times:</span></div>"
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{startTime} - #{endTime} #{hourCount}</span></div>"
      else
         popupHtml += "<br>"
      popupHtml += "</div><div class='gantt-popup-info-row--half'>"
      popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Work Days:</span></div>"
      popupHtml += "<div class='gantt-popup__work-days-container'>"
      if d.workDays[0]
         popupHtml += "<span class='gantt-popup__work-day gantt-popup__work-day--active'>Su</span>"
      else
         popupHtml += "<span class='gantt-popup__work-day'>Su</span>"

      if d.workDays[1]
         popupHtml += "<span class='gantt-popup__work-day gantt-popup__work-day--active'>M</span>"
      else
         popupHtml += "<span class='gantt-popup__work-day'>M</span>"

      if d.workDays[2]
         popupHtml += "<span class='gantt-popup__work-day gantt-popup__work-day--active'>Tu</span>"
      else
         popupHtml += "<span class='gantt-popup__work-day'>Tu</span>"

      if d.workDays[3]
         popupHtml += "<span class='gantt-popup__work-day gantt-popup__work-day--active'>W</span>"
      else
         popupHtml += "<span class='gantt-popup__work-day'>W</span>"

      if d.workDays[4]
         popupHtml += "<span class='gantt-popup__work-day gantt-popup__work-day--active'>Th</span>"
      else
         popupHtml += "<span class='gantt-popup__work-day'>Th</span>"

      if d.workDays[5]
         popupHtml += "<span class='gantt-popup__work-day gantt-popup__work-day--active'>F</span>"
      else
         popupHtml += "<span class='gantt-popup__work-day'>F</span>"

      if d.workDays[6]
         popupHtml += "<span class='gantt-popup__work-day gantt-popup__work-day--active'>Sa</span>"
      else
         popupHtml += "<span class='gantt-popup__work-day'>Sa</span>"
      popupHtml += "</div></div>"

      formattedBatchStartDate = DateUtils.formatDetachedDay(d.batchStartDay, defaultStore.getDateFormat(), SHORT_FORMAT_OPTIONS)
      formattedBatchEndDate = DateUtils.formatDetachedDay(d.batchEndDay, defaultStore.getDateFormat(), SHORT_FORMAT_OPTIONS)
      formattedStartDate = DateUtils.formatDetachedDay(d.startDay, defaultStore.getDateFormat(), SHORT_FORMAT_OPTIONS)
      formattedEndDate = DateUtils.formatDetachedDay(d.endDay, defaultStore.getDateFormat(), SHORT_FORMAT_OPTIONS)

      unless formattedBatchStartDate == formattedStartDate && formattedBatchEndDate == formattedEndDate
         if d.resourceId?
            popupHtml += "<div class='gantt-popup-info-row'><div class='gantt-popup-info-row--half'>"
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>This Occurence:</span></div>"
            popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{formattedStartDate} - #{formattedEndDate}</span></div>"

      popupHtml += "</div><div class='gantt-popup-info-row--half'>"
      if d.resourceId?
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Total Assignment Range:</span></div>"
      else
         # Is a Request
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Total Request Range:</span></div>"
      popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-data'>#{formattedBatchStartDate} - #{formattedBatchEndDate}</span></div>"
      popupHtml += "</div>"

      if d.overtime
         assignmentToolTipHeight += 40
         popupHtml += "<div class='gantt-popup-info-row--half'>"
         popupHtml += "<div class='gantt-popup-info-row'><span class='gantt-popup-label'>Overtime:</span></div>"
         popupHtml += "<div class='gantt-popup-info-row'>"
         popupHtml += "<span class='gantt-popup-prefix'>Straight:</span><span class='gantt-popup-data gantt-popup-data--inline'>#{d.paySplit.straight}</span>"
         popupHtml += "<span class='gantt-popup-prefix'>OT:</span><span class='gantt-popup-data gantt-popup-data--inline'>#{d.paySplit.overtime}</span>"
         popupHtml += "<span class='gantt-popup-prefix'>Unpaid:</span><span class='gantt-popup-data gantt-popup-data--inline'>#{d.paySplit.unpaid}</span>"
         popupHtml += "</div>"
         popupHtml += "</div></div>"
      else
         popupHtml += "</div>"

      detachedToday = DateUtils.getDetachedDay(new Date())

      if d.resourceId? and @canManageAssignments
         # Assignment
         if @selectedViewingByEntity().value() == "projects"
            assignmentsGroupIds = if authManager.usingTypedGroups() then d.personAssignableGroupIds else d.personGroupIds
         else
            assignmentsGroupIds = d.groupIds

         usersGroupIds = if authManager.usingTypedGroups() then authManager.authedUser().accessGroupIds() else authManager.authedUser().groupIds()

         outsideGroupAccess = false
         unless authManager.isAdmin()
            foundMatch = false
            for id in usersGroupIds
               if (assignmentsGroupIds or []).indexOf(id) != -1
                  foundMatch = true
                  break
            # If the resource is an Admin, they are available to all Groups.
            if assignmentsGroupIds?.length == 0
               foundMatch = true
            outsideGroupAccess = true unless foundMatch

         if d.isRestrictedProject
            popupHtml += "<div class='gantt-popup-info-row'>"
            popupHtml += "<div class='gantt-popup__editing-warning'>You do not have access to the Project this Assignment belongs to.</div>"
            popupHtml += "</div>"
         else if outsideGroupAccess
            groupWarningEntity = if @selectedViewingByEntity().value() == "projects" then "Persons's" else "Project's"
            popupHtml += "<div class='gantt-popup-info-row--half'>"
            popupHtml += "<div class='gantt-popup__editing-warning'>You do not have access to the group(s) this #{groupWarningEntity} assignment belongs to.</div>"
            popupHtml += "</div>"
         else
            unless formattedBatchStartDate == formattedStartDate && formattedBatchEndDate == formattedEndDate
               if @canManageAssignments
                  if d.projectStatus == "active"
                     if d.resourceId?
                        popupHtml += "<div class='gantt-popup-info-row'><div class='gantt-popup-info-row--half'>"
                        popupHtml += "<a class='gantt-popup__edit-btn' data-bind='click: () => editAssignment(false)'>Edit This Occurence</a>"
                        if d.endDay < detachedToday
                           popupHtml += "<div class='gantt-popup__editing-warning'>Caution: This occurence is in the past.</div>"
                        else if d.startDay < detachedToday
                           popupHtml += "<div class='gantt-popup__editing-warning'>Caution: This occurence has already started.</div>"
                        popupHtml += "</div>"

            popupHtml += "<div class='gantt-popup-info-row--half'>"

            if d.projectStatus == "active"
               popupHtml += "<a class='gantt-popup__edit-btn' data-bind='click: () => editAssignment(true)'>Edit Total Assignment</a>"
            else
               popupHtml += "<div class='gantt-popup__editing-warning'>You can only edit assignments on active projects.</div>"

            needsWarnings = if d.resourceId? then d.projectStatus == "active" else (d.projectStatus == "active" or d.projectStatus == "pending")

         if needsWarnings
            warningEntity = if d.resourceId? then "assignment" else "request"
            if d.batchEndDay < detachedToday
               popupHtml += "<div class='gantt-popup__editing-warning'>Caution: This entire #{warningEntity} is in the past.</div>"
            else if d.batchStartDay < detachedToday
               popupHtml += "<div class='gantt-popup__editing-warning'>Caution: This #{warningEntity} has already started.</div>"

         popupHtml += "</div></div>"

      else if !d.resourceId?

         popupHtml += "<div class='gantt-popup-info-row--half'>"

         # Request
         if @canManageOthersRequests or (@canManageRequests and d.creatorId == authManager.authedUser().id)
            if d.projectStatus == "active" or d.projectStatus == "pending"
               popupHtml += "<a class='gantt-popup__edit-btn' data-bind='click: editRequest'>Edit Request</a>"
            else
               popupHtml += "<div class='gantt-popup__editing-warning'>You can't edit requests on inactive projects.</div>"

         needsWarnings = if d.resourceId? then d.projectStatus == "active" else (d.projectStatus == "active" or d.projectStatus == "pending")
         if needsWarnings
            warningEntity = if d.resourceId? then "assignment" else "request"
            if d.batchEndDay < detachedToday
               popupHtml += "<div class='gantt-popup__editing-warning'>Caution: This entire #{warningEntity} is in the past.</div>"
            else if d.batchStartDay < detachedToday
               popupHtml += "<div class='gantt-popup__editing-warning'>Caution: This #{warningEntity} has already started.</div>"

         popupHtml += "</div></div>"

      popupHtml += "</div></div>"

      # TODO: Pull this into shared.
      if event.pageY > (frameHeight / 2)
         # Bottom half of page.
         if (event.pageY - eventClickOffset) <= assignmentToolTipHeight
            verticalAddative = ((assignmentToolTipHeight - event.pageY) + eventClickOffset)
            toolTipVerticalOffset = (event.pageY + verticalAddative) - assignmentToolTipHeight
         else
            toolTipVerticalOffset = event.pageY - (assignmentToolTipHeight + 70)
      else
         # Top half of the page.
         if event.pageY + assignmentToolTipHeight >= frameHeight
            verticalAddative = (((event.pageY + assignmentToolTipHeight) - frameHeight) + eventClickOffset)
            toolTipVerticalOffset =  event.pageY - verticalAddative
         else
            toolTipVerticalOffset =  event.pageY - eventClickOffset

      # Prevent it from getting in the toopbar.
      if toolTipVerticalOffset <= 60
         toolTipVerticalOffset = 65

      if event.pageX > (frameWidth / 2)
         # Right side of the page.
         if (event.pageX - 20) <= toolTipWidth
            horizontalAddative = (toolTipWidth - event.pageX)
            toolTipHorizontalOffset = event.pageX - (toolTipWidth - 20 - horizontalAddative)
         else
            toolTipHorizontalOffset = event.pageX - (toolTipWidth + 20)

         # Make sure it doesn't collide with right controls (120px).
         if toolTipHorizontalOffset + toolTipWidth >= frameWidth - 120
            toolTipHorizontalOffset = (toolTipHorizontalOffset - ((toolTipHorizontalOffset + toolTipWidth) - (frameWidth - 120))) - 20
      else
         # Left side of the page.
         if event.pageX + toolTipWidth >= frameWidth
            horizontalAddative = ((event.pageX + toolTipWidth) - frameWidth)
            toolTipHorizontalOffset = (event.pageX - horizontalAddative) - 30
         else
            toolTipHorizontalOffset = event.pageX - 30

      $(".gantt-popup-content").html(popupHtml)
      $toolTip.css("top", toolTipVerticalOffset + "px")
      $toolTip.css("left", toolTipHorizontalOffset + "px")
      $toolTip.css("height", assignmentToolTipHeight + "px")
      $toolTip.css("width", toolTipWidth + "px")

      # TODO: Update this to handle request differently.
      if d.resourceId?
         @activePopupAssignment(d)
      else
         @activePopupRequest(d)

      ko.cleanNode($toolTip[0])
      ko.applyBindings(@, $toolTip[0])

   handleViewingEntityChange: (newVal) =>
      @setTitle("Gantt")

      router.removeQueryParam(App.RouteName.GANTT, "viewId")
      router.updateUrlQueryParam(App.RouteName.GANTT, "entity", newVal.value())
      queryParams = router.getCurrentQueryParams()

      #region Updating search values
      if @showPeopleGantt()
         peopleSearchKey = BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PEOPLE_SEARCH
         storedPeopleSearch = BrowserStorageUtils.getBasicValue(peopleSearchKey)
         @peopleSearch(storedPeopleSearch ? "")
      else
         projectSearchKey = BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PROJECT_SEARCH
         storedProjectSearch = BrowserStorageUtils.getBasicValue(projectSearchKey)
         @projectSearch(storedProjectSearch ? "")
      #endregion

      #region Updating filters
      # Filter options
      if @showPeopleGantt()
         @labeledFilterOptions(@peopleViewFilterOptions())
      else
         @labeledFilterOptions(@projectsViewFilterOptions())

      # Applied filters
      chipKey = if @showPeopleGantt() then "people-chip-filters" else "project-chip-filters"
      storedChips = BrowserStorageUtils.getPageFilterChips(chipKey)
      if storedChips?
         @filterChips(storedChips)
      else
         if @showPeopleGantt()
            @filterChips(Gantt2ViewModel.DEFAULT_PEOPLE_FILTER_CHIPS)
         else
            @filterChips(Gantt2ViewModel.DEFAULT_PROJECTS_FILTER_CHIPS)
      setTimeout(() =>
         @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))
      , 0)
      #endregion

      #region Updating config values
      savedProjectConfig = BrowserStorageUtils.getJsonValue(BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PROJECT_CONFIG)
      if savedProjectConfig?
         @projectGanttViewConfig(savedProjectConfig)

      savedPeopleConfig = BrowserStorageUtils.getJsonValue(BrowserStorageUtils.BrowserLocalStorageKey.GANTT_PEOPLE_CONFIG)
      if savedPeopleConfig?
         @peopleGanttViewConfig(savedPeopleConfig)
      #endregion

      #region Updating start day and viewing range
      if queryParams.dayFilter
         newDate = DateUtils.getAttachedDate(Number(queryParams.dayFilter))
         newDate = new Date() unless 2100 >= newDate.getFullYear() >= 2000
         @viewStartDate(newDate)

      if queryParams.range
         rangeVal = Number(queryParams.range)
         if Gantt2ViewModel.VALID_RANGE_OPTIONS.indexOf(rangeVal) != -1
            @selectedRangeDays(rangeVal)
      #endregion

      # Updating the above values should trigger an event listener from setupViewConfigSubscriptions
      # and their callbacks will take care of calling @loadAssignments(0)

   handleGroupChange: (newGroupId) =>
      return unless newGroupId?

      @projectPageCursor.dispose()
      @projectPageCursor = @getNewProjectPageCursor()
      @peoplePageCursor.dispose()
      @peoplePageCursor = @getNewPeoplePageCursor()
      @loadSupportData()

      if @selectedViewingByEntity().value() == 'people'
         @loadAssignments(@peoplePageCursor.lastIndex())
      else
         @loadAssignments(@projectPageCursor.lastIndex())

   handleViewingDateChange: () =>
      return @disposableViewStartBlock = false if @disposableViewStartBlock
      @setTitle("Gantt")
      days = @selectedRangeDays()
      if 91 <= days < 182
         @viewStartDate().setDate(@viewStartDate().getDate() - @viewStartDate().getDay())
         @disposableViewStartBlock = true
         @viewStartDate(@viewStartDate())
      else if 182 <= days
         @viewStartDate().setDate(1)
         @disposableViewStartBlock = true
         @viewStartDate(@viewStartDate())

      dayFilter = DateUtils.getDetachedDay(@viewStartDate())
      router.updateUrlQueryParam(App.RouteName.GANTT, "dayFilter", dayFilter)
      @loadAssignments(0)

   handleViewingRangeChange: (newValue) =>
      return @disposableViewStartBlock = false if @disposableViewStartBlock
      @setTitle("Gantt")
      days = @selectedRangeDays()
      if 91 <= days < 182
         @viewStartDate().setDate(@viewStartDate().getDate() - @viewStartDate().getDay())
         @disposableViewStartBlock = true
         @viewStartDate(@viewStartDate())
      else if 182 <= days
         @viewStartDate().setDate(1)
         @disposableViewStartBlock = true
         @viewStartDate(@viewStartDate())

      dayFilter = DateUtils.getDetachedDay(@viewStartDate())
      router.updateUrlQueryParam(App.RouteName.GANTT, "dayFilter", dayFilter)
      router.updateUrlQueryParam(App.RouteName.GANTT, "range", newValue)
      @loadAssignments(0)
   #endregion

   #region CRUD helpers
   createAssignment: (projectId) =>
      if projectId
         project = await ProjectStore.getProject(projectId).payload
         @ganttPage.createEditDeleteAssignment({
            assignment: null,
            assignmentInstanceRange: null,
            newAssignmentSeedData: {
               project: project.data
            }
         })
      else
         @ganttPage.createEditDeleteAssignment({
            assignment: null,
            assignmentInstanceRange: null,
         })

   editAssignment: (editTotalRange) =>
      assignmentId = @activePopupAssignment().batchId
      foundAssignment = await @ganttPage.getSingleFindAssignment(assignmentId)
      totalAssignmentRange = null
      if editTotalRange == false
         totalAssignmentRange = {
            startDay: foundAssignment.start_day,
            endDay: foundAssignment.end_day,
         }
      occurenceStartDay = @activePopupAssignment().startDay
      occurenceEndDay = @activePopupAssignment().endDay
      assignment = ko.pureComputed(() => {
         ...foundAssignment,
         start_day: if editTotalRange then foundAssignment.start_day else occurenceStartDay,
         end_day: if editTotalRange then foundAssignment.end_day else occurenceEndDay,
      })
      @ganttPage.createEditDeleteAssignment({
         assignment: assignment,
         totalAssignmentRange: totalAssignmentRange,
      })

   createRequest: (projectId) =>
      project = await ProjectStore.getProject(projectId).payload
      @ganttPage.createEditDeleteRequest({
         request: null,
         newRequestSeedData: {
            project: project.data
         }
      })

   editRequest: =>
      requestId = @activePopupRequest().batchId
      foundRequest = await @ganttPage.getSingleFindRequest(requestId)
      request = ko.pureComputed(() -> foundRequest)
      @ganttPage.createEditDeleteRequest({
         request: request,
      })

   fillRequest: (resourceOption) =>
      resourceId = resourceOption.value()

      @closeGanttPopup()

      request = @activePopupRequest()

      pendingAssignment = {
         pendingId: GUID()
         projectId: request.projectId
         costCodeId: request.costCodeId or null
         labelId: request.labelId or null
         resourceId: resourceId
         startTime: request.startTime
         endTime: request.endTime
         percentAllocated: request.percentAllocated
         startDay: request.batchStartDay
         endDay: request.batchEndDay
         isPending: true
         workDays: request.workDays
         statusId: request.statusId or null
         fillingRequest: true
      }
      supportData = @ganttPage.state.assignmentSupportData

      pane1 = new GanttPane(resourceId, request.batchStartDay, pendingAssignment, supportData, @costingConfig(), request.batchId)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'assignments-details-modal'}, (modal, modalStatus, observableData) =>
         addedAssignments = observableData.data.newAssignments ? []
         removedRequestsData = if observableData.data.removedRequestData? then [observableData.data.removedRequestData] else []
         @maybeUpdateGantt({
            addedAssignments,
            removedRequestsData
         })
         return if modalStatus == "cancelled"
         if observableData.data.notify
            alertManager.showAssignmentMessageModal(observableData.data, request)
   #endregion

   #region Maybe helpers
   maybeUpdateGantt: ({
      addedAssignments = [],
      removedAssignmentsData = [],
      addedRequests = [],
      removedRequestsData = [],
   }) =>
      needsAssignmentRedraw = await @maybeUpdateAssignmentRows(addedAssignments, removedAssignmentsData)
      needsRequestRedraw = @maybeUpdateRequestRows(addedRequests, removedRequestsData)

      if needsAssignmentRedraw || needsRequestRedraw
         if @showPeopleGantt()
            # Clean this up later
            scrollTop = $("#gantt-chart-wrapper").scrollTop()
            scrollTop = 0 unless scrollTop?
            oldRows = @rows()
         else
            # Clean this up later
            scrollTop = $("#project-gantt-chart-wrapper").scrollTop()
            scrollTop = 0 unless scrollTop?
            # Decoupling
            oldRows = JSON.parse(JSON.stringify(ko.toJS(@rawProjectRows())))

         @rows([])
         @processRows oldRows, (newRows) =>
            @rows(newRows)
            @handleRedraw(scrollTop)

   maybeUpdateAssignmentRows: (addedAssignments, removedAssignmentData) =>
      needsRedraw = false
      if removedAssignmentData.length > 0
         removedAssignmentData.forEach (removedAssignment) =>
            if @showPeopleGantt()
               for row in @rows()
                  if row.personId == removedAssignment.resourceId
                     needsRedraw = true
                     foundProject = false
                     removeProject = false
                     for project in row.projects
                        if project.projectId == removedAssignment.projectId
                           foundProject = true
                           project.assignments = project.assignments.filter (item) ->
                              return item.batchId != removedAssignment.id
                           removeProject = true if project.assignments.length < 1

                     if foundProject and removeProject
                        row.projects = row.projects.filter (item) ->
                           return item.projectId != removedAssignment.projectId
            else
               # Removing old assignment
               for row in @rawProjectRows()
                  if row.projectId == removedAssignment.projectId
                     needsRedraw = true
                     removeCat = false
                     for categoryId, subcategories of row.groupedAssignments
                        categoryId = null if categoryId == "null"
                        # Clunky, but handles null.
                        if String(categoryId) == String(removedAssignment.costCodeId)
                           removeSubcat = false
                           for subcat in subcategories
                              # Clunky, but handles null.
                              if String(subcat.labelId) == String(removedAssignment.labelId)
                                 removePerson = false
                                 for person in subcat.peopleAssignments
                                    if person.personId == removedAssignment.resourceId
                                       person.assignments = person.assignments.filter (item) ->
                                          return item.batchId != removedAssignment.id
                                       removePerson = person.assignments.length == 0
                                       break
                                 if removePerson
                                    subcat.peopleAssignments = subcat.peopleAssignments.filter (item) ->
                                       return item.personId != removedAssignment.resourceId
                                    removeSubcat = subcat.peopleAssignments.length == 0
                                 break
                           if removeSubcat
                              subcategories = subcategories.filter (item) ->
                                 return String(item.labelId) != String(removedAssignment.labelId)

                              row.groupedAssignments[categoryId] = subcategories

                              removeCat = subcategories.length == 0
                           break
                     if removeCat
                        delete row.groupedAssignments[removedAssignment.costCodeId]
                     break
      if addedAssignments.length > 0
         # Handles formatting for both people and project gantt.
         # TODO: Need to aggregate all the formatting locations
         # into a shared utlity so only changes in 1 place are required.
         formattedAssignments = addedAssignments.map (ass) ->
            newFormattedAssignment = {
               batchEndDay: ass.endDay() or null
               batchId: ass.id
               batchStartDay: ass.startDay() or null
               color: ass.baggage().project_color or null
               costCodeId: ass.costCodeId()
               costCodeName: ass.baggage().cost_code_name
               employeeNumber: ass.baggage().employee_number
               end: DateUtils.getAttachedDate(ass.endDay() + 1)
               endDay: ass.endDay()
               endTime: ass.endTime()
               groupIds: ass.baggage().group_ids
               hourlyWage: ass.baggage().hourly_wage
               instanceId: null
               jobNumber: ass.baggage().job_number
               labelId: ass.labelId()
               labelName: ass.baggage().label_name
               overtime: ass.overtime()
               percentAllocated: ass.percentAllocated()
               personAssignableGroupIds: ass.baggage().person_assignable_group_ids
               personGroupIds: ass.baggage().person_group_ids
               personName: ass.baggage().person_name
               positionColor: ass.baggage().position_color
               positionName: ass.baggage().position_name
               positionRate: ass.baggage().position_rate
               positionSequence: ass.baggage().position_sequence
               projectColor: ass.baggage().project_color or null
               projectId: ass.projectId()
               projectName: ass.baggage().project_name
               projectStatus: ass.baggage().project_status
               resourceId: ass.resourceId()
               # TODO: Is this a problem?
               singleDay: null
               start: DateUtils.getAttachedDate(ass.startDay())
               startDay: ass.startDay()
               startTime: ass.startTime()
               status: ass.status() or null
               statusId: ass.statusId() or null
               statusName: if ass.status()? then ass.status().name else null
               wageOverrides: ass.baggage().wage_overrides
               workDays: ass.workDays()
            }

            if ass.percentAllocated()?
               # Show the entire day
               newFormattedAssignment['startTime'] = 0
               newFormattedAssignment['endTime'] = 24

            if ass.paySplit()?
               newFormattedAssignment['paySplit'] = {
                  overtime: Number(ass.paySplit().overtime)
                  straight: Number(ass.paySplit().straight)
                  unpaid: Number(ass.paySplit().unpaid)
               }

            if ass.overtimeRates()?
               newFormattedAssignment['overtimeRates'] = {
                  "0": Number(ass.overtimeRates()["0"])
                  "1": Number(ass.overtimeRates()["1"])
                  "2": Number(ass.overtimeRates()["2"])
                  "3": Number(ass.overtimeRates()["3"])
                  "4": Number(ass.overtimeRates()["4"])
                  "5": Number(ass.overtimeRates()["5"])
                  "6": Number(ass.overtimeRates()["6"])
               }

            return newFormattedAssignment

         if @showPeopleGantt()
            for row in @rows()
               for assignment in formattedAssignments
                  if row.personId == assignment.resourceId
                     needsRedraw = true

                     foundProject = false
                     for project in row.projects
                        if project.projectId == assignment.projectId
                           foundProject = true
                           project.assignments.push(assignment)

                     unless foundProject
                        row.projects.push({
                           assignments: [assignment]
                           containedAssBars: 1
                           jobNumber: assignment.jobNumber
                           leadingAssBars: 0
                           leadingToBars: 0
                           projectColor: assignment.projectColor
                           projectEndDate: null
                           projectId: assignment.projectId
                           projectName: assignment.projectName
                           # TODO: This needs to get fixed.
                           projectStartDate: null
                        })

         else
            # Adding new assignment
            for newFormattedAssignment in formattedAssignments
               for row in @rawProjectRows()
                  if row.projectId == newFormattedAssignment.projectId
                     needsRedraw = true
                     foundCat = null
                     foundSubCat = null
                     foundPerson = null

                     for categoryId, subcategories of row.groupedAssignments
                        categoryId = null if categoryId == "null"
                        # Clunky, but handles null.
                        if String(categoryId) == String(newFormattedAssignment.costCodeId)
                           foundCat = String(categoryId)
                           for subcat in subcategories
                              # Clunky, but handles null.
                              if String(subcat.labelId) == String(newFormattedAssignment.labelId)
                                 foundSubCat = String(subcat.labelId)
                                 for person in subcat.peopleAssignments
                                    # NOTE: There's a bug where the person that was updated has disappeared from the @rawProjectRows observable, so this
                                    # condition never actually returns truthy. Add a debugger below and log the value of the rawProjectRows observable and
                                    # you'll see what I mean. The reason it still gets drawn to the screen is because foundPerson never gets set to true,
                                    # so the following 'unless' check for it fails and the code proceeds to insert the "new" person again.
                                    # I have no clue why or how the updated person is getting removed from the observable. I tried using a computed to log
                                    # any changes to that observable and the change-in-question was never logged. Feels like a bug with Knockout.js
                                    if person.personId == newFormattedAssignment.resourceId
                                       foundPerson = person.personId
                                       person.assignments.push(newFormattedAssignment)
                                       break
                                 break
                           break

                     unless foundPerson?
                        try
                           payload = await TimeOffStore.getTimeOffForPerson(newFormattedAssignment.resourceId).payload
                           timeoffArray = payload.data
                           timeoffInstances = timeoffArray.flatMap((timeoff) =>
                              timeoff.instances.map((instance) => ({
                                 reason: timeoff.reason
                                 isPaid: timeoff.is_paid
                                 repeat: timeoff.repeat
                                 startTime: timeoff.batch_start_time
                                 endTime: timeoff.batch_end_time
                                 startDay: instance.start_day
                                 start: DateUtils.getAttachedDate(instance.start_day)
                                 endDay: instance.end_day
                                 end: DateUtils.getAttachedDate(instance.end_day + 1)
                              }))
                           )
                        catch err
                           console.error("Error getting timeoff for person:", err)
                           timeoffInstances = []

                        if !foundCat?
                           # New Category
                           newSubcatSets = [{
                              labelId: String(newFormattedAssignment.labelId)
                              peopleAssignments: [{
                                 personId: newFormattedAssignment.resourceId
                                 personName: newFormattedAssignment.personName
                                 positionColor: newFormattedAssignment.positionColor
                                 positionName: newFormattedAssignment.positionName
                                 positionSequence: newFormattedAssignment.positionSequence
                                 assignments: [newFormattedAssignment]
                                 timeoff: timeoffInstances
                              }]
                           }]
                           row.groupedAssignments[String(newFormattedAssignment.costCodeId)] = newSubcatSets
                        else if !foundSubCat?
                           # New Subcategory
                           newSubcatSet = {
                              labelId: String(newFormattedAssignment.labelId)
                              peopleAssignments: [{
                                 personId: newFormattedAssignment.resourceId
                                 personName: newFormattedAssignment.personName
                                 positionColor: newFormattedAssignment.positionColor
                                 positionName: newFormattedAssignment.positionName
                                 positionSequence: newFormattedAssignment.positionSequence
                                 assignments: [newFormattedAssignment]
                                 timeoff: timeoffInstances
                              }]
                           }
                           row.groupedAssignments[foundCat].push(newSubcatSet)
                        else if !foundPerson?
                           # New Person
                           for subcat in row.groupedAssignments[foundCat]
                              if String(subcat.labelId) == foundSubCat
                                 subcat.peopleAssignments.push({
                                    personId: newFormattedAssignment.resourceId
                                    personName: newFormattedAssignment.personName
                                    positionColor: newFormattedAssignment.positionColor
                                    positionName: newFormattedAssignment.positionName
                                    positionSequence: newFormattedAssignment.positionSequence
                                    assignments: [newFormattedAssignment]
                                    timeoff: timeoffInstances
                                 })
                                 break
                     break

      return needsRedraw

   maybeUpdateRequestRows: (addedRequests, removedRequestsData) =>
      return false if @showPeopleGantt()
      needsRedraw = false

      if removedRequestsData and removedRequestsData.length > 0
         needsRedraw = true
         # Removing Old
         for removedRequest in removedRequestsData
            for row in @rawProjectRows()
               if row.projectId == removedRequest.projectId
                  needsRedraw = true
                  removeCat = false
                  for categoryId, subcategories of row.groupedRequests
                     categoryId = null if categoryId == "null"
                     # Clunky, but handles null.
                     if String(categoryId) == String(removedRequest.costCodeId)
                        removeSubcat = false
                        for subcat in subcategories
                           # Clunky, but handles null.
                           if String(subcat.labelId) == String(removedRequest.labelId)
                              subcat.requests = subcat.requests.filter (item) ->
                                 return item.batchId != removedRequest.id

                              removeSubcat = subcat.requests.length == 0
                              break
                        if removeSubcat
                           subcategories = subcategories.filter (item) ->
                              return String(item.labelId) != String(removedRequest.labelId)

                           row.groupedRequests[categoryId] = subcategories
                           removeCat = subcategories.length == 0
                        break
                  if removeCat
                     delete row.groupedRequests[removedRequest.costCodeId]
                  break

      if addedRequests and addedRequests.length > 0
         needsRedraw = true
         for request in addedRequests
            addedRequest = @formatRequest(request)
            for row in @rawProjectRows()
               if row.projectId == addedRequest.projectId
                  foundCat = null
                  foundSubCat = null

                  removeCat = false
                  for categoryId, subcategories of row.groupedRequests
                     categoryId = null if categoryId == "null"
                     # Clunky, but handles null.
                     if String(categoryId) == String(addedRequest.costCodeId)
                        foundCat = String(categoryId)
                        removeSubcat = false
                        for subcat in subcategories
                           # Clunky, but handles null.
                           if String(subcat.labelId) == String(addedRequest.labelId)
                              foundSubCat = String(subcat.labelId)
                              subcat.requests.push(addedRequest)
                              subcat
                              break
                        break

                  unless foundSubCat?
                     if !foundCat?
                        # New Category
                        newSubcatSets = [{
                           labelId: addedRequest.labelId
                           requests: [addedRequest]
                        }]
                        row.groupedRequests[String(addedRequest.costCodeId)] = newSubcatSets
                     else if !foundSubCat?
                        # New Subcategory
                        newSubcatSet = {
                           labelId: addedRequest.labelId
                           requests: [addedRequest]
                        }
                        row.groupedRequests[foundCat].push(newSubcatSet)

                  break

      return needsRedraw
   #endregion

   #region Action helpers
   closeGanttPopup: ->
      $(".gantt-bar--selected").each -> @.classList.remove("gantt-bar--selected")
      @activePopupAssignment(null)
      @activePopupPerson(null)
      $toolTip = $("#tool-tip")
      $toolTip.animate {opacity: 0}, 200, ->
         $toolTip.css("display", "none")
         $(".gantt-popup-content").html(null)

   openLaborPlansPage: (project) =>
      return unless @canManageRequests
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/projects/#{project.projectId}/create-labor-plan?gantt")

   sendAssignmentAlerts: (projectId) =>
      explanationText = "Define which people on this project you would like to alert."
      options = {
         forAlert: true
      }
      if @selectedRangeDays() > 1
         options['rangeEndDate'] = DateUtils.incrementDate(@viewStartDate(), (@selectedRangeDays() - 1))

      pane1 = new SelectPeopleByAssignmentPane(projectId, @viewStartDate(), "Alert People", "Edit Alert Template", explanationText, options)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'message-people-by-assignments-modal--alert'}, (modal, exitStatus, observableData) =>
         if exitStatus == "finished" and observableData.data.selectedAssignmentRange?
            alertContext = observableData.data.context
            peopleToMessage = observableData.data.peopleToMessage
            defaultRecipients = observableData.data.defaultRecipients
            selectedAssignmentRange = observableData.data.selectedAssignmentRange
            if observableData.data.scheduledAlertDate?
               scheduledDay = DateUtils.getDetachedDay(observableData.data.scheduledAlertDate)
            else
               scheduledDay = null

            if observableData.data.scheduledAlertDate?
               scheduledTime = observableData.data.scheduledAlertTime
            else
               scheduledTime = null

            fetchedAlertTemplate = null

            pane1 = new ProcessingNoticePaneViewModel("Getting Alert Template", true)
            processingModal = new Modal()
            processingModal.setPanes([pane1])
            modalManager.showModal processingModal, null, {class: "processing-notice-modal"}, (modal, exitStatus) =>
               return if exitStatus == "cancelled"

               options = {
                  returnOneOffTemplate: true
                  actionBtnText: "Review Recipients"
                  negationBtnText: "Cancel"
               }

               if fetchedAlertTemplate?
                  options['existingMessage'] = fetchedAlertTemplate
               else
                  fetchedAlertTemplate = new CannedMessage(Gantt2ViewModel.DefaultAlertTemplate)
                  options['existingMessage'] = fetchedAlertTemplate

               # TODO: Add in project specific tokens like roles.
               tokens = Gantt2ViewModel.BaseAssignmentTokens
               pane1 = new CreateCannedMessagePane(null, projectId, "assignment-new", tokens, "Alert People", options)
               modal = new Modal()
               modal.setPanes([pane1])
               modalManager.showModal modal, null, {class: "create-canned-message-modal"}, (modal, exitStatus, observableData) =>
                  return if exitStatus == "cancelled"
                  fetchedAlertTemplate = new CannedMessage(observableData.data.template, true)

                  if alertContext == "open"
                     actionText = "Send"
                  else if alertContext == "drafts"
                     actionText = "Save"
                  else if alertContext == "scheduled"
                     actionText = "Schedule"

                  pane1 = new RecipientsListPane(peopleToMessage, actionText, defaultRecipients)
                  modal = new Modal()
                  modal.setPanes([pane1])
                  modalManager.showModal modal, null, {class: ""}, (modal, exitStatus, observableData) =>
                     return if exitStatus == "cancelled"
                     ignoreRecipientIds = observableData.data.removedRecipients.map (person) ->
                        return person.id

                     requestBody = {
                        context: alertContext
                        template: fetchedAlertTemplate.allToJson()
                        assignments_start_day: selectedAssignmentRange.startDay
                        assignments_end_day: selectedAssignmentRange.endDay
                        people_ids_to_ignore: ignoreRecipientIds or []
                        scheduled_day: scheduledDay or undefined
                        scheduled_time: scheduledTime or undefined
                        group_id: undefined
                        project_id: projectId
                     }

                     if authManager.selectedGroupId() != "my-groups"
                        requestBody["group_id"] = authManager.selectedGroupId()

                     try
                        payloadRequest = {
                           body: requestBody
                        }
                        await AlertStore.sendOneOffProjectAlerts(payloadRequest).payload
                     catch err
                        console.error "Error in sendOneOffProjectAlerts: ", err

            try
               alertTemplatePayload = await ProjectStore.getProjectAlertInfo(projectId, "assignment-new").payload
               if alertTemplatePayload.data.template?
                  fetchedAlertTemplate = new CannedMessage(alertTemplatePayload.data.template, true)
               else
                  fetchedAlertTemplate = null
            catch err
               console.error "Error: ", err
               Bugsnag.notify(err, (event) =>
                  event['context'] = "getProjectAlertInfo and create a CannedMessage object"
                  event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
                  event.addMetadata('payload', alertTemplatePayload)
               )
            finally
               modalManager.modalFinished()

   messagePeople: (projectId) =>
      explanationText = "Define which people on this project you would like to message."
      options = {}
      if @selectedRangeDays() > 1
         options['rangeEndDate'] = DateUtils.incrementDate(@viewStartDate(), (@selectedRangeDays() - 1))

      pane1 = new SelectPeopleByAssignmentPane(projectId, @viewStartDate(), "Message People", "Create Message", explanationText, options)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'message-people-by-assignments-modal'}, (modal, exitStatus, observableData) =>
         if exitStatus == "finished" and observableData.data.peopleToMessage? and observableData.data.peopleToMessage.length > 0

            pane1 = new CreateMessageOrAlertPane({}, 'Message People', observableData.data.peopleToMessage, false, null, null, false)
            modal.setPanes([pane1])
            modalManager.showModal(modal, null, {class: 'create-message-modal'})
   #endregion

   #region Formatting/post-processing helpers
   formatRequest: (placeholder) ->
      newRequest = {
         batchEndDay: placeholder.endDay() or null
         batchId: placeholder.id
         batchStartDay: placeholder.startDay() or null
         costCodeId: placeholder.costCodeId()
         costCodeName: placeholder.baggage().cost_code_name
         creatorId: placeholder.creatorId()
         end: DateUtils.getAttachedDate(placeholder.endDay() + 1)
         endDay: placeholder.endDay()
         groupIds: placeholder.groupIds()
         id: placeholder.id
         instructionText: if placeholder.instructionText()? then placeholder.instructionText() else null
         jobNumber: placeholder.baggage().job_number
         labelId: placeholder.labelId()
         labelName: placeholder.baggage().label_name
         positionColor: if placeholder.position()? then placeholder.position().color() else null
         positionId: if placeholder.position()?.id? then placeholder.position().id else null
         positionName: if placeholder.position()? then placeholder.position().name() else null
         positionRate: if placeholder.position()?.hourlyRate()? then placeholder.position().hourlyRate() else null
         positionSequence: if placeholder.position()? then placeholder.position().sequence() else null
         projectColor: placeholder.baggage().project_color
         projectId: placeholder.projectId()
         projectName: placeholder.baggage().project_name
         projectStatus: placeholder.baggage().project_status
         singleDay: null
         start: DateUtils.getAttachedDate(placeholder.startDay())
         startDay: placeholder.startDay()
         status: placeholder.status() or null
         statusId: placeholder.status()?.id or null
         statusName: if placeholder.status()? then placeholder.status().name() else null
         workDays: placeholder.workDays()
         workScopeText: if placeholder.workScopeText()? then placeholder.workScopeText() else null
      }

      if placeholder.startTime()? and placeholder.endTime()?
         newRequest['startTime'] = placeholder.startTime()
         newRequest['endTime'] = placeholder.endTime()
         newRequest['percentAllocated'] = null
      else
         newRequest['startTime'] = null
         newRequest['endTime'] = null
         newRequest['percentAllocated'] = placeholder.percentAllocated()

      return newRequest

   processProjectRows: (newRows, next) =>
      ### Compression Comparison Alg. Cases.
         __________________
         | Range          |
         ------------------
      __________    __________
      | Case A |    | Case B |
      ----------    ----------
            ___________
            | Case C  |
            -----------
      _________________________
      | Case D                |
      -------------------------
      ###

      unless @canViewProjectFinancials
         if @projectGanttViewConfig().totalsUnit == "cost"
            @projectGanttViewConfig().totalsUnit = "people"


      viewingStartDay = @viewStartDay()
      viewingEndDay = if @selectedRangeDays() == 1 then @viewStartDay() else DateUtils.incrementDetachedDay(@viewStartDay(), @selectedRangeDays() - 1)
      maxPaidHours = @costingConfig().paidShiftHours
      if @costingConfig()?.overtimeDayRates?
         defaultOvertimeRates = {
            "0": @costingConfig().overtimeDayRates.sunday
            "1": @costingConfig().overtimeDayRates.monday
            "2": @costingConfig().overtimeDayRates.tuesday
            "3": @costingConfig().overtimeDayRates.wednesday
            "4": @costingConfig().overtimeDayRates.thursday
            "5": @costingConfig().overtimeDayRates.friday
            "6": @costingConfig().overtimeDayRates.saturday
         }
      else
         defaultOvertimeRates = null

      pageLeadingProjects = 0
      pageLeadingCats = 0
      pageLeadingSubcats = 0
      pageLeadingAssBars = 0
      pageLeadingRequestCats = 0
      pageLeadingRequestSubcats = 0
      pageLeadingRequestBars = 0
      pageLeadingTotalsBars = 0
      pageLeadingRequestsHeaders = 0

      for project in newRows
         # Project Counts
         pLeadingCats = 0
         pLeadingSubcats = 0
         pLeadingAssBars = 0
         pLeadingRequestCats = 0
         pLeadingRequestSubcats = 0
         pLeadingRequestBars = 0


         project['leadingProjects'] = pageLeadingProjects
         project['leadingCats'] = pageLeadingCats
         project['leadingSubcats'] = pageLeadingSubcats
         project['leadingAssBars'] = pageLeadingAssBars
         project['leadingRequestCats'] = pageLeadingRequestCats
         project['leadingRequestSubcats'] = pageLeadingRequestSubcats
         project['leadingRequestBars'] = pageLeadingRequestBars
         project['leadingTotalsBars'] = pageLeadingTotalsBars
         project['leadingRequestsHeaders'] = pageLeadingRequestsHeaders

         groupedAssignments = []
         groupedRequests = []
         projectsDailyHours = {}
         projectsDailyHeads = {}
         projectsDailyCost = {}
         projectsRequestDailyHours = {}
         projectsRequestDailyHeads = {}
         projectsRequestDailyCost = {}

         # TODO: Clean up janky sorting solution.
         sortedCategories = []
         for categoryId, subcategories of project.groupedAssignments
            sortedCategories.push({
               categoryId: categoryId
               categorySequence: if categoryId? and categoryId != 'null'  then project.costCodeSequences[categoryId] else -1
               subcategories: subcategories
            })
            sortedCategories = sortedCategories.sort (a, b) ->
               return a.categorySequence - b.categorySequence

         for categrySet in sortedCategories
            categoryId = categrySet.categoryId
            subcategories = categrySet.subcategories
            # Category counts
            cLeadingSubcats = 0
            cLeadingAssBars = 0

            # TODO: This is sloppy, clean up.
            categoryId = null if categoryId == "null"
            assignmentGroup = {
               # TODO: Update this
               categoryName: if categoryId? then subcategories[0].peopleAssignments[0].assignments[0].costCodeName else null
               categoryId: categoryId
               categorySequence: categrySet.categorySequence
               leadingCats: pLeadingCats
               leadingSubcats: pLeadingSubcats
               leadingAssBars: pLeadingAssBars
            }

            subcategories = subcategories.sort (a, b) -> 
               aS = if a.labelId? and a.labelId != 'null' then project.labelSequences[a.labelId] else -1
               bS = if b.labelId? and b.labelId != 'null' then project.labelSequences[b.labelId] else -1
               return aS - bS

            for subcatSet in subcategories
               # Subcategory counts
               sLeadingAssBars = 0

               subcatSet['subcategoryName'] = if subcatSet.labelId? then subcatSet.peopleAssignments[0].assignments[0].labelName else null
               subcatSet['subcategorySequence'] = if subcatSet.labelId? then project.labelSequences[subcatSet.labelId] else 0
               subcatSet['subcategoryId'] = subcatSet.labelId or null
               subcatSet['leadingSubcats'] = cLeadingSubcats
               subcatSet['leadingAssBars'] = cLeadingAssBars

               # Sort
               if @projectGanttViewConfig().peopleSort == "name"
                  subcatSet.peopleAssignments = subcatSet.peopleAssignments.sort (a, b) =>
                     if @lastNamesFirst
                        aFull = "#{a.personName.last} #{a.personName.first}".toLowerCase()
                        bFull = "#{b.personName.last} #{b.personName.first}".toLowerCase()
                     else
                        aFull = "#{a.personName.first} #{a.personName.last}".toLowerCase()
                        bFull = "#{b.personName.first} #{b.personName.last}".toLowerCase()

                     if aFull < bFull
                        return -1
                     else if aFull > bFull
                        return 1
                     else
                        return 0
               else if @projectGanttViewConfig().peopleSort == "job-title"
                  subcatSet.peopleAssignments = subcatSet.peopleAssignments.sort (a, b) ->
                     aFull = if a.positionSequence? then a.positionSequence else 10000000
                     bFull = if b.positionSequence? then b.positionSequence else 10000000

                     return aFull - bFull
               else if @projectGanttViewConfig().peopleSort == "earliest-assignment"
                  subcatSet.peopleAssignments = subcatSet.peopleAssignments.sort (a, b) ->
                     earliestA = a.assignments.sort((assA, assB) ->
                        return assA.startDay - assB.startDay
                     )[0].startDay
                     earliestB = b.assignments.sort((assA, assB) ->
                        return assA.startDay - assB.startDay
                     )[0].startDay
                     return earliestA - earliestB

               for personSet in subcatSet.peopleAssignments
                  assTiers = {0: []}

                  personSet['leadingAssBars'] = sLeadingAssBars

                  # TODO: This could be optimized
                  if @selectedRangeDays() < 91
                     # Daily
                     for ass in personSet.assignments
                        workDays = ass.workDays
                        startDay = Number(ass.startDay)
                        endDay = Number(ass.endDay)

                        # Only care about data in viewing range
                        if startDay > viewingEndDay or endDay < viewingStartDay
                           continue
                        else if startDay < viewingStartDay
                           processingDay = viewingStartDay
                        else
                           processingDay = startDay

                        soonestEndDay = if endDay < viewingEndDay then endDay else viewingEndDay
                        while processingDay <= soonestEndDay
                           processingDate = DateUtils.getAttachedDate(processingDay)
                           weekDay = processingDate.getDay()

                           unless workDays[weekDay]
                              processingDay = DateUtils.incrementDetachedDay(processingDay)
                              continue

                           # TODO: This isn't accurate if they are double booked on the same job.
                           if projectsDailyHeads[processingDay]?
                              matchedDayData = projectsDailyHeads[processingDay]
                              if matchedDayData.peopleIds.indexOf(ass.resourceId) == -1
                                 matchedDayData.count++
                                 matchedDayData.peopleIds.push(ass.resourceId)
                           else
                              projectsDailyHeads[processingDay] = {count: 1, peopleIds: [ass.resourceId]}

                           if ass.percentAllocated?
                              assignmentHours = maxPaidHours * (ass.percentAllocated / 100)
                           else
                              assignmentHours = DateUtils.getDurationHours(ass.startTime, ass.endTime)
                              assignmentHours = Math.ceil(assignmentHours)

                           # if overtime is enabled but no pay-split field is found, log an error
                           if ass.overtime and !ass.pay_split and !ass.paySplit
                              console.error("overtime assignment does not have pay-split:", ass)
                              Bugsnag.notify("overtime assignment does not have pay-split", (event) =>
                                 event['context'] = "gantt-2.coffee__processProjectRows 1"
                                 event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
                                 event.addMetadata("Assignment", ass)
                              )

                           # even if overtime is enabled, make sure we have a pay-split before moving forward
                           if ass.overtime and defaultOvertimeRates? and (ass.pay_split or ass.paySplit)
                              ass.paySplit = ass.paySplit ? ass.pay_split ? null

                              assignmentHours = ass.paySplit.straight + ass.paySplit.overtime
                              if projectsDailyHours[processingDay]?
                                 projectsDailyHours[processingDay] += assignmentHours
                              else
                                 projectsDailyHours[processingDay] = assignmentHours

                              # Make sure projectsDailyCost[processingDay] is defined with a numeric value.
                              projectsDailyCost[processingDay] = projectsDailyCost[processingDay] || 0
                              hourlyRate = null
                              if project.wageOverrides? and project.wageOverrides.length > 0
                                 applicableOverrides = project.wageOverrides.filter (wage) =>
                                    wage.archived == false and wage.position_id == ass.positionId

                                 if applicableOverrides.length > 0
                                    hourlyRate = applicableOverrides[0].rate

                              if !hourlyRate? and (ass.hourlyWage? or ass.positionRate?)
                                 hourlyRate = if ass.hourlyWage? and ass.hourlyWage > 0 then ass.hourlyWage else ass.positionRate

                              if hourlyRate?
                                 workDay = String(DateUtils.getDetachedDayWorkday(processingDay))
                                 straightCost = ass.paySplit.straight * hourlyRate
                                 overtimeRateMultiplier = if ass.overtimeRates? then ass.overtimeRates[workDay] else defaultOvertimeRates[workDay]
                                 overtimeCost = (ass.paySplit.overtime * hourlyRate) * overtimeRateMultiplier
                                 totalCost = Math.ceil(straightCost + overtimeCost)

                                 projectsDailyCost[processingDay] += totalCost

                           else
                              # If we are using percent-allocated, do not clip the hours worked to maxPaidHours.
                              # We want the front-end user to see that the person has more than the max allocated.
                              assignmentHours = maxPaidHours if ass.percentAllocated == null && assignmentHours > maxPaidHours

                              if projectsDailyHours[processingDay]?
                                 projectsDailyHours[processingDay] += assignmentHours
                              else
                                 projectsDailyHours[processingDay] = assignmentHours

                              # Make sure projectsDailyCost[processingDay] is defined with a numeric value.
                              projectsDailyCost[processingDay] = projectsDailyCost[processingDay] || 0
                              hourlyRate = null
                              if project.wageOverrides? and project.wageOverrides.length > 0
                                 applicableOverrides = project.wageOverrides.filter (wage) =>
                                    wage.archived == false and wage.position_id == ass.positionId

                                 if applicableOverrides.length > 0
                                    hourlyRate = applicableOverrides[0].rate

                              if !hourlyRate? and (ass.hourlyWage? or ass.positionRate?)
                                 hourlyRate = if ass.hourlyWage? and ass.hourlyWage > 0 then ass.hourlyWage else ass.positionRate

                              if hourlyRate?
                                 projectsDailyCost[processingDay] += Math.ceil(assignmentHours * hourlyRate)

                           processingDay = DateUtils.incrementDetachedDay(processingDay)
                  else if @selectedRangeDays() < 182
                     # Weekly
                     for ass in personSet.assignments
                        workDays = ass.workDays
                        startDay = Number(ass.startDay)
                        endDay = Number(ass.endDay)

                        # Only care about data in viewing range
                        if startDay > viewingEndDay or endDay < viewingStartDay
                           continue
                        else if startDay < viewingStartDay
                           processingDay = viewingStartDay
                        else
                           processingDay = startDay

                        soonestEndDay = if endDay < viewingEndDay then endDay else viewingEndDay
                        while processingDay <= soonestEndDay
                           processingDate = DateUtils.getAttachedDate(processingDay)
                           weekDay = processingDate.getDay()

                           unless workDays[weekDay]
                              processingDay = DateUtils.incrementDetachedDay(processingDay)
                              continue

                           weekStartDate = DateUtils.getAttachedDate(processingDay)
                           weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
                           weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

                           # TODO: This isn't accurate if they are double booked on the same job.
                           if projectsDailyHeads[processingDay]?
                              matchedDayData = projectsDailyHeads[processingDay]
                              if matchedDayData.peopleIds.indexOf(ass.resourceId) == -1
                                 matchedDayData.count++
                                 matchedDayData.peopleIds.push(ass.resourceId)
                           else
                              projectsDailyHeads[processingDay] = {weekStart: weeksStartingDay, count: 1, peopleIds: [ass.resourceId]}

                           if ass.percentAllocated?
                              assignmentHours = maxPaidHours * (ass.percentAllocated / 100)
                           else
                              assignmentHours = DateUtils.getDurationHours(ass.startTime, ass.endTime)
                              assignmentHours = Math.ceil(assignmentHours)

                           # if overtime is enabled but no pay-split field is found, log an error
                           if ass.overtime and !ass.pay_split and !ass.paySplit
                              console.error("overtime assignment does not have pay-split:", ass)
                              Bugsnag.notify("overtime assignment does not have pay-split", (event) =>
                                 event['context'] = "gantt-2.coffee__processProjectRows 2"
                                 event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
                                 event.addMetadata("Assignment", ass)
                              )

                           # even if overtime is enabled, make sure we have a pay-split before moving forward
                           if ass.overtime and defaultOvertimeRates? and (ass.pay_split or ass.paySplit)
                              ass.paySplit = ass.paySplit ? ass.pay_split ? null

                              assignmentHours = ass.paySplit.straight + ass.paySplit.overtime
                              if projectsDailyHours[processingDay]?
                                 projectsDailyHours[processingDay].count += assignmentHours
                              else
                                 projectsDailyHours[processingDay] = {
                                    weekStart: weeksStartingDay,
                                    count: assignmentHours
                                 }

                              projectsDailyCost[processingDay] = projectsDailyCost[processingDay] || {weekStart: weeksStartingDay, count: 0}
                              hourlyRate = null
                              if project.wageOverrides? and project.wageOverrides.length > 0
                                 applicableOverrides = project.wageOverrides.filter (wage) =>
                                    wage.archived == false and wage.position_id == ass.positionId

                                 if applicableOverrides.length > 0
                                    hourlyRate = applicableOverrides[0].rate

                              if !hourlyRate? and (ass.hourlyWage? or ass.positionRate?)
                                 hourlyRate = if ass.hourlyWage? and ass.hourlyWage > 0 then ass.hourlyWage else ass.positionRate

                              if hourlyRate?
                                 workDay = String(DateUtils.getDetachedDayWorkday(processingDay))
                                 straightCost = ass.paySplit.straight * hourlyRate
                                 overtimeRateMultiplier = if ass.overtimeRates? then ass.overtimeRates[workDay] else defaultOvertimeRates[workDay]
                                 overtimeCost = (ass.paySplit.overtime * hourlyRate) * overtimeRateMultiplier
                                 totalCost = Math.ceil(straightCost + overtimeCost)

                                 projectsDailyCost[processingDay].count += totalCost

                           else
                              # If we are using percent-allocated, do not clip the hours worked to maxPaidHours.
                              # We want the front-end user to see that the person has more than the max allocated.
                              assignmentHours = maxPaidHours if ass.percentAllocated == null && assignmentHours > maxPaidHours

                              if projectsDailyHours[processingDay]?
                                 projectsDailyHours[processingDay].count += assignmentHours
                              else
                                 projectsDailyHours[processingDay] = {
                                    weekStart: weeksStartingDay,
                                    count: assignmentHours
                                 }
                              projectsDailyCost[processingDay] = projectsDailyCost[processingDay] || {weekStart: weeksStartingDay, count: 0}
                              hourlyRate = null
                              if project.wageOverrides? and project.wageOverrides.length > 0
                                 applicableOverrides = project.wageOverrides.filter (wage) =>
                                    wage.archived == false and wage.position_id == ass.positionId

                                 if applicableOverrides.length > 0
                                    hourlyRate = applicableOverrides[0].rate

                              if !hourlyRate? and (ass.hourlyWage? or ass.positionRate?)
                                 hourlyRate = if ass.hourlyWage? and ass.hourlyWage > 0 then ass.hourlyWage else ass.positionRate

                              if hourlyRate?
                                 projectsDailyCost[processingDay].count += (assignmentHours * hourlyRate)

                           processingDay = DateUtils.incrementDetachedDay(processingDay)
                  else
                     # Monthy
                     for ass in personSet.assignments
                        workDays = ass.workDays
                        startDay = Number(ass.startDay)
                        endDay = Number(ass.endDay)

                        # Only care about data in viewing range
                        if startDay > viewingEndDay or endDay < viewingStartDay
                           continue
                        else if startDay < viewingStartDay
                           processingDay = viewingStartDay
                        else
                           processingDay = startDay

                        soonestEndDay = if endDay < viewingEndDay then endDay else viewingEndDay
                        while processingDay <= soonestEndDay
                           processingDate = DateUtils.getAttachedDate(processingDay)
                           weekDay = processingDate.getDay()

                           unless workDays[weekDay]
                              processingDay = DateUtils.incrementDetachedDay(processingDay)
                              continue

                           # TODO: This isn't accurate if they are double booked on the same job.
                           if projectsDailyHeads[processingDay]?
                              matchedDayData = projectsDailyHeads[processingDay]
                              if matchedDayData.peopleIds.indexOf(ass.resourceId) == -1
                                 matchedDayData.count++
                                 matchedDayData.peopleIds.push(ass.resourceId)
                           else
                              projectsDailyHeads[processingDay] = {count: 1, peopleIds: [ass.resourceId]}

                           if ass.percentAllocated?
                              assignmentHours = maxPaidHours * (ass.percentAllocated / 100)
                           else
                              assignmentHours = DateUtils.getDurationHours(ass.startTime, ass.endTime)
                              assignmentHours = Math.ceil(assignmentHours)

                           # if overtime is enabled but no pay-split field is found, log an error
                           if ass.overtime and !ass.pay_split and !ass.paySplit
                              console.error("overtime assignment does not have pay-split:", ass)
                              Bugsnag.notify("overtime assignment does not have pay-split", (event) =>
                                 event['context'] = "gantt-2.coffee__processProjectRows 3"
                                 event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
                                 event.addMetadata("Assignment", ass)
                              )

                           # even if overtime is enabled, make sure we have a pay-split before moving forward
                           if ass.overtime and defaultOvertimeRates? and (ass.pay_split or ass.paySplit)
                              ass.paySplit = ass.paySplit ? ass.pay_split ? null

                              assignmentHours = ass.paySplit.straight + ass.paySplit.overtime
                              if projectsDailyHours[processingDay]?
                                 projectsDailyHours[processingDay] += assignmentHours
                              else
                                 projectsDailyHours[processingDay] = assignmentHours

                              # Make sure projectsDailyCost[processingDay] is defined with a numeric value.
                              projectsDailyCost[processingDay] = projectsDailyCost[processingDay] || 0
                              hourlyRate = null
                              if project.wageOverrides? and project.wageOverrides.length > 0
                                 applicableOverrides = project.wageOverrides.filter (wage) =>
                                    wage.archived == false and wage.position_id == ass.positionId

                                 if applicableOverrides.length > 0
                                    hourlyRate = applicableOverrides[0].rate

                              if !hourlyRate? and (ass.hourlyWage? or ass.positionRate?)
                                 hourlyRate = if ass.hourlyWage? and ass.hourlyWage > 0 then ass.hourlyWage else ass.positionRate

                              if hourlyRate?
                                 workDay = String(DateUtils.getDetachedDayWorkday(processingDay))
                                 straightCost = ass.paySplit.straight * hourlyRate
                                 overtimeRateMultiplier = if ass.overtimeRates? then ass.overtimeRates[workDay] else defaultOvertimeRates[workDay]
                                 overtimeCost = (ass.paySplit.overtime * hourlyRate) * overtimeRateMultiplier
                                 totalCost = Math.ceil(straightCost + overtimeCost)

                                 projectsDailyCost[processingDay] += totalCost

                           else
                              # If we are using percent-allocated, do not clip the hours worked to maxPaidHours.
                              # We want the front-end user to see that the person has more than the max allocated.
                              assignmentHours = maxPaidHours if ass.percentAllocated == null && assignmentHours > maxPaidHours

                              if projectsDailyHours[processingDay]?
                                 projectsDailyHours[processingDay] += assignmentHours
                              else
                                 projectsDailyHours[processingDay] = assignmentHours

                              # Make sure projectsDailyCost[processingDay] is defined with a numeric value.
                              projectsDailyCost[processingDay] = projectsDailyCost[processingDay] || 0
                              hourlyRate = null
                              if project.wageOverrides? and project.wageOverrides.length > 0
                                 applicableOverrides = project.wageOverrides.filter (wage) =>
                                    wage.archived == false and wage.position_id == ass.positionId

                                 if applicableOverrides.length > 0
                                    hourlyRate = applicableOverrides[0].rate

                              if !hourlyRate? and (ass.hourlyWage? or ass.positionRate?)
                                 hourlyRate = if ass.hourlyWage? and ass.hourlyWage > 0 then ass.hourlyWage else ass.positionRate

                              if hourlyRate?
                                 projectsDailyCost[processingDay] += Math.ceil(assignmentHours * hourlyRate)

                           processingDay = DateUtils.incrementDetachedDay(processingDay)

                  # Need to check if the person has any self-conflicting assignments.
                  if personSet.assignments.length > 1
                     if @selectedRangeDays() == 1
                        for ass in personSet.assignments
                           foundClash = false

                           # Check if assignment is overnight
                           if ass.startTime > ass.endTime
                              # Sanitize s/e times for comparison.
                              if ass.singleDay < @viewStartDay()
                                 # This was trailing from day before.
                                 startTime = 0
                                 endTime = ass.endTime
                              else
                                 startTime = ass.startTime
                                 endTime = 23.99
                           else
                              startTime = ass.startTime
                              endTime = ass.endTime

                           for key, value of assTiers
                              key = Number(key)
                              foundClash = false
                              if value.length == 0
                                 value.push({s: ass.startTime, e: ass.endTime, singleDay: ass.singleDay})
                                 ass['leadingAssBars'] = key
                                 break

                              for range in value
                                 # Check if the range is overnight.
                                 if range.s > range.e
                                    if range.singleDay < @viewStartDay()
                                       # This was trailing from day before.
                                       rangeStart = 0
                                       rangeEnd = range.e
                                    else
                                       rangeStart = range.s
                                       rangeEnd = 23.99
                                 else
                                    rangeStart = range.s
                                    rangeEnd = range.e

                                 # Case B & C
                                 if ((startTime >= rangeStart and startTime < rangeEnd) or
                                 # Case A
                                 (endTime > rangeStart and endTime <= rangeEnd) or
                                 # Case D
                                 (startTime <= rangeStart and endTime >= rangeEnd))
                                    foundClash = true
                                    break

                              # check breaks
                              unless foundClash
                                 value.push({s: ass.startTime, e: ass.endTime, singleDay: ass.singleDay})
                                 ass['leadingAssBars'] = key
                                 break

                              # If this is the last tier, we need another
                              if Object.keys(assTiers).length - 1 == key
                                 newTier = key + 1
                                 assTiers[newTier] = [{s: ass.startTime, e: ass.endTime, singleDay: ass.singleDay}]
                                 ass['leadingAssBars'] = newTier
                                 break

                     else
                        for ass in personSet.assignments
                           foundClash = false
                           for key, value of assTiers
                              key = Number(key)
                              foundClash = false
                              if value.length == 0
                                 value.push({s: ass.startDay, e: ass.endDay})
                                 ass['leadingAssBars'] = key
                                 break

                              for range in value
                                 # With this, we have to account for the fact that we will increment end days
                                 # by 1 always for d3's scale to work properly.
                                 if ((ass.startDay >= range.s and ass.startDay < range.e + 1) or
                                 (ass.endDay >= range.s and ass.endDay <= range.e) or
                                 (ass.startDay <= range.s and ass.endDay >= range.e))
                                    foundClash = true
                                    break

                              # check breaks
                              unless foundClash
                                 value.push({s: ass.startDay, e: ass.endDay})
                                 ass['leadingAssBars'] = key
                                 break

                              # If this is the last tier, we need another
                              if Object.keys(assTiers).length - 1 == key
                                 newTier = key + 1
                                 assTiers[newTier] = [{s: ass.startDay, e: ass.endDay}]
                                 ass['leadingAssBars'] = newTier
                                 break

                     assTierCount = Object.keys(assTiers).length
                     sLeadingAssBars += assTierCount
                     personSet['containedAssBars'] = assTierCount
                  else
                     personSet.assignments[0]['leadingAssBars'] = 0
                     personSet['containedAssBars'] = 1
                     sLeadingAssBars++

               subcatSet['containedAssBars'] = sLeadingAssBars

               cLeadingSubcats++ if subcatSet.subcategoryName?
               cLeadingAssBars += sLeadingAssBars

            assignmentGroup['subcategories'] = subcategories
            groupedAssignments.push(assignmentGroup)

            if categoryId?
               pLeadingCats++
            pLeadingSubcats += cLeadingSubcats
            pLeadingAssBars += cLeadingAssBars


         sortedRequestCategories = []
         for categoryId, subcategories of project.groupedRequests
            sortedRequestCategories.push({
               categoryId: categoryId
               categorySequence: if categoryId? and categoryId != 'null' then project.costCodeSequences[categoryId] else -1
               subcategories: subcategories
            })

         sortedRequestCategories = sortedRequestCategories.sort (a, b) ->
            return a.categorySequence - b.categorySequence

         for categrySet in sortedRequestCategories
            categoryId = categrySet.categoryId
            subcategories = categrySet.subcategories

            # Category counts
            cLeadingRequestSubcats = 0
            cLeadingRequestAssBars = 0

            categoryId = null if categoryId == "null"
            requestGroup = {
               # TODO: Update this
               categoryName: if categoryId? && subcategories?[0]?.requests?[0] then subcategories[0].requests[0].costCodeName else null
               categoryId: categoryId
               categorySequence: categrySet.categorySequence
               leadingCats: pLeadingRequestCats
               leadingSubcats: pLeadingRequestSubcats
               leadingAssBars: pLeadingRequestBars
            }

            subcategories = subcategories.sort (a, b) ->
               aS = if a.labelId? and a.labelId != 'null' then project.labelSequences[a.labelId] else -1
               bS = if b.labelId? and b.labelId != 'null' then project.labelSequences[b.labelId] else -1
               return aS - bS

            for subcatSet in subcategories
               # Subcategory counts
               sLeadingRequestAssBars = 0

               subcatSet['subcategoryName'] = if subcatSet.labelId? && subcatSet.requests?[0] then subcatSet.requests[0].labelName else null
               subcatSet['subcategorySequence'] = if subcatSet.labelId? then project.labelSequences[subcatSet.labelId] else 0
               subcatSet['subcategoryId'] = subcatSet.labelId or null
               subcatSet['leadingSubcats'] = cLeadingRequestSubcats
               subcatSet['leadingAssBars'] = cLeadingRequestAssBars

               # Sort
               if @projectGanttViewConfig().peopleSort == "earliest-assignment"
                  subcatSet.requests = subcatSet.requests.sort((a, b) -> a.startDay - b.startDay)
               else
                  # Default to sorting by the job title since 'name' doesn't apply to requests.
                  subcatSet.requests = subcatSet.requests.sort (a, b) ->
                     return a.positionSequence - b.positionSequence

               # for request in project.requests
               for request in subcatSet.requests
                  request['leadingRequestsBars'] = sLeadingRequestAssBars
                  sLeadingRequestAssBars++

                  if @selectedRangeDays() < 91
                     # Daily
                     workDays = request.workDays
                     startDay = Number(request.startDay)
                     endDay = Number(request.endDay)

                     # Only care about data in viewing range
                     if startDay > viewingEndDay or endDay < viewingStartDay
                        continue
                     else if startDay < viewingStartDay
                        processingDay = viewingStartDay
                     else
                        processingDay = startDay

                     soonestEndDay = if endDay < viewingEndDay then endDay else viewingEndDay
                     while processingDay <= soonestEndDay
                        processingDate = DateUtils.getAttachedDate(processingDay)
                        weekDay = processingDate.getDay()

                        unless workDays[weekDay]
                           processingDay = DateUtils.incrementDetachedDay(processingDay)
                           continue

                        # TODO: This isn't accurate if they are double booked on the same job.
                        if projectsRequestDailyHeads[processingDay]?
                           matchedDayData = projectsRequestDailyHeads[processingDay]
                           if matchedDayData.requestIds.indexOf(request.id) == -1
                              matchedDayData.count++
                              matchedDayData.requestIds.push(request.id)
                        else
                           projectsRequestDailyHeads[processingDay] = {count: 1, requestIds: [request.id]}

                        # TODO: Account for overtime here if specified.
                        if request.percentAllocated?
                           requestHours = maxPaidHours * (request.percentAllocated / 100)
                        else
                           requestHours = DateUtils.getDurationHours(request.startTime, request.endTime)
                           requestHours = Math.ceil(requestHours)
                           requestHours = maxPaidHours if requestHours > maxPaidHours

                        if projectsRequestDailyHours[processingDay]?
                           projectsRequestDailyHours[processingDay] += requestHours
                        else
                           projectsRequestDailyHours[processingDay] = requestHours

                        # Make sure projectsRequestDailyCost[processingDay] is defined with a numeric value.
                        projectsRequestDailyCost[processingDay] = projectsRequestDailyCost[processingDay] || 0
                        hourlyRate = null
                        if project.wageOverrides? and project.wageOverrides.length > 0
                           applicableOverrides = project.wageOverrides.filter (wage) =>
                              wage.archived == false and wage.position_id == request.positionId

                           if applicableOverrides.length > 0
                              hourlyRate = applicableOverrides[0].rate

                        if !hourlyRate? and request.positionRate?
                           hourlyRate = request.positionRate

                        if hourlyRate?
                           projectsRequestDailyCost[processingDay] += (requestHours * hourlyRate)

                        processingDay = DateUtils.incrementDetachedDay(processingDay)

                  else if @selectedRangeDays() < 182
                     # Weekly
                     workDays = request.workDays
                     startDay = Number(request.startDay)
                     endDay = Number(request.endDay)

                     # Only care about data in viewing range
                     if startDay > viewingEndDay or endDay < viewingStartDay
                        continue
                     else if startDay < viewingStartDay
                        processingDay = viewingStartDay
                     else
                        processingDay = startDay

                     soonestEndDay = if endDay < viewingEndDay then endDay else viewingEndDay
                     while processingDay <= soonestEndDay
                        processingDate = DateUtils.getAttachedDate(processingDay)
                        weekDay = processingDate.getDay()

                        unless workDays[weekDay]
                           processingDay = DateUtils.incrementDetachedDay(processingDay)
                           continue

                        weekStartDate = DateUtils.getAttachedDate(processingDay)
                        weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
                        weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

                        # TODO: This isn't accurate if they are double booked on the same job.
                        if projectsRequestDailyHeads[processingDay]?
                           matchedDayData = projectsRequestDailyHeads[processingDay]
                           if matchedDayData.requestIds.indexOf(request.id) == -1
                              matchedDayData.count++
                              matchedDayData.requestIds.push(request.id)
                        else
                           projectsRequestDailyHeads[processingDay] = {weekStart: weeksStartingDay, count: 1, requestIds: [request.id]}

                        # TODO: Account for overtime here if specified.
                        if request.percentAllocated?
                           requestHours = maxPaidHours * (request.percentAllocated / 100)
                        else
                           requestHours = DateUtils.getDurationHours(request.startTime, request.endTime)
                           requestHours = Math.ceil(requestHours)
                           requestHours = maxPaidHours if requestHours > maxPaidHours

                        if projectsRequestDailyHours[processingDay]?
                           projectsRequestDailyHours[processingDay].count += requestHours
                        else
                           projectsRequestDailyHours[processingDay] = {
                              weekStart: weeksStartingDay,
                              count: requestHours
                           }

                        projectsRequestDailyCost[processingDay] = projectsRequestDailyCost[processingDay] || {weekStart: weeksStartingDay, count: 0}
                        hourlyRate = null
                        if project.wageOverrides? and project.wageOverrides.length > 0
                           applicableOverrides = project.wageOverrides.filter (wage) =>
                              wage.archived == false and wage.position_id == request.positionId
                           if applicableOverrides.length > 0
                              hourlyRate = applicableOverrides[0].rate

                        if !hourlyRate? and request.positionRate?
                           hourlyRate = request.positionRate

                        if hourlyRate?
                           projectsRequestDailyCost[processingDay].count += (requestHours * hourlyRate)

                        processingDay = DateUtils.incrementDetachedDay(processingDay)

                  else
                     # Monthly
                     workDays = request.workDays
                     startDay = Number(request.startDay)
                     endDay = Number(request.endDay)

                     # Only care about data in viewing range
                     if startDay > viewingEndDay or endDay < viewingStartDay
                        continue
                     else if startDay < viewingStartDay
                        processingDay = viewingStartDay
                     else
                        processingDay = startDay

                     soonestEndDay = if endDay < viewingEndDay then endDay else viewingEndDay
                     while processingDay <= soonestEndDay
                        processingDate = DateUtils.getAttachedDate(processingDay)
                        weekDay = processingDate.getDay()

                        unless workDays[weekDay]
                           processingDay = DateUtils.incrementDetachedDay(processingDay)
                           continue

                        # TODO: This isn't accurate if they are double booked on the same job.
                        if projectsRequestDailyHeads[processingDay]?
                           matchedDayData = projectsRequestDailyHeads[processingDay]
                           if matchedDayData.requestIds.indexOf(request.id) == -1
                              matchedDayData.count++
                              matchedDayData.requestIds.push(request.id)
                        else
                           projectsRequestDailyHeads[processingDay] = {count: 1, requestIds: [request.id]}

                        # TODO: Account for overtime here if specified.
                        if request.percentAllocated?
                           requestHours = maxPaidHours * (request.percentAllocated / 100)
                        else
                           requestHours = DateUtils.getDurationHours(request.startTime, request.endTime)
                           requestHours = Math.ceil(requestHours)
                           requestHours = maxPaidHours if requestHours > maxPaidHours

                        if projectsRequestDailyHours[processingDay]?
                           projectsRequestDailyHours[processingDay] += requestHours
                        else
                           projectsRequestDailyHours[processingDay] = requestHours

                        # Make sure projectsRequestDailyCost[processingDay] is defined with a numeric value.
                        projectsRequestDailyCost[processingDay] = projectsRequestDailyCost[processingDay] || 0
                        hourlyRate = null
                        if project.wageOverrides? and project.wageOverrides.length > 0
                           applicableOverrides = project.wageOverrides.filter (wage) =>
                              wage.archived == false and wage.position_id == request.positionId

                           if applicableOverrides.length > 0
                              hourlyRate = applicableOverrides[0].rate

                        if !hourlyRate? and request.positionRate?
                           hourlyRate = request.positionRate

                        if hourlyRate?
                           projectsRequestDailyCost[processingDay] += (requestHours * hourlyRate)

                        processingDay = DateUtils.incrementDetachedDay(processingDay)


               cLeadingRequestSubcats++ if subcatSet.subcategoryName?
               cLeadingRequestAssBars += sLeadingRequestAssBars

            requestGroup['subcategories'] = subcategories
            groupedRequests.push(requestGroup)

            if categoryId?
               pLeadingRequestCats++
            pLeadingRequestSubcats += cLeadingRequestSubcats
            pLeadingRequestBars += cLeadingRequestAssBars

         project['groupedAssignments'] = groupedAssignments
         project['groupedRequests'] = groupedRequests

         project['containedCats'] = pLeadingCats
         project['containedSubcats'] = pLeadingSubcats
         project['containedAssBars'] = pLeadingAssBars
         project['containedRequestCats'] = pLeadingRequestCats
         project['containedRequestSubcats'] = pLeadingRequestSubcats
         project['containedRequestBars'] = pLeadingRequestBars
         project['projectsDailyHours'] = projectsDailyHours
         project['projectsDailyHeads'] = projectsDailyHeads
         project['projectsDailyCost'] = projectsDailyCost
         project['projectsRequestDailyHours'] = projectsRequestDailyHours
         project['projectsRequestDailyHeads'] = projectsRequestDailyHeads
         project['projectsRequestDailyCost'] = projectsRequestDailyCost

         pageLeadingProjects++
         pageLeadingCats += pLeadingCats
         pageLeadingSubcats += pLeadingSubcats
         pageLeadingAssBars += pLeadingAssBars
         pageLeadingRequestCats += pLeadingRequestCats
         pageLeadingRequestSubcats += pLeadingRequestSubcats
         pageLeadingRequestBars += pLeadingRequestBars
         pageLeadingTotalsBars++ if pLeadingAssBars > 0
         if pLeadingRequestBars > 0
            # Don't want to count totalbars since that will be contained in request header.
            pageLeadingRequestsHeaders++
      return next(newRows)

   processPeopleRows: (newRows, next) =>
      ### Compression Comparison Alg. Cases.
         __________________
         | Range          |
         ------------------
      __________    __________
      | Case A |    | Case B |
      ----------    ----------
            ___________
            | Case C  |
            -----------
      _________________________
      | Case D                |
      -------------------------   
      ###

      viewingStartDay = @viewStartDay()
      viewingEndDay = if @selectedRangeDays() == 1 then @viewStartDay() else DateUtils.incrementDetachedDay(@viewStartDay(), @selectedRangeDays() - 1)
      maxPaidHours = @costingConfig().paidShiftHours
      if @costingConfig()?.overtimeDayRates?
         defaultOvertimeRates = {
            "0": @costingConfig().overtimeDayRates.sunday
            "1": @costingConfig().overtimeDayRates.monday
            "2": @costingConfig().overtimeDayRates.tuesday
            "3": @costingConfig().overtimeDayRates.wednesday
            "4": @costingConfig().overtimeDayRates.thursday
            "5": @costingConfig().overtimeDayRates.friday
            "6": @costingConfig().overtimeDayRates.saturday
         }
      else
         defaultOvertimeRates = null

      pageLeadingPeople = 0
      pageLeadingPeopleAssBars = 0
      pageLeadingPeopleToBars = 0

      for person in newRows
         # Person Counts
         peLeadingAssBars = 0
         peLeadingToBars = 0

         person['leadingPeople'] = pageLeadingPeople
         person['leadingAssBars'] = pageLeadingPeopleAssBars
         person['leadingToBars'] = pageLeadingPeopleToBars

         personsDailyHours = {}
         personsDailyCost = {}

         # Timeoff.
         workingToTier = 0
         for to in person.timeoff
            to['tier'] = workingToTier
            workingToTier++

         toTierCount = workingToTier

         peLeadingToBars += toTierCount

         # Sort Projects
         person.projects = person.projects.sort (a, b) =>
            if @peopleGanttViewConfig().projectSort == "name"
               return if a.projectName > b.projectName then 1 else -1
            else if @peopleGanttViewConfig().projectSort == "job-number"
               aNumber = a.jobNumber or "zzzzzzzzzzzz"
               bNumber = b.jobNumber or "zzzzzzzzzzzz"
               return if aNumber > bNumber then 1 else -1
            else if @peopleGanttViewConfig().projectSort == "earliest-start-date"
               aDate = a.projectStartDate or Number.MAX_VALUE
               bDate = b.projectStartDate or Number.MAX_VALUE
               return if aDate > bDate then 1 else -1
            else if @peopleGanttViewConfig().projectSort == "earliest-end-date"
               aDate = a.projectEndDate or Number.MAX_VALUE
               bDate = b.projectEndDate or Number.MAX_VALUE
               return if aDate > bDate then 1 else -1
            else if @peopleGanttViewConfig().projectSort == "earliest-assignment"
               earliestA = a.assignments.sort((assA, assB) ->
                  return assA.startDay - assB.startDay
               )[0].startDay
               earliestB = b.assignments.sort((assA, assB) ->
                  return assA.startDay - assB.startDay
               )[0].startDay
               return earliestA - earliestB

         for project in person.projects
            project['leadingToBars'] = peLeadingToBars
            project['leadingAssBars'] = peLeadingAssBars

            assTiers = {0: []}

            # TODO: This could be optimized
            if @selectedRangeDays() < 91
               # Daily
               for ass in project.assignments
                  workDays = ass.workDays
                  startDay = Number(ass.startDay)
                  endDay = Number(ass.endDay)

                  # Only care about data in viewing range
                  if startDay > viewingEndDay or endDay < viewingStartDay
                     continue
                  else if startDay < viewingStartDay
                     processingDay = viewingStartDay
                  else
                     processingDay = startDay

                  soonestEndDay = if endDay < viewingEndDay then endDay else viewingEndDay
                  while processingDay <= soonestEndDay
                     processingDate = DateUtils.getAttachedDate(processingDay)
                     weekDay = processingDate.getDay()

                     unless workDays[weekDay]
                        processingDay = DateUtils.incrementDetachedDay(processingDay)
                        continue

                     if ass.percentAllocated?
                        assignmentHours = maxPaidHours * (ass.percentAllocated / 100)
                     else
                        assignmentHours = DateUtils.getDurationHours(ass.startTime, ass.endTime)
                        assignmentHours = Math.ceil(assignmentHours)

                     hourlyRate = null
                     if ass.wageOverrides? and ass.wageOverrides.length > 0
                        applicableOverrides = ass.wageOverrides.filter (wage) =>
                           wage.archived == false and wage.position_id == person.positionId

                        if applicableOverrides.length > 0
                           hourlyRate = applicableOverrides[0].rate

                     if !hourlyRate? and (ass.hourlyWage? and ass.hourlyWage > 0)
                        hourlyRate = ass.hourlyWage
                     if !hourlyRate? and (ass.positionRate? and ass.positionRate > 0)
                        hourlyRate = ass.positionRate

                     # if overtime is enabled but no pay-split field is found, log an error
                     if ass.overtime and !ass.pay_split and !ass.paySplit
                        console.error("overtime assignment does not have pay-split:", ass)
                        Bugsnag.notify("overtime assignment does not have pay-split", (event) =>
                           event['context'] = "gantt-2.coffee__processPeopleRows 1"
                           event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
                           event.addMetadata("Assignment", ass)
                        )

                     # even if overtime is enabled, make sure we have a pay-split before moving forward
                     if ass.overtime and defaultOvertimeRates? and (ass.pay_split or ass.paySplit)
                        ass.paySplit = ass.paySplit ? ass.pay_split ? null

                        assignmentHours = ass.paySplit.straight + ass.paySplit.overtime
                        if personsDailyHours[processingDay]?
                           personsDailyHours[processingDay] += assignmentHours
                        else
                           personsDailyHours[processingDay] = assignmentHours

                        if hourlyRate?
                           workDay = String(DateUtils.getDetachedDayWorkday(processingDay))
                           straightCost = ass.paySplit.straight * hourlyRate
                           overtimeRateMultiplier = if ass.overtimeRates? then ass.overtimeRates[workDay] else defaultOvertimeRates[workDay]
                           overtimeCost = (ass.paySplit.overtime * hourlyRate) * overtimeRateMultiplier
                           totalCost = Math.ceil(straightCost + overtimeCost)

                           if personsDailyCost[processingDay]?
                              personsDailyCost[processingDay] += totalCost
                           else
                              personsDailyCost[processingDay] = totalCost
                     else
                        # If we are using percent-allocated, do not clip the hours worked to maxPaidHours.
                        # We want the front-end user to see that the person has more than the max allocated.
                        assignmentHours = maxPaidHours if ass.percentAllocated == null && assignmentHours > maxPaidHours

                        if personsDailyHours[processingDay]?
                           personsDailyHours[processingDay] += assignmentHours
                        else
                           personsDailyHours[processingDay] = assignmentHours

                        if hourlyRate?
                           if personsDailyCost[processingDay]?
                              personsDailyCost[processingDay] += Math.ceil(assignmentHours * hourlyRate)
                           else
                              personsDailyCost[processingDay] = Math.ceil(assignmentHours * hourlyRate)

                     processingDay = DateUtils.incrementDetachedDay(processingDay)

            else if @selectedRangeDays() < 730
               # Weekly & Monthly for people.
               for ass in project.assignments
                  workDays = ass.workDays
                  startDay = Number(ass.startDay)
                  endDay = Number(ass.endDay)

                  # Only care about data in viewing range
                  if startDay > viewingEndDay or endDay < viewingStartDay
                     continue
                  else if startDay < viewingStartDay
                     processingDay = viewingStartDay
                  else
                     processingDay = startDay

                  soonestEndDay = if endDay < viewingEndDay then endDay else viewingEndDay
                  while processingDay <= soonestEndDay
                     processingDate = DateUtils.getAttachedDate(processingDay)
                     weekDay = processingDate.getDay()

                     unless workDays[weekDay]
                        processingDay = DateUtils.incrementDetachedDay(processingDay)
                        continue

                     weekStartDate = DateUtils.getAttachedDate(processingDay)
                     weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
                     weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

                     if ass.percentAllocated?
                        assignmentHours = maxPaidHours * (ass.percentAllocated / 100)
                     else
                        assignmentHours = DateUtils.getDurationHours(ass.startTime, ass.endTime)
                        assignmentHours = Math.ceil(assignmentHours)

                     hourlyRate = null
                     if ass.wageOverrides? and ass.wageOverrides.length > 0
                        applicableOverrides = ass.wageOverrides.filter (wage) =>
                           wage.archived == false and wage.position_id == person.positionId

                        if applicableOverrides.length > 0
                           hourlyRate = applicableOverrides[0].rate

                     if !hourlyRate? and (ass.hourlyWage? and ass.hourlyWage > 0)
                        hourlyRate = ass.hourlyWage
                     if !hourlyRate? and (ass.positionRate? and ass.positionRate > 0)
                        hourlyRate = ass.positionRate

                     # if overtime is enabled but no pay-split field is found, log an error
                     if ass.overtime and !ass.pay_split and !ass.paySplit
                        console.error("overtime assignment does not have pay-split:", ass)
                        Bugsnag.notify("overtime assignment does not have pay-split", (event) =>
                           event['context'] = "gantt-2.coffee__processPeopleRows 2"
                           event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
                           event.addMetadata("Assignment", ass)
                        )

                     # even if overtime is enabled, make sure we have a pay-split before moving forward
                     if ass.overtime and defaultOvertimeRates? and (ass.pay_split or ass.paySplit)
                        ass.paySplit = ass.paySplit ? ass.pay_split ? null

                        assignmentHours = ass.paySplit.straight + ass.paySplit.overtime
                        if personsDailyHours[processingDay]?
                           personsDailyHours[processingDay].count += assignmentHours
                        else
                           personsDailyHours[processingDay] = {
                              weekStart: weeksStartingDay,
                              count: assignmentHours
                           }

                        if hourlyRate?
                           workDay = String(DateUtils.getDetachedDayWorkday(processingDay))
                           straightCost = ass.paySplit.straight * hourlyRate
                           overtimeRateMultiplier = if ass.overtimeRates? then ass.overtimeRates[workDay] else defaultOvertimeRates[workDay]
                           overtimeCost = (ass.paySplit.overtime * hourlyRate) * overtimeRateMultiplier
                           totalCost = Math.ceil(straightCost + overtimeCost)

                           if personsDailyCost[processingDay]?
                              personsDailyCost[processingDay].count += totalCost
                           else
                              personsDailyCost[processingDay] = {
                                 weekStart: weeksStartingDay,
                                 count: totalCost
                              }
                     else
                        # If we are using percent-allocated, do not clip the hours worked to maxPaidHours.
                        # We want the front-end user to see that the person has more than the max allocated.
                        assignmentHours = maxPaidHours if ass.percentAllocated == null && assignmentHours > maxPaidHours

                        if personsDailyHours[processingDay]?
                           personsDailyHours[processingDay].count += assignmentHours
                        else
                           personsDailyHours[processingDay] = {
                              weekStart: weeksStartingDay,
                              count: assignmentHours
                           }

                        if hourlyRate?
                           if personsDailyCost[processingDay]?
                              personsDailyCost[processingDay].count += (assignmentHours * hourlyRate)
                           else
                              personsDailyCost[processingDay] = {
                                 weekStart: weeksStartingDay,
                                 count: (assignmentHours * hourlyRate)
                              }

                     processingDay = DateUtils.incrementDetachedDay(processingDay)
            else
               # Quarterly for people.
               for ass in project.assignments
                  workDays = ass.workDays
                  startDay = Number(ass.startDay)
                  endDay = Number(ass.endDay)

                  # Only care about data in viewing range
                  if startDay > viewingEndDay or endDay < viewingStartDay
                     continue
                  else if startDay < viewingStartDay
                     processingDay = viewingStartDay
                  else
                     processingDay = startDay

                  soonestEndDay = if endDay < viewingEndDay then endDay else viewingEndDay
                  while processingDay <= soonestEndDay
                     processingDate = DateUtils.getAttachedDate(processingDay)
                     weekDay = processingDate.getDay()

                     unless workDays[weekDay]
                        processingDay = DateUtils.incrementDetachedDay(processingDay)
                        continue

                     # weekStartDate = DateUtils.getAttachedDate(processingDay)
                     # weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
                     # weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

                     if ass.percentAllocated?
                        assignmentHours = maxPaidHours * (ass.percentAllocated / 100)
                     else
                        assignmentHours = DateUtils.getDurationHours(ass.startTime, ass.endTime)
                        assignmentHours = Math.ceil(assignmentHours)

                     hourlyRate = null
                     if ass.wageOverrides? and ass.wageOverrides.length > 0
                        applicableOverrides = ass.wageOverrides.filter (wage) =>
                           wage.archived == false and wage.position_id == person.positionId

                        if applicableOverrides.length > 0
                           hourlyRate = applicableOverrides[0].rate

                     if !hourlyRate? and (ass.hourlyWage? and ass.hourlyWage > 0)
                        hourlyRate = ass.hourlyWage
                     if !hourlyRate? and (ass.positionRate? and ass.positionRate > 0)
                        hourlyRate = ass.positionRate

                     # if overtime is enabled but no pay-split field is found, log an error
                     if ass.overtime and !ass.pay_split and !ass.paySplit
                        console.error("overtime assignment does not have pay-split:", ass)
                        Bugsnag.notify("overtime assignment does not have pay-split", (event) =>
                           event['context'] = "gantt-2.coffee__processPeopleRows 3"
                           event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
                           event.addMetadata("Assignment", ass)
                        )

                     # even if overtime is enabled, make sure we have a pay-split before moving forward
                     if ass.overtime and defaultOvertimeRates? and (ass.pay_split or ass.paySplit)
                        ass.paySplit = ass.paySplit ? ass.pay_split ? null

                        assignmentHours = ass.paySplit.straight + ass.paySplit.overtime
                        if personsDailyHours[processingDay]?
                           personsDailyHours[processingDay] += assignmentHours
                        else
                           personsDailyHours[processingDay] = assignmentHours

                        if hourlyRate?
                           workDay = String(DateUtils.getDetachedDayWorkday(processingDay))
                           straightCost = ass.paySplit.straight * hourlyRate
                           overtimeRateMultiplier = if ass.overtimeRates? then ass.overtimeRates[workDay] else defaultOvertimeRates[workDay]
                           overtimeCost = (ass.paySplit.overtime * hourlyRate) * overtimeRateMultiplier
                           totalCost = Math.ceil(straightCost + overtimeCost)

                           if personsDailyCost[processingDay]?
                              personsDailyCost[processingDay]+= totalCost
                           else
                              personsDailyCost[processingDay] = totalCost
                     else
                        # If we are using percent-allocated, do not clip the hours worked to maxPaidHours.
                        # We want the front-end user to see that the person has more than the max allocated.
                        assignmentHours = maxPaidHours if ass.percentAllocated == null && assignmentHours > maxPaidHours

                        if personsDailyHours[processingDay]?
                           personsDailyHours[processingDay] += assignmentHours
                        else
                           personsDailyHours[processingDay] = assignmentHours

                        if hourlyRate?
                           if personsDailyCost[processingDay]?
                              personsDailyCost[processingDay] += (assignmentHours * hourlyRate)
                           else
                              personsDailyCost[processingDay] = (assignmentHours * hourlyRate)

                     processingDay = DateUtils.incrementDetachedDay(processingDay)
            # Need to check if the person has any self-conflicting assignments.
            if project.assignments.length > 1
               if @selectedRangeDays() == 1
                  for ass in project.assignments
                     foundClash = false

                     # Check if assignment is overnight
                     if ass.startTime > ass.endTime
                        # Sanitize s/e times for comparison.
                        if ass.singleDay < @viewStartDay()
                           # This was trailing from day before.
                           startTime = 0
                           endTime = ass.endTime
                        else
                           startTime = ass.startTime
                           endTime = 23.99
                     else
                        startTime = ass.startTime
                        endTime = ass.endTime

                     for key, value of assTiers
                        key = Number(key)
                        foundClash = false
                        if value.length == 0
                           value.push({s: ass.startTime, e: ass.endTime, singleDay: ass.singleDay})
                           ass['leadingAssBars'] = key
                           break

                        for range in value
                           # Check if the range is overnight.
                           if range.s > range.e
                              if range.singleDay < @viewStartDay()
                                 # This was trailing from day before.
                                 rangeStart = 0
                                 rangeEnd = range.e
                              else
                                 rangeStart = range.s
                                 rangeEnd = 23.99
                           else
                              rangeStart = range.s
                              rangeEnd = range.e

                           # Case B & C
                           if ((startTime >= rangeStart and startTime < rangeEnd) or
                           # Case A
                           (endTime > rangeStart and endTime <= rangeEnd) or
                           # Case D
                           (startTime <= rangeStart and endTime >= rangeEnd))
                              foundClash = true
                              break

                        # check breaks
                        unless foundClash
                           value.push({s: ass.startTime, e: ass.endTime, singleDay: ass.singleDay})
                           ass['leadingAssBars'] = key
                           break

                        # If this is the last tier, we need another
                        if Object.keys(assTiers).length - 1 == key
                           newTier = key + 1
                           assTiers[newTier] = [{s: ass.startTime, e: ass.endTime, singleDay: ass.singleDay}]
                           ass['leadingAssBars'] = newTier
                           break

               else
                  for ass in project.assignments
                     foundClash = false
                     for key, value of assTiers
                        key = Number(key)
                        foundClash = false
                        if value.length == 0
                           value.push({s: ass.startDay, e: ass.endDay})
                           ass['leadingAssBars'] = key
                           break

                        for range in value
                           # With this, we have to account for the fact that we will increment end days
                           # by 1 always for d3's scale to work properly.
                           if ((ass.startDay >= range.s and ass.startDay < range.e + 1) or
                           (ass.endDay >= range.s and ass.endDay <= range.e) or
                           (ass.startDay <= range.s and ass.endDay >= range.e))
                              foundClash = true
                              break

                        # check breaks
                        unless foundClash
                           value.push({s: ass.startDay, e: ass.endDay})
                           ass['leadingAssBars'] = key
                           break

                        # If this is the last tier, we need another
                        if Object.keys(assTiers).length - 1 == key
                           newTier = key + 1
                           assTiers[newTier] = [{s: ass.startDay, e: ass.endDay}]
                           ass['leadingAssBars'] = newTier
                           break

               assTierCount = Object.keys(assTiers).length
               peLeadingAssBars += assTierCount
               project['containedAssBars'] = assTierCount
            else
               project.assignments[0]['leadingAssBars'] = 0
               project['containedAssBars'] = 1
               peLeadingAssBars++

         person['containedAssBars'] = peLeadingAssBars
         person['containedToBars'] = peLeadingToBars
         person['personsDailyHours'] = personsDailyHours
         person['personsDailyCost'] = personsDailyCost

         pageLeadingPeople++
         pageLeadingPeopleAssBars += peLeadingAssBars
         pageLeadingPeopleToBars += peLeadingToBars

      return next(newRows)

   processRows: (newRows, next) =>
      ### Compression Comparison Alg. Cases.
         __________________
         | Range          |
         ------------------
      __________    __________
      | Case A |    | Case B |
      ----------    ----------
            ___________
            | Case C  |
            -----------
      _________________________
      | Case D                |
      -------------------------
      ###
      if @selectedViewingByEntity().value() == "projects"
         return @processProjectRows(newRows, next)

      return @processPeopleRows(newRows, next)

   mergePayloadWithBase: (payload) =>
      return Object.assign({
         start_day: @viewStartDay()
         number_of_days: @selectedRangeDays()
         filters: @getFilterParams()
      }, payload)
   #endregion

   #region Filter helpers
   refineOnlyShowFilter: (filterChips) =>
      onlyShowFilter = null;
      filteredFilterChips = filterChips.filter((chip) =>
         if chip.property == "only_show"
            onlyShowFilter = chip
            return false
         else
            return true
      )
      filteredFilterChips.push(onlyShowFilter) unless onlyShowFilter == null
      @filterChips(filteredFilterChips) unless filterChips.length == filteredFilterChips.length
      @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))

   getFilterParams: () =>
      params = {}
      @refineOnlyShowFilter(@filterChips())
      chips = @filterChips()
      if ((!@showPeopleGantt() and !@canViewRequests) or
      (@showPeopleGantt() and !@canViewPeopleTimeoff))
         if !chips.find((chip) => chip.property == "only_show" and chip.value == "assignments")
            chips.push(Gantt2ViewModel.ASSIGNMENTS_ONLY_FILTER_CHIP)
      for chip in chips
         filter = {
            property: chip.property
            type: chip.type
            negation: chip.negation
         }

         if chip.customFieldId?
            filter['customFieldId'] = chip.customFieldId
            filterName = chip.customFieldId
         else
            filterName = chip.filterName.replace(/\./g, "")

         filter['filterName'] = filterName

         filter['classifier'] = chip.classifier if chip.classifier? and chip.classifier != ANY

         if chip.enableRelativeDate
            filter.value = JSON.stringify(chip.value)
         else if chip.value instanceof Array
            filter['value'] = chip.value.map (i) -> return ko.unwrap(i.value)
         else if chip.property == 'project_roles'
            val = [chip.classifier, chip.value].map (id) ->
               return if id == ANY then null else id
            filter['value'] = val
         else
            filter['value'] = chip.value

         if params[filterName]?
            params[filterName].push(filter)
         else
            params[filterName] = [filter]

      return params

   filterDuplicateRecordsSupplier: (identityField) => (records = []) =>
      return records.filter (record) =>
         isNotLoaded = !@rowKeys.has(record[identityField])
         @rowKeys.add(record[identityField])
         return isNotLoaded
   #endregion

#region Class Constants
Gantt2ViewModel.VALID_RANGE_OPTIONS = [1, 7, 14, 28, 56, 91, 182, 364, 730, 1095]

Gantt2ViewModel.DEFAULT_PEOPLE_FILTER_CHIPS = []
Gantt2ViewModel.DEFAULT_PROJECTS_FILTER_CHIPS = [{
   classifier: null,
   classifierLabel: null,
   customFieldId: null,
   filterName: "Status",
   property: "status",
   value: "active",
   valueName: "Active"
}]

# This is used for permissions that can't see requests or timeoff.
Gantt2ViewModel.ASSIGNMENTS_ONLY_FILTER_CHIP = {
   classifier: null,
   classifierLabel: null,
   customFieldId: null,
   filterName: "Only Show",
   property: "only_show",
   value: "assignments",
   valueName: "Assignments"
}

Gantt2ViewModel.PROJECT_VIEW_DEFAULTS = {
   projectSort: "name"
   totalsUnit: "people"
   showJobNumbers: false
   showResourcesNotInGroup: false
   showPositionName: false
   showStatus: false
   peopleSort: "name"
   barSplit: "solid"
   barColor: "project-color"
}

Gantt2ViewModel.PEOPLE_VIEW_DEFAULTS = {
   peopleSort: "name"
   totalsUnit: "hours"
   showProjectNumbers: false
   showResourcesNotInGroup: false
   showPositionName: false
   showEmployeeNumber: false
   barSplit: "solid"
   barColor: "project-color"
   projectSort: "name"
}

Gantt2ViewModel.BaseAssignmentTokens = [
   {
      name: "Assignee's Name"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "name"
      subject2Type: "people"
   }
   {
      name: "Assignee's Email"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "email"
      subject2Type: "people"
   }
   {
      name: "Assignee's Phone"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "phone"
      subject2Type: "people"
   }
   {
      name: "Assignee's Job Title"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "position_id"
      subject2Type: "people"
      subject3Key: "name"
      subject3Type: "positions"
   }
   {
      name: "Start Date"
      subject1Key: "start_day"
      subject1Type: "assignments"
   }
   {
      name: "End Date"
      subject1Key: "end_day"
      subject1Type: "assignments"
   }
   {
      name: "Daily Start Time"
      subject1Key: "start_time"
      subject1Type: "assignments"
   }
   {
      name: "Daily End Time"
      subject1Key: "end_time"
      subject1Type: "assignments"
   }
   {
      name: "Project Name"
      subject1Key: "name"
      subject1Type: "projects"
   }
   {
      name: "Project Address"
      subject1Key: "address_1"
      subject1Type: "projects"
   }
   {
      name: "Project Address 2"
      subject1Key: "address_2"
      subject1Type: "projects"
   }
   {
      name: "Project City"
      subject1Key: "city_town"
      subject1Type: "projects"
   }
   {
      name: "Project State"
      subject1Key: "state_province"
      subject1Type: "projects"
   }
   {
      name: "Project Postal Code"
      subject1Key: "zipcode"
      subject1Type: "projects"
   }
   {
      name: "Project Country"
      subject1Key: "country"
      subject1Type: "projects"
   }
   {
      name: "Project Number"
      subject1Key: "job_number"
      subject1Type: "projects"
   }
]

Gantt2ViewModel.BaseAssignmentTokens.push({
   name: "Assignment Status"
   subject1Key: "status_id"
   subject1Type: "assignments"
   subject2Key: "name"
   subject2Type: "statuses"
})

Gantt2ViewModel.PeopleGanttElementClass = {
   CONTENT_CONTAINER: "gantt-chart-wrapper"
   SCROLLING_CONTENT: "chart"
}

Gantt2ViewModel.ProjectGanttElementClass = {
   CONTENT_CONTAINER: "project-gantt-chart-wrapper"
   SCROLLING_CONTENT: "chart"
}

# Used for one off alerts on projects.
Gantt2ViewModel.DefaultAlertTemplate = {
   id: "one-off"
   owner_id: "not-set"
   subject: "Assignment Alert for LC-DT<{1}>",
   content: "Assignment Info:\nLC-DT<{2}> - LC-DT<{3}>\nLC-DT<{4}> - LC-DT<{5}>\nLC-DT<{6}>\nProject #: LC-DT<{7}>",
   dynamic_tokens: [
      {
         id: "1",
         name: "Assignee's Name",
         subject_1_id: null,
         subject_1_key: "resource_id",
         subject_1_type: "assignments",
         subject_2_key: "name",
         subject_2_type: "people"
      },
      {
         id: "2",
         name: "Start Date",
         subject_1_id: null,
         subject_1_key: "start_day",
         subject_1_type: "assignments",
      },
      {
         id: "3",
         name: "End Date",
         subject_1_id: null,
         subject_1_key: "end_day",
         subject_1_type: "assignments",
      },
      {
         id: "4",
         name: "Daily Start Time",
         subject_1_id: null,
         subject_1_key: "start_time",
         subject_1_type: "assignments",
      },
      {
         id: "5",
         name: "Daily End Time",
         subject_1_id: null,
         subject_1_key: "end_time",
         subject_1_type: "assignments",
      },
      {
         id: "6",
         name: "Project Name",
         subject_1_id: null,
         subject_1_key: "name",
         subject_1_type: "projects",
      },
      {
         id: "7",
         name: "Project Number",
         subject_1_id: null,
         subject_1_key: "job_number",
         subject_1_type: "projects",
      }
   ],
   include_signature: true,
   is_group: false,
   is_private: true,
   type: "one-off-alert"
}
#endregion
