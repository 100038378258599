import "./tag-action-report.styl"
import template from "./tag-action-report.pug"
import { DateUtils } from "@/lib/utils/date"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { Url as UrlUtils } from "@/lib/utils/url"
import { Format as FormatUtils } from "@/lib/utils/format"
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { groupStore } from "@/stores/group-store"
import { defaultStore } from "@/stores/default-store"
import { requestContext } from "@/stores/common/request-context"
import { ReportStore as ReportStoreCore } from "@/stores/report-store.core"

### Models ###
import { ValueSet } from "@/models/value-set"
import {
   ReportType
} from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ListViewExportsPane } from "@/lib/components/popup/list-view-exports-pane"
import { SaveReportPane } from "@/lib/components/popup/save-report-pane"

### UI Assets ###
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

import * as ko from "knockout"

export class TagActionReportViewModel extends PageContentViewModel
   constructor: ->
      # assertArgs(arguments, nullable(Object))
      super(template(), "Tags Requiring Action")

      @tagCategoriesEnabled = authManager.companyModules()?.tagCategories

      @existingReportOptions = ko.observableArray()
      @selectedExistingReport = ko.observable()
      @groupedReportOptions = ko.observable()

      @existingConfig = ko.observable(null)
      authManager.selectedGroupId.subscribeChange(@handleGroupChange)
      @isMyGroups = ko.observable(false)

      @tagOptions = ko.observableArray()
      @selectedTagOptions = ko.observableArray()
      @categorizedTags = ko.observable()

      @filterChips = ko.observableArray()

      @reportDisabled = ko.pureComputed =>
         return @selectedTagOptions().length <= 0

      @showWarningTags = ko.observable(true)
      @showEmployeeNumber = ko.observable(true)
      @showJobTitle = ko.observable(true)
      @showExprDate = ko.observable(true)
      @showDaysUntil = ko.observable(true)

      @tagDisplayOptions = ko.observableArray([
         new SegmentedControllerItem("Name", TagActionReportViewModel.TagDisplayOption.NAME)
         new SegmentedControllerItem("Abbreviation", TagActionReportViewModel.TagDisplayOption.ABBREVIATION)
         new SegmentedControllerItem("Both", TagActionReportViewModel.TagDisplayOption.BOTH)
      ])
      @selectedTagDisplayOption = ko.observable(@tagDisplayOptions()[0])

      @flagToUseJWTAuth = LaunchDarklyClient.getFlagValue("use-jwt-auth")

      @exportPopupBuilder = =>
         return if @reportDisabled()
         pdfUrl = @buildDownloadUrl(TagActionReportViewModel.FileType.PDF)
         csvUrl = @buildDownloadUrl(TagActionReportViewModel.FileType.CSV)
         if @flagToUseJWTAuth
            return new Popup "Exports", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
               [new ListViewExportsPane(pdfUrl, csvUrl)],
               ['report-detail-page__toolbar-btn', 'icon-export--gray'], ['list-view__popup--exports'],
               () -> return
         else
            return new Popup "Exports", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
               [new ListViewExportsPane(pdfUrl, csvUrl, true, @buildReportData())],
               ['report-detail-page__toolbar-btn', 'icon-export--gray'], ['list-view__popup--exports'],
               () -> return

      @exportFilesPopupWrapper = ko.observable({
         popupBuilder: @exportPopupBuilder
         options: {triggerClasses: ['icon-export--gray']}
      })

      @saveReportCallbacks = {
         handleSave: @handleReportSave
         handleSaveAs: @handleReportSaveAs
         handleDelete: @handleReportDelete
      }
      @saveReportPopupBuilder = =>
         return if @reportDisabled()
         existingReportId = @existingConfig()?.id or null
         existingReportName = @existingConfig()?.name or null
         return new Popup "Save", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new SaveReportPane(existingReportId, existingReportName, @saveReportCallbacks)],
            ['report-detail-page__toolbar-btn', 'icon-export--gray'], ['save-report-popup'],
            () -> return

      @saveReportPopupWrapper = ko.observable({
         popupBuilder: @saveReportPopupBuilder
         options: {triggerClasses: ['icon-export--gray']}
      })

      @loadData()

   handleFilterItemChange: (item) =>
      if item.selected()
         @filterChips.push(item)
      else
         @filterChips.remove(item)

   removeFilterChip: (item) =>
      item.selected(false)
      @filterChips.remove(item)
      @selectedTagOptions.remove(item)

   buildReportData: ->
      params = {
         time_string: DateUtils.formatDate(new Date(), defaultStore.getDateFormat())
         day_filter: DateUtils.getDetachedDay(new Date())
         tag_ids: @filterChips().map (item) -> item.value()
         show_employee_number: @showEmployeeNumber()
         show_job_title: @showJobTitle()
         tag_display_option: @selectedTagDisplayOption().value()
         show_expr_date: @showExprDate()
         show_days_until: @showDaysUntil()
         show_warning_tags: @showWarningTags()
         date_format: defaultStore.getDateFormat()
      }

      if authManager.selectedGroupId() == null
         params['group_id'] = 'my-groups'
      else
         params['group_id'] = authManager.selectedGroupId()

      return params

   buildDownloadUrl: (fileType) =>
      url = if @flagToUseJWTAuth
            # we do not need baseUrl because this uses store.requestJson
            "/api/v3/reports/tag-action/#{fileType}/signed-url?"
            params = UrlUtils.serializeParams(@buildReportData())
            url += params
         else
            requestContext.baseUrl + "/api/v3/reports/tag-action/#{fileType}"
      
      return url

   handleReportSave: (name) =>
      reportData = @buildReportData()
      reportData['name'] = name
      if @existingConfig()?
         try
            await ReportStoreCore.updateTagActionReportOptions({ params: { report_id: @existingConfig().id }, body: reportData }).payload;
         catch err
            return console.error("Error in tag-action handleReportSave: ", err)
      else
         try
            result = await ReportStoreCore.createTagActionReportOptions({ body: reportData }).payload;
            newId = result.data.id
            newOption = new ValueSet({name: reportData.name, value: newId})
            @existingReportOptions.push(newOption)
            @selectedExistingReport(newOption)
            config = { id: newId }
            for key, val of reportData
               newKey = FormatUtils.toUnderscore(key)
               config[newKey] = val
            @existingConfig(config)
         catch err
            return console.error("Creation failed in tag-action handleReportSave: ", err)

   handleReportSaveAs: (name) =>
      reportData = @buildReportData()
      reportData['name'] = name
      try
         result = await ReportStoreCore.createTagActionReportOptions({ body: reportData }).payload;
         newId = result.data.id
         newOption = new ValueSet({name: reportData.name, value: newId})
         @existingReportOptions.push(newOption)
         @selectedExistingReport(newOption)
         config = {id: newId}
         for key, val of reportData
            newKey = FormatUtils.toUnderscore(key)
            config[newKey] = val
         @existingConfig(config)
      catch err
         return console.error("Error in tag-action handleReportSaveAs: ", err)

   handleReportDelete: =>
      try
         await ReportStoreCore.deleteReportOptions(@existingConfig().id).payload;
      catch err
         return console.error("Error in tag-action handleReportDelete: ", err)

   processExistingConfig: ->
      return unless @existingConfig()?
      # Clear out arrays to prevent dupes.
      @selectedTagOptions([])
      @filterChips([])

      config = @existingConfig()
      if @tagCategoriesEnabled
         for key, value of @categorizedTags()
            for tag in value
               if config.tag_ids.indexOf(tag.value()) != -1
                  tag.baggage({group: key})
                  tag.selected(true)
                  @selectedTagOptions.push(tag)
                  @filterChips.push(tag)
      else
         for tag in @tagOptions()
            if config.tag_ids.indexOf(tag.value()) != -1
               tag.selected(true)
               @selectedTagOptions.push(tag)
               @filterChips.push(tag)

      @showWarningTags(config.show_warning_tags)
      @showEmployeeNumber(config.show_employee_number)
      @showJobTitle(config.show_job_title)

      for option in @tagDisplayOptions()
         if option.value() == config.tag_display_option
            @selectedTagDisplayOption(option)
            break

      @showExprDate(config.show_expr_date)
      @showDaysUntil(config.show_days_until)

   handleExistingReportSelection: (option) =>
      if option.group?
         reportId = option.data.value()
      else
         reportId = option.value()

      try
         result = await ReportStoreCore.getReportOptions(reportId).payload
         config = result.data
         @existingConfig(config)
         @processExistingConfig()
      catch err
         return console.error("Error in tag-action handleExistingReportSelection: ", err)

   handleGroupChange: (newGroupId) =>
      @isMyGroups(newGroupId == 'my-groups')
      @clearGroupDependentProperties()
      @loadData()

   clearGroupDependentProperties: =>
      @existingConfig(null)
      @existingReportOptions([])
      @groupedReportOptions({})
      @selectedExistingReport(null)
      @tagOptions([])
      @selectedTagOptions([])
      @filterChips([])

   loadData: ->
      if @tagCategoriesEnabled
         entities = ['categorized-tags']
      else
         entities = ['tags']
      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities authManager.selectedGroupId(), entities, (err, data) =>
         return console.log "get group entity options err: ", err if err?
         if data.tagOptions? and data.tagOptions.length > 0
            @tagOptions data.tagOptions.map (tag) =>
               return new MultiDropDownItem(tag.name(), tag.value(), false, tag.color())
         else if data.categorizedTagOptions?
            @categorizedTags(data.categorizedTagOptions)

         @processExistingConfig()

      try
         resultDB = await ReportStoreCore.getReportsByType(authManager.selectedGroupId(), ReportType.TAG_ACTION).payload;
         reportOptions = resultDB.data.map (item) -> return new ValueSet(item)
         if authManager.selectedGroupId() == 'my-groups'
            @existingReportOptions([])
            groupedReportOptions = {}
            for option in reportOptions
               if groupedReportOptions[option.baggage().group_name]?
                  groupedReportOptions[option.baggage().group_name].push(option)
               else
                  groupedReportOptions[option.baggage().group_name] = [option]
            @groupedReportOptions(groupedReportOptions)
            @selectedExistingReport(null)
            @isMyGroups(true)
         else
            @existingReportOptions(reportOptions)
            @groupedReportOptions({})
            @selectedExistingReport(null)
            @isMyGroups(false)
      catch err
         return console.error("Error in tag-action loadData: ", err)

TagActionReportViewModel.TagDisplayOption = {
   NAME: "name"
   ABBREVIATION: "abbreviation"
   BOTH: "both"
}
TagActionReportViewModel.FileType = {
   PDF: "pdf"
   CSV: "csv"
}