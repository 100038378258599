import "./qr-codes.styl"
import template from "./qr-codes.pug"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import * as ko from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { groupStore } from "@/stores/group-store"
import { SettingsStore } from "@/stores/settings-store.core"

### Models ###
import { PermissionLevel } from "@/models/permission-level"
import { QRCodesViewModelCore } from "@/views/settings/vm/qr-codes/qr-codes.core"
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"

export class QRCodesViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Settings - QR Codes")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canManageQrCodesSettings = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_QR_CODES_SETTINGS)

      @core = new QRCodesViewModelCore()

      @canSave = ko.pureComputed =>
         config = @core.state.existingConfig()
         return false unless config?
         return true if @core.state.codesForPeopleEnabled() != config.people?
         return true if @core.state.codesForProjectsEnabled() != config.projects?
         if config.people?
            # People - Tags
            if !config.people.tags?
               # Newly enabled.
               return true if @core.state.showPeopleTags()
            else

               if (@core.state.showPeopleTags() != config.people.tags? or
               config.people.tags.show_expr_date != @core.state.peopleTagsShowExpr() or
               config.people.tags.show_attachments != @core.state.peopleTagsShowAttachments() or
               config.people.tags.tag_ids.length != @core.state.selectedPeopleTags().length)
                  return true

               # Check if selected tags are same length
               for option in @core.state.selectedPeopleTags()
                  return true if config.people.tags.tag_ids.indexOf(option.value()) == -1

            # People - Fields
            return true if config.people.fields.length != @core.state.selectedPeopleFields().length
            for option in @core.state.selectedPeopleFields()
               return true if config.people.fields.indexOf(option.value()) == -1

         if config.projects?
            # Projects - Tags
            if !config.projects.tags?
               # Newly enabled.
               return true if @core.state.showProjectTags()
            else

               if (@core.state.showProjectTags() != config.projects.tags? or
               config.projects.tags.show_attachments != @core.state.projectTagsShowAttachments() or
               config.projects.tags.tag_ids.length != @core.state.selectedProjectTags().length)
                  return true

               # Check if selected tags are same length
               for option in @core.state.selectedProjectTags()
                  return true if config.projects.tags.tag_ids.indexOf(option.value()) == -1

            # Projects - Roles
            if !config.projects.roles?
               # Newly enabled.
               return true if @core.state.showProjectRoles()
            else

               if (@core.state.showProjectRoles() != config.projects.roles? or
               config.projects.roles.show_phone != @core.state.showRolesPhone() or
               config.projects.roles.show_email != @core.state.showRolesEmail() or
               config.projects.roles.show_photo !=  @core.state.showRolesPhoto() or
               config.projects.roles.position_ids.length != @core.state.selectedRoles().length)
                  return true

               # Check if selected tags are same length
               for option in @core.state.selectedRoles()
                  return true if config.projects.roles.position_ids.indexOf(option.value()) == -1

            # Project - Fields
            return true if config.projects.fields.length != @core.state.selectedProjectFields().length
            for option in @core.state.selectedProjectFields()
               return true if config.projects.fields.indexOf(option.value()) == -1

      @core.state.loadData()

   handleProjectTagFilterItemChange: (item) =>
      if item.selected()
         @core.state.projectTagChips.push(item)
      else
         @core.state.projectTagChips.remove(item)

   removeProjectTagFilterChip: (item) =>
      item.selected(false)
      @core.state.projectTagChips.remove(item)
      @core.state.selectedProjectTags.remove(item)

   handleProjectRoleFilterItemChange: (item) =>
      if item.selected()
         @core.state.projectRoleChips.push(item)
      else
         @core.state.projectRoleChips.remove(item)

   removeProjectRoleFilterChip: (item) =>
      item.selected(false)
      @core.state.projectRoleChips.remove(item)
      @core.state.selectedRoles.remove(item)

   handleProjectFieldItemChange: (item) =>
      if item.selected()
         @core.state.projectFieldChips.push(item)
      else
         @core.state.projectFieldChips.remove(item)

   removeProjectFieldChip: (item) =>
      item.selected(false)
      @core.state.projectFieldChips.remove(item)
      @core.state.selectedProjectFields.remove(item)

   handlePeopleTagFilterItemChange: (item) =>
      if item.selected()
         @core.state.peopleTagFilterChips.push(item)
      else
         @core.state.peopleTagFilterChips.remove(item)

   removePeopleTagFilterChip: (item) =>
      item.selected(false)
      @core.state.peopleTagFilterChips.remove(item)
      @core.state.selectedPeopleTags.remove(item)

   handlePeopleFieldItemChange: (item) =>
      if item.selected()
         @core.state.peopleFieldChips.push(item)
      else
         @core.state.peopleFieldChips.remove(item)

   removePeopleFieldChip: (item) =>
      item.selected(false)
      @core.state.peopleFieldChips.remove(item)
      @core.state.selectedPeopleFields.remove(item)

   cancelPanelChanges: ->
      @core.state.processExistingConfig()

   savePanelChanges: ->
      data = {}
      # Project
      if @core.state.codesForProjectsEnabled()
         projectData = {}
         # Tags
         if @core.state.showProjectTags()
            projectData['tags'] = {
               show_attachments: @core.state.projectTagsShowAttachments()
            }
            if @core.state.selectedProjectTagFilterOption().value()
               projectData.tags['tag_ids'] = @core.state.selectedProjectTags().map (i) -> return i.value()
            else
               projectData.tags['tag_ids'] = []
         else
            projectData['tags'] = null

         # Roles
         if @core.state.showProjectRoles()
            projectData['roles'] = {
               show_phone: @core.state.showRolesPhone()
               show_email: @core.state.showRolesEmail()
               show_photo: @core.state.showRolesPhoto()
            }
            if @core.state.selectedRoleFilterOptions().value()
               projectData.roles['position_ids'] = @core.state.selectedRoles().map (i) -> return i.value()
            else
               projectData.roles['position_ids'] = []
         else
            projectData['roles'] = null

         # Fields
         projectData['fields'] = @core.state.selectedProjectFields().map (i) -> return i.value()

         data['projects'] = projectData

      else
         data['projects'] = null

      # People
      if @core.state.codesForPeopleEnabled()
         peopleData = {}

         # Tags
         if @core.state.showPeopleTags()
            peopleData['tags'] = {
               show_attachments: @core.state.peopleTagsShowAttachments()
               show_expr_date: @core.state.peopleTagsShowExpr()
            }

            if @core.state.selectedPeopleTagFilterOption().value()
               peopleData.tags['tag_ids'] = @core.state.selectedPeopleTags().map (i) -> return i.value()
            else
               peopleData.tags['tag_ids'] = []
         else
            peopleData['tags'] = null

         # Fields
         peopleData['fields'] = @core.state.selectedPeopleFields().map (i) -> return i.value()

         data['people'] = peopleData

      else
         data['people'] = null

      @updateQrCodeConfig data, (err, success) =>
         return console.log "Error: ", err if err
         if success
            @core.state.existingConfig(data)
            authManager.forceSessionRenew()

   updateQrCodeConfig: (payload, callback) ->
      try
         result = await SettingsStore.updateQrCodeConfig(payload).payload
         callback(null, result.data)
      catch err
         callback(err)

   getCompanyQrCodeConfig: (callback) ->
      try
         result = await SettingsStore.getQrCodeConfig().payload
         callback(null, result.data)
      catch err
         callback(err)

   loadData: ->
      loadedSupportData = false
      entities = ['positions', 'tags']
      
      # TODO: Support categorized tags

      if authManager.companyModules().customFields
         entities.push('people_custom_fields')
         entities.push('projects_custom_fields')

      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities "my-groups", entities, (err, data) =>
         return console.log "Error: ", err if err
         @peopleFieldOptions(@defaultPeopleFields)
         @projectFieldOptions(@defaultProjectFields)
         if data.peopleCustomFields?
            for item in data.peopleCustomFields
               @peopleFieldOptions.push(new MultiDropDownItem(item.name, item.id, false, null, {custom_field: true}))

         if data.projectsCustomFields?
            for item in data.projectsCustomFields
               @projectFieldOptions.push(new MultiDropDownItem(item.name, item.id, false, null, {custom_field: true}))
         
         # Need two arrays so chip interaction doesn't get weird.
         @projectTagOptions data.tagOptions.map (valueSet) ->
            return new MultiDropDownItem(valueSet.name(), valueSet.value(), false)

         @peopleTagOptions data.tagOptions.map (valueSet) ->
            return new MultiDropDownItem(valueSet.name(), valueSet.value(), false)

         @roleOptions data.positionOptions.map (valueSet) ->
            return new MultiDropDownItem(valueSet.name(), valueSet.value(), false)

         loadedSupportData = true
         @processExistingConfig() if @existingConfig()?

      @getCompanyQrCodeConfig (err, config) =>
         return console.log "Error: ", err if err
         @existingConfig(config)
         @processExistingConfig() if loadedSupportData
