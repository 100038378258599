import "./manpower-report.styl"
import template from "./manpower-report.pug"
import { DateUtils } from "@/lib/utils/date"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { Url as UrlUtils } from "@/lib/utils/url"
import { Format as FormatUtils } from "@/lib/utils/format"
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import * as ko from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { defaultStore } from "@/stores/default-store"
import { groupStore } from "@/stores/group-store"
import { ReportStore } from "@/stores/report-store.core"
import { requestContext } from "@/stores/common/request-context"

### Models ###
import { ValueSet } from "@/models/value-set"
import { PermissionLevel } from "@/models/permission-level"
import {
   ReportType
} from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ListViewExportsPane } from "@/lib/components/popup/list-view-exports-pane"
import { SaveReportPane } from "@/lib/components/popup/save-report-pane"

### UI Assets ###
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

export class ManpowerReportViewModel extends PageContentViewModel
   constructor: () ->
      # assertArgs(arguments, nullable(Object))
      super(template(), "Workforce")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canViewPeopleTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS)

      @existingReportOptions = ko.observableArray()
      @selectedExistingReport = ko.observable()
      @groupedReportOptions = ko.observable()

      @existingConfig = ko.observable(null)
      authManager.selectedGroupId.subscribeChange(@handleGroupChange)
      @isMyGroups = ko.observable(false)

      @selectedDate = ko.observable(new Date())

      @reportDisabled = ko.pureComputed =>
         if ((@selectedProjectFiltrationOption().value() == ManpowerReportViewModel.ProjectFilterOptions.FILTERED and
         @projectFilterChips().length == 0) or
         (@selectedPositionFiltrationOption().value() == ManpowerReportViewModel.PositionFilterOptions.FILTERED and
         @positionFilterChips().length == 0))
            return true
         return false

      @projectFiltrationOptions = ko.observableArray([
         new SegmentedControllerItem("All Active Projects", ManpowerReportViewModel.ProjectFilterOptions.ALL)
         new SegmentedControllerItem("Filtered", ManpowerReportViewModel.ProjectFilterOptions.FILTERED)
      ])

      @selectedProjectFiltrationOption = ko.observable(@projectFiltrationOptions()[0])
      @filteringProjects = ko.pureComputed =>
         return @selectedProjectFiltrationOption().value() == ManpowerReportViewModel.ProjectFilterOptions.FILTERED
      @selectedProjectFiltrationOption.subscribe (newVal) =>
         @projectFilterChips([]) if newVal.value() == ManpowerReportViewModel.ProjectFilterOptions.ALL
         for project in @selectedActiveProjects()
            project.selected(false)
         @selectedActiveProjects([])

         for project in @selectedPendingProjects()
            project.selected(false)
         @selectedPendingProjects([])

         for project in @selectedInactiveProjects()
            project.selected(false)
         @selectedInactiveProjects([])

      @positionFiltrationOptions = ko.observableArray([
         new SegmentedControllerItem("All Active People", ManpowerReportViewModel.PositionFilterOptions.ALL)
         new SegmentedControllerItem("Filtered", ManpowerReportViewModel.PositionFilterOptions.FILTERED)
      ])

      @selectedPositionFiltrationOption = ko.observable(@positionFiltrationOptions()[0])
      @filteringPositions = ko.pureComputed =>
         return @selectedPositionFiltrationOption().value() == ManpowerReportViewModel.PositionFilterOptions.FILTERED
      @selectedPositionFiltrationOption.subscribe (newVal) =>
         @positionFilterChips([]) if newVal.value() == ManpowerReportViewModel.PositionFilterOptions.ALL

      @newPagePerProject = ko.observable(false)
      @includedProjectsWithoutAssignments = ko.observable(false)
      @includeRequests = ko.observable(false)

      @activeProjectOptions = ko.observableArray()
      @selectedActiveProjects = ko.observableArray()

      @pendingProjectOptions = ko.observableArray()
      @selectedPendingProjects = ko.observableArray()

      @inactiveProjectOptions = ko.observableArray()
      @selectedInactiveProjects = ko.observableArray()

      @positionOptions = ko.observableArray()
      @selectedPositions = ko.observableArray()

      @projectFilterChips = ko.observableArray()
      @positionFilterChips = ko.observableArray()

      @displayJobNumber = ko.observable(true)
      @displayStatus = ko.observable(false)
      @displayCustomer = ko.observable(false)
      @displayProjectType = ko.observable(false)
      @displayProjectStartDate = ko.observable(false)
      @displayProjectEndDate = ko.observable(false)
      @displayPercentComplete = ko.observable(false)
      @displayProjectRoles = ko.observable(false)
      @displayRequestStatus = ko.observable(false)

      @displayPeopleJobTitle = ko.observable(true)
      @displayPeopleEmployeeId = ko.observable(false)
      @displayPeoplePhone = ko.observable(false)
      @displayPeopleEmail = ko.observable(false)
      @listLastNamesFirst = ko.observable(false)
      @displayAssignmentDates = ko.observable(false)
      @displayAssignmentTimes = ko.observable(false)
      @displayPeopleTags = ko.observable(false)
      @displayAssignmentStatus = ko.observable(false)

      @projectSortByOptions = ko.observableArray([
         new SegmentedControllerItem("Project Name", ManpowerReportViewModel.ProjectSortByOptions.NAME)
         new SegmentedControllerItem("Project Number", ManpowerReportViewModel.ProjectSortByOptions.JOB_NUMBER)
      ])
      @selectedProjectSortBy = ko.observable(@projectSortByOptions()[0])

      @nameDisplayOrderOptions = ko.observableArray([
         new SegmentedControllerItem("First Last", ManpowerReportViewModel.NameDisplayOrder.FIRST_NAME)
         new SegmentedControllerItem("Last, First", ManpowerReportViewModel.NameDisplayOrder.LAST_NAME)
      ])
      @selectedNameDisplayOrder = ko.observable(@nameDisplayOrderOptions()[0])

      @exportPopupBuilder = =>
         return if @reportDisabled()
         pdfUrl = @buildDownloadUrl()
         return new Popup "Exports", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new ListViewExportsPane(pdfUrl, null)],
            ['report-detail-page__toolbar-btn', 'icon-export--gray'], ['list-view__popup--exports'],

      @exportFilesPopupWrapper = ko.observable({
         popupBuilder: @exportPopupBuilder
         options: {triggerClasses: ['icon-export--gray']}
      })

      @saveReportCallbacks = {
         handleSave: @handleReportSave
         handleSaveAs: @handleReportSaveAs
         handleDelete: @handleReportDelete
      }
      @saveReportPopupBuilder = =>
         return if @reportDisabled()
         existingReportId = @existingConfig()?.id or null
         existingReportName = @existingConfig()?.name or null
         return new Popup "Save", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new SaveReportPane(existingReportId, existingReportName, @saveReportCallbacks)],
            ['report-detail-page__toolbar-btn', 'icon-export--gray'], ['save-report-popup'],

      @saveReportPopupWrapper = ko.observable({
         popupBuilder: @saveReportPopupBuilder
         options: {triggerClasses: ['icon-export--gray']}
      })

      @loadData()

   handleProjectFilterItemChange: (item) =>
      if item.selected()
         @projectFilterChips.push(item)
      else
         @projectFilterChips.remove(item)

   removeProjectFilterChip: (item) =>
      item.selected(false)
      @projectFilterChips.remove(item)
      # Since we don't know which it came from
      @selectedActiveProjects.remove(item)
      @selectedPendingProjects.remove(item)
      @selectedInactiveProjects.remove(item)

   handlePositionFilterItemChange: (item) =>
      if item.selected()
         @positionFilterChips.push(item)
      else
         @positionFilterChips.remove(item)

   removePositionFilterChip: (item) =>
      item.selected(false)
      @positionFilterChips.remove(item)
      @selectedPositions.remove(item)

   handleExistingReportSelection: (option) =>
      if option.group?
         reportId = option.data.value()
      else
         reportId = option.value()

      try
         result = await ReportStore.getReportOptions(reportId).payload
         config = result.data
         @existingConfig(config)
         @processExistingConfig()
      catch err
         return console.log "error: ", err


   buildReportData: =>
      params = {
         time_string: DateUtils.formatDate(new Date(), defaultStore.getDateFormat())
         assignment_date: DateUtils.formatDate(@selectedDate(), defaultStore.getDateFormat())
         day_filter: DateUtils.getDetachedDay(@selectedDate())
         position_ids: @positionFilterChips().map (item) -> item.value() || []
         project_ids: @projectFilterChips().map (item) -> item.value() || []
         new_page_per_project: @newPagePerProject()
         projects_without_assignments: @includedProjectsWithoutAssignments()
         include_requests: @includeRequests()
         display_job_number: @displayJobNumber()
         display_status: @displayStatus()
         display_customer: @displayCustomer()
         display_project_type: @displayProjectType()
         display_project_start_date: @displayProjectStartDate()
         display_project_end_date: @displayProjectEndDate()
         display_percent_complete: @displayPercentComplete()
         display_project_roles: @displayProjectRoles()
         display_people_job_title: @displayPeopleJobTitle()
         display_people_employee_id: @displayPeopleEmployeeId()
         display_people_phone: @displayPeoplePhone()
         display_people_email: @displayPeopleEmail()
         list_last_names_first: @selectedNameDisplayOrder().value() == ManpowerReportViewModel.NameDisplayOrder.LAST_NAME
         display_assignment_dates: @displayAssignmentDates()
         display_assignment_times: @displayAssignmentTimes()
         display_people_tags: @displayPeopleTags()
         date_format: defaultStore.getDateFormat()
         display_request_status: @displayRequestStatus()
         display_assignment_status: @displayAssignmentStatus()
      }

      if @displayJobNumber() and @selectedProjectSortBy().value() == ManpowerReportViewModel.ProjectSortByOptions.JOB_NUMBER
         params['sort_by'] = ManpowerReportViewModel.ProjectSortByOptions.JOB_NUMBER

      return params

   clearGroupDependentProperties: =>
      @activeProjectOptions([])
      @selectedActiveProjects([])
      @pendingProjectOptions([])
      @selectedPendingProjects([])
      @inactiveProjectOptions([])
      @selectedInactiveProjects([])
      @projectFilterChips([])
      @positionOptions([])
      @selectedPositions([])
      @positionFilterChips([])

   handleGroupChange: (newGroupId) =>
      @isMyGroups(newGroupId == 'my-groups')
      @clearGroupDependentProperties()
      @loadData()

   buildDownloadUrl: () =>
      url = if LaunchDarklyClient.getFlagValue("use-jwt-auth")
            # we do not need baseUrl because this uses store.requestJson
            "/api/v3/groups/#{authManager.selectedGroupId()}/reports/manpower/signed-url?"
         else
            requestContext.baseUrl + "/api/v3/groups/#{authManager.selectedGroupId()}/reports/manpower?"

      params = UrlUtils.serializeParams(@buildReportData())
      url += params
      return url

   processExistingConfig: ->
      return unless @existingConfig()?
      config = @existingConfig()

      if config.project_ids? and config.project_ids.length > 0
         @selectedProjectFiltrationOption(@projectFiltrationOptions()[1])
         for project in @activeProjectOptions()
            if config.project_ids.indexOf(project.value()) != -1
               @projectFilterChips.push(project)
               @selectedActiveProjects.push(project)
         for project in @pendingProjectOptions()
            if config.project_ids.indexOf(project.value()) != -1
               @projectFilterChips.push(project)
               @selectedPendingProjects.push(project)
         for project in @inactiveProjectOptions()
            if config.project_ids.indexOf(project.value()) != -1
               @projectFilterChips.push(project)
               @selectedInactiveProjects.push(project)
      else
         @selectedProjectFiltrationOption(@projectFiltrationOptions()[0])

      if config.position_ids? and config.position_ids.length > 0
         @selectedPositionFiltrationOption(@positionFiltrationOptions()[1])
         for position in @positionOptions()
            if config.position_ids.indexOf(position.value()) != -1
               @positionFilterChips.push(position)
      else
         @selectedPositionFiltrationOption(@positionFiltrationOptions()[0])

      @newPagePerProject(config.new_page_per_project)
      @includedProjectsWithoutAssignments(config.projects_without_assignments)
      @includeRequests(config.include_requests)
      @displayJobNumber(config.display_job_number)
      @displayStatus(config.display_status)
      @displayCustomer(config.display_customer)
      @displayProjectType(config.display_project_type)
      @displayProjectStartDate(config.display_project_start_date)
      @displayProjectEndDate(config.display_project_end_date)
      @displayPercentComplete(config.display_percent_complete)
      @displayProjectRoles(config.display_project_roles)
      @displayPeopleJobTitle(config.display_people_job_title)
      @displayPeopleEmployeeId(config.display_people_employee_id)
      @displayPeoplePhone(config.display_people_phone)
      @displayPeopleEmail(config.display_people_email)
      @displayAssignmentDates(config.display_assignment_dates)
      @displayAssignmentTimes(config.display_assignment_times)
      @displayPeopleTags(config.display_people_tags)
      @displayAssignmentStatus(config.display_assignment_status)
      @displayRequestStatus(config.display_request_status)

      if config.sort_by == ManpowerReportViewModel.ProjectSortByOptions.JOB_NUMBER
         @selectedProjectSortBy(@projectSortByOptions()[1])
      else
         @selectedProjectSortBy(@projectSortByOptions()[0])

      if config.list_last_names_first
         @selectedNameDisplayOrder(@nameDisplayOrderOptions()[1])
      else
         @selectedNameDisplayOrder(@nameDisplayOrderOptions()[0])

   handleReportSave: (name) =>
      reportData = @buildReportData()
      reportData['name'] = name
      if authManager.selectedGroupId() == null
         reportData['group_id'] = 'my-groups'
      else
         reportData['group_id'] = authManager.selectedGroupId()
      if @existingConfig()?
         try
            await ReportStore.updateManpowerReportOptions({ params: {report_id:@existingConfig().id }, body: reportData});
         catch err
            return console.log "Error: ", err
      else
         try
            result = await ReportStore.createManpowerReportOptions(reportData).payload;
            newId = result.data.id
            newOption = new ValueSet({name: reportData.name, value: newId})
            @existingReportOptions.push(newOption)
            @selectedExistingReport(newOption)
            config = {id: newId}
            for key, val of reportData
               newKey = FormatUtils.toUnderscore(key)
               config[newKey] = val
            @existingConfig(config)
         catch err
            return console.log "Error: ", err

   handleReportSaveAs: (name) =>
      reportData = @buildReportData()
      reportData['name'] = name
      if authManager.selectedGroupId() == null
         reportData['group_id'] = 'my-groups'
      else
         reportData['group_id'] = authManager.selectedGroupId()

      try
         result = await ReportStore.createManpowerReportOptions(reportData).payload;
         newId = result.data.id
         newOption = new ValueSet({name: reportData.name, value: newId})
         @existingReportOptions.push(newOption)
         @selectedExistingReport(newOption)
         config = {id: newId}
         for key, val of reportData
            newKey = FormatUtils.toUnderscore(key)
            config[newKey] = val
         @existingConfig(config)
      catch err
         return console.log "Error: ", err

   handleReportDelete: =>
      try
         await ReportStore.deleteReportOptions(@existingConfig().id);
      catch err
         return console.log "error: ", err

   loadData: ->
      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities authManager.selectedGroupId(), ['active_projects', 'pending_projects', 'inactive_projects', 'positions'], (err, data) =>
         return console.log "get group entity options err: ", err if err?
         @activeProjectOptions data.activeProjectOptions.map (project) =>
            return new MultiDropDownItem(project.name(), project.id, false, project.value(), project.baggage())

         @pendingProjectOptions data.pendingProjectOptions.map (project) =>
            return new MultiDropDownItem(project.name(), project.id, false, project.value(), project.baggage())

         @inactiveProjectOptions data.inactiveProjectOptions.map (project) =>
            return new MultiDropDownItem(project.name(), project.id, false, project.value(), project.baggage())

         @positionOptions data.positionOptions.map (position) =>
            return new MultiDropDownItem(position.name(), position.value(), false, position.color())

         @processExistingConfig()

         try
            result = await ReportStore.getReportsByType(authManager.selectedGroupId(), ReportType.MANPOWER).payload;
            reportOptions = result.data.map (item) -> return new ValueSet(item)

            if authManager.selectedGroupId() == 'my-groups'
               @existingReportOptions([])
               groupedReportOptions = {}
               for option in reportOptions
                  if groupedReportOptions[option.baggage().group_name]?
                     groupedReportOptions[option.baggage().group_name].push(option)
                  else
                     groupedReportOptions[option.baggage().group_name] = [option]
               @groupedReportOptions(groupedReportOptions)
               @selectedExistingReport(null)
               @isMyGroups(true)
            else
               @existingReportOptions(reportOptions)
               @groupedReportOptions({})
               @selectedExistingReport(null)
               @isMyGroups(false)
         catch error
            return console.log "Error: ", error

ManpowerReportViewModel.ProjectFilterOptions = {
   ALL: "all"
   FILTERED: "filtered"
}
ManpowerReportViewModel.PositionFilterOptions = {
   ALL: "all"
   FILTERED: "filtered"
}
ManpowerReportViewModel.ProjectSortByOptions = {
   NAME: "name"
   JOB_NUMBER: "job-number"
}
ManpowerReportViewModel.NameDisplayOrder = {
   FIRST_NAME: "first"
   LAST_NAME: "last"
}
ManpowerReportViewModel.FileType = {
   PDF: "pdf"
}
