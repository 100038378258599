import "./home.styl"
import template from "./home.pug"
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { DateUtils } from "@/lib/utils/date"
import { Format } from "@/lib/utils/format"
import { PageCursor } from "@/lib/utils/page-cursor"
import { Flag } from "@/flags"
import { requestContext } from "@/stores/common/request-context";

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { AuthManager2 } from "@/lib/managers/auth-manager-2"
import { fanoutManager } from "@/lib/managers/fanout-manager"
import { FanoutManager } from "@/lib/managers/fanout-manager"
import { NotificationStore } from "@/stores/notification-store.core"
import { defaultStore } from "@/stores/default-store"
import { groupStore, GroupStore } from "@/stores/group-store"
import { PersonStore } from "@/stores/person-store.core"

### Mediators ###
import { PageableLoadingMediator } from "@/lib/mediators/pageable-loading-mediator"

### Models ###
import { PermissionLevel } from "@/models/permission-level"
import { Notification } from "@/models/notification"

### Framework Includes ###
import ko from "knockout"

SHORT_FORMAT_OPTIONS = {
   yearFormat: DateUtils.YearFormat.FULL
   monthFormat: DateUtils.MonthFormat.ABBREV
   dayFormat: DateUtils.DayFormat.ONE_DIGIT
   weekdayFormat: DateUtils.WeekDayFormat.ABBREV
}
ITEMS_PER_REQUEST = 50
PRIVACY_SETTINGS_CONFIRMED = 'privacy-settings-confirmed';

export class HomeViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Home")
      @enableEmbeddedAppUsers = requestContext.usingProcoreHostApp
      ###------------------------------------
         Permissions
      ------------------------------------###
      @canViewPeople = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE)
      @canViewProject = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT)

      @companyName = ko.observable()
      if Flag.ENABLE_WORKFORCE_PLANNING_V1 || requestContext.usingProcoreHostApp
         @logoUrl = ko.observable(null)
      else
         @logoUrl = ko.observable("#{window.config.LC_DOMAIN}/images/lc-logo-vert.png")
      @showLogo = ko.pureComputed =>
         return @logoUrl() != null

      @cursor = @getNewCursor()
      @currentRequest = ko.observable()

      @progressMediator = new PageableLoadingMediator () =>
         @refresh({skip: 0})

      @notifications = ko.observableArray()
      @groupCount = ko.observable("...")
      @peopleCount = ko.observable("...")
      @projectCount = ko.observable("...")
      @hasUnreadNotifications = ko.pureComputed =>
         return @notifications().filter((item) -> !item.viewed()).length > 0

      fanoutManager.getSubscription("vm.HomeViewModel",
         FanoutManager.Channel.USERS_NOTIFICATIONS, () => @refresh({skip: 0}))

      @loadData()

   getMetaString: (notification) ->
      createdAt = new Date(notification.createdAt())
      date = DateUtils.formatDate(createdAt)
      time = DateUtils.getTime(createdAt)
      return "#{notification.authorName()} - #{date} at #{time}"

   getActionColorClass: (notification) ->
      if notification.action() == Notification.Action.CREATE
         return 'home-page__notifications-row__action-dot--green'
      else if notification.action() == Notification.Action.UPDATE
         return 'home-page__notifications-row__action-dot--blue'
      else if notification.action() == Notification.Action.DELETE
         return 'home-page__notifications-row__action-dot--red'
      else if notification.action() == Notification.Action.WARNING
         return 'home-page__notifications-row__action-dot--yellow'
      return ''

   maybeGetDayBreakString: (notification, index) ->
      index = ko.unwrap(index)
      if index == 0
         return DateUtils.formatDetachedDay(new Date(notification.createdAt()), defaultStore.getDateFormat(), SHORT_FORMAT_OPTIONS)
      else
         priorDate = new Date(@notifications()[index - 1].createdAt()).getDate()
         currentDate = new Date(notification.createdAt()).getDate()
         if priorDate != currentDate
            return DateUtils.formatDetachedDay(new Date(notification.createdAt()), defaultStore.getDateFormat(), SHORT_FORMAT_OPTIONS)
         else
            return null

   navigateToNotification: (notification) ->
      ###
         Need to use my-groups instead of the last viewed Group from auth.
         This will handle clicking into a notification where the entity
         does not belong to the last viewed Group.
      ###
      MY_GROUPS = "my-groups"

      # TODO: Save cursor location for resumption.
      try
         await NotificationStore.notificationViewed(notification.id).payload
      catch error
         return console.log "Error: ", error

      if notification.entity() == Notification.Entity.PERSON
         router.navigate(null, "/groups/#{MY_GROUPS}/people/#{notification.subject1Id()}")
      else if notification.entity() == Notification.Entity.PROJECT
         router.navigate(null, "/groups/#{MY_GROUPS}/projects/#{notification.subject1Id()}")
      else if notification.entity() == Notification.Entity.ASSIGNMENT || notification.entity() == Notification.Entity.REQUEST
         filterData = encodeURIComponent(JSON.stringify({
            value: notification.subject1Id()
            valueName: notification.subject1Name()
            filterName: "Project"
            property: "id"
         }))
         url = "/groups/#{MY_GROUPS}/gantt?notificationFilter=#{filterData}"
         if notification.meta()?.start_day?
            startDay = notification.meta().start_day
            url += "&dayFilter=#{startDay}"
         router.navigate(null, url)

   clearAllNotifications: ->
      try
         success = await NotificationStore.clearAllNotifications().payload
         if success
            for item in @notifications()
               item.viewed(true)
      catch error
         return console.log "Error: ", error
         
   getNewCursor: () =>
      return new PageCursor({
         cacheId: "home"
         defaultLimit: ITEMS_PER_REQUEST
         sizeOf: (data) => data.length
         loader: (params, callback) => 
            try
               notifications = await NotificationStore.getNotifications(params).payload;
               # TODO: Use notification-serializer instead of new Notiication
               data =  notifications.data.map (item) -> return new Notification(item);
               return callback(null, data)
            catch err
               console.log "Error: ", err
               return callback(err)
      })

   onReachedBottom: =>
      @loadBottomRecords()

   loadBottomRecords: () =>
      return if @currentRequest() or  !@cursor.hasMoreBottomRecords()
      @progressMediator.showLoader()
      @progressMediator.startProgress()

      @currentRequest @cursor.increment({}, (err, data) =>
         if err
            # TODO: Show error.
            return console.log "Error: ", err
         @progressMediator.appendData =>
            @currentRequest(null)
            ko.utils.arrayPushAll(@notifications, data)
      )

   refresh: (overrides = {}) =>
      if @currentRequest()?
         @currentRequest().cancel()
         @currentRequest(null)

      @cursor.startingIndex(overrides.skip) if overrides.skip?

      @notifications([])
      @loadBottomRecords(overrides)

   loadData: ->
      try
         result = await PersonStore.getUserHomeInfo().payload;
         data = result.data;

         # Please leave this.
         # It's required to confirm the embedded experience can load in Firefox and Safari
         if requestContext.usingProcoreHostApp
            window.localStorage.setItem(PRIVACY_SETTINGS_CONFIRMED, 'true')

         @companyName(data.companyName)
         if data.logoUrl?
            @logoUrl(data.logoUrl)

         if data.groupCount?
            @groupCount(Format.formatNumber(data.groupCount))

         if data.peopleCount?
            @peopleCount(Format.formatNumber(data.peopleCount))

         if data.projectCount?
            @projectCount(Format.formatNumber(data.projectCount))

      catch error
         console.log "Error getHomeInfo: ", error

   signout: ->
      executeSignout = =>
         groupStore.emptyVault(GroupStore.ValutKey.GROUP_OPTIONS)
         authManager.logout () ->
            AuthManager2.navigateToSignIn()

      curChild = @getChild("main")
      if curChild?.dispose?
         curChild.dispose(executeSignout)
      else
         executeSignout()

   dispose: (next) ->
      fanoutManager.unsubscribe("vm.HomeViewModel", FanoutManager.Channel.USERS_NOTIFICATIONS)
      @cursor.dispose() if @cursor?

      next()
