import "./look-ahead-report.styl"
import template from "./look-ahead-report.pug"
import { DateUtils } from "@/lib/utils/date"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { Url as UrlUtils } from "@/lib/utils/url"
import { Format as FormatUtils } from "@/lib/utils/format"
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { groupStore } from "@/stores/group-store"
import { defaultStore } from "@/stores/default-store"
import { ProjectStore } from "@/stores/project-store.core"
import { ReportStore as ReportStoreCore } from "@/stores/report-store.core"
import { requestContext } from "@/stores/common/request-context"

### Models ###
import { ValueSet } from "@/models/value-set"
import {
   ReportType
} from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane"
import { ListViewExportsPane } from "@/lib/components/popup/list-view-exports-pane"
import { SaveReportPane } from "@/lib/components/popup/save-report-pane"

### UI Assets ###
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

import * as ko from "knockout"

export class LookAheadReportViewModel extends PageContentViewModel
   constructor: ->
      # assertArgs(arguments, nullable(Object))
      super(template(), "Availability Look-Ahead")
      @existingConfig = ko.observable(null)
      @existingReportOptions = ko.observableArray()
      @groupedReportOptions = ko.observable()
      @isMyGroups = ko.observable(false)
      @selectedExistingReport = ko.observable()
      authManager.selectedGroupId.subscribeChange(@handleGroupChange)

      @positionOptions = ko.observableArray()
      @selectedPositionOptions = ko.observableArray()

      @filterChips = ko.observableArray()

      @reportDisabled = ko.pureComputed =>
         return @selectedPositionOptions().length <= 0

      @assignmentShowCountOptions = ko.observableArray([
         new SegmentedControllerItem("1", 1)
         new SegmentedControllerItem("2", 2)
         new SegmentedControllerItem("3", 3)
      ])
      @selectedAssignmentShowCount = ko.observable(@assignmentShowCountOptions()[0])
      @assignmentCount = ko.pureComputed =>
         return @selectedAssignmentShowCount().value()

      @colorOptions = ko.observableArray()
      @assignmentOneColor = ko.observable("#efb9ce")
      @colorSelectorPopupBuilderOne = =>
         return new Popup("Select Project Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@assignmentOneColor)],
            ['cp-select-color-btn', 'cp-select-color-btn__color'],
            ['create-project__color-popup', 'popup--color-selector'])

      @colorSelectorPopupWrapperOne = {
         popupBuilder: @colorSelectorPopupBuilderOne
         options: {triggerClasses: ['cp-select-color-btn__color']}
      }

      @assignmentTwoColor = ko.observable("#f9f5c8")
      @colorSelectorPopupBuilderTwo = =>
         return new Popup("Select Project Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@assignmentTwoColor)],
            ['cp-select-color-btn', 'cp-select-color-btn__color'],
            ['create-project__color-popup', 'popup--color-selector'])

      @colorSelectorPopupWrapperTwo = {
         popupBuilder: @colorSelectorPopupBuilderTwo
         options: {triggerClasses: ['cp-select-color-btn__color']}
      }

      @assignmentThreeColor = ko.observable("#c7e4f5")
      @colorSelectorPopupBuilderThree = =>
         return new Popup("Select Project Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@assignmentThreeColor)],
            ['cp-select-color-btn', 'cp-select-color-btn__color'],
            ['create-project__color-popup', 'popup--color-selector'])

      @colorSelectorPopupWrapperThree = {
         popupBuilder: @colorSelectorPopupBuilderThree
         options: {triggerClasses: ['cp-select-color-btn__color']}
      }

      @nameFormatOptions = ko.observableArray([
         new SegmentedControllerItem("John Doe", LookAheadReportViewModel.NameFormat.FULL)
         new SegmentedControllerItem("J. Doe", LookAheadReportViewModel.NameFormat.LAST)
         new SegmentedControllerItem("John D.", LookAheadReportViewModel.NameFormat.FIRST)
      ])
      @selectedNameFormat = ko.observable(@nameFormatOptions()[0])

      # Column Display Options
      @appliedRoleOptions = ko.observableArray()
      @selectedRoleOptions = ko.observableArray()
      @showAssignmentDuration = ko.observable(true)
      @showAssignmentEnd = ko.observable(true)
      @showAssignmentStart = ko.observable(true)
      @showAssignmentStatus = ko.observable(true)
      @showAvailableAfterDate = ko.observable(true)
      @showEmployeeNumber = ko.observable(true)
      @showJobName = ko.observable(true)
      @showJobNumber = ko.observable(true)
      @showJobTitle = ko.observable(true)
      @showStateProvince = ko.observable(false)
      @sortByAvailableAfter = ko.observable(true)

      @exportPopupBuilder = =>
         return if @reportDisabled()
         pdfUrl = @buildDownloadUrl(LookAheadReportViewModel.FileType.PDF)
         csvUrl = @buildDownloadUrl(LookAheadReportViewModel.FileType.CSV)
         return new Popup "Exports", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new ListViewExportsPane(pdfUrl, csvUrl)],
            ['report-detail-page__toolbar-btn', 'icon-export--gray'], ['list-view__popup--exports'],
            () -> return

      @exportFilesPopupWrapper = ko.observable({
         popupBuilder: @exportPopupBuilder
         options: {triggerClasses: ['icon-export--gray']}
      })

      @saveReportCallbacks = {
         handleSave: @handleReportSave
         handleSaveAs: @handleReportSaveAs
         handleDelete: @handleReportDelete
      }
      @saveReportPopupBuilder = =>
         return if @reportDisabled()
         existingReportId = @existingConfig()?.id or null
         existingReportName = @existingConfig()?.name or null
         return new Popup "Save", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new SaveReportPane(existingReportId, existingReportName, @saveReportCallbacks)],
            ['report-detail-page__toolbar-btn', 'icon-export--gray'], ['save-report-popup'],
            () -> return

      @saveReportPopupWrapper = ko.observable({
         popupBuilder: @saveReportPopupBuilder
         options: {triggerClasses: ['icon-export--gray']}
      })

      @loadData()

   isJWTAuthOn: =>
      jwtAuthFlag = LaunchDarklyClient.getFlagValue("use-jwt-auth")
      return jwtAuthFlag

   handleReportSave: (name) =>
      reportData = @buildReportData()
      reportData['name'] = name
      reportData['role_ids'] = reportData.role_sets.map (item) -> return item.positionId
      delete reportData.role_sets
      if @existingConfig()?
         try
            await ReportStoreCore.updateLookAheadReportOptions({ params: { report_id:@existingConfig().id }, body: reportData }).payload;
         catch err
            return console.error("Error in look-ahead handleReportSave: ", err)
      else
         try
            result = await ReportStoreCore.createLookAheadReportOptions({ body: reportData }).payload;
            newId = result.data.id
            newOption = new ValueSet({name: reportData.name, value: newId})
            @existingReportOptions.push(newOption)
            @selectedExistingReport(newOption)
            config = {id: newId}
            for key, val of reportData
               newKey = FormatUtils.toUnderscore(key)
               config[newKey] = val
            @existingConfig(config)
         catch err
            return console.error("Creation failed in look-ahead handleReportSave: ", err)

   handleReportSaveAs: (name) =>
      reportData = @buildReportData()
      reportData['name'] = name
      reportData['role_ids'] = reportData.role_sets.map (item) -> return item.positionId
      delete reportData.role_sets
      try
         result = await ReportStoreCore.createLookAheadReportOptions({ body: reportData }).payload;
         newId = result.data.id
         newOption = new ValueSet({name: reportData.name, value: newId})
         @existingReportOptions.push(newOption)
         @selectedExistingReport(newOption)
         config = {id: newId}
         for key, val of reportData
            newKey = FormatUtils.toUnderscore(key)
            config[newKey] = val
         @existingConfig(config)
      catch err
         return console.error("Error in look-ahead handleReportSaveAs: ", err)

   handleReportDelete: =>
      try
         await ReportStoreCore.deleteReportOptions(@existingConfig().id).payload;
      catch err
         return console.error("Error in look-ahead handleReportDelete: ", err)

   handleExistingReportSelection: (option) =>
      if option.group?
         reportId = option.data.value()
      else
         reportId = option.value()

      try
         result = await ReportStoreCore.getReportOptions(reportId).payload
         config = result.data
         @existingConfig(config)
         @processExistingConfig()
      catch err
         return console.error("Error in look-ahead handleExistingReportSelection: ", err)

   handleRoleSelection: (selected, role) =>
      if @selectedRoleOptions().indexOf(role) != -1
         @selectedRoleOptions.remove(role)
      else
         @selectedRoleOptions.push(role)

   handleFilterItemChange: (item) =>
      if item.selected()
         @filterChips.push(item)
      else
         @filterChips.remove(item)

   removeFilterChip: (item) =>
      item.selected(false)
      @filterChips.remove(item)
      @selectedPositionOptions.remove(item)

   buildReportData: ->
      params = {
         time_string: DateUtils.formatDate(new Date(), defaultStore.getDateFormat())
         day_filter: DateUtils.getDetachedDay(new Date())
         assignment_count: @selectedAssignmentShowCount().value()
         column_colors: [@assignmentOneColor()]
         name_format: @selectedNameFormat().value()
         show_job_name: @showJobName()
         show_job_number: @showJobNumber()
         show_assignment_start: @showAssignmentStart()
         show_assignment_end: @showAssignmentEnd()
         show_assignment_duration: @showAssignmentDuration()
         order_by_available_after: if @showAssignmentDuration() then @sortByAvailableAfter() else false
         role_sets: @selectedRoleOptions().map (item) ->
            return {
               positionId: item.value()
               roleName: item.name()
            }
         show_available_after_date: @showAvailableAfterDate()
         show_employee_number: @showEmployeeNumber()
         show_job_title: @showJobTitle()
         date_format: defaultStore.getDateFormat()
         show_assignment_status: @showAssignmentStatus()
         show_state_province: @showStateProvince()
      }
      params['position_ids'] = @filterChips().map (item) -> item.value()
      params.column_colors.push(@assignmentTwoColor()) if @selectedAssignmentShowCount().value() > 1
      params.column_colors.push(@assignmentThreeColor()) if @selectedAssignmentShowCount().value() > 2

      if authManager.selectedGroupId() == null
         params['group_id'] = 'my-groups'
      else
         params['group_id'] = authManager.selectedGroupId()

      return params

   processExistingConfig: ->
      return unless @existingConfig()?
      # Clear out arrays to prevent dupes.
      @selectedPositionOptions([])
      @filterChips([])
      @selectedRoleOptions([])

      config = @existingConfig()
      for position in @positionOptions()
         if config.position_ids.indexOf(position.value()) != -1
            position.selected(true)
            @selectedPositionOptions.push(position)
            @filterChips.push(position)
      for option in @assignmentShowCountOptions()
         if option.value() == config.assignment_count
            @selectedAssignmentShowCount(option)
            break

      @assignmentOneColor(config.column_colors[0])
      @assignmentTwoColor(config.column_colors[1]) if config.assignment_count > 1
      @assignmentThreeColor(config.column_colors[2])  if config.assignment_count > 2

      for option in @nameFormatOptions()
         if option.value() == config.name_format
            @selectedNameFormat(option)
            break

      @showEmployeeNumber(config.show_employee_number)
      @showJobTitle(config.show_job_title)
      @showAssignmentStart(config.show_assignment_start)
      @showAssignmentEnd(config.show_assignment_end)
      @showAvailableAfterDate(config.show_available_after_date)
      @showAssignmentDuration(config.show_assignment_duration)
      @showJobName(config.show_job_name)
      @showJobNumber(config.show_job_number)
      @showAssignmentStatus(config.show_assignment_status)
      @showStateProvince(config.show_state_province)

      @sortByAvailableAfter(config.order_by_available_after)

      for option in @appliedRoleOptions()
         if config.role_ids.indexOf(option.value()) != -1
            option.selected(true)
            @selectedRoleOptions.push(option)

   buildDownloadUrl: (fileType) =>
      url = if @isJWTAuthOn()
            # we do not need baseUrl because this uses store.requestJson
            "/api/v3/reports/look-ahead/#{fileType}/signed-url?"
         else
            requestContext.baseUrl + "/api/v3/reports/look-ahead/#{fileType}?"

      params = UrlUtils.serializeParams(@buildReportData())
      url += params
      return url

   handleGroupChange: (newGroupId) =>
      @isMyGroups(newGroupId == 'my-groups')
      @clearGroupDependentProperties()
      @loadData()

   clearGroupDependentProperties: =>
      @existingConfig(null)
      @existingReportOptions([])
      @groupedReportOptions({})
      @selectedExistingReport(null)
      @positionOptions([])
      @selectedPositionOptions([])
      @filterChips([])
      @appliedRoleOptions([])
      @selectedRoleOptions([])

   loadData: ->
      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities authManager.selectedGroupId(), ['positions'], (err, data) =>
         return console.log "get group entity options err: ", err if err?
         @positionOptions data.positionOptions.map (position) =>
            return new MultiDropDownItem(position.name(), position.value(), false, position.color())
         @processExistingConfig()

      result = await ProjectStore.getProjectAppliedRoleOptions(authManager.selectedGroupId()).payload
      @appliedRoleOptions result.data.map (role) ->
         roleOption = new ValueSet({name: role.position_name, value: role.position_id})
         roleOption['selected'] = ko.observable(false)
         return roleOption
      @processExistingConfig()

      try
         resultDB = await ReportStoreCore.getReportsByType(authManager.selectedGroupId(), ReportType.LOOK_AHEAD).payload;
         reportOptions = resultDB.data.map (item) -> return new ValueSet(item)
         if authManager.selectedGroupId() == 'my-groups'
            @existingReportOptions([])
            groupedReportOptions = {}
            for option in reportOptions
               if groupedReportOptions[option.baggage().group_name]?
                  groupedReportOptions[option.baggage().group_name].push(option)
               else
                  groupedReportOptions[option.baggage().group_name] = [option]
            @groupedReportOptions(groupedReportOptions)
            @selectedExistingReport(null)
            @isMyGroups(true)
         else
            @existingReportOptions(reportOptions)
            @groupedReportOptions({})
            @selectedExistingReport(null)
            @isMyGroups(false)
      catch err
         return console.error("Error in look-ahead loadData: ", err)

      defaultStore.getResourceColorStrings (err, colors) =>
         @colorOptions(colors)

LookAheadReportViewModel.NameFormat = {
   FULL: "full"
   LAST: "last"
   FIRST: "first"
}
LookAheadReportViewModel.FileType = {
   PDF: "pdf"
   CSV: "csv"
}