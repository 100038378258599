// Markup & styles
import "./assignments-list-2.styl";
import template from "./assignments-list-2.pug";

// Base & Utils
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import type { Observable, ObservableArray, Subscription } from "knockout";
import { observable, observableArray, pureComputed } from "knockout";
import { DateUtils } from "@/lib/utils/date";
import type { Filter, LabeledFilterOptions } from "@/lib/components/chip-filter/chip-filter";
import { Format } from "@/lib/utils/format";
import { App } from "../app";
import { router } from "@/lib/router";
import { formatName } from "@/lib/utils/preferences";
import { createProjectRolesFilterOption } from "@/lib/utils/project-role-helper";
import {
   BrowserLocalStorageKey,
   getPageFilterChips,
   storePageFilterChips,
} from "@/lib/utils/browser-storage";
import type { Callback } from "@/lib/type-utils";

// Filters
import { ChipFilterMediator } from "@/lib/mediators/chip-filter-mediator";
import { accumulateCustomFieldChipFilters } from "@/lib/utils/custom-field-helper";
import { buildDateFilterInstance, serializedFilters } from "@/lib/utils/chip-filter-helper";
import type { FilterOption } from "@/lib/components/chip-filter/chip-filter";

// Auth & Stores
import { authManager } from "@/lib/managers/auth-manager";
import type { GetGroupEntitiesData, GetProjectAppliedRoleOptionsData } from "@/stores/group-store";
import { groupStore } from "@/stores/group-store";
import {
   AssignmentsList2GridStore,
   NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE,
} from "./assignments-list-2-grid-store";
import type {
   AssignmentSupportData,
   NestedComputedAssignmentSupportData,
} from "@/stores/assignment-2-store.core";
import { PeopleStore } from "@/stores/people-store";
import { DefaultStore, defaultStore } from "@/stores/default-store";
import { SavedViewStore } from "@/stores/saved-view-store.core";
import { AssignmentsColumnKey } from "@laborchart-modules/common/dist/rethink/schemas/column-headers/assignments-columns";
import { StatusStore } from "@/stores/status-store.core";
import { LegacyStore } from "@/stores/legacy-store.core";

// Modules, API & Schemas
import type {
   FindAssignmentsPaginatedQueryParams,
   SerializedFindAssignment,
} from "@laborchart-modules/lc-core-api/dist/api/assignments";
import { ColumnEntityType } from "@laborchart-modules/common/dist/rethink/schemas/column-headers/column-header";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { SerializedCustomFieldInstance } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-instance-serializer";
import type { CreateAssignmentPayload } from "@laborchart-modules/lc-core-api/dist/api/assignments/create-assignment";
import { noStatusFilter } from "@laborchart-modules/common/dist/reql-builder/procedures/common/find-records";

// Models
import { PermissionLevel } from "@/models/permission-level";
import { ValueSet } from "@/models/value-set";
import type { ColumnHeaderData } from "@/models/column-header";
import { ColumnHeader } from "@/models/column-header";

// Grid & Editors
import type { GridCellFocus, GridSortOrder } from "@/lib/components/grid/virtual-grid/virtual-grid";
import type { ColumnTemplate, ProjectRole } from "@/lib/components/grid/grid-column-manager";
import { GridColumnManager } from "@/lib/components/grid/grid-column-manager";
import { EditActionCell } from "@/lib/components/grid/cells/edit-action-cell";
import { LinkedTextCell } from "@/lib/components/grid/cells/linked-text-cell";
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import { createColumnGroupForEach } from "@/lib/components/grid/grid-column-group";
import { CheckboxColumnGroupManager } from "@/lib/components/grid/column-groups/checkbox-column-group-manager";
import type { RowBase } from "@/lib/components/grid/grid-store";
import { LoadingState } from "@/lib/components/grid/grid-store";
import type { GridActionProviderParams } from "@/lib/components/grid/grid-column";
import {
   GridActionResult,
   GridActionSource,
   GridCursorState,
} from "@/lib/components/grid/grid-column";
import { ColumnWidthDefault, ColumnWidthMin } from "@/lib/components/grid/column-defaults";
import type { EditorComponentFactory } from "@/lib/components/editors/common/editor-component";
import { DisabledEditor } from "@/lib/components/editors/disabled-editor/disabled-editor";

// Modals
import { Modal } from "@/lib/components/modals/modal";
import { SaveViewPane } from "@/lib/components/modals/save-view-pane/save-view-pane";
import { modalManager as legacyModalManager } from "@/lib/managers/modal-manager";
import { ListViewExportModalPane } from "@/lib/components/modals/list-view-export-modal-pane";
import type { AssignmentDetailsModalParams } from "@/lib/components/modals/assignment-details-modal/assignment-details-modal";
import { AssignmentDetailsModalType } from "@/lib/components/modals/assignment-details-modal/assignment-details-modal";
import { AssignmentDetailsModal } from "@/lib/components/modals/assignment-details-modal/assignment-details-modal";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import { ProcessingNoticePaneViewModel } from "@/lib/components/modals/processing-notice-pane";

// Managers
import {
   Icons,
   Notification,
   notificationManagerInstance,
} from "@/lib/managers/notification-manager";

// Flags
import { Flag } from "@/flags";
import type { BatchDeleteValidator } from "@/lib/components/batch-actions/batch-delete/batch-delete";
import type { Conflict } from "@/lib/components/batch-actions/batch-actions";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { FindAssignmentsSortBy } from "@laborchart-modules/common/dist/reql-builder/procedures/enums/find-assignments";

import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser";

const TODAY_FILTER_CHIP_TEMPLATE: Filter = {
   classifier: ">=",
   classifierLabel: "On or After",
   customFieldId: null,
   filterName: "End Date",
   type: "date",
   negation: false,
   property: "end_date",
   value: DateUtils.getDetachedDay(new Date()),
   valueName: "Today",
};

const ITEMS_PER_REQUEST = 40;

const DISABLED_EDITOR_CANNOT_EDIT_ASSINGMENT = DisabledEditor.create({
   text: NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE,
});
export class AssignmentsList2 extends PageContentViewModel {
   readonly allowExportingData = authManager.checkAuthAction(
      PermissionLevel.Action.ALLOW_EXPORTING_DATA,
   );
   readonly canManageAssignments = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_ASSIGNMENTS,
   );
   readonly canViewPeople = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE);
   readonly canViewProject = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT);
   readonly canViewPeopleSensitive = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_PEOPLE_SENSITIVE,
   );
   readonly canViewPeopleFinancials = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_PEOPLE_FINANCIALS,
   );
   readonly canViewProjectSensitive = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_PROJECT_SENSITIVE,
   );
   readonly canViewProjectFinancials = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_PROJECT_FINANCIALS,
   );
   readonly canManageAlerts = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ALERTS);
   readonly canViewAllStatuses = authManager.checkAuthAction(
      PermissionLevel.Action.CAN_VIEW_ALL_STATUSES,
   );

   readonly sortOrder = observable<GridSortOrder>({
      columnKey: "end_day",
      direction: Order.ASCENDING,
   });
   readonly store: Observable<AssignmentsList2GridStore>;
   readonly selectedIds = observableArray<string>();
   readonly viewConfigured = observable(false);

   readonly chipFilterMediator = new ChipFilterMediator();
   readonly defaultChips = [
      {
         ...TODAY_FILTER_CHIP_TEMPLATE,
         value: DateUtils.getDetachedDay(new Date()),
      },
   ];
   readonly filterChips: ObservableArray<Filter>;
   readonly cellFocus = observable<GridCellFocus | null>(null);
   readonly labeledFilterOptions = pureComputed(() => this.createLabeledFilterOptions());
   readonly noAssignmentsMessage = pureComputed(() => {
      return this.filterChips().length ? "No Matching Assignments" : "No Assignments";
   });
   readonly paddingAroundColumns = pureComputed(() => {
      return this.canManageAssignments ? 8 : 16;
   });
   // Batch editor customization.
   readonly batchEditors = pureComputed(() =>
      this.store().batchEditFields().concat(this.columnManager()!.getCustomColumnBatchEditors()),
   );

   readonly conflictModalColumnGroups = pureComputed(() => {
      return this.createRowIdentifierColumnGroups();
   });

   //#region Batch Delete
   readonly batchDeleteValidator: BatchDeleteValidator<SerializedFindAssignment> = (
      selectedAssignments,
   ) => {
      const conflicts: Array<Conflict<SerializedFindAssignment>> = [];

      const valid = selectedAssignments.filter((assignment) => {
         const current_day = DateUtils.getDetachedDay(new Date());

         if (current_day > assignment.start_day && current_day < assignment.end_day) {
            conflicts.push({
               record: assignment,
               reason: "Cannot batch-delete an in-progress assignment",
            });
            return false;
         } else if (current_day > assignment.end_day) {
            conflicts.push({
               record: assignment,
               reason: "Cannot batch-delete a completed assignment",
            });
            return false;
         }

         return true;
      });

      return {
         conflicts,
         valid,
      };
   };

   readonly batchDeleteAction = async (
      stagedForDeletion: SerializedFindAssignment[],
   ): Promise<void> => {
      const deletedIds = await this.store().batchDeleteRows(stagedForDeletion);
      this.selectedIds.removeAll(deletedIds);
   };
   //#endregion

   readonly selectedAssignments = pureComputed<SerializedFindAssignment[]>(() => {
      return this.selectedIds().map(
         (id) =>
            this.store()
               .rows()
               .find((assignment) => assignment.id == id)!,
      );
   });

   readonly assignmentCountText = pureComputed(() => {
      if (
         this.store().loadingState() == LoadingState.INITIAL ||
         this.columnManager()!.loadingState() != "loaded"
      )
         return "Showing ...";
      const selectedCount = this.selectedAssignments().length;
      const totalPossible = this.store().totalPossible();
      if (this.columnManager()!.columnGroups().length === 0 || totalPossible === 0)
         return "Showing 0";
      if (selectedCount === totalPossible) {
         return `All ${totalPossible} Assignments Selected`;
      }
      if (selectedCount > 0 && totalPossible > 0) {
         return `${selectedCount}/${totalPossible} Selected`;
      }
      return `Showing ${totalPossible}`;
   });

   private readonly groupEntities = observable<GetGroupEntitiesData | null>();
   private readonly statusOptions = observableArray<ValueSet<string>>();

   private readonly subscriptions: Subscription[] = [];
   private isSelectedGroupIdChanging = false;
   private readonly assignmentCreationSupportData = observable<AssignmentSupportData | null>(null);
   private readonly assignmentSupportData: NestedComputedAssignmentSupportData = {
      companyTbdWeeks: pureComputed(
         () => this.assignmentCreationSupportData()?.companyTbdWeeks ?? 0,
      ),
      groupedCostCodeOptions: pureComputed(
         () => this.assignmentCreationSupportData()?.groupedCostCodeOptions ?? {},
      ),
      groupedLabelOptions: pureComputed(
         () => this.assignmentCreationSupportData()?.groupedLabelOptions ?? {},
      ),
      overtimeDayRates: pureComputed(
         () => this.assignmentCreationSupportData()?.overtimeDayRates ?? null,
      ),
      paidShiftHours: pureComputed(() => this.assignmentCreationSupportData()?.paidShiftHours ?? 8),
      projectOptions: pureComputed(
         () => this.assignmentCreationSupportData()?.projectOptions ?? [],
      ),
      resourceOptions: pureComputed(
         () => this.assignmentCreationSupportData()?.resourceOptions ?? [],
      ),
      statusOptions: pureComputed(() => this.assignmentCreationSupportData()?.statusOptions ?? []),
   };

   private readonly checkboxColumnGroupManager: CheckboxColumnGroupManager<RowBase>;
   readonly columnManager = observable<GridColumnManager<SerializedFindAssignment> | null>(null);
   // TODO: Remove when ENABLE_BATCH_DELETE flag is no longer needed
   private readonly batchDeleteEnabled: boolean = Flag.ENABLE_BATCH_DELETE;
   private readonly nonIndexAssignmentSortsEnabled = LaunchDarklyBrowser.getFlagValue(
      "nonindex-assignment-sorts",
   );

   constructor(queryParams: Record<string, string | boolean> | null = {}) {
      super(template(), "List");

      this.filterChips = observableArray<Filter>();
      this.store = observable(this.createStore({ startFromCurrentCursor: true }));

      this.checkboxColumnGroupManager = new CheckboxColumnGroupManager({
         selectedIds: this.selectedIds,
         allIds: pureComputed<string[]>(() => {
            return this.store()
               .rows()
               .map((assignment) => assignment.id);
         }),
      });

      this.columnManager(
         new GridColumnManager({
            listViewType: PeopleStore.ListViewType.Assignments,
            templates: this.createColumnTemplates(),
            hasSortableCustomColumns: this.nonIndexAssignmentSortsEnabled,
            customFieldConfig: {
               fieldEntity: ColumnEntityType.ASSIGNMENTS,
               isEditableProvider: (meta) =>
                  meta.field_entity === ColumnEntityType.ASSIGNMENTS && this.canManageAssignments,
               isVisibleProvider: (columnHeader) => this.customFieldIsVisible(columnHeader),
               saveProvider: (rows, columnHeader, value) =>
                  this.store().updateCustomFields(rows, columnHeader.meta()!, value),
               valueExtractor: (assignment, columnHeader) =>
                  this.customFieldValueExtractor(assignment, columnHeader),
            },
            projectRolesConfig: authManager.checkAuthAction(
               PermissionLevel.Action.VIEW_PROJECT_ROLES,
            )
               ? {
                    valueExtractor: (assignment, positionId) =>
                       this.projectRolesExtractor(assignment, positionId),
                 }
               : null,
         }),
      );

      // Check if we want to load from saved view or not.
      if (queryParams && queryParams.viewId) {
         // Resume saved view.
         this.columnManager()!.load(true);
         this.loadSavedView(queryParams.viewId as string);
      } else {
         const viewOptions: { [key: string]: any } = {};

         if (queryParams && (queryParams.sortBy || queryParams.sortDirection)) {
            const sortOrder = this.sortOrder();
            viewOptions["sortOrder"] = {
               columnKey: queryParams.sortBy || sortOrder.columnKey,
               direction: queryParams.sortDirection
                  ? queryParams.sortDirection == Order.DESCENDING
                     ? Order.DESCENDING
                     : Order.ASCENDING
                  : sortOrder.direction,
            };
         }
         const savedChips = this.getPageFilterChips()?.map((chip) =>
            chip.valueName == TODAY_FILTER_CHIP_TEMPLATE.valueName
               ? TODAY_FILTER_CHIP_TEMPLATE
               : chip,
         );
         (viewOptions["filters"] = savedChips || this.defaultChips),
            this.setupViewConfig(viewOptions);
         this.columnManager()!.load();
      }

      this.loadGroupEntities();
      this.loadAssignmentCreationSupportData();
      this.loadStatusData();
   }

   // Wrapper function browser-storage's for getPageFilterChips.
   private getPageFilterChips(key?: string) {
      const filterChips = getPageFilterChips(key) || [];
      let shouldUpdateFilters = false;
      // TODO: Can remove this after July 1st, 2022.
      const transformedFilters = filterChips.map((el) => {
         if (el.filterName === "Status" || el.filterName === "Flag") {
            shouldUpdateFilters = true;
            return {
               ...el,
               filterName: "Assignment Status",
               property: "status_id",
            };
         } else {
            return el;
         }
      });

      if (shouldUpdateFilters) {
         storePageFilterChips(transformedFilters);
      }
      return transformedFilters;
   }

   onNewAssignmentButtonClicked = (): void => {
      this.editOrCreateAssignment();
   };

   onExportClicked = (): void => {
      const { group_id, ...queryParams } = this.createQueryParams();
      const pane = new ListViewExportModalPane(
         {
            ...queryParams,
            group_id: group_id ? group_id : "my-groups",
            column_headers: this.columnManager()!
               .getActiveColumnHeaders()
               .map((header) => {
                  const value = header.allToJson();
                  delete value.baggage;
                  // Make sure null values are undefined.
                  if (value.key_id == null) {
                     delete value.key_id;
                  }
                  return value;
               }),
            display_last_names_first:
               authManager.authedUser()?.preferences()?.displayLastNamesFirst() || false,
         },
         "Export Assignments Report",
         "assignments-2-report",
         "assignments_list",
      );
      const modal = new Modal();
      modal.setPanes([pane]);
      legacyModalManager.showModal(modal, null, { class: "list-view-export-modal-pane" });
   };

   dispose(next: (() => void) | null): void {
      this.subscriptions.forEach((s) => s.dispose());
      this.columnManager()!.dispose();
      this.checkboxColumnGroupManager.dispose();
      if (next) next();
   }

   // TODO: Sort all these types out.
   private setupViewConfig = (options: {
      sortOrder?: { columnKey: string; direction: Order };
      filters?: Filter[];
      search?: string;
      columnHeaders?: ColumnHeader[];
   }) => {
      if (options.sortOrder) {
         this.sortOrder(options.sortOrder);
      }

      if (options.filters) {
         this.filterChips(options.filters);
         setTimeout(() => {
            this.chipFilterMediator.updateVisibleFilters(this.filterChips().slice(0));
         }, 0);
      }

      if (options.columnHeaders) {
         // This should only get hit by saved views.
         this.columnManager()!.updateColumnHeaders(options.columnHeaders, true);
      }

      this.reload({ startFromCurrentCursor: false });
      this.setupSubscriptions();
   };

   private setupSubscriptions = () => {
      this.subscriptions.push(
         authManager.selectedGroupId.subscribe(this.onSelectedGroupIdChanged, this),
         this.filterChips.subscribe(this.onFilterChipsChanged, this),
         this.sortOrder.subscribe(this.onSortOrderChanged, this),
         this.checkboxColumnGroupManager.hasAllChecked.subscribe(
            this.onHasAllRowsCheckedChanged,
            this,
         ),
      );
   };

   private async loadSavedView(savedViewId: string) {
      const savedViewPayload = await SavedViewStore.getSavedView(savedViewId).payload;
      const savedView = savedViewPayload.data;
      if (savedView == null) return;

      // TODO: Check that the view is valid.

      this.setTitle(savedView.name);

      const viewOptions: { [key: string]: any } = {};

      if (savedView.view_config.sort_by && savedView.view_config.sort_direction) {
         viewOptions["sortOrder"] = {
            columnKey: savedView.view_config.sort_by,
            direction: savedView.view_config.sort_direction,
         };
      }

      if (savedView.chip_filters) {
         viewOptions["filters"] = savedView.chip_filters.map((item: any) => {
            return Format.snakeCaseObjectToCamelCase(item);
         });
      }

      if (savedView.search) {
         viewOptions["search"] = savedView.search;
      }

      if (savedView.view_config.column_headers) {
         viewOptions["columnHeaders"] = savedView.view_config.column_headers.map(
            (item: ColumnHeaderData) => {
               return new ColumnHeader(item);
            },
         );

         /**
          * Minor hackaround:
          * backupProjectRoleOptions are only used in the scenario that the normal project
          * role options have not had the chance to load yet.
          * Without this code, a race condition may occur that allows the saved view to
          * load without waiting for roleTemplates within columnManager to be set, causing
          * project role columns not to load with the saved view.
          */
         const backupProjectRoleOptions: GetProjectAppliedRoleOptionsData[] =
            savedView.view_config.column_headers
               .filter((item: ColumnHeaderData) => {
                  return item.key === "positions";
               })
               .map<GetProjectAppliedRoleOptionsData>((item: ColumnHeaderData) => {
                  return {
                     position_id: item.key_id!,
                     position_name: item.name!,
                  };
               });
         this.columnManager()!.maybeSetRoleTemplates(backupProjectRoleOptions);
      }

      this.setupViewConfig(viewOptions);
   }

   private async loadStatusData() {
      const stream = await StatusStore.findStatusesStream({}).stream;
      const statuses: Array<ValueSet<string>> = [];
      for await (const status of stream) {
         statuses.push(
            new ValueSet({
               id: status.id,
               name: status.name,
               value: status.id,
               color: status.color,
               selected: false,
            }),
         );
      }
      this.statusOptions(statuses);
   }

   private loadGroupEntities() {
      const entities = ["positions", "people"];
      if (authManager.companyModules()?.customFields) {
         entities.push(
            "assignments_custom_field_filters",
            "people_custom_field_filters",
            "projects_custom_field_filters",
         );
      }
      // TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities(authManager.selectedGroupId(), entities, (err, data) => {
         if (err) {
            return notificationManagerInstance.show(
               new Notification({
                  icon: Icons.WARNING,
                  text: "An unexpected error prevented the filters from loading.",
               }),
            );
         }

         // Rename custom fields that exist on both people on projects.
         if (
            authManager.companyModules()?.customFields &&
            data.peopleCustomFieldFilters?.length &&
            data.projectsCustomFieldFilters?.length
         ) {
            data.peopleCustomFieldFilters.forEach((field) => {
               const duplicates = data.projectsCustomFieldFilters!.filter((f) => {
                  return f.id == field.id;
               });
               duplicates.forEach((projectCustomField) => {
                  projectCustomField.name = `Project's ${projectCustomField.name}`;
               });
               if (duplicates.length) {
                  field.name = `Person's ${field.name}`;
               }
            });
         }

         this.groupEntities(data);
      });
   }

   private loadAssignmentCreationSupportData() {
      return this.fetchAssignmentCreationSupportData((err, supportData) => {
         if (err) {
            return notificationManagerInstance.show(
               new Notification({
                  icon: Icons.WARNING,
                  text: "Assignments cannot be created or updated due to an unexpected error.",
               }),
            );
         }
         this.assignmentCreationSupportData(supportData ?? null);
      });
   }

   private async fetchAssignmentCreationSupportData(callback: Callback<AssignmentSupportData>) {
      let result: any = null;
      try {
         const group_id =
            authManager.selectedGroupId() === "my-groups"
               ? undefined
               : authManager.selectedGroupId();
         result = await LegacyStore.getAssignmentCreationSupportData({ group_id }).payload;
         callback(null, LegacyStore.formatAssignmentCreationSupportData(result.data));
      } catch (err) {
         console.error("Error", err);
         Bugsnag.notify(err as NotifiableError, (event) => {
            event.context = "assignments-list__fetchAssignmentCreationSupportData";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata("fetchAssignmentCreationSupportData", result);
         });
         callback(err as Error);
      }
   }

   private onSelectedGroupIdChanged(groupId: string) {
      // Set isSelectedGroupIdChanging to notify the rest of the listeners that
      // the group ID is changing and they should respond accordingly.
      this.isSelectedGroupIdChanging = true;

      // Update the filter chips from browser storage. Specify the path manually because the URL
      // has not yet been updated.
      this.filterChips(
         getPageFilterChips(
            `${BrowserLocalStorageKey.CHIP_FILTERS}_/groups/${groupId}/assignments-list`,
         ) || this.defaultChips,
      );
      this.chipFilterMediator.updateVisibleFilters(this.filterChips().slice(0));

      // Update the available project roles, since these are the only other
      // column option with Group restrictions.
      this.columnManager()!.loadProjectRoles();

      // Create a new store to use the new group ID and filter chips.
      this.reload({ startFromCurrentCursor: true });
      this.loadGroupEntities();
      this.isSelectedGroupIdChanging = false;
   }

   private onFilterChipsChanged(filterChips: Array<Filter<unknown>>) {
      if (!this.isSelectedGroupIdChanging) {
         this.reload({ startFromCurrentCursor: false });
         storePageFilterChips(filterChips);
      }
   }

   private onSortOrderChanged(sortOrder: GridSortOrder) {
      router.updateUrlQueryParam(App.RouteName.ASSIGNMENTS_LIST, "sortBy", sortOrder.columnKey);
      router.updateUrlQueryParam(
         App.RouteName.ASSIGNMENTS_LIST,
         "sortDirection",
         sortOrder.direction,
      );
      this.reload({ startFromCurrentCursor: false });
   }

   private onHasAllRowsCheckedChanged(hasAllRowsChecked: boolean) {
      if (hasAllRowsChecked) {
         this.store().loadAll();
      } else {
         this.store().cancelLoadAll();
      }
   }

   showSaveViewModal = (): void => {
      const queryParams = this.createQueryParams();

      const modal = new Modal();
      const pane = new SaveViewPane(App.PageName.ASSIGNMENTS_LIST, {
         columnHeaders: this.columnManager()!.getActiveColumnHeaders(),
         filters: this.filterChips(),
         sortBy: queryParams.sort_by,
         sortDirection: queryParams.sort_direction,
      });
      modal.setPanes([pane]);
      legacyModalManager.showModal(modal, null, { class: "save-view-modal" });
   };

   private editOrCreateAssignment(assignment: SerializedFindAssignment | null = null) {
      const showModal = () => {
         if (assignment == null) {
            this.showCreateOrEditAssignmentModal({
               assignment: null,
               assignmentCreationSupportData: this.assignmentSupportData,
            });
         } else {
            const observableAssignment = observable(assignment);
            const liveAssignment = pureComputed(() => observableAssignment());
            this.showCreateOrEditAssignmentModal({
               assignment: liveAssignment,
               assignmentCreationSupportData: this.assignmentSupportData,
            });

            const assignmentId = assignment.id;
            this.store().rows.subscribe((rows) => {
               const assignmentFromRow = rows.find((assignment) => assignment.id == assignmentId);
               if (assignmentFromRow == null) {
                  modalManager.clearModal();
               } else {
                  observableAssignment(assignmentFromRow);
               }
            });
         }
      };

      if (this.assignmentCreationSupportData() != null) {
         return showModal();
      }
      const pane = new ProcessingNoticePaneViewModel("Loading Support Data", true);
      const modal = new Modal();
      modal.setPanes([pane]);
      legacyModalManager.showModal(modal, null, { class: "processing-notice-modal" });

      const isCreateOrEditModalReady = pureComputed(() => {
         return (
            legacyModalManager.animatingModalIn() === false &&
            this.assignmentCreationSupportData() !== null
         );
      });

      const isReadySubscription = isCreateOrEditModalReady.subscribe((isReady) => {
         if (isReady !== true) return;
         if (isReady === true) {
            legacyModalManager.maybeCancelModal(() => {
               showModal();
            });
            isReadySubscription.dispose();
         }
      });
   }

   private showCreateOrEditAssignmentModal({
      assignment,
      assignmentCreationSupportData,
   }: {
      assignment: AssignmentDetailsModalParams["assignment"];
      assignmentCreationSupportData: NestedComputedAssignmentSupportData;
   }) {
      if (!this.canManageAssignments) return;

      const createAssignment = async (
         assignment: CreateAssignmentPayload,
         showAlertModal?: boolean,
      ) => {
         await this.store().createAssignmentFromModal(assignment, showAlertModal);
         modalManager.clearModal();
         this.reload({ startFromCurrentCursor: false });
      };

      modalManager.setModal({
         modal: AssignmentDetailsModal.factory({
            assignment: assignment ?? null,
            createAssignment: createAssignment,
            customFields: pureComputed(() => this.columnManager()!.entityCustomFields()),
            deleteAssignment: async (deleteParams) => {
               this.selectedIds(this.selectedIds().filter((assId) => assId !== assignment!().id));
               await this.store().deleteAssignmentFromModal(deleteParams);
            },
            assignmentDetailsModalType:
               assignment == null
                  ? AssignmentDetailsModalType.CREATE_ASSIGNMENT
                  : AssignmentDetailsModalType.EDIT_ASSIGNMENT,
            requestSize: modalManager.requestSize,
            supportData: assignmentCreationSupportData,
            updateAssignment: this.store().updateAssignmentFromModal,
         }),
      });
   }

   private createLabeledFilterOptions(): LabeledFilterOptions {
      const groupEntities = this.groupEntities();
      if (!groupEntities) {
         return {};
      }

      const filterOptions: LabeledFilterOptions = {
         Project: observable<FilterOption>({
            property: "project_search",
            type: "text",
         }),
         "Employee ID": observable<FilterOption>({
            property: "employee_number",
            type: "text",
         }),
         "Assignment Start Date": observable(buildDateFilterInstance("start_date")),
         "Assignment End Date": observable(buildDateFilterInstance("end_date")),
         "Assignment Percent Allocated": observable<FilterOption>({
            property: "percent_allocation",
            disableSearch: true,
            classifiers: [
               { listLabel: "< (Less Than)", chipLabel: "<", value: "<" },
               { listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<=" },
               { listLabel: "= (Equal To)", chipLabel: "=", value: "=" },
               { listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">=" },
               { listLabel: "> (Greater Than)", chipLabel: ">", value: ">" },
            ],
            type: "number",
         }),
         "Assignment Overtime": observable<FilterOption>({
            property: "overtime",
            values: [new ValueSet({ name: "True", value: true })],
            type: "bool",
         }),
         "Allocation Type": observable<FilterOption>({
            type: "select",
            disableSearch: true,
            property: "allocation_type",
            values: [
               new ValueSet({ name: "Hours", value: "hours" }),
               new ValueSet({ name: "Percentage", value: "percentage" }),
            ],
         }),
         "Person Status": observable<FilterOption>({
            property: "person_status",
            values: [
               new ValueSet({ name: "Active", value: "active" }),
               new ValueSet({ name: "Inactive", value: "inactive" }),
            ],
            type: "select",
         }),
         "Project Status": observable<FilterOption>({
            property: "project_status",
            values: [
               new ValueSet({ name: "Active", value: "active" }),
               new ValueSet({ name: "Pending", value: "pending" }),
               new ValueSet({ name: "Inactive", value: "inactive" }),
            ],
            type: "select",
         }),
      };

      if (this.statusOptions().length) {
         const statusFilterOptions = Format.keyableSort(this.statusOptions(), "name");
         if (this.canViewAllStatuses) {
            statusFilterOptions.unshift(
               new ValueSet({
                  name: noStatusFilter.name,
                  value: noStatusFilter.value_sets[0].value,
                  selected: false,
               }),
            );
         }
         filterOptions["Assignment Status"] = observable<FilterOption>({
            type: "select",
            property: "status_id",
            values: statusFilterOptions,
         });
      }
      if (groupEntities.peopleOptions?.length) {
         filterOptions["Person"] = observable<FilterOption>({
            type: "select",
            property: "person_id",
            values: Format.keyableSort(groupEntities.peopleOptions, "name"),
         });
      }
      if (groupEntities.positionOptions?.length) {
         filterOptions["Job Title"] = observable<FilterOption>({
            type: "select",
            property: "person_position",
            values: groupEntities.positionOptions,
         });
      }
      if (groupEntities.peopleOptions?.length && groupEntities.positionOptions?.length) {
         filterOptions["Project Roles"] = createProjectRolesFilterOption({
            positionOptions: groupEntities.positionOptions,
            peopleOptions: groupEntities.peopleOptions,
         });
      }

      if (authManager.companyModules()?.customFields) {
         if (groupEntities.peopleCustomFieldFilters) {
            accumulateCustomFieldChipFilters(
               filterOptions,
               groupEntities.peopleCustomFieldFilters,
               {
                  propertyName: "person_custom_fields",
                  sensitiveFields: authManager.peopleSensitiveFields(),
                  canViewSensitiveFields: this.canViewPeopleSensitive,
                  canViewFinancials: this.canViewPeopleFinancials,
               },
            );
         }
         if (groupEntities.projectsCustomFieldFilters) {
            accumulateCustomFieldChipFilters(
               filterOptions,
               groupEntities.projectsCustomFieldFilters,
               {
                  propertyName: "project_custom_fields",
                  sensitiveFields: authManager.projectsSensitiveFields(),
                  canViewSensitiveFields: this.canViewProjectSensitive,
                  canViewFinancials: this.canViewProjectFinancials,
               },
            );
         }
         if (groupEntities.assignmentsCustomFieldFilters) {
            accumulateCustomFieldChipFilters(
               filterOptions,
               groupEntities.assignmentsCustomFieldFilters,
               {
                  propertyName: "assignment_custom_fields",

                  // Sensitive and financial fields don't yet exist on Assignments.
                  sensitiveFields: [],
                  canViewSensitiveFields: true,
                  canViewFinancials: true,
               },
            );
         }
      }

      return filterOptions;
   }

   private createColumnTemplates(): Array<ColumnTemplate<SerializedFindAssignment>> {
      const formatOptionalTime = (time: number | null) => {
         if (time == null) return "";
         return DefaultStore.Data.TIME_OPTIONS.find((t) => t.value == time)?.name || "";
      };
      const formatBoolean = (value: boolean | null) => {
         return Format.capitalize(Boolean(value).toString());
      };
      const isProjectFieldVisible = (field: string) => {
         return (
            this.canViewProjectSensitive || !authManager.projectsSensitiveFields().includes(field)
         );
      };
      const templateVisitors: Array<{
         visit: () => boolean;
         accept: () => ColumnTemplate<SerializedFindAssignment>;
      }> = [
         {
            visit: () => this.canManageAssignments || this.canManageAlerts,
            accept: () => ({
               ...this.checkboxColumnGroupManager.columnGroup.columns[0],
               isFixed: true,
            }),
         },
         {
            visit: () => this.canManageAssignments,
            accept: () => ({
               header: "",
               key: AssignmentsColumnKey.EDIT_ASSIGNMENT,
               width: 32,
               isFixed: true,
               cellFactory: EditActionCell.factory({
                  onClick: (assigment) => {
                     this.editOrCreateAssignment(assigment);
                  },
                  isDisabled: (assignment) => !this.store().canEditAssignment(assignment),
               }),
               cursorStateProvider: this.defaultCursorStateProvider,
               actionProvider: ({
                  row,
                  source,
               }: GridActionProviderParams<SerializedFindAssignment>) => {
                  if (!this.store().canEditAssignment(row)) return GridActionResult.REMAIN_FOCUSED;
                  if (source == GridActionSource.ENTER_KEY) this.editOrCreateAssignment(row);
                  return GridActionResult.REMAIN_FOCUSED;
               },
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Name",
               key: AssignmentsColumnKey.NAME,
               width: ColumnWidthDefault.PERSON_NAME,
               minWidth: ColumnWidthMin.PERSON_NAME,
               isDefault: true,
               isSortable: this.nonIndexAssignmentSortsEnabled,
               isResizable: true,
               cursorStateProvider: this.defaultCursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  this.protectedEditorFactory({
                     assignment: row,
                     cursorState,
                     editorFactory: this.store().personEditorFactory,
                  }),
               ...(this.canViewPeople
                  ? LinkedTextCell.columnProviders((assignment: SerializedFindAssignment) => ({
                       text: formatName(assignment.person.name),
                       href: this.store().canAccessAssignmentPerson(assignment)
                          ? `/groups/${authManager.selectedGroupId()}/people/${
                               assignment.person.id
                            }`
                          : null,
                    }))
                  : TextCell.columnProviders((assignment) => formatName(assignment.person.name))),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Job Title",
               key: AssignmentsColumnKey.POSITION,
               width: ColumnWidthDefault.JOB_TITLE,
               minWidth: ColumnWidthMin.JOB_TITLE,
               isDefault: true,
               isSortable: this.nonIndexAssignmentSortsEnabled,
               isResizable: true,
               ...ColorCircleTextCell.columnProviders((assignment) => {
                  return {
                     text: assignment.person.job_title?.name || "",
                     color: assignment.person.job_title?.color || null,
                  };
               }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Gender",
               key: AssignmentsColumnKey.GENDER,
               width: 50,
               minWidth: 50,
               isDefault: false,
               isResizable: true,
               ...TextCell.columnProviders((assignment) =>
                  assignment.person.gender ? Format.capitalize(assignment.person.gender) : "",
               ),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Project",
               key: AssignmentsColumnKey.PROJECT_NAME,
               width: ColumnWidthDefault.PROJECT_NAME,
               minWidth: ColumnWidthMin.PROJECT_NAME,
               isDefault: true,
               isSortable: this.nonIndexAssignmentSortsEnabled,
               isResizable: true,
               cursorStateProvider: this.defaultCursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  this.protectedEditorFactory({
                     assignment: row,
                     cursorState,
                     editorFactory: this.store().projectEditorFactory,
                  }),
               ...(this.canViewProject
                  ? LinkedTextCell.columnProviders((assignment: SerializedFindAssignment) => ({
                       text: assignment.project.name,
                       href: this.store().canAccessAssignmentProject(assignment)
                          ? `/groups/${authManager.selectedGroupId()}/projects/${
                               assignment.project_id
                            }`
                          : null,
                    }))
                  : TextCell.columnProviders((assignment) => assignment.project.name)),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Project #",
               key: AssignmentsColumnKey.JOB_NUMBER,
               width: ColumnWidthDefault.PROJECT_NUMBER,
               minWidth: ColumnWidthMin.PROJECT_NUMBER,
               isDefault: true,
               isSortable: this.nonIndexAssignmentSortsEnabled,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.project.job_number || ""),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Start Date",
               key: AssignmentsColumnKey.START_DAY,
               width: ColumnWidthDefault.SHORT_DATE,
               minWidth: ColumnWidthMin.SHORT_DATE,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               cursorStateProvider: this.defaultCursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  this.protectedEditorFactory({
                     assignment: row,
                     cursorState,
                     editorFactory: this.store().startDayEditorFactory,
                  }),
               ...TextCell.columnProviders((assignment) =>
                  this.formatDetachedDay(assignment.start_day),
               ),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "End Date",
               key: AssignmentsColumnKey.END_DAY,
               width: ColumnWidthDefault.SHORT_DATE,
               minWidth: ColumnWidthMin.SHORT_DATE,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               cursorStateProvider: this.defaultCursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  this.protectedEditorFactory({
                     assignment: row,
                     cursorState,
                     editorFactory: this.store().endDayEditorFactory,
                  }),
               ...TextCell.columnProviders((assignment) =>
                  this.formatDetachedDay(assignment.end_day),
               ),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Start Time",
               key: AssignmentsColumnKey.START_TIME,
               width: ColumnWidthDefault.TIME,
               minWidth: ColumnWidthMin.TIME,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               cursorStateProvider: (row) => {
                  if (!this.store().canEditAssignment(row))
                     return GridCursorState.ACTIONABLE_DISABLED;
                  return row.start_time != null
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().startTimeEditorFactory([row])
                     : !this.store().canEditAssignment(row)
                     ? DISABLED_EDITOR_CANNOT_EDIT_ASSINGMENT
                     : DisabledEditor.create({
                          text: "Start Time cannot be changed when Percent Allocation is set.",
                       }),
               ...TextCell.columnProviders((assignment) =>
                  formatOptionalTime(assignment.start_time),
               ),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "End Time",
               key: AssignmentsColumnKey.END_TIME,
               width: ColumnWidthDefault.TIME,
               minWidth: ColumnWidthMin.TIME,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               cursorStateProvider: (row) => {
                  if (!this.store().canEditAssignment(row))
                     return GridCursorState.ACTIONABLE_DISABLED;
                  return row.end_time != null
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().endTimeEditorFactory([row])
                     : !this.store().canEditAssignment(row)
                     ? DISABLED_EDITOR_CANNOT_EDIT_ASSINGMENT
                     : DisabledEditor.create({
                          text: "End Time cannot be changed when Percent Allocation is set.",
                       }),
               ...TextCell.columnProviders((assignment) => formatOptionalTime(assignment.end_time)),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "% Allocation",
               key: AssignmentsColumnKey.PERCENT_ALLOCATED,
               width: 115,
               minWidth: 85,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               cursorStateProvider: (row) => {
                  if (!this.store().canEditAssignment(row))
                     return GridCursorState.ACTIONABLE_DISABLED;
                  return row.percent_allocated != null
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().percentAllocatedEditorFactory([row])
                     : !this.store().canEditAssignment(row)
                     ? DISABLED_EDITOR_CANNOT_EDIT_ASSINGMENT
                     : DisabledEditor.create({
                          text: "Percent Allocation cannot be changed when Start Time and End Time are set.",
                       }),
               ...TextCell.columnProviders((assignment) => {
                  return assignment.percent_allocated ? `${assignment.percent_allocated}%` : "";
               }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Status",
               key: AssignmentsColumnKey.STATUS,
               width: ColumnWidthDefault.STATUS,
               minWidth: ColumnWidthMin.STATUS,
               isDefault: true,
               isSortable: this.nonIndexAssignmentSortsEnabled,
               isResizable: true,
               ...ColorCircleTextCell.columnProviders((assignment) => {
                  return {
                     text: assignment.status?.name || "",
                     color: assignment.status?.color || null,
                  };
               }),
               cursorStateProvider: this.defaultCursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  this.protectedEditorFactory({
                     assignment: row,
                     cursorState,
                     editorFactory: this.store().statusEditorFactory,
                  }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Employee ID",
               key: AssignmentsColumnKey.EMPLOYEE_NUMBER,
               width: ColumnWidthDefault.EMPLOYEE_NUMBER,
               minWidth: ColumnWidthMin.EMPLOYEE_NUMBER,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.person.employee_number || ""),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Category",
               key: AssignmentsColumnKey.COST_CODE_NAME,
               width: ColumnWidthDefault.CATEGORY,
               minWidth: ColumnWidthMin.CATEGORY,
               isSortable: this.nonIndexAssignmentSortsEnabled,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.category?.name || ""),
               cursorStateProvider: (row) => {
                  if (!this.store().canEditAssignment(row))
                     return GridCursorState.ACTIONABLE_DISABLED;
                  return row.project.categories.length > 0
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().costCodeEditorFactory([row])
                     : !this.store().canEditAssignment(row)
                     ? DISABLED_EDITOR_CANNOT_EDIT_ASSINGMENT
                     : DisabledEditor.create({
                          text: "Project does not have any categories.",
                       }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Subcategory",
               key: AssignmentsColumnKey.LABEL_NAME,
               width: ColumnWidthDefault.SUBCATEGORY,
               minWidth: ColumnWidthMin.SUBCATEGORY,
               isSortable: this.nonIndexAssignmentSortsEnabled,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.subcategory?.name || ""),
               cursorStateProvider: (row) => {
                  if (!this.store().canEditAssignment(row))
                     return GridCursorState.ACTIONABLE_DISABLED;
                  return this.hasSubcategoryOptions(row)
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().costCodeLabelEditorFactory([row])
                     : !this.store().canEditAssignment(row)
                     ? DISABLED_EDITOR_CANNOT_EDIT_ASSINGMENT
                     : !row.category
                     ? DisabledEditor.create({
                          text: "Category must be set to update the subcategory.",
                       })
                     : DisabledEditor.create({
                          text: "Category does not have any subcategories.",
                       }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Overtime",
               key: AssignmentsColumnKey.OVERTIME,
               width: 100,
               minWidth: 50,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => formatBoolean(assignment.overtime)),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Work Days",
               key: AssignmentsColumnKey.WORK_DAYS,
               width: 100,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => {
                  return DateUtils.getFormattedWorkDays(assignment.work_days);
               }),
            }),
         },
         {
            visit: () => isProjectFieldVisible(AssignmentsColumnKey.ADDRESS_1),
            accept: () => ({
               header: "Project Address",
               key: AssignmentsColumnKey.ADDRESS_1,
               width: ColumnWidthDefault.ADDRESS_1,
               minWidth: ColumnWidthMin.ADDRESS_1,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.project.address_1 || ""),
            }),
         },
         {
            visit: () => isProjectFieldVisible(AssignmentsColumnKey.ADDRESS_2),
            accept: () => ({
               header: "Project Address 2",
               key: AssignmentsColumnKey.ADDRESS_2,
               width: ColumnWidthDefault.ADDRESS_2,
               minWidth: ColumnWidthMin.ADDRESS_2,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.project.address_2 || ""),
            }),
         },
         {
            visit: () => isProjectFieldVisible(AssignmentsColumnKey.CITY_TOWN),
            accept: () => ({
               header: "Project City",
               key: AssignmentsColumnKey.CITY_TOWN,
               width: ColumnWidthDefault.CITY_TOWN,
               minWidth: ColumnWidthMin.CITY_TOWN,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.project.city_town || ""),
            }),
         },
         {
            visit: () => isProjectFieldVisible(AssignmentsColumnKey.STATE_PROVINCE),
            accept: () => ({
               header: "Project State",
               key: AssignmentsColumnKey.STATE_PROVINCE,
               width: ColumnWidthDefault.STATE_PROVINCE,
               minWidth: ColumnWidthMin.STATE_PROVINCE,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.project.state_province || ""),
            }),
         },
         {
            visit: () => isProjectFieldVisible(AssignmentsColumnKey.COUNTRY),
            accept: () => ({
               header: "Project Country",
               key: AssignmentsColumnKey.COUNTRY,
               width: ColumnWidthDefault.COUNTRY,
               minWidth: ColumnWidthMin.COUNTRY,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.project.country || ""),
            }),
         },
         {
            visit: () => isProjectFieldVisible(AssignmentsColumnKey.ZIPCODE),
            accept: () => ({
               header: "Project Postal",
               key: AssignmentsColumnKey.ZIPCODE,
               width: ColumnWidthDefault.ZIPCODE,
               minWidth: ColumnWidthMin.ZIPCODE,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.project.zipcode || ""),
            }),
         },
      ];
      return templateVisitors
         .filter((visitor) => visitor.visit())
         .map((visitor) => visitor.accept());
   }

   private createRowIdentifierColumnGroups(): Array<GridColumnGroup<SerializedFindAssignment>> {
      return createColumnGroupForEach(
         {
            header: "Name",
            key: AssignmentsColumnKey.NAME,
            width: 140,
            cellFactory: TextCell.factory((assignment) => formatName(assignment.person.name)),
         },
         {
            header: "Project",
            key: AssignmentsColumnKey.PROJECT_NAME,
            width: 160,
            cellFactory: TextCell.factory((assignment) => assignment.project.name),
         },
         {
            header: "Start Date",
            key: AssignmentsColumnKey.START_DAY,
            width: 70,
            cellFactory: TextCell.factory((assignment) =>
               this.formatDetachedDay(assignment.start_day),
            ),
         },
         {
            header: "End Date",
            key: AssignmentsColumnKey.END_DAY,
            width: 70,
            cellFactory: TextCell.factory((assignment) =>
               this.formatDetachedDay(assignment.end_day),
            ),
         },
      );
   }

   private reload(params: { startFromCurrentCursor: boolean }) {
      this.store(this.createStore(params));
      // This is an async control flow for the inital load
      // and then is useless past that.
      this.viewConfigured(true);
      this.selectedIds([]);
   }

   private createStore({ startFromCurrentCursor }: { startFromCurrentCursor: boolean }) {
      return new AssignmentsList2GridStore({
         cacheKey: `${authManager.selectedGroupId()}-assignments-list`,
         customFields: pureComputed(() => this.columnManager()!.entityCustomFields()),
         errorModalColumnGroups: this.createRowIdentifierColumnGroups(),
         queryParams: this.createQueryParams(),
         startFromCurrentCursor,
         columnManager: this.columnManager,
      });
   }

   private createQueryParams(): FindAssignmentsPaginatedQueryParams {
      const sortOrder = this.sortOrder();
      const [column, customFieldId, customFieldEntity] = sortOrder.columnKey.split(":");
      let sortBy: FindAssignmentsSortBy;

      if (sortOrder.columnKey === "name") {
         sortBy = authManager.authedUser()?.preferences()?.displayLastNamesFirst()
            ? FindAssignmentsSortBy.PERSON_NAME_LAST
            : FindAssignmentsSortBy.PERSON_NAME_FIRST;
      } else if (column === "custom_fields" && customFieldEntity) {
         if (customFieldEntity === "people") {
            sortBy = FindAssignmentsSortBy.PERSON_CUSTOM_FIELD;
         } else if (customFieldEntity === "projects") {
            sortBy = FindAssignmentsSortBy.PROJECT_CUSTOM_FIELD;
         } else {
            sortBy = FindAssignmentsSortBy.ASSIGNMENT_CUSTOM_FIELD;
         }
      } else {
         sortBy = sortOrder.columnKey as FindAssignmentsSortBy;
      }
      return {
         ...(customFieldId ? { custom_field_id: customFieldId } : {}),
         filters: serializedFilters(this.filterChips(), "name"),
         group_id:
            authManager.selectedGroupId() != "my-groups"
               ? authManager.selectedGroupId()
               : undefined,
         sort_by: sortBy,
         sort_direction: this.sortOrder().direction,
         limit: ITEMS_PER_REQUEST,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
   }

   private customFieldIsVisible(columnHeader: ColumnHeader): boolean {
      const meta = columnHeader.meta();
      if (!meta) return false;
      if (meta.field_type == "currency") {
         const canViewFinancials = this.canViewFinancialCustomField(meta.field_entity);
         if (!canViewFinancials) {
            return false;
         }
         // Intentionally fall through to check sensitivity even after passing
         // the financials check.
      }
      return this.canViewSensitiveOrFieldIsNotSensitive(meta.field_entity, meta.field_property);
   }

   private canViewFinancialCustomField(entityType: ColumnEntityType): boolean {
      switch (entityType) {
         // No financial Assignments permissions yet.
         case ColumnEntityType.ASSIGNMENTS:
            return true;
         case ColumnEntityType.PEOPLE:
            return this.canViewPeopleFinancials;
         case ColumnEntityType.PROJECTS:
            return this.canViewProjectFinancials;
         // Default to the safe option of not showing field.
         default:
            return false;
      }
   }

   private canViewSensitiveOrFieldIsNotSensitive(
      entityType: ColumnEntityType,
      fieldName: string,
   ): boolean {
      switch (entityType) {
         // No sensitive Assignments fields yet.
         case ColumnEntityType.ASSIGNMENTS:
            return true;
         case ColumnEntityType.PEOPLE:
            return this.canViewPeopleSensitive
               ? true
               : !authManager.peopleSensitiveFields().includes(fieldName);
         case ColumnEntityType.PROJECTS:
            return this.canViewProjectSensitive
               ? true
               : !authManager.projectsSensitiveFields().includes(fieldName);
         // Default to the safe option of not showing field.
         default:
            return false;
      }
   }

   private customFieldValueExtractor(
      assignment: SerializedFindAssignment,
      columnHeader: ColumnHeader,
   ) {
      const fieldId = columnHeader.meta()!.field_id;
      const customFields = this.getCustomFieldsForEntity(
         assignment,
         columnHeader.meta()!.field_entity,
      );
      return customFields.find((f) => f.field_id == fieldId)?.value ?? null;
   }

   private getCustomFieldsForEntity(
      assignment: SerializedFindAssignment,
      entityType: ColumnEntityType,
   ): SerializedCustomFieldInstance[] {
      switch (entityType) {
         case ColumnEntityType.ASSIGNMENTS:
            return assignment.custom_fields;
         case ColumnEntityType.PEOPLE:
            return assignment.person.custom_fields;
         case ColumnEntityType.PROJECTS:
            return assignment.project.custom_fields;
         default:
            return [];
      }
   }

   private projectRolesExtractor(
      assignment: SerializedFindAssignment,
      positionId: string,
   ): ProjectRole[] {
      const roles = assignment.project.roles || [];
      return roles
         .filter((role) => role.job_title_id == positionId)
         .map((role) => ({
            id: role.id,
            personName: role.person.name ? formatName(role.person.name) || "" : "",
            personId: role.person_id,
         }));
   }

   private formatDetachedDay(day: number) {
      return DateUtils.formatDetachedDay(day, defaultStore.getDateFormat());
   }

   private hasSubcategoryOptions(row: SerializedFindAssignment) {
      if (!row.category) return false;
      const category = row.project.categories.find((category) => category.id === row.category?.id);
      return category?.subcategories?.length ?? 0 > 0;
   }

   private defaultCursorStateProvider = (assignment: SerializedFindAssignment) => {
      return this.store().canEditAssignment(assignment)
         ? GridCursorState.ACTIONABLE
         : GridCursorState.ACTIONABLE_DISABLED;
   };

   private protectedEditorFactory<TValue = unknown, TSaveValue = TValue>({
      assignment,
      cursorState,
      editorFactory,
   }: {
      assignment: SerializedFindAssignment;
      cursorState: GridCursorState;
      editorFactory: EditorComponentFactory<SerializedFindAssignment, TValue, TSaveValue>;
   }) {
      return cursorState == GridCursorState.ACTIONABLE
         ? editorFactory([assignment])
         : DISABLED_EDITOR_CANNOT_EDIT_ASSINGMENT;
   }
}
